<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px"  >
    <div *ngIf="!isFormReady" class="card overflow-auto -mt-16">
        <div style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      background: white;">
            <mat-spinner color="primary"> </mat-spinner>
        </div>
    </div>

    <form (ngSubmit)="changePassword()" [formGroup]="form" @fadeInUp class="" fxFlex="auto">

        <div fxLayout="row" fxLayoutAlign="between center" class="bloc-header my-3">
            <h3 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 rtl:border-l" fxFlex="none">
                {{"Change Password" | translate}}
            </h3>
        </div>

        <mat-divider class="-mx-12 text-border"></mat-divider>

        <div class="input-bloc w-full">

            <div class="customInput">
                <span class="confirm-form-title flex-auto">{{"Enter your old password"| translate}} </span>
                <mat-form-field class="sm:ml-6 w-full">
                    <input matInput [type]="inputType" formControlName="oldPassword"
                        placeholder="{{'Old password'| translate}}" [(ngModel)]="changePwdTemplate.oldPassword">
                    <button (click)="toggleVisibility()" mat-icon-button matSuffix
                        matTooltip="Toggle Visibility" type="button">
                        <mat-icon *ngIf="visible" [icIcon]="icVisibility"></mat-icon>
                        <mat-icon *ngIf="!visible" [icIcon]="icVisibilityOff"></mat-icon>
                    </button>
                    <mat-error *ngIf="f?.oldPassword?.errors?.required">{{"This field is required"| translate}} </mat-error>
                </mat-form-field>
            </div>

            <div class="customInput">
                <span class="confirm-form-title flex-auto">{{"New password" | translate}} </span>
                <mat-form-field class="sm:ml-6 w-full">
                    <input matInput [type]="inputType" formControlName="newPassword"
                        placeholder="{{'new password'| translate}}" [(ngModel)]="changePwdTemplate.newPassword"
                        (ngModelChange)="onCheckConfirmNewPwd()">
                    <button (click)="toggleVisibility()" mat-icon-button matSuffix
                        matTooltip="Toggle Visibility" type="button">
                        <mat-icon *ngIf="visible" [icIcon]="icVisibility"></mat-icon>
                        <mat-icon *ngIf="!visible" [icIcon]="icVisibilityOff"></mat-icon>
                    </button>
                    <mat-error *ngIf="f?.newPassword?.errors?.required">{{"This field is required" | translate}} </mat-error>
                </mat-form-field>
            </div>

            <div class="customInput">
                <span class="confirm-form-title flex-auto">{{"Confirm New password"| translate}} </span>
                <mat-form-field class="sm:ml-6 w-full">
                    <input matInput [type]="inputType" formControlName="confNewPassword"
                        placeholder="{{'Confirm new password'| translate}}" [(ngModel)]="changePwdTemplate.confNewPassword"
                        (ngModelChange)="onCheckConfirmNewPwd()">
                    <button (click)="toggleVisibility()" mat-icon-button matSuffix
                        matTooltip="Toggle Visibility" type="button">
                        <mat-icon *ngIf="visible" [icIcon]="icVisibility"></mat-icon>
                        <mat-icon *ngIf="!visible" [icIcon]="icVisibilityOff"></mat-icon>
                    </button>
                    <mat-error *ngIf="f?.confNewPassword?.errors?.required">
                        {{"This field is required" | translate}}
                    </mat-error>
                </mat-form-field>
                <mat-error *ngIf="hasNewPwdInputError && checkNewPwdErrorMessage">
                    <span class="identical">
                        {{ checkNewPwdErrorMessage| translate }}
                    </span>
                </mat-error>
            </div>

            <div class="2xl:alert-error error-bloc" *ngIf="errorMessage && !form.valid">
                <span>{{errorMessage | translate}}</span>
            </div>

        </div>

        <div fxLayout="row" fxLayoutAlign="end center" class="">
            <button mat-flat-button [disabled]="!form.valid || hasNewPwdInputError" class="confirm-btn" type="submit"> {{"Save" | translate}} </button>
        </div>

    </form>

</div>