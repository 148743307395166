import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  ValidatorFn,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  USER_SESSION_KEY,
  SUMMARY_DATA_KEY,
  BUSINESS_DATA_KEY,
} from 'src/app/Models/constants';
import {
  User,
  MerchantData,
  SummaryData,
} from 'src/app/Models/models.interface';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { TransactionsService } from 'src/app/services/transactions.service';
import { ConfirmTransfersComponent } from '../../../confirm-transfers/confirm-transfers.component';
import { DisburseCashComponent } from '../../disburse-cash.component';

import icClose from '@iconify/icons-ic/twotone-close';

@Component({
  selector: 'vex-add-update-disbursement-modal',
  templateUrl: './add-update-disbursement-modal.component.html',
  styleUrls: ['./add-update-disbursement-modal.component.scss'],
})
export class AddUpdateDisbursementModalComponent implements OnInit, OnDestroy {
  mode: 'create' | 'update' = 'create';
  operators = [
    { name: 'MTN', value: 'mtn' },
    { name: 'MOOV', value: 'moov' },
    { name: 'VODAFONE', value: 'vodafone' },
    { name: 'AIRTEL-TIGO', value: 'airtel-tigo' },
  ];

  countryData = {
    BJ: {
      currency: 'XOF',
      code: '+229',
      operators: [{ name: 'MTN', value: 'mtn' }, { name: 'MOOV', value: 'moov' }],
    },
    CI: {
      currency: 'XOF',
      code: '+225',
      operators: [
        { name: 'MTN', value: 'mtn' },
        { name: 'ORANGE', value: 'orange' },
        { name: 'MOOV', value: 'moov' }
      ],
    },
    GH: {
      currency: 'GHS',
      code: '+233',
      operators: [
        { name: 'MTN', value: 'mtn' },
        { name: 'VODAFONE', value: 'vodafone' },
        { name: 'AIRTEL-TIGO', value: 'airtel-tigo' },
      ],
    },
    TG: {
      currency: 'XOF',
      code: '+227',
      operators: [{ name: 'MOOV', value: 'moov' }],
    },
    SN: {
      currency: 'XOF',
      code: '+221',
      operators: [
        { name: 'MTN', value: 'MTN' },
        { name: 'ORANGE', value: 'orange' },
      ],
    },
    NG: {
      currency: 'NGN',
      code: '+234',
      operators: [{ name: 'MTN', value: 'MTN' }],
    },
  };

  icClose = icClose;
  transferForm: FormGroup;
  currency: string = 'XOF';
  dailingCode: string = '+229';
  maxLength: number = 8;
  transferData: any;
  userData: User;
  module_id: any = '102';
  moduleData: Object[];
  userBusinessData: MerchantData;
  isDisbursing: boolean;
  unsubscribe$ = new Subject();
  credentials: string;
  hasError: boolean;
  errorMessage: string;
  phoneNumberValidationPattern = /^[0-9]{0,15}$/;
  hasPhoneInputError: boolean;
  checkPhoneErrorMessage: string;

  validationMessages = {
    repeat_phone_no: {
      pattern: 'Only digits allowed starting with ',
      required: 'Receiver\'s Phone Field  is required.',
      min: 'Please provide a correct phone number',
    },
    phone_no: {
      pattern: 'Only digits allowed starting with ',
      required: 'Receiver\'s Phone Field  is required.',
      min: 'Please provide a correct phone number',
      invalidPrefix: 'Invalid phone number prefix, Operator not found!',
    },
    amount: {
      pattern: 'Only decimal number allowed',
      required: 'Amount This Field  is required.',
    },
  };
  merchantSummaryData: SummaryData;
  placeHolder = '96040522';
  networkProviders: any[];
  @Input() country: any;
  operatorPrefixData: any;
  isFormReady = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<DisburseCashComponent>,
    private fb: FormBuilder,
    private transactionsService: TransactionsService,
    private snackBar: MatSnackBar,
    private router: Router,
    private dialog: MatDialog,
    private sharedData: SharedDataService
  ) {
    const sessionData = localStorage.getItem(USER_SESSION_KEY);
    this.userData = JSON.parse(sessionData);
    this.credentials = `${this.userData?.api_secret_key_live}:${this.userData?.api_public_key_live}`; 
    const summaryData = JSON.parse(localStorage.getItem(SUMMARY_DATA_KEY));
    this.merchantSummaryData = summaryData;

  }

  ngOnInit() {
    const businessData = localStorage.getItem(BUSINESS_DATA_KEY);
    this.userBusinessData = JSON.parse(businessData);
    //this.credentials = `${this.userBusinessData.api_secret_key_live}:${this.userBusinessData.api_public_key_live}`;
    this.mode = this.defaults?.customer ? 'update' : 'create';
    this.setOperatorDialingCodes();
    this.getModulesData(this.credentials);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initForm() {
    this.transferForm = this.fb.group({
      phone_no: [
        this.defaults?.customer?.phone || '',
        [
          Validators.required, 
          Validators.pattern(this.phoneNumberValidationPattern),
          Validators.min(8)
        ],
      ],
      repeat_phone_no: [
        this.defaults?.customer?.phone || '',
        [
          Validators.required,
          Validators.pattern(this.phoneNumberValidationPattern),
          Validators.min(8),
        ],
      ],
      name: [this.defaults?.customer?.name || ''],
      amount: [
        this.defaults?.customer?.amount || '',
        [Validators.required, Validators.pattern(/^\d+(\.\d+)?$/)],
      ],
      operator: [this.defaults?.customer?.network || 'mtn', Validators.required],
      object: [this.defaults.object || '']
    });

    console.log('[this.defaults?.country]', this.defaults?.country);

    this.transferForm.get('phone_no').valueChanges.subscribe((value) => {
      const prefixesData = this.operatorPrefixData.filter(
        (data) => data.country === this.defaults?.country
      );
      let isValidPrefix = prefixesData.some((data) =>
        data.prefixes.includes(value.substring(0, 2))
      );

      if (this.defaults?.country === 'GH') {
        isValidPrefix = prefixesData.some((data) =>
          data.prefixes.includes(value.substring(0, 3))
        );
      }
      if (isValidPrefix) {
        let selectedPrefixData = prefixesData.find((data) =>
          data.prefixes.includes(value.substring(0, 2))
        );

        if (this.defaults?.country === 'GH') {
          selectedPrefixData = prefixesData.find((data) =>
            data.prefixes.includes(value.substring(0, 3))
          );
        }

        const operator = selectedPrefixData?.operator;
        this.transferForm.get('operator').patchValue(operator);
      } else {
       this.transferForm.get('phone_no').setErrors({ invalidPrefix: true });
      }
      this.transferForm.updateValueAndValidity();
    });

    this.maxLength = this.getMaxLength(this.defaults?.country);
    this.currency = this.countryData[this.defaults?.country].currency;
    this.placeHolder = this.defaults?.country === 'BJ' ? '96040522' : '0544990518';
    this.operators = this.countryData[this.defaults?.country].operators;
  }

  validatePrefixlidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const prefixesData = this.operatorPrefixData.filter(
        (data) => data.country === this.defaults.country
      );
      prefixesData.forEach((prefixeData) => {
        if (!prefixeData.prefixes.split(',').includes(control.value.substring(0, 2))) {
          return { invalidPrefix: true };
        }
      });
      return null;
    };
  }

  setOperatorDialingCodes() {
    this.transactionsService
      .getOperatorDailingPrefixes(this.credentials)
      .pipe(take(1))
      .subscribe((response) => {
        if (response && response?.status === true) {
          this.operatorPrefixData = response.data;
          this.isFormReady = true;
          this.initForm();
        }
      });
  }

  getNetworkProviders(value) {
    return this.countryData[value].operators;
  }

  confirmTransfers() {
    this.dialog.open(ConfirmTransfersComponent);
    const fee = this.getPalFee(
      this.transferForm.value['amount'],
      this.transferForm.value['country']
    );
    const amount = parseFloat(this.transferForm.value['amount']);
    this.transferForm.get('amount').setValue(amount);

    if (!this.transferForm.value['object']) {
      this.transferForm
        .get('object')
        .setValue(this.userBusinessData?.business_legal_name);
    }
    // this.isDisbursing = true;
    this.transferData = {
      ...this.transferForm.value,
      currency: this.currency,
      module_id: this.module_id,
      user_id: this.userData.user_id,
      charges: fee,
      hasExceededFeeTransfers: this.hasExceededFeeTransfers,
    };
    this.sharedData.saveTransferData(this.transferData, this.credentials);
  }

  openSnackbar(message) {
    this.snackBar.open(message, 'CLOSE', {
      duration: 3000,
      horizontalPosition: 'right',
    });
  }

  get maxTransactionAmount() {
    return this.currency === 'XOF' ? 560000 : 6190;
  }

  get hasExceededFeeTransfers(): boolean {
    return (
      this.merchantSummaryData?.totalTransactionsAmount >
      this.maxTransactionAmount
    );
  }

  getPalFee(amount, country: string): number {
    const charges = this.userBusinessData.charges;
    if (this.hasExceededFeeTransfers && charges === 0) {
      switch (country) {
        case 'GH':
          return (amount * 0.5) / 100;
        case 'BJ':
          return (amount * 1) / 100;
        default:
          return 0;
      }
    } else if (this.hasExceededFeeTransfers && charges !== 0) {
      return (amount * charges) / 100;
    } else {
      return 0;
    }
  }

  getModulesData(credentials) {
    this.transactionsService
      .getModulesData(credentials)
      .pipe(take(1))
      .subscribe((data) => {
        this.moduleData = data;
        this.networkProviders = this.moduleData.map(
          (data: any) => data.operator
        );
      });
  }

  getMaxLength(country) {
    if (country === 'BJ') {
      return 8;
    } else {
      return 10;
    }
  }

  setCurrency(option) {
    this.country = option.value;
    this.currency = this.countryData[option.value].currency;
    this.dailingCode = this.countryData[option.value].code;
    // this.placeHolder = option.value === 'BJ' ? '96040522' : '0544990518';
    // this.transferForm.get("phone_no")?.setValue(this.dailingCode);

    if (option.value === 'BJ') {
      this.networkProviders = this.networkProviders.filter(
        (provider) => provider !== 'vodafone' && provider !== 'airtel-tigo'
      );
    }
    if (option.value === 'GH') {
      this.module_id = 103;
      this.transferForm.get('operator').setValue('mtn');
    }
  }

  setSelectedModule(option) {
    const selectedModule = this.moduleData.find((data) => {
      return (
        data['country'] === this.country &&
        data['currency'] === this.currency &&
        data['operator'] === option.value
      );
    });
    this.module_id = selectedModule['id'];
  }

  close() {
    this.dialogRef.close();
  }

  onCheckConfirmNumber = () => {
    if (
      this.transferForm.value['phone_no'] ===
      this.transferForm.value['repeat_phone_no']
    ) {
      this.hasPhoneInputError = false;
    } else {
      this.hasPhoneInputError = true;
      this.checkPhoneErrorMessage = 'phone numbers must be identical';
    }
  }

  get phoneNumber(): AbstractControl {
    return this.transferForm.controls['phone_no'];
  }

  get confirmPhoneNumber(): AbstractControl {
    return this.transferForm.controls['repeat_phone_no'];
  }

  save() {
    if (this.isAllowed('6') || this.isAllowed('1')) {
      if (this.mode === 'create') {
        this.addDisbursement();
      } else if (this.mode === 'update') {
        this.updateDisbursement();
      }
    }else{
      this.openSnackbar("You are not allowed");
    }
    
  }
  isAllowed(habl: string) {

    let habilitations = []
    let isAllow = false
    if (this.userData.secondaryAccount) {
      if (this.userData.habilitations != undefined) {
        habilitations = this.userData.habilitations.split(',')
        for (let hab of habilitations) {
          if (hab == habl) {
            isAllow = true
          }
        }
      }
    } else {
      isAllow = true
    }
    return isAllow
  }

  addDisbursement() {
    const disbursement = this.transferForm.value;
    const filteredDisbursement = {
      phone: disbursement.phone_no,
      amount: disbursement.amount,
      name: this.defaults?.customer?.name || '',
      network: disbursement.operator,
      index: this.defaults?.customer?.index,
      object: this.defaults.object
    };

    this.dialogRef.close(filteredDisbursement);
  }

  updateDisbursement() {
    const disbursement = this.transferForm.value;
    const filteredDisbursement = {
      phone: disbursement.phone_no,
      amount: disbursement.amount,
      name: this.defaults?.customer?.name,
      network: disbursement.operator,
      index: this.defaults?.customer?.index,
      object: this.defaults.object
    };

    this.dialogRef.close(filteredDisbursement);
  }

  get isCreateMode() {
    return this.mode === 'create';
  }

  get isUpdateMode() {
    return this.mode === 'update';
  }
}
