<div class="">
  <mat-toolbar class="" >
    <div class="primary-btns">
        <button mat-button *ngFor="let option of this.menu.options" color="{{option.value==this.menu.current.value?'primary':''}}" (click)="selectOption(option)">{{option.name}}</button>
    </div>
    <div class="secondary-btns">
     
    </div>
  </mat-toolbar>
  <mat-divider class="-mx-6 text-border toolbar-mat-divider"></mat-divider>
  <div class="mx-8">
    <div class="mt-8">
      <div *ngIf="this.menu.current.value=='live_api_keys'">
        <div class="input-row">
          <div class="customInput">
            <span>Live Secret Key</span>
            <mat-form-field>
              <input
                matInput
                [type]="hide ? 'password' : 'text'"
                #api_secret_key_live
                [value]="this.userData?.api_secret_key_live"
                readonly
              />
              <button
                mat-icon-button
                matSuffix
                (click)="hide = changeIsibility(hide)"
                [attr.aria-lavel]="'hide password'"
                [attr.aria-pressed]="hide"
              >
                <ic-icon [icon]="hide ? icHidden : icVisible"></ic-icon>
              </button>
              <button
                *ngIf="!hide"
                mat-icon-button
                matSuffix
                placement="top"
                ngbPopover="Copied!"
                (click)="copyInputMessage(api_secret_key_live)"
              >
                <ic-icon [icon]="icCopy"></ic-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
        <div class="input-row">
          <div class="customInput">
            <span>Live Public Key</span>
            <mat-form-field>
              <input
                matInput
                [type]="pubhide ? 'password' : 'text'"
                #api_public_key_live
                [value]="this.userData?.api_public_key_live"
                readonly
              />
              <button
                mat-icon-button
                matSuffix
                (click)="pubhide = changeIsibility(pubhide)"
                [attr.aria-lavel]="'hide password'"
                [attr.aria-pressed]="pubhide"
              >
                <ic-icon [icon]="pubhide ? icHidden : icVisible"></ic-icon>
              </button>
              <button
              *ngIf="!pubhide"
                mat-icon-button
                matSuffix
                matTooltip="Copy"
                (click)="copyInputMessage(api_public_key_live)"
              >
                <ic-icon [icon]="icCopy"></ic-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
        <div class="input-row">
          <div class="customInput">
            <span>User ID</span>
            <mat-form-field>
              <!-- <mat-label class="text-xs text-gray-300">User Id</mat-label> -->
              <input
                #user_id
                matInput
                [type]="userhide ? 'password' : 'text'"
                [value]="this.userData?.user_id"
                readonly
              />
              <button
                mat-icon-button
                matSuffix
                (click)="userhide = changeIsibility(userhide)"
                [attr.aria-lavel]="'hide password'"
                [attr.aria-pressed]="userhide"
              >
                <ic-icon [icon]="userhide ? icHidden : icVisible"></ic-icon>
              </button>
              <button
                mat-icon-button
                *ngIf="!userhide"
                matSuffix
                matTooltip="Copy"
                (click)="copyInputMessage(user_id)"
              >
                <ic-icon [icon]="icCopy"></ic-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
        <div class="input-row">
          <div class="customInput">
            <span>Callback URL</span>
            <mat-form-field>
              <!-- <mat-label class="text-xs text-gray-300">callback_url</mat-label> -->
              <input
                [(ngModel)]="callbackURL"
                matInput
                [value]="userBusinessData?.callback_url"
                [readonly]="!edit"
              />
              <button
                mat-icon-button
                matSuffix
                matTooltip="Copy"
                (click)="edit = changeIsibility(edit)"
              >
                <ic-icon [icon]="icEdit"></ic-icon>
              </button>
            </mat-form-field>
            <button
              *ngIf="edit"
              mat-raised-button
              color="primary"
              [disabled]=""
              (click)="updateCallbackUrl(callbackURL)"
            >
              {{ isUpdating ? "Saving..." : "Save Changes" }}
            </button>
          </div>
        </div>
      </div>
  
      <div *ngIf="this.menu.current.value=='test_api_keys'">
        <div class="input-row">
          <div class="customInput">
            <span>Test Secret Key</span>
            <mat-form-field>
              <input
                matInput
                #api_secret_key_test
                [type]="testsecrethide ? 'password' : 'text'"
                [value]="this.userData?.api_secret_key_test"
                readonly
              />
              <button
                mat-icon-button
                matSuffix
                (click)="testsecrethide = changeIsibility(testsecrethide)"
                [attr.aria-lavel]="'hide password'"
                [attr.aria-pressed]="testsecrethide"
              >
                <ic-icon [icon]="testsecrethide ? icHidden : icVisible"></ic-icon>
              </button>
              <button
                mat-icon-button
                matSuffix
                matTooltip="Copy"
                *ngIf="!testsecrethide"
                (click)="copyInputMessage(api_secret_key_test)"
              >
                <ic-icon [icon]="icCopy"></ic-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
        <div class="input-row">
          <div class="customInput">
            <span>Test Public Key</span>
            <mat-form-field>
              <input
                [type]="testpubhide ? 'password' : 'text'"
                #api_public_key_test
                matInput
                [value]="this.userData?.api_public_key_test"
                readonly
              />
              <button
                mat-icon-button
                matSuffix
                (click)="testpubhide = changeIsibility(testpubhide)"
                [attr.aria-lavel]="'hide password'"
                [attr.aria-pressed]="testpubhide"
              >
                <ic-icon [icon]="testpubhide ? icHidden : icVisible"></ic-icon>
              </button>
              <button
              *ngIf="!testpubhide"
                mat-icon-button
                matSuffix
                matTooltip="Copy"
                (click)="copyInputMessage(api_public_key_test)"
              >
                <ic-icon [icon]="icCopy"></ic-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="this.menu.current.value=='pal_api_doc'">
      
      <!--https://api.pals.africa/docs-->
        <a
        mat-raised-button
        color="primary"
        class="text-sm"
       href="https://documenter.getpostman.com/view/28415742/2s9Xy3rBGV"
        target="_blank"
      >
        View Api Docs
      </a>
    </div>
  </div>
  
</div>