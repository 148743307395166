<div class="card bg-primary" fxLayout="row" fxLayout.xs="column">
  <div class="p-4 ml-4" fxFlex="none" fxFlexAlign="center">
    <img src="assets/img/illustrations/checklist.svg" style="height: 168px; width: 152px">
  </div>

  <div class="p-6 text-primary-contrast" fxFlex="auto">
    <h2 class="headline m-0">Good Job, David!</h2>
    <p class="caption m-0 opacity-50">You've finished all of your tasks for this week.</p>

    <div class="mt-4" fxLayout="row" fxLayoutAlign="start center">
      <ic-icon [icon]="icCheckCircle" class="mr-2" size="24px"></ic-icon>
      <span class="body-2 cursor-pointer">Finish Dashboard Design</span>
    </div>

    <div class="mt-1" fxLayout="row" fxLayoutAlign="start center">
      <ic-icon [icon]="icCheckCircle" class="mr-2" size="24px"></ic-icon>
      <span class="body-2 cursor-pointer">Fix Issue #74</span>
    </div>

    <div class="mt-1" fxLayout="row" fxLayoutAlign="start center">
      <ic-icon [icon]="icCheckCircle" class="mr-2" size="24px"></ic-icon>
      <span class="body-2 cursor-pointer">Publish version 2.3.3</span>
    </div>
  </div>
</div>
