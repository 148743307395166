<vex-page-layout>



    <mat-toolbar class="" color="primary">
        <div class="primary-btns">
            <button mat-button *ngFor="let option of this.menu.options"
                color="{{option.value==this.menu.current.value?'primary':''}}"
                (click)="selectOption(option)">{{option.name |translate}}</button>
        </div>
        <div class="secondary-btns">
        </div>
    </mat-toolbar>



    <div *ngIf="this.menu.current.value=='payin'"> 
        <vex-collections></vex-collections>
    </div>
    <!--
    <vex-page-layout-content [class.container]="layoutCtrl.value === 'boxed'"
        [class.px-gutter]="layoutCtrl.value === 'fullwidth'" class="-mt-6" *ngIf="this.menu.current.value=='payin'">
 
        <div [@stagger]="true" class="w-full" vexContainer>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="30px">
                <div @fadeInUp class="card" fxFlex="auto">
                    <div *ngIf="!isFormReady" class="card overflow-auto -mt-16">
                        <div style="
              display: flex;
              justify-content: center;
              align-items: center;
              background: white;
            ">
                            <mat-spinner color="primary"> </mat-spinner>
                        </div>
                    </div>
                    <div class="table-btns-bar mt-7 ml-0 pl-0">
                        <button app mat-button class="px-1" (click)="openFilterFormPopup()">
                            {{"Filters Applied" |translate}} <mat-icon [icIcon]="icFilterList" class="ml-1 mb-1"
                                color="black"></mat-icon>
                        </button>
                        <button (click)="exportAsXlsx()" class="px-1" mat-button>
                            {{"Download" |translate}} <mat-icon class="ml-1 mb-1" color="black"
                                [icIcon]="icCloudDownload"></mat-icon>
                        </button>
                    </div>
                    <div class="mt-3">
                        <div class="bg-card rounded-full border px-4" fxFlex="250px" fxFlex.lt-md="auto" fxLayout="row"
                            fxLayoutAlign="start center">
                            <ic-icon [icIcon]="icSearch" size="28px"></ic-icon>
                            <input [formControl]="searchCtrl"
                                class="px-4 py-3 border-0 outline-none w-full bg-transparent"
                                placeholder="Enter trans ID to find..." type="search">
                        </div>
                    </div>
                </div>
            </div>
        </div>
 
        <br>
        <br>
        <br>
        <br>
        <div class="card overflow-auto -mt-16">
            <div *ngIf="isLoading" class="card overflow-auto -mt-16">
                <div style="
          display: flex;
          justify-content: center;
          align-items: center;
          background: white;
        ">
                    <mat-spinner color="primary"> </mat-spinner>
                </div>
            </div>

            <table *ngIf="!isLoading" mat-table [dataSource]="dataSource" class="w-full mat-elevation-z" @stagger
                matSort matTableExporter #exporter="matTableExporter">
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef>ID</th>
                    <td mat-cell *matCellDef="let element">{{ element.reference}}</td>
                </ng-container>


                <ng-container matColumnDef="created_at">
                    <th mat-header-cell *matHeaderCellDef> {{"Date & Time" |translate}} </th>
                    <mat-cell matTooltipPosition="after" [matTooltip]="element.formatedDate" *matCellDef="let element">
                        {{ element.created_at }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="country">
                    <th mat-header-cell *matHeaderCellDef>{{"Country" |translate}} </th>
                    <td mat-cell *matCellDef="let element">{{ element.country }}</td>
                </ng-container>

                <ng-container matColumnDef="operator">
                    <th mat-header-cell *matHeaderCellDef>Network</th>
                    <td mat-cell *matCellDef="let element">{{ element.operator }}</td>
                </ng-container>

                <ng-container matColumnDef="wallet">
                    <th mat-header-cell *matHeaderCellDef>{{"Wallet number" |translate}} </th>
                    <td mat-cell *matCellDef="let element">{{ element.phone_no }}</td>
                </ng-container>

                <ng-container matColumnDef="currency">
                    <th mat-header-cell *matHeaderCellDef>{{"Currency" |translate}} </th>
                    <td mat-cell *matCellDef="let element">{{ element.currency }}</td>
                </ng-container>

                <ng-container matColumnDef="amount_collected">
                    <th mat-header-cell *matHeaderCellDef>{{"Amount Collected" |translate}} </th>
                    <td mat-cell *matCellDef="let element">{{ element.amount }}</td>
                </ng-container>

                <ng-container matColumnDef="settled_amount">
                    <th mat-header-cell *matHeaderCellDef>{{ "Setttled Amount" |translate}} </th>
                    <td mat-cell *matCellDef="let element">{{ element.amount - element.charges }}</td>
                </ng-container>

                <ng-container matColumnDef="filter">
                    <th *matHeaderCellDef>
                        <button [matMenuTriggerFor]="columnFilterMenu" class="" fxFlex="none" mat-icon-button
                            matTooltip="Filter Columns" type="button">
                            <mat-icon [icIcon]="icFilterList" color="primary"></mat-icon>
                        </button>
                    </th>
                    <td mat-cell *matCellDef="let element"></td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>{{"Status" |translate }} </th>
                    <td mat-cell *matCellDef="let item">
                        <div [ngClass]="item.state?.cssClasses" class="px-2 py-1 my-4 text-xs font-medium rounded"
                            fxFlex="none">
                            <span> {{ item.state?.text }}</span>
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">
                        {{
                        errorMessage || "No Transaction Data Found Or Matching The filter" |translate
                        }}
                    </td>
                </tr>
            </table>

            <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize"
                class="sticky left-0"></mat-paginator>
        </div>
 
    </vex-page-layout-content>
    <mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
        <ng-container *ngFor="let column of columns">
            <button class="checkbox-item mat-menu-item" *ngIf="column.property!='filter'">
                <mat-checkbox (change)="toggleColumnVisibility(column, $event)" [(ngModel)]="column.visible"
                    [checked]="column.visible" color="primary" name="columnsFilter" ngDefaultControl>
                    {{ column.label }}
                </mat-checkbox>
            </button>
        </ng-container>
    </mat-menu>-->







     <!--PAY OUTS-->
    <div *ngIf="this.menu.current.value=='payouts'">
        <vex-mobile-transfers></vex-mobile-transfers>
    </div> 
    <!--
        <vex-page-layout-content [class.container]="layoutCtrl.value === 'boxed'"
        [class.px-gutter]="layoutCtrl.value === 'fullwidth'" class="-mt-6" *ngIf="this.menu.current.value=='payouts'">
        <div [@stagger]="true" class="w-full" vexContainer>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="30px">
                <div @fadeInUp class="card" fxFlex="auto">
                    <div *ngIf="!isFormReady" class="card overflow-auto -mt-16">
                        <div style="
                display: flex;
                justify-content: center;
                align-items: center;
                background: white;">
                            <mat-spinner color="primary"> </mat-spinner>
                        </div>
                    </div>
                    <div class="table-btns-bar mt-7 ml-0 pl-0">
                        <button app mat-button class="px-0" (click)="MopenFilterFormPopup()">
                            {{"Filters Applied" |translate}} <mat-icon [icIcon]="icFilterList" class="ml-1 mb-1"
                                color="black"></mat-icon>
                        </button>
                        <button (click)="MexportAsXlsx()" class="px-0" mat-button>
                            {{"Download" |translate}} <mat-icon class="ml-1 mb-1" color="black"
                                [icIcon]="icCloudDownload"></mat-icon>
                        </button>
                        <button mat-button type="submit" class="disburs" (click)="MopenDisburseMobilePopup()">
                            <mat-icon class="mr-3 text-white" matPrefix>add_circle_outlined</mat-icon>
                            {{"Wallet Transfer" |translate}}
                        </button>
                    </div>
                    <div class="mt-3">
                        <div class="bg-card rounded-full border px-4" fxFlex="250px" fxFlex.lt-md="auto" fxLayout="row"
                            fxLayoutAlign="start center">
                            <ic-icon [icIcon]="icSearch" size="28px"></ic-icon>
                            <input [formControl]="searchCtrl"
                                class="px-4 py-3 border-0 outline-none w-full bg-transparent"
                                placeholder="{{'Enter trans ID to find...' }}" type="search">
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <br />
        <br />
        <br />
        <br />

        <div class="card overflow-auto -mt-16">
            <div *ngIf="isLoading" class="card overflow-auto -mt-16">
                <div style="
              display: flex;
              justify-content: center;
              align-items: center;
              background: white;
            ">
                    <mat-spinner color="primary"> </mat-spinner>
                </div>
            </div>
            <table *ngIf="!isLoading" mat-table [dataSource]="MdataSource" class="w-full mat-elevation-z" @stagger
                matSort matTableExporter #exporter="matTableExporter">
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef>ID</th>
                    <td mat-cell *matCellDef="let element">{{ element.reference }}</td>
                </ng-container>
                <ng-container matColumnDef="created_at">
                    <th mat-header-cell *matHeaderCellDef>{{"Date & Time" |translate}} </th>
                    <mat-cell matTooltipPosition="after" [matTooltip]="element.formatedDate" *matCellDef="let element">
                        {{ element.created_at }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="country">
                    <th mat-header-cell *matHeaderCellDef>{{"Country" |translate}} </th>
                    <td mat-cell *matCellDef="let element">{{ element.country }}</td>
                </ng-container>
                <ng-container matColumnDef="provider">
                    <th mat-header-cell *matHeaderCellDef>{{"Network" |translate}} </th>
                    <td mat-cell *matCellDef="let element">{{ element.operator }}</td>
                </ng-container>
                <ng-container matColumnDef="wallet">
                    <th mat-header-cell *matHeaderCellDef>{{"Wallet number" |translate}} </th>
                    <td mat-cell *matCellDef="let element">{{ element.phone_no }}</td>
                </ng-container>
                <ng-container matColumnDef="charges">
                    <th mat-header-cell *matHeaderCellDef>{{"Pal Fee" |translate}} </th>
                    <td mat-cell *matCellDef="let element">{{ element.charges }}</td>
                </ng-container>
                <ng-container matColumnDef="currency">
                    <th mat-header-cell *matHeaderCellDef>{{"Currency"|translate }} </th>
                    <td mat-cell *matCellDef="let element">{{ element.currency }}</td>
                </ng-container>
                <ng-container matColumnDef="amount">
                    <th mat-header-cell *matHeaderCellDef>{{"Amount Sent" |translate}} </th>
                    <td mat-cell *matCellDef="let element">{{ element.amount }}</td>
                </ng-container>
                <ng-container matColumnDef="business_start_balance">
                    <th mat-header-cell *matHeaderCellDef>{{"Start Balance" |translate}} </th>
                    <td mat-cell *matCellDef="let element">{{ element.business_start_balance }}</td>
                </ng-container>
                <ng-container matColumnDef="business_end_balance">
                    <th mat-header-cell *matHeaderCellDef>{{"End Balance" |translate}} </th>
                    <td mat-cell *matCellDef="let element">{{ element.business_end_balance }}</td>
                </ng-container>
                <ng-container matColumnDef="network_transaction_ref">
                    <th mat-header-cell *matHeaderCellDef>{{"Reference" |translate}} </th>
                    <td mat-cell *matCellDef="let element">{{ element.network_transaction_ref }}</td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>{{"Status" |translate}} </th>
                    <td mat-cell *matCellDef="let item">
                        <div [ngClass]="item.state?.cssClasses" class="px-2 py-1 my-4 text-xs font-medium rounded"
                            fxFlex="none">
                            <span> {{ item.state?.text }}</span>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="filter">
                    <th *matHeaderCellDef>
                        <button [matMenuTriggerFor]="columnFilterMenu" class="" fxFlex="none" mat-icon-button
                            matTooltip="Filter Columns" type="button">
                            <mat-icon [icIcon]="icFilterList" color="primary"></mat-icon>
                        </button>
                    </th>
                    <td mat-cell *matCellDef="let element"></td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="MdisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: MdisplayedColumns"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">
                        {{
                        errorMessage || "No Transaction Data Found Or Matching The filter" |translate
                        }}
                    </td>
                </tr>
            </table>

            <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize"
                class="sticky left-0"></mat-paginator>
        </div>
    </vex-page-layout-content>
    <mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
        <ng-container *ngFor="let column of transactionColumns">
            <button class="checkbox-item mat-menu-item" *ngIf="column.property!='filter'">
                <mat-checkbox (change)="toggleColumnVisibility(column, $event)" [(ngModel)]="column.visible"
                    [checked]="column.visible" color="primary" name="columnsFilter" ngDefaultControl>
                    {{ column.label }}
                </mat-checkbox>
            </button>
        </ng-container>
    </mat-menu>
    <mat-menu #menuMakeTransfer="matMenu" id="disburse-popup" (close)="closeDisburseMenu()"
        (closed)="closeDisburseMenu()"
        class="z-10 w-50 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600 block"
        style="position: absolute; inset: 0 auto auto 0; margin: 0; transform: translate(23px, 70px);"
        data-popper-reference-hidden="" data-popper-escaped="" data-popper-placement="bottom">

        <ul class="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownInformationButton">
            <li (click)="MopenDisburseMobilePopup()">
                <a mat-button class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                    <span class="icon menu-item-icon"> 

                    </span>
                    <div class="disburse-content">
                        <h4>
                            {{"Disburse to Mobile wallet" |translate}}
                        </h4>
                        <p>{{"Send to mobile wallet or merchant account" |translate}} </p>
                    </div>
                </a>
            </li>

            <mat-divider></mat-divider>

            <li>
                <a [routerLink]="['/dashboards/make-transfer']" mat-button
                    class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                    <span class="icon menu-item-icon"> 
                    </span>
                    <div class="disburse-content">
                        <h4>
                            {{"Disburse to Bank account" |translate }}
                        </h4>
                        <p>{{"Send to bank account" |translate }} </p>
                    </div>
                </a>
            </li>

            <mat-divider></mat-divider>

            <li>
                <a [routerLink]="['/dashboards/bulk-disbursement']" (click)="toggleDisbursePopup()" mat-button
                    class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white ">
                    <span class="icon menu-item-icon">
                        <mat-icon class="" [icIcon]="icon_peapole"></mat-icon>
                    </span>
                    <div class=" disburse-content">
                        <h4>
                            {{"Bulk disbursement" |translate }}
                        </h4>
                        <p>{{"Send to multiple wallet at once" |translate }} </p>
                    </div>
                </a>
            </li>
        </ul>
    </mat-menu>
    -->




    <!--BANK TRANSACTIONS-->
    <div *ngIf="this.menu.current.value=='all_transfers'">
        <vex-bank-transfers></vex-bank-transfers>
    </div>
   





    <!--EXCHANGES-->
    <div *ngIf="this.menu.current.value=='all_swaps'">
        <vex-swaps></vex-swaps>
    </div>
    


 


</vex-page-layout>




 
 