

<div #originRef (click)="showPopover(originRef)" [class.bg-hover]="dropdownOpen"
  class="relative flex items-center py-1 pl-3 pr-1 rounded cursor-pointer select-none trans-ease-out hover:bg-hover"
  matRipple>
  
  <!-- s -->
  <div class="flex flex-auto items-center justify-center"> 
    <div class="flex items-center justify-center rounded-full h-9 w-9 text-primary bg-primary-light">
      <mat-icon *ngIf="!userBusinessData?.business_logo" class="notification-icon text-teal" [icIcon]="icDomain"></mat-icon>
      <img *ngIf="userBusinessData?.business_logo" class="rounded-full h-9 w-9 " [src]="userPhoto" alt="" > 
    </div>
    <mat-icon class="arrow">arrow_drop_down</mat-icon> 
  </div>
</div>
