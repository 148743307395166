import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import icClose from '@iconify/icons-ic/twotone-close';
import moment, { now } from 'moment';
import { COUNTRIES } from 'src/app/Models/constants';
import peopole from '@iconify/icons-ic/people-outline';

@Component({
  selector: 'vex-transaction-report-filter-form',
  templateUrl: './transaction-report-filter-form.component.html',
  styleUrls: ['./transaction-report-filter-form.component.scss']
})
export class TransactionReportFilterFormComponent implements OnInit {

 
  icClose = icClose;
  icon_peapole = peopole;


  form: FormGroup;


  statuses = [
    { name: 'Pending', value: 1 },
    { name: 'Completed', value: 3 },
    { name: 'Error', value: 4 },
    { name: 'Network Error', value: 6 },
    { name: 'Processing', value: 2 },
    { name: 'Cancelled', value: 0 },
  ];
  operators = [
    { name: 'MTN', value: 'mtn' },
    { name: 'MOOV', value: 'moov' },
    { name: 'VODAFONE', value: 'vodafone' },
    { name: 'AIRTEL-TIGO', value: 'airtel-tigo' },
  ];
  countries = COUNTRIES;
  availableCountries = [
    { code: 'GH', label: 'GHANA' },
    { code: 'BJ', label: 'BENIN' },
    { code: 'CI', label: `COTE D'IVOIRE` }
  ];
  networkProviders = ['mtn', 'orange'];
  currencies = ['GHS', 'XOF', 'XAF', 'NGN'];
  constructor(private formBuilder: FormBuilder,private dialogRef: MatDialogRef<TransactionReportFilterFormComponent>) { 
    this.form = this.formBuilder.group({
      country: [],
      status: [],
      dateFrom: [moment(now()).subtract(1,'months').toDate(), [Validators.required]],
      dateTo: [moment(now()).toDate(), [Validators.required]],
      currency: [],
      operator: [],
    });
  }

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close(this.form); //on close pass data to parent
   }

}
