<vex-page-layout-content [class.container]="layoutCtrl.value === 'boxed'"
    [class.px-gutter]="layoutCtrl.value === 'fullwidth'" class="-mt-6">
    <!--[@stagger]="true"-->
    <div class="w-full" vexContainer>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="30px">
            <!--@fadeInUp-->
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <div class="card" fxFlex="auto">
                
            </div>
        </div>
    </div>

    <div class="card overflow-auto -mt-16">

        

        <!--@stagger-->
        <table mat-table [dataSource]="exchangeDataSource" class="w-full mat-elevation-z" Exchangesort>
            <ng-container matColumnDef="reference">
                <th mat-header-cell *matHeaderCellDef>ID</th>
                <td mat-cell *matCellDef="let element">{{ element.id }}</td>
            </ng-container>
            <ng-container matColumnDef="created_at">
                <th mat-header-cell *matHeaderCellDef>{{ "Date & Time" | translate}}</th>
                <mat-cell *matCellDef="let element">
                    {{ formattedDate(element.created_at) }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="start_currency">
                <th mat-header-cell *matHeaderCellDef>{{"Start Currency" | translate}} </th>
                <td mat-cell *matCellDef="let element">{{ element.start_currency }}</td>
            </ng-container>

            <ng-container matColumnDef="end_currency">
                <th mat-header-cell *matHeaderCellDef>{{"End Currency" | translate}} </th>
                <td mat-cell *matCellDef="let element">{{ element.end_currency }}</td>
            </ng-container>

            <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef>{{"Amount"| translate}} </th>
                <td mat-cell *matCellDef="let element">{{ element.amount }} {{ element.start_currency }}</td>
            </ng-container>
            <ng-container matColumnDef="amount_swap">
                <th mat-header-cell *matHeaderCellDef>{{"Amount Swap" | translate}} </th>
                <td mat-cell *matCellDef="let element">{{ element.amount_swap }} {{ element.end_currency }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="exchangeDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: exchangeDisplayedColumns"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">
                    {{
                    errorMessage || "No Exchange Data Found " | translate
                    }}
                </td>
            </tr>
        </table>

        <div *ngIf="isLoading" class="card overflow-auto -mt-16">
            <br><br> <br>
            <div style=" display: flex; justify-content: center; align-items: center; background: white;">
                <mat-spinner color="primary"> </mat-spinner>
            </div>
        </div>


        <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" class="sticky left-0"></mat-paginator>
    </div>
</vex-page-layout-content>