<hr style="border-color: rgba(0, 0, 0, 0.169);">
<div class="container mt-5 pt-3 ms-5 me-5 pl-5 pr-5">

    <div class="ms-5 me-5 pl-5 pr-5" style="display: flex; flex-wrap: wrap; margin-left: 20px; margin-right: 20px;">
        <div style="flex: 1 1 300px; margin-right: 10px;">
            <div style="width: 300px; height: 240px; border: 1px solid #ccc; border-radius: 4px; margin-top: 20px;">
                <div style="padding: 10px;">
                    <strong>{{"Mobile Wallets" | translate}} </strong>
                </div>
                <div style="padding: 10px;"><br><br>
                    {{"Send Cash to mobile wallets and merchant accounts instantly" | translate}}
                </div>
                <hr style="border-color: rgba(0, 0, 0, 0.201); margin-top: 35px">
                <div style="padding: 10px; background-color: #f5f5f5; cursor: pointer;"
                    (click)="openDisburseMobilePopup()">
                    <strong>{{"Send" | translate}} </strong>
                    <mat-icon [svgIcon]="'arrow'" class="menu-item-icon mr-1 "
                    style="position: relative; top: 7px; left: 221px;" matListIcon aria-hidden="false"></mat-icon>
                </div>
            </div>
        </div>
        <div style="flex: 1 1 300px; margin-right: 10px;">
            <div style="width: 300px; height: 240px; border: 1px solid #ccc; border-radius: 4px;  margin-top: 20px;">
                <div style="padding: 10px;">
                    <strong>{{"Bulk Payment" | translate}} </strong>
                </div>
                <div style="padding: 10px;"><br><br>
                    {{"Send Cash to more than one receipient"| translate}}
                </div>
                <hr style="border-color: rgba(0, 0, 0, 0.201); margin-top: 55px">
                <div style="padding: 10px; background-color: #f5f5f5; cursor: pointer;"
                    (click)="toggleBulkDisbursePopup()">
                    <strong>{{"Send"| translate}} </strong>
                    <mat-icon [svgIcon]="'arrow'" class="menu-item-icon mr-1 "
                    style="position: relative; top: 7px; left: 221px;" matListIcon aria-hidden="false"></mat-icon>
                </div>
            </div>
        </div>
        <div style="flex: 1 1 300px;">
            <div style="width: 300px; height: 240px; border: 1px solid #ccc; border-radius: 4px;  margin-top: 20px;">
                <div style="padding: 10px;">
                    <strong>{{"Bank Account" | translate}} </strong>
                </div>
                <div style="padding: 10px;"> <br><br>
                    {{"Send Cash to 8+ counties bank account" | translate}}
                </div>
                <hr style="border-color: rgba(0, 0, 0, 0.201); margin-top: 55px;">
                <div style="padding: 10px; background-color: #f5f5f5; cursor: pointer;"
                    (click)="openDisburseBankPopup()">
                    <strong>{{"Send" | translate}} </strong>


                    <strong>
                        <mat-icon [svgIcon]="'arrow'" class="menu-item-icon mr-1 "
                        style="position: relative; top: 7px; left: 221px; " matListIcon aria-hidden="false"></mat-icon>
                    </strong>

                </div>
            </div>
        </div>
    </div>


</div>