import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { fadeInUp400ms } from '../../../../../@vex/animations/fade-in-up.animation';
import { stagger40ms } from '../../../../../@vex/animations/stagger.animation';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { Customer } from '../../../apps/aio-table/interfaces/customer.model';
import { TableColumn } from '../../../../../@vex/interfaces/table-column.interface';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { aioTableData, aioTableLabels } from '../../../../../static-data/aio-table-data';
import { BUSINESS_DATA_KEY, COUNTRIES, SUMMARY_DATA_KEY, TRANSACTION_TABLE_LABELS, USER_SESSION_KEY } from '../../../../Models/constants';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableExporterDirective } from 'mat-table-exporter';
import { SummaryData } from '../../../../Models/models.interface';
import { AuthserviceService } from '../../../../services/authservice.service';
import { Router } from '@angular/router';
import { TransactionsService } from '../../../../services/transactions.service';
import { takeUntil } from 'rxjs/operators';
import moment from 'moment';

import icEdit from '@iconify/icons-ic/twotone-edit';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icSearch from '@iconify/icons-ic/twotone-search';
import icAdd from '@iconify/icons-ic/twotone-add';
import icFilterList from '@iconify/icons-ic/twotone-filter-list';
import icPhone from '@iconify/icons-ic/twotone-phone';
import icMail from '@iconify/icons-ic/twotone-mail';
import icMap from '@iconify/icons-ic/twotone-map';
import icMoreHoriz from '@iconify/icons-ic/twotone-more-horiz';
import icFolder from '@iconify/icons-ic/twotone-folder';
import icDateRange from '@iconify/icons-ic/twotone-date-range';
import icPerson from '@iconify/icons-ic/twotone-person';
import icRefresh from '@iconify/icons-ic/twotone-refresh';
import icBook from '@iconify/icons-ic/twotone-book';
import icCloudDownload from '@iconify/icons-ic/twotone-cloud-download';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DisburseCashComponent } from '../../disburse-cash/disburse-cash.component';
import { FilterFormComponent } from './filter-form/filter-form.component';
import { AddBeneficiaryComponent } from './add-beneficiary/add-beneficiary.component';
import { MatMenu } from '@angular/material/menu';
import peopole from '@iconify/icons-ic/people-outline';
import { BeneficiariesService } from 'src/app/services/beneficiaries.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SuspendedBusinessModalComponent } from '../../suspended-business-modal/suspended-business-modal.component';
import { NoBusinessModalComponent } from '../../no-business-modal/no-business-modal.component';
import { PendingBusinessModalComponent } from '../../pending-business-modal/pending-business-modal.component';

@Component({
  selector: 'vex-all-transfers',
  templateUrl: './all-transfers.component.html',
  styleUrls: ['./all-transfers.component.scss'],
  animations: [fadeInUp400ms, stagger40ms],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'standard',
      } as MatFormFieldDefaultOptions,
    },
  ],
})
export class AllTransfersComponent implements OnInit, OnDestroy, AfterViewInit {
  layoutCtrl = new FormControl('boxed');

  /**
   * Simulating a service with HTTP that returns Observables
   * You probably want to remove this and do all requests in a service with HTTP
   */
  subject$: ReplaySubject<Customer[]> = new ReplaySubject<Customer[]>(1);
  data$: Observable<Customer[]> = this.subject$.asObservable();
  unsubscribe$ = new Subject();
  @Input()
  transfersColumns: TableColumn<Customer>[] = [

    { label: 'ID', property: 'reference', type: 'text', visible: true },
    {
      label: 'date',
      property: 'created_at',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    // {
    //   label: 'Country',
    //   property: 'country',
    //   type: 'text',
    //   visible: true,
    //   cssClasses: ['font-medium'],
    // },
    {
      label: 'Bank',
      property: 'bank_name',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Account No',
      property: 'account_number',
      type: 'text',
      visible: true,
    },
    {
      label: 'Swift Code',
      property: 'swift_code',
      type: 'text',
      visible: false,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Beneficiary',
      property: 'account_name',
      type: 'text',
      visible: false,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Amount Sent',
      property: 'amount',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Status',
      property: 'status',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Filter',
      property: 'filter',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
  ];
  transactionsDisplayedColumns: string[] = [];
  trasactionColsOrder: string[] = [];

  beneficiariesDisplayedColumns: string[] = [

    'beneficiary_name',
    'bank_name',
    'account_number',
    'swift_code',
    'beneficiary_country',
    'options',

  ];
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  transactionsDataSource: MatTableDataSource<any> | null;
  beneficiariesDataSource: MatTableDataSource<any> | null;
  selection = new SelectionModel<any>(true, []);
  searchCtrl = new FormControl();
  searchCtrlBeneficiary = new FormControl();
  exportOptions = {
    fileName: 'test',
    sheet: { reportsProps: { Author: 'PAL' } },
  };

  labels = aioTableLabels;
  form: FormGroup;

  statusLabels = TRANSACTION_TABLE_LABELS;
  banksData: any;

  statuses = [
    { name: 'Pending', value: 1 },
    { name: 'Completed', value: 3 },
    { name: 'Failed', value: 4 },
    { name: 'Network Error', value: 6 },
    { name: 'Processing', value: 2 },
    { name: 'Cancelled', value: 0 },
  ];
  operators = [
    { name: 'MTN', value: 'mtn' },
    { name: 'MOOV', value: 'moov' },
    { name: 'VODAFONE', value: 'vodafone' },
    { name: 'AIRTEL-TIGO', value: 'airtel-tigo' },
  ];
  countries = COUNTRIES;
  availableCountries = ['GH', 'BJ', 'CI', 'NGN'];
  networkProviders = ['mtn', 'orange'];
  currencies = ['GHS', 'XOF', 'XAF', 'NGN'];
  userData: any;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild(MatTableExporterDirective)
  matTableExporter: MatTableExporterDirective;

  @ViewChild('menuMakeTransfer', { static: true })
  menuMakeTransfer: MatMenu;

  transactionsData: any;
  beneficiariesData: any;
  hasNoTransactions: boolean;
  isLoading: boolean = false;
  merchantSummaryData: SummaryData;

  userSessionData: any;
  hasError = false;
  errorMessage: string;

  customers: any;
  users: any;
  products: any;
  transactions: any;
  userBusinessData: any;
  credentials: string;

  icPhone = icPhone;
  icMail = icMail;
  icMap = icMap;
  icEdit = icEdit;
  icSearch = icSearch;
  icPerson = icPerson;
  icDelete = icDelete;
  icAdd = icAdd;
  icFilterList = icFilterList;
  icMoreHoriz = icMoreHoriz;
  icFolder = icFolder;
  icDateRange = icDateRange;
  icBook = icBook;
  icRefresh = icRefresh;
  icCloudDownload = icCloudDownload;
  icon_peapole = peopole;

  menu: { current: { name: string, value: string }, options: { name: string, value: string }[] } = {
    current: { name: 'All Transfert', value: 'all_transfers' },
    options: [
      { name: 'All Transfers', value: "all_transfers" },
      { name: 'Beneficiaries', value: "beneficiaries" },
    ]
  }; 
  numberOfBeneficiaries = 0; 
  isOpenDisbursePopup = false; 
  beneficiaryOperationData: {
    message: string;
    selectedBankBeneficiary: any
  } = { message: null, selectedBankBeneficiary: null };

  constructor(
    private authService: AuthserviceService,
    private router: Router,
    private fb: FormBuilder,
    private transactionService: TransactionsService,
    private beneficiariesService: BeneficiariesService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
    this.transfersColumns.forEach(
      e => {
        if (e.visible) {
          this.transactionsDisplayedColumns.push(e.property);
        }
      }
    )
    const user = localStorage.getItem('current_user');
    const sessionData = JSON.parse(localStorage.getItem(USER_SESSION_KEY));
    this.userData = sessionData;
    this.credentials = `${this.userData?.api_secret_key_live}:${this.userData?.api_public_key_live}`;

    this.merchantSummaryData = JSON.parse(localStorage.getItem(SUMMARY_DATA_KEY));

    const businessData = localStorage.getItem(BUSINESS_DATA_KEY);
    if (businessData !== 'undefined') {
      this.userBusinessData = JSON.parse(businessData);
    }
    //this.credentials = `${this.userBusinessData?.api_secret_key_live}:${this.userBusinessData?.api_public_key_live}`;

    if (!sessionData) {
      router.navigate(['/auth/login']).then();
    }
  }

  get transfersVisibleColumns() {
    return this.transfersColumns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }

  /**
   * Example on how to get data and pass it to the table - usually you would want a dedicated service with a HTTP request for this
   * We are simulating this request here.
   */
  getData() {
    return of(aioTableData.map((customer) => new Customer(customer)));
  }

  onCreateTransfer() {
    if (this.userData.hasBusiness || !!this.userBusinessData) {

      /* VTX 21-08
            if (this.userBusinessData.status !== 'approved') {
              this.dialog.open(SuspendedBusinessModalComponent);
            } else {
              this.router.navigate(['/dashboards/make-transfer']);
            }
      */
      if (this.userBusinessData?.status === 'pending') {
        this.dialog.open(PendingBusinessModalComponent);
      } else if (this.userBusinessData?.status === 'suspended') {
        this.dialog.open(SuspendedBusinessModalComponent);
      } else {
        this.router.navigate(['/dashboards/make-transfer']).then();
      }


      this.isOpenDisbursePopup = false;
    } else {
      this.dialog.open(NoBusinessModalComponent);
    }
  }

  exportAsXlsx() {

    if (this.isAllowed("7")) {
      this.matTableExporter.exportTable('xlsx', {
        fileName: 'Transactions Report',
        sheet: 'report',
        Props: { Author: 'PAL Africa' },
      });
    } else {
      this.openSnackbar("You are not allowed");
    }

  }
  isAllowed(habl: string) {

    let habilitations = []
    let isAllow = false
    if (this.userData.secondaryAccount) {
      if (this.userData.habilitations != undefined) {
        habilitations = this.userData.habilitations.split(',')
        for (let hab of habilitations) {
          if (hab == habl) {
            isAllow = true
          }
        }
      }
    } else {
      isAllow = true
    }
    return isAllow
  }

  refreshData() {
    this.getTransactionsList();

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit() {
    this.form = this.fb.group({
      country: [''],
      dateFrom: [''],
      dateTo: [''],
      currency: [''],
      status: [''],
    });

    this.getBeneficiariesList();
    this.searchTransfers();

    this.searchCtrl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => this.onFilterChange(value));

    this.searchCtrlBeneficiary.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => this.onFilterChangeBeneficiary(value));
  }

  resetForm() {
    this.form = this.fb.group({
      country: [''],
      dateFrom: [''],
      dateTo: [''],
      currency: [''],
      status: [''],
    });
    this.getTransactionsList();
  }

  getTransactionsList(filteredData = null) {
    this.isLoading = true;
    // userId = 'a9twRK1JpPPQDrB6hNvfAr2ju682' this is a test User_uid
    if (filteredData) {
      const transactions = filteredData;
      this.isLoading = false;
      this.transactionsData = transactions?.map((details) => {
        details.state = this.getStatusLabel(details.state);
        // details.palFee = this.getPalFee(details.amount, details.country);
        details.formatedDate = moment(details.created_at).fromNow();
        // details.country = this.getCountryName(details.country);
        return details;
      });

      this.hasNoTransactions = transactions.length === 0;
      this.transactionsDataSource = new MatTableDataSource(this.transactionsData);
      this.transactionsDataSource.paginator = this.paginator;
      this.transactionsDataSource.sort = this.sort;
    } else {
      this.transactionService
        .getUserTransactions(this.userData?.user_id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (transactions) => {
            this.isLoading = false;
            this.transactionsData = transactions?.map((details) => {
              details.state = this.getStatusLabel(details.state);
              // details.palFee = this.getPalFee(details.amount, details.country);
              details.formatedDate = moment(details.created_at).fromNow();
              details.country = this.getCountryName(details.country);
              return details;
            });

            this.hasNoTransactions = transactions.length === 0;
            this.transactionsDataSource = new MatTableDataSource(this.transactionsData);
            this.transactionsDataSource.paginator = this.paginator;
            this.transactionsDataSource.sort = this.sort;
          },
          (error) => {
            this.isLoading = false;
            console.error(error.message);
          }
        );
    }
  }

  get isFormReady(): boolean {
    return true;
  }

  getCountryName(countryCode: string): string {
    const countryData = this.countries.find(
      (country) => country.code === countryCode
    );
    return countryData?.name;
  }

  getBankName(code: string) {
    const bank = this.banksData.find(
      (data) => data.code === code
    );
    return bank.name || 'N/A';
  }

  ngAfterViewInit() {
    this.transactionsDataSource.paginator = this.paginator;
    this.transactionsDataSource.sort = this.sort;
  }

  searchTransfers() {
    if (this.form.value['dateFrom'] && this.form.value['dateTo']) {
      const from = new Date(this.form.value['dateFrom'])
        .toISOString()
        .slice(0, 19)
        .replace('T', ' ');
      const to = new Date(this.form.value['dateTo'])
        .toISOString()
        .slice(0, 19)
        .replace('T', ' ');
      // this.form.get("dateFrom").setValue(from);
      // this.form.get("dateTo").setValue(to);
      this.form.value['dateFrom'] = from;
      this.form.value['dateTo'] = to;
    }

    this.isLoading = true;
    this.transactionService
      .searchTransactions(
        this.credentials,
        this.userBusinessData?.user_id,
        this.form.value,
        'bank_transfers'
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => {
          this.isLoading = false;
          if (response['status'] === true) {
            this.transactions = response['data'];
            this.getTransactionsList(response['data']);
            this.transactionsDataSource = new MatTableDataSource();
            this.transactionsDataSource.data = this.transactions;
            this.transactionsDataSource.paginator = this.paginator;
            this.transactionsDataSource.sort = this.sort;
            if (!this.transactionsDataSource.data.length) {
              this.hasError = true;
              this.errorMessage =
                'No data Found for the specified search criteria. Please try with different data';
            } else {
              this.hasError = false;
              this.errorMessage = '';
            }
          } else {
            this.hasError = true;
            this.errorMessage =
              'No data Found for the specified search criteria. Please try with different data';
          }
        },
        (erro) => {
          this.isLoading = false;
          this.hasError = true;
          this.errorMessage =
            'No data Found for the specified search criteria. Please try with different data';
        }
      );
  }

  getStatusLabel(status: string) {
    return this.statusLabels.find((label) => label.text === status);
  }

  onFilterChange(value: string) {
    if (!this.transactionsDataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.transactionsDataSource.filter = value;
  }

  onFilterChangeBeneficiary(value: string) {
    if (!this.beneficiariesDataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.beneficiariesDataSource.filter = value;
  }

  toggleColumnVisibilityTrasactions(column, event) {

    if (!column.visible) {
      this.transactionsDisplayedColumns = this.transactionsDisplayedColumns.filter(col => column.property !== col)
    } else {
      let columns = [column.property, ...this.transactionsDisplayedColumns]
      this.transactionsDisplayedColumns = this.transfersColumns.map(
        ordoredCol => columns.find(col => col == ordoredCol.property)
      ).filter(col => col)
    }
  }

  getFlag(country) {


    switch (country.toLowerCase()) {

      case "benin":
        return "<img src='assets/contryFlg/flags/1/bj.png'>"
      case "ghana":
        return "<img src='assets/contryFlg/flags/1/gh.png'>"
      case "togo":
        return "<img src='assets/contryFlg/flags/1/tg.png'>"
      case "nigeria":
        return "<img src='assets/contryFlg/flags/1/ng.png'>"
      case "cote d'ivoire":
        return "<img src='assets/contryFlg/flags/1/ci.png'>"
      case "senegal":
        return "<img src='assets/contryFlg/flags/1/se.png'>"
      case "mali":
        return "<img src='assets/contryFlg/flags/1/ml.png'>"
      case "niger":
        return "<img src='assets/contryFlg/flags/1/ne.png'>"
      case "côte d'ivoire":
        return "<img src='assets/contryFlg/flags/1/ci.png'>"
      case "guinea-bissau":
        return "<img src='assets/contryFlg/flags/1/gw.png'>"
      case "burkina faso":
        return "<img src='assets/contryFlg/flags/1/bf.png'>"
      default:
        return ''

    }

  }


  deleteOrder(selected: any[]) {

  }

  openDisburseMobilePopup() {
    // this.isOpenDisbursePopup = !this.isOpenDisbursePopup;

    const config: MatDialogConfig = {
      width: '600px'
    };
    const dialogRef = this.dialog.open(DisburseCashComponent, config);
  }

  openFilterFormPopup() {
    // this.isOpenDisbursePopup = !this.isOpenDisbursePopup;

    const config: MatDialogConfig = {
      width: '600px'
    };
    const dialogRef = this.dialog.open(FilterFormComponent, config);
    dialogRef.afterClosed().subscribe(form => {
      this.form = form;
      this.searchTransfers();
    })
  }

  openAddBeneficiaryPopup() {
    // this.isOpenDisbursePopup = !this.isOpenDisbursePopup;

    const config: MatDialogConfig = {
      width: '600px',
    };
    const dialogRef = this.dialog.open(AddBeneficiaryComponent, config);
    dialogRef.afterClosed().subscribe(response => {
      if (response.status == true) {
        this.openSnackbar(response.message);
        this.getBeneficiariesList();
      }else{
        if(response.status == false){
          this.openSnackbar(response.message || 'Error. Please retry again later.');
        }else{
          this.showInternalServerErrorMessage()
        }
      }
    });
  }

  openSnackbar(message) {
    this.snackBar.open(message, 'CLOSE', {
      duration: 3000,
      horizontalPosition: 'right',
    });
  }

  selectBankBeneficiary(id) {
    this.beneficiaryOperationData.selectedBankBeneficiary = id;
  }

  selectOption(current: { name: string, value: string }) {
    this.menu.current = current;
  }

  toggleDisbursePopup() {
    this.isOpenDisbursePopup = !this.isOpenDisbursePopup;
  }
  closeDisburseMenu() {
    this.isOpenDisbursePopup = false;
  }

  deleteBankBeneficiary(id: number) {
    this.beneficiariesService.deleteBankBeneficiary(this.credentials, { user_id: this.userData?.user_id, id: id })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => {
          this.openSnackbar(response.message);
          this.getBeneficiariesList();
        },
        (error) => {
          this.isLoading = false;
          this.showInternalServerErrorMessage()
        }
      );
  }
  showInternalServerErrorMessage() {
    this.errorMessage = "Error. Please retry again later."
    this.openSnackbar("Error. Please retry again later.");
  }


  getBeneficiariesList(filteredData = null) {
    this.isLoading = true;

    this.beneficiariesService.getBankBeneficiaries(this.userData?.user_id, this.credentials)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (beneficiaries) => {
          this.beneficiariesData = beneficiaries?.map((details) => {
            // details.palFee = this.getPalFee(details.amount, details.country);
            details.formatedDate = moment(details.created_at).fromNow();
            details.beneficiary_country = this.getCountryName(details.beneficiary_country);

            return details;
          });
          this.isLoading = false;
          this.beneficiariesDataSource = new MatTableDataSource(this.beneficiariesData);
          this.beneficiariesDataSource.paginator = this.paginator;
          this.beneficiariesDataSource.sort = this.sort;
          this.numberOfBeneficiaries = this.beneficiariesDataSource.data.length;
        },
        (error) => {
          this.isLoading = false;
          console.error(error.message);
        }
      );

  }



}
