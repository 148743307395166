import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Notification } from '../interfaces/notification.interface';
import { DateTime } from 'luxon';
import { trackById } from '../../../../utils/track-by';
import icSettings from '@iconify/icons-ic/twotone-settings';
import icChevronRight from '@iconify/icons-ic/twotone-chevron-right';
import icNotificationsOff from '@iconify/icons-ic/twotone-notifications-off';
import icClearAll from '@iconify/icons-ic/twotone-clear-all';
import icShoppingBasket from '@iconify/icons-ic/twotone-shopping-basket';
import icAccountCircle from '@iconify/icons-ic/twotone-account-circle';
import icInsertChart from '@iconify/icons-ic/twotone-insert-chart';
import icCheckCircle from '@iconify/icons-ic/twotone-check-circle';
import icDescription from '@iconify/icons-ic/twotone-description';
import icFeedback from '@iconify/icons-ic/twotone-feedback';
import icVerifiedUser from '@iconify/icons-ic/twotone-verified-user';
import icFileCopy from '@iconify/icons-ic/twotone-file-copy';
import { UserNotificationService } from 'src/app/services/user-notification.service';
import { BUSINESS_DATA_KEY, SUMMARY_DATA_KEY, USER_SESSION_KEY, } from 'src/app/Models/constants';
import { User } from 'src/app/Models/models.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'vex-toolbar-notifications-dropdown',
  templateUrl: './toolbar-notifications-dropdown.component.html',
  styleUrls: ['./toolbar-notifications-dropdown.component.scss']
})
export class ToolbarNotificationsDropdownComponent implements OnInit {

  @Output() closeButtonClicked: EventEmitter<void> = new EventEmitter<void>();
  userData: User;
  browserLanguage: string
  notifications: Notification[] = []
  /*
    notifications: Notification[] = [
      {
        id: '1',
        label: 'New Order Received',
        icon: icShoppingBasket,
        colorClass: 'text-primary',
        datetime: DateTime.local().minus({ hour: 1 })
      },
      {
        id: '2',
        label: 'New customer has registered',
        icon: icAccountCircle,
        colorClass: 'text-orange',
        datetime: DateTime.local().minus({ hour: 2 })
      },
      {
        id: '3',
        label: 'Campaign statistics are available',
        icon: icInsertChart,
        colorClass: 'text-purple',
        datetime: DateTime.local().minus({ hour: 5 })
      },
      {
        id: '4',
        label: 'Project has been approved',
        icon: icCheckCircle,
        colorClass: 'text-green',
        datetime: DateTime.local().minus({ hour: 9 })
      },
      {
        id: '5',
        label: 'Client reports are available',
        icon: icDescription,
        colorClass: 'text-primary',
        datetime: DateTime.local().minus({ hour: 30 })
      },
      {
        id: '6',
        label: 'New review received',
        icon: icFeedback,
        colorClass: 'text-orange',
        datetime: DateTime.local().minus({ hour: 40 }),
        read: true
      },
      {
        id: '7',
        label: '22 verified registrations',
        icon: icVerifiedUser,
        colorClass: 'text-green',
        datetime: DateTime.local().minus({ hour: 60 })
      },
      {
        id: '8',
        label: 'New files available',
        icon: icFileCopy,
        colorClass: 'text-amber',
        datetime: DateTime.local().minus({ hour: 90 })
      }
    ];*/

  icSettings = icSettings;
  icChevronRight = icChevronRight;
  icClearAll = icClearAll;
  icNotificationsOff = icNotificationsOff;
  trackById = trackById;

  constructor(
    private userNotificationService: UserNotificationService,
    private translate: TranslateService,
  ) {
    const sessionData = localStorage.getItem(USER_SESSION_KEY);
    this.userData = JSON.parse(sessionData);
    //this.credentials = `${this.userData?.api_secret_key_live}:${this.userData?.api_public_key_live}`; 


    
    // vtx TRADUCTION 
    this.browserLanguage = window.navigator.language;
    let lang = this.browserLanguage.split('-')
    if (lang[0] == 'en') {

    }
    if (lang[0] == 'fr') {
      translate.setDefaultLang(lang[0]);
      this.translate.use(lang[0]);
    }


  }

  ngOnInit() {
    this.getUserNotifications()
  }


  getUserNotifications() {

    let res = this.userNotificationService.getUserNotifications(this.userData.user_id).subscribe(
      (res) => {
        console.log(res)
        let notificationsP = res["data"].reverse()

        for (let notif of notificationsP) {
          const createdAtDateTime = DateTime.fromISO(notif.created_at);
          const hoursElapsed = DateTime.local().diff(createdAtDateTime).as('hours');
          this.notifications.push({
            id: notif.id,
            label: notif.titre,
            icon: icCheckCircle,
            colorClass: 'text-green',
            datetime: DateTime.local().minus({ hour: hoursElapsed })
          },)
        }
      }
    )
  }

  redirect() {
    this.closeButtonClicked.emit();
  }



}
