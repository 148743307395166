import { ChangeDetectorRef, Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import icGroup from '@iconify/icons-ic/twotone-group';
import icPageView from '@iconify/icons-ic/twotone-pageview';
import icCloudOff from '@iconify/icons-ic/twotone-cloud-off';
import icTimer from '@iconify/icons-ic/twotone-timer';
import { defaultChartOptions } from '../../../../@vex/utils/default-chart-options';
import {
  Order,
  tableSalesData,
} from '../../../../static-data/table-sales-data';
import { TableColumn } from '../../../../@vex/interfaces/table-column.interface';
import icMoreVert from '@iconify/icons-ic/twotone-more-vert';
import { TransactionsService } from 'src/app/services/transactions.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  BUSINESS_DATA_KEY,
  BUSINESS_PARAMETERS,
  BUSINESS_PARAMETERS_V2,
  SUMMARY_DATA_KEY,
  USER_SESSION_KEY,
  VIRTUAL_BANK_ACCOUNT,
} from 'src/app/Models/constants';
import { BusinessService } from 'src/app/services/business.service';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';


import { TranslateService } from '@ngx-translate/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CollaborationTeamService } from 'src/app/services/collaboration-team.service';
import { MatTableExporterDirective } from 'mat-table-exporter';
import { Customer } from '../../apps/aio-table/interfaces/customer.model';
import { MatMenu } from '@angular/material/menu';
import { RejectedBusinessModalComponent } from '../rejected-business-modal/rejected-business-modal.component';
import { SuspendedBusinessModalComponent } from '../suspended-business-modal/suspended-business-modal.component';
import { PendingBusinessModalComponent } from '../pending-business-modal/pending-business-modal.component';
import { NoBusinessModalComponent } from '../no-business-modal/no-business-modal.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'vex-dashboard-analytics',
  templateUrl: './dashboard-analytics.component.html',
  styleUrls: ['./dashboard-analytics.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
})
export class DashboardAnalyticsComponent implements OnInit, OnDestroy {
  tableColumns: TableColumn<Order>[] = [
    {
      label: '',
      property: 'status',
      type: 'badge',
    },
    {
      label: 'PRODUCT',
      property: 'name',
      type: 'text',
    },
    {
      label: '$ PRICE',
      property: 'price',
      type: 'text',
      cssClasses: ['font-medium'],
    },
    {
      label: 'DATE',
      property: 'timestamp',
      type: 'text',
      cssClasses: ['text-secondary'],
    },
  ];
  tableData = tableSalesData;

  series: ApexAxisChartSeries = [
    {
      name: 'Subscribers',
      data: [28, 40, 36, 0, 52, 38, 60, 55, 67, 33, 89, 44],
    },
  ];

  userSessionsSeries: ApexAxisChartSeries = [
    {
      name: 'Users',
      data: [10, 50, 26, 50, 38, 60, 50, 25, 61, 80, 40, 60],
    },
    {
      name: 'Sessions',
      data: [5, 21, 42, 70, 41, 20, 35, 50, 10, 15, 30, 50],
    },
  ];

  salesSeries: ApexAxisChartSeries = [
    {
      name: 'Sales',
      data: [28, 40, 36, 0, 52, 38, 60, 55, 99, 54, 38, 87],
    },
  ];
  pageViewsSeries: ApexAxisChartSeries = [
    {
      name: 'Page Views',
      data: [405, 800, 200, 600, 105, 788, 600, 204],
    },
  ];
  uniqueUsersSeries: ApexAxisChartSeries = [
    {
      name: 'Unique Users',
      data: [356, 806, 600, 754, 432, 854, 555, 1004],
    },
  ];
  uniqueUsersOptions = defaultChartOptions({
    chart: {
      type: 'area',
      height: 100,
    },
    colors: ['#ff9800'],
  });
  icGroup = icGroup;
  icPageView = icPageView;
  icCloudOff = icCloudOff;
  icTimer = icTimer;
  icMoreVert = icMoreVert;
  dateArray: string[];
  options: import('src/@vex/components/chart/chart.component').ApexOptions;
  currentMonthTransactions: any;
  unsubscribe$ = new Subject();
  userData: any;
  businessData :any;
  credentials: string;
  merchantSummaryData: any;
  userBusinessData: any;

  currentMonthAmount: any;
  balanceData: any;
  dataLoaded = false;
  collectionsBalanceData: any;
  isLoading: boolean = true;
  browserLanguage: string
  currentBalance: any = 0
  currentBalanceCurrency: any = "XOF"
  form = new FormGroup({
    currency: new FormControl('')
  })

  dataSource: MatTableDataSource<any> | null;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTableExporterDirective)
  matTableExporter: MatTableExporterDirective;
  secondariesAccount: any
  showOptions: boolean
  isDropdownOpen = false;
  hasTeam: boolean = false
  userRole = []

  checkBoxGroups = [


    {
      id: "1",
      roleTitle: "Admin",
      roleCheckBoxes: [
        { id: "1", text: "Will have all right as you", disabled: false, checked: false }
      ],
      disabled: false,
      checked: false
    },
    {
      id: "2",
      roleTitle: "Compliance Officer",
      roleCheckBoxes: [
        { id: "2", text: "Can edit business profile", disabled: false, checked: false },
      ],
      disabled: false,
      checked: false
    },
    {
      id: "3",
      roleTitle: "Account Manager",
      roleCheckBoxes: [
        { id: "3", text: "Can view balance", disabled: false, checked: false },
        { id: "4", text: "Can fund balance", disabled: false, checked: false },
        { id: "5", text: "Can view balance histories", disabled: false, checked: false },
        { id: "6", text: "Can create transaction", disabled: false, checked: false },
        { id: "7", text: "Can download report", disabled: false, checked: false },
      ],
      disabled: false,
      checked: false
    },
    {
      id: "4",
      roleTitle: "Developper",
      roleCheckBoxes: [
        { id: "8", text: "Can view API keys", disabled: false, checked: false },
      ],
      disabled: false,
      checked: false
    }


    /**
     *  {
      id: "1",
      roleTitle: "Admin Account Manager",
      roleCheckBoxes: [
        {
          title: "Admin authorization",
          checkBoxes: [
            { id: "1", text: "Will have all right as you", disabled: false, checked: false }
          ]
        }
      ],
      disabled: false,
      checked: false
    },
    {
      id: "2",
      roleTitle: "Costumer Support",
      roleCheckBoxes: [
        {
          title: "Business authorization",
          checkBoxes: [
            { id: "2", text: "Can edit business profile", disabled: false, checked: false },
          ]
        },
        {
          title: "Balance authorization",
          checkBoxes: [
            { id: "3", text: "Can view balance", disabled: false, checked: false },
            { id: "4", text: "Can fund balance", disabled: false, checked: false },
            { id: "5", text: "Can view balance histories", disabled: false, checked: false },
          ]
        },
        {
          title: "Transaction authorization",
          checkBoxes: [
            { id: "6", text: "Can create transaction", disabled: false, checked: false },
            { id: "7", text: "Can download report", disabled: false, checked: false },
          ]
        }

      ],
      disabled: false,
      checked: false
    },
    {
      id: "3",
      roleTitle: "Developper",
      roleCheckBoxes: [
        {
          title: "Developer authorization",
          checkBoxes: [
            { id: "8", text: "Can view API keys", disabled: false, checked: false },
          ]
        }
      ],
      disabled: false,
      checked: false
    }
     */
  ]


  columnFilterMenu: MatMenu;
  displayedColumns = ['first_name', 'last_name', 'email', 'role', 'status', 'action'];
  currencyControl = new FormControl();

  collectionParameters : any
  userVirtualAccount : any 
  //


  constructor(
    private cd: ChangeDetectorRef,
    private transactionService: TransactionsService,
    private service: BusinessService,
    private translate: TranslateService,
    private sanitizer: DomSanitizer,
    private iconRegistry: MatIconRegistry,
    private router: Router,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private collaborationTeamService: CollaborationTeamService,
    private dialog: MatDialog,  
  ) {


    this.form.get('currency')?.valueChanges.subscribe((value) => {
      this.currentBalanceCurrency = value; // Mettez à jour la variable
    });


    const sessionData = JSON.parse(localStorage.getItem(USER_SESSION_KEY));
    this.userData = sessionData;
    this.credentials = `${this.userData?.api_secret_key_live}:${this.userData?.api_public_key_live}`;

    const summaryData = JSON.parse(localStorage.getItem(SUMMARY_DATA_KEY));
    this.merchantSummaryData = summaryData;

    let businessDataString = localStorage.getItem(BUSINESS_DATA_KEY); 
    if(businessDataString != undefined && businessDataString != 'undefined'){ 
      const businessData = JSON.parse(businessDataString);
      this.businessData = businessData; 
    }
    


    // vtx TRADUCTION 
    this.browserLanguage = window.navigator.language;
    let lang = this.browserLanguage.split('-')
    if (lang[0] == 'en') { 
    }
    if (lang[0] == 'fr') {
      translate.setDefaultLang(lang[0]);
      this.translate.use(lang[0]);
    }


    iconRegistry.addSvgIcon(
      'bank-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/balance-account.svg')
    );
    iconRegistry.addSvgIcon(
      'phone',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/phone.svg')
    );
    iconRegistry.addSvgIcon(
      'profile-group',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/profile-group.svg')
    );
    iconRegistry.addSvgIcon(
      'naira',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/naira.svg')
    );
    iconRegistry.addSvgIcon(
      'arrow',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/arrow.svg')
    );
    iconRegistry.addSvgIcon(
      'payment',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/payment.svg')
    );
    iconRegistry.addSvgIcon(
      'more',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/more.svg')
    );
    iconRegistry.addSvgIcon(
      'send-mail',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/send-mail.svg')
    );
    iconRegistry.addSvgIcon(
      'delete',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/delete.svg')
    );
    iconRegistry.addSvgIcon(
      'send-mail2',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/send-mail2.svg')
    );
    iconRegistry.addSvgIcon(
      'send-mail3',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/send-mail3.svg')
    );
    iconRegistry.addSvgIcon(
      'refresh',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/refresh.svg')
    );



  }
  translateHeader(value) {
    return this.translate.get(value);
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  ngOnInit() {

    /*
    const data  = [
      { first_name: "RICHARD", last_name: "AKPA", email:"e@gmail.com" },
      { first_name: "JULIE", last_name: "SMITH", email:"e@gmail.com" },
      { first_name: "JULIE", last_name: "SMITH", email:"e@gmail.com" },
      { first_name: "JULIE", last_name: "SMITH", email:"e@gmail.com" },
      { first_name: "JULIE", last_name: "SMITH", email:"e@gmail.com" },
      // ... Ajoutez d'autres objets de données si nécessaire
    ];
    this.dataSource = new MatTableDataSource(data)
    */


    this.getUserBalances();
    this.getUserCollectionsBalances();
    this.getBusinessParameters(this.userData.user_id)
    this.getVirtualAccount(this.userData.user_id)
    this.getSecondariesAccount()
    this.form.patchValue({
      currency: this.currentBalanceCurrency,
    });

    setTimeout(() => {
      const businessData = localStorage.getItem(BUSINESS_DATA_KEY);
      if (businessData !== 'undefined') {
        this.userBusinessData = JSON.parse(businessData);
        //this.credentials = `${this.userBusinessData?.api_secret_key_live}:${this.userBusinessData?.api_public_key_live}`;
      }

      if (this.userBusinessData && this.credentials) {
        //VTX REMOVEthis.getTransactionsSummaryCurrentMonthData();
      }

      const temp = [
        {
          name: 'Subscribers',
          data: [55, 213, 55, 0, 213, 55, 33, 55],
        },
        {
          name: '',
        },
      ];
    }, 3000);
  }


  getBusinessParameters(user_id: any) { 
    this.service.getBusinessPrameters(user_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(response => { 
        if (response && response['status'] === true) { 
          localStorage.setItem(BUSINESS_PARAMETERS_V2, JSON.stringify(response['data']))
          this.collectionParameters = response['data']


           
          localStorage.setItem(BUSINESS_PARAMETERS, JSON.stringify(response['data']['collection']))
        } else {
          if (response['status'] === false) {
            //this.hasError = true;
           // this.errorMessage = response['message'];
          } else {
            //this.hasError = true;
            //this.showInternalServerErrorMessage()
          }
        }
      })
  }
  getVirtualAccount(user_id: any) {

    this.service.getVirtualAccount(user_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(response => { 
        if (response && response['status'] === true) {
          this.userVirtualAccount = response['data']  
          localStorage.setItem(VIRTUAL_BANK_ACCOUNT, JSON.stringify(this.userVirtualAccount))
          
        } else {
          if (response['status'] === false) { 
            //this.hasError = true;
            //this.errorMessage = response['message'];
          } else {
            //this.hasError = true;
            //this.showInternalServerErrorMessage()
          }
        }
      })
  }




  submitAction() {

  }
  changeAction(event: Event) {
    const selectedValue = (event.target as HTMLSelectElement).value;
    this.currentBalanceCurrency = selectedValue;
    /*
    let collectionB = 0
    if (!isNaN(this.collectionsBalanceData[selectedValue])) {
      collectionB = this.collectionsBalanceData[selectedValue]
    }
    */
    let balance = 0
    if (!isNaN(this.balanceData[selectedValue])) {
      balance = this.balanceData[selectedValue]
    }


    //this.currentBalance = collectionB + balance
    this.currentBalance = balance

    if (!this.currentBalance) {
      this.currentBalance = 0
    }

  }
  initailizeBalance() {
    //this.currentBalance = this.collectionsBalanceData[this.currentBalanceCurrency] + this.balanceData[this.currentBalanceCurrency]
    this.currentBalance = this.balanceData[this.currentBalanceCurrency]
    if (!this.currentBalance) {
      this.currentBalance = 0
    }
  }


  getUserCollectionsBalances() {
    this.service
      .getUserCollectionsBalances(this.userData.user_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response: any) => {
        if (response.data.length) {
          const balanceDataMap = this.toMap(response.data);
          Object.keys(balanceDataMap).forEach((currency) => {
            const balances = balanceDataMap[currency].map(
              (data) => data.balance
            );
            const result = balances?.reduce((acc: any, cur: any) => {
              return (balanceDataMap[currency] = acc + Number(cur));
            }, 0) as number;
            return result.toFixed(2);
          });
          this.collectionsBalanceData = balanceDataMap;
          this.initailizeBalance()
        }
      });
  }

  getUserBalances() {
    this.isLoading = true;
    this.service
      .getUserBalances(this.userData.user_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response: any) => {
        if (response.data.length) {
          const balanceDataMap = this.toMap(response.data);
          Object.keys(balanceDataMap).forEach((currency) => {
            const countries = balanceDataMap[currency].map(
              (data) => data.country
            );
            const filtered = balanceDataMap[currency].filter(
              ({ country }, index) => !countries.includes(country, index + 1)
            );
            const balances = filtered.map((data) => data.balance);

            const result = balances?.reduce((acc: any, cur: any) => {
              return (balanceDataMap[currency] = acc + Number(cur));
            }, 0) as number;
            return result.toFixed(2);
          });
          this.balanceData = balanceDataMap;
          this.initailizeBalance()
        }
      },
        (error) => {
          this.dataLoaded = false;
          this.isLoading = false;
        });
  }

  private toMap(balanceData) {
    const result = balanceData.reduce((dataMap, item) => {
      dataMap[item.currency] = dataMap[item.currency] || [];
      dataMap[item.currency].push(item);
      return dataMap;
    }, Object.create(null));

    return result;
  }


  getTransactionsSummaryCurrentMonthData() {
    this.isLoading = true;
    const date = new Date();
    const startDate = new Date(date.getFullYear(), date.getMonth(), 1)
      .toISOString()
      .slice(0, 19)
      .replace('T', ' ');
    const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0)
      .toISOString()
      .slice(0, 19)
      .replace('T', ' ');
    const range = {
      from: startDate,
      to: endDate,
    };
    this.transactionService
      .getUserTransactionSummary(
        this.userBusinessData?.user_id,
        this.credentials,
        range
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response: any) => {
        this.isLoading = false;
        const summaryData = response?.data;
        if (summaryData) {
          this.currentMonthTransactions = summaryData.totalTransactionsCount;
          this.currentMonthAmount = summaryData.totalTransactionsAmount;
          const seriesData = [];
          this.dateArray = Object.keys(summaryData.monthlyTransactions);
          Object.keys(summaryData.monthlyTransactions).forEach((_date: any) => {
            const dailyTransactions = summaryData.monthlyTransactions[_date].map(
              (transaction: any) => transaction.amount
            );
            let dailyRevenue = dailyTransactions.reduce(
              (acc, cur) => acc + Number(cur),
              0
            );
            dailyRevenue = parseFloat(dailyRevenue).toFixed(2);
            const dailyTransactionsCount =
              summaryData.monthlyTransactions[_date].length;
            seriesData.push(dailyTransactionsCount);
          });
          this.salesSeries = [
            {
              name: 'Disbursements',
              data: seriesData,
            },
          ];
          this.options = defaultChartOptions({
            grid: {
              show: true,
              strokeDashArray: 3,
              padding: {
                left: 16,
              },
            },
            chart: {
              type: 'bar',
              height: 300,
              sparkline: {
                enabled: false,
              },
              zoom: {
                enabled: false,
              },
            },
            stroke: {
              width: 4,
            },
            labels: this.dateArray,
            xaxis: {
              type: 'datetime',
              labels: {
                show: true,
              },
            },
            yaxis: {
              floating: false,
              decimalsInFloat: 0,
              labels: {
                show: true,
              },
            },
          });
        }
        this.dataLoaded = true;
        return summaryData;
      },
        (error) => {
          this.dataLoaded = false;
          this.isLoading = false;
          console.error(error.message);
        }
      );
  }



  openDisburseBankPopup() {
    if (this.isAllowed('6') || this.isAllowed('1')) {
      this.router.navigate(['/dashboards/make-transfer']).then();
    } else {
      this.openSnackbar("You are not allowed");
    }
  }

  openMakeTransferChoicePopup() {
    if (this.isAllowed('6') || this.isAllowed('1')) {

      if (this.userData.hasBusiness || !!this.businessData) {
        if (this.businessData?.status === 'pending') {
          this.dialog.open(PendingBusinessModalComponent);
        } else if (this.businessData?.status === 'suspended') {
          this.dialog.open(SuspendedBusinessModalComponent);
        }
        else if (this.businessData?.status === 'rejected') {
          this.dialog.open(RejectedBusinessModalComponent);
        } else {
          if(  this.collectionParameters.disbursment_status == 'allowed' ){
            this.router.navigate(['/dashboards/make-transfer-c']).then();
          }else{
            this.openSnackbar("You are not allowed")
          }
          
        }
      } else {
        this.dialog.open(NoBusinessModalComponent);
      }


    } else {
      this.openSnackbar("You are not allowed");
    }
  }

  toggleBulkDisbursePopup() {
    if (this.isAllowed('6') || this.isAllowed('1')) {
      this.router.navigate(['/dashboards/bulk-disbursement']).then();
    } else {
      this.openSnackbar("You are not allowed");
    }
  }
  openDisburseMobilePopup() {
    if (this.isAllowed('6') || this.isAllowed('1')) {
      this.router.navigate(['/dashboards/make-mobile-disbursment']).then();
    } else {
      this.openSnackbar("You are not allowed");
    }


  }

  openDisburseBankNigeriaPopup() {
    if (this.isAllowed('6') || this.isAllowed('1')) {

      if (this.userData.hasBusiness || !!this.businessData) {
        if (this.businessData?.status === 'pending') {
          this.dialog.open(PendingBusinessModalComponent);
        } else if (this.businessData?.status === 'suspended') {
          this.dialog.open(SuspendedBusinessModalComponent);
        }
        else if (this.businessData?.status === 'rejected') {
          this.dialog.open(RejectedBusinessModalComponent);
        } else {

          if(  this.collectionParameters.disbursment_status == 'allowed' ){
            this.router.navigate(['/dashboards/make-transfer', 'NG']).then();
          }else{
            this.openSnackbar("You are not allowed")
          }
          
        }
      } else {
        this.dialog.open(NoBusinessModalComponent);
      }


      
    } else {
      this.openSnackbar("You are not allowed");
    }
  }
  openExchangePupop() {
    if (this.isAllowed('6') || this.isAllowed('1')) {

      if (this.userData.hasBusiness || !!this.businessData) {
        if (this.businessData?.status === 'pending') {
          this.dialog.open(PendingBusinessModalComponent);
        } else if (this.businessData?.status === 'suspended') {
          this.dialog.open(SuspendedBusinessModalComponent);
        }
        else if (this.businessData?.status === 'rejected') {
          this.dialog.open(RejectedBusinessModalComponent);
        } else {
          if(this.collectionParameters.exchange_status == 'allowed'){
            this.router.navigate(['/dashboards/swap']).then();
          }else{
            this.openSnackbar("You are not allowed")
          } 
        }
      } else {
        this.dialog.open(NoBusinessModalComponent);
      }


      
    } else {
      this.openSnackbar("You are not allowed");
    }
  }
  openTeamCollaboratorsPopup(element?) {
    if (this.isAllowedToInvite('1')) {
      this.router.navigate(['/dashboards/team', element.id]).then();
    } else {
      this.openSnackbar("You are not allowed");
    }
  }
  openTeamCollaboratorsPopup2() {
    if (this.isAllowedToInvite('1')) {

      if (this.userData.hasBusiness || !!this.businessData) {
        if (this.businessData?.status === 'pending') {
          this.dialog.open(PendingBusinessModalComponent);
        } else if (this.businessData?.status === 'suspended') {
          this.dialog.open(SuspendedBusinessModalComponent);
        }
        else if (this.businessData?.status === 'rejected') {
          this.dialog.open(RejectedBusinessModalComponent);
        } else {
          this.router.navigate(['/dashboards/team']).then();
        }
      } else {
        this.dialog.open(NoBusinessModalComponent);
      }
 
    } else {
      this.openSnackbar("You are not allowed");
    }
  }

  openSnackbar(message) {
    this.snackBar.open(message, 'CLOSE', {
      duration: 5000,
      horizontalPosition: 'right',
    });
  }

  isAllowed(habl: string) {

    let habilitations = []
    let isAllow = false
    if (this.userData.secondaryAccount) {
      if (this.userData.habilitations != undefined) {
        habilitations = this.userData.habilitations.split(',')
        for (let hab of habilitations) {
          if (hab == habl) {
            isAllow = true
          }
        }
      }
    } else {
      isAllow = true
    }
    return isAllow
  }
  isAllowedToInvite(habl: string) {

    let habilitations = []
    let isAllow = false
    if (this.userData.secondaryAccount) {
      return false
      /*
      if (this.userData.habilitations != undefined) {
        habilitations = this.userData.habilitations.split(',')
        for (let hab of habilitations) {
          if (hab == habl) {
            isAllow = true
          }
        }
      }
      */
    } else {
      isAllow = true
    }
    return isAllow
  }

  getStatus(status) {
    if (status == "0") {
      return "Pending";
    }
    if (status == "1") {
      return "Joined";
    }
  }
  getSecondariesAccount() {
    this.dataSource = new MatTableDataSource();
    this.collaborationTeamService
      .getSecondariesAccount(
        this.credentials
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => {
          if (response['status'] === true) {
            //this.transactions = response['data'];
            this.secondariesAccount = response['data'].reverse()
            if (response['data'].length > 0) {
              this.hasTeam = true;
            }
            this.dataSource = new MatTableDataSource(this.secondariesAccount);

            let liste = []
            for (let secondaryAccount of this.secondariesAccount) {
              liste.push({ fullname: secondaryAccount.email, status: secondaryAccount.status, authorization: "A" })

            }

            this.userRole = []
            for (let secondaryAccount of this.secondariesAccount) {
              this.getSecondaryUserHabilitations(secondaryAccount.email)
            }


          }
        },
        (erro) => {

        }
      );

  }



  /*
    toggleDropdown() { 
      this.isDropdownOpen = !this.isDropdownOpen;
    }
  
    selectOption(option: string) { 
      // Logique à exécuter lorsque vous sélectionnez une option 
    }
    */
  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  closeDropdown() {
    this.isDropdownOpen = false;
  }
  getRole(user) {
    this.getSecondaryUserHabilitations(user.email)
  }
  getSecondaryUserHabilitations(email) {
    this.collaborationTeamService.getSecondaryUserHabilitation(email, this.credentials).subscribe(
      response => {
        let roleTilte: any
        let selectedUserHabilitations = response['data']['habilitations']
        if (selectedUserHabilitations != undefined) {
          selectedUserHabilitations = selectedUserHabilitations.split(',')
          for (let g of this.checkBoxGroups) {
            roleTilte = ""
            for (let role of g.roleCheckBoxes) {
              for (let hab of selectedUserHabilitations) {
                if (role.id == hab) {
                  roleTilte = g.roleTitle
                  role.checked = true
                }
              }
            }
            this.userRole.push({ email: email, role: roleTilte })


          }



        }
      }
    )
  }
  removeUser(user) {
    this.collaborationTeamService.removeSecondaryUser(user.email, this.credentials).subscribe(
      (response) => {
        if (response['status'] == true) {
          this.openSnackbar(response['message']);
          this.getSecondariesAccount()
        }
      },
      (error) => {
        this.showInternalServerErrorMessage()
      }
    )
  }
  showInternalServerErrorMessage() {
    this.openSnackbar("Error. Please retry again later.");
  }







}
