<vex-page-layout class="px-5 py-5 mb-5">
  <mat-toolbar class="py-3" color="primary">
    <div class="primary-btns ms-5">
      <h3 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 rtl:border-l" fxFlex="none" fxHide.xs>
        {{"Make Mobile Transfer" | translate}}
      </h3>
    </div>
  </mat-toolbar>
  <vex-page-layout-content [class.container]="layoutCtrl.value === 'boxed'"
    [class.px-gutter]="layoutCtrl.value === 'fullwidth'" class="-mt-6 pb-5 mb-5">

    <div [@stagger]="true" class="w-full" vexContainer>
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="30px">

        
        <form [formGroup]="transferForm" class="mt-5 formCostum">

          <mat-divider class="-mx-6 text-border"></mat-divider>
          <mat-dialog-content fxLayout="column" class="content-part mx-50">

            <div *ngIf="!confirmedTransfer">


              <div class="customInput  my-5" *ngIf="!selectBenefeficiary">
                <button (click)="onSelectBeneficiary()" class="btn centered-button"
                  style="background-color: #2BB996; color: white; height: 45px; ">
                  <div class="flex items-center flex-none speci-nav sidenav-toolbar">
                    <mat-icon [svgIcon]="'side-cash-in'" class="menu-item-icon mr-1" matListIcon
                      aria-hidden="false"></mat-icon>
                    <span> {{"Select a beneficiary" | translate}} </span>
                  </div>
                </button>
              </div> <br>



              <div class="input-row" *ngIf="!selectBenefeficiary">
                <mat-radio-group (change)="toggle($event)" [(ngModel)]="isMerchantNumber"
                  formControlName="is_merchant_transfer" color="primary" style="width: 100%;"
                  aria-label="Select an option">
                  <mat-radio-button class="" checked [value]="false">{{"Mobile Wallet" | translate}} </mat-radio-button>
                  <mat-radio-button class="" [value]="true">{{"Merchant Wallet"| translate}} </mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="customInput customSelect mt-5" *ngIf="selectBenefeficiary && !afterBeneficiarySelected">
                <span class="confirm-form-title flex-auto">{{"Select the beneficiary" | translate}} </span>
                <mat-form-field>
                  <mat-select placeholder="Select a Beneficiary" formControlName="beneficiary_id">
                    <mat-option *ngFor="let beneficiary of beneficiariesFormated" [value]="beneficiary.id">
                      {{beneficiary.full_name}} {{beneficiary.phone_no}} | {{getCountryNameByCode(beneficiary.country)}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>




              <div class="country" *ngIf="!selectBenefeficiary">
                <div class="grid grid-flow-col gap-4 grid-cols grid-rows">
                  <div class="label">
                    <label for="">{{"Select the country" | translate}} </label>
                  </div>
                </div>
                <mat-form-field class="">
                  <mat-select placeholder="Select a country" (selectionChange)="setCurrency($event)"
                    formControlName="country">
                    <mat-option value="BJ">
                      <div style="display: flex; align-items: center;">
                        <img src="assets/contryFlg/flags/1/bj.png"
                          style="height: 20px; width: auto; margin-right: 10px;">
                        BENIN
                      </div>
                    </mat-option>
                    <mat-option value="GH">
                      <div style="display: flex; align-items: center;">
                        <img src="assets/contryFlg/flags/1/gh.png"
                          style="height: 20px; width: auto; margin-right: 10px;">
                        GHANA
                      </div>
                    </mat-option>
                    <mat-option value="CI">
                      <div style="display: flex; align-items: center;">
                        <img src="assets/contryFlg/flags/1/ci.png"
                          style="height: 20px; width: auto; margin-right: 10px;">
                        COTE D'IVOIRE
                      </div>

                    </mat-option>
                    <mat-option value="SN">
                      <div style="display: flex; align-items: center;">
                        <img src="assets/contryFlg/flags/1/sn.png"
                          style="height: 20px; width: auto; margin-right: 10px;">
                        SENEGAL
                      </div> 
                    </mat-option>
                    <mat-option value="CM">
                      <div style="display: flex; align-items: center;">
                         <img src="assets/contryFlg/flags/1/cm.png"
                          style="height: 20px; width: auto; margin-right: 10px;"> 
                        CAMEROUN
                      </div> 
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="input-row" *ngIf="showAmountInput">
                <div class="customInput">
                  <div class="flex input-label">
                    <label for="">{{"How much to disburse?" | translate}} </label>
                  </div>
                  <mat-form-field class=" flex-auto w-full">
                    <div matPrefix class="currency-prefix">
                      <span>{{ currency }}</span>
                    </div>
                    <input matInput formControlName="amount" placeholder="">
                    <mat-error>
                      <span *ngFor="let error of transferForm.get('amount').errors | keyvalue">
                        {{ validationMessages.amount[error.key] }}
                      </span>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>


              <div class="input-row mb-2" *ngIf="!selectBenefeficiary">
                <div class="flex input-label" style="justify-content:space-between ;">
                  <label for="">{{isMerchantNumber?"Merchant Number":"Mobile wallet number" | translate}}</label>
                  <!--<div class="text-primary">{{ this.transferForm.get('operator').value }}</div>-->
                </div>
                <div class="flex"> 

                  
                  <div class="customSelect  " style="width: 75%;">
                    <mat-form-field class="flex-auto w-full">
                      <input (ngModelChange)="onCheckConfirmNumber()" matInput formControlName="phone_no" #input
                        type="tel" pattern="\d*" placeholder="{{ placeHolder }}" maxlength="{{ maxLength }}"
                        id="phone_no">
                      <mat-error>
                        <span *ngFor=" let error of transferForm?.get('phone_no').errors | keyvalue ">
                          {{ validationMessages.phone_no[error.key] }}
                        </span>
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class=" customSelect " style="width: 25%;">
                    <mat-form-field>
                      <mat-select placeholder="Select an Operator" formControlName="operator">
                        <mat-option *ngFor="let operator of avalaibleOperators" [value]="operator">
                          {{operator | uppercase}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field> 
                  </div> 


                </div>
              </div>


              <div class="mobile-number2" *ngIf="!selectBenefeficiary">
                <div class="">
                  <div class="flex input-label" style="justify-content:space-between ;">
                    <label for="">{{"Repeat" | translate}} {{isMerchantNumber?"Merchant Number":"Mobile wallet number" | translate}}</label>
                    <!--<div class="text-primary">{{ this.transferForm.get('operator').value }}</div>-->
                  </div>
                </div>
                <mat-form-field class="">
                  <input (ngModelChange)="onCheckConfirmNumber()" matInput formControlName="repeat_phone_no" #input
                    type="tel" pattern="\d*" placeholder="{{ placeHolder }}" maxlength="{{ maxLength }}">
                  <mat-error>
                    <span *ngFor=" let error of transferForm.get('repeat_phone_no').errors | keyvalue ">
                      {{ validationMessages.repeat_phone_no[error.key] }}
                    </span>
                  </mat-error>
                </mat-form-field>
                <mat-error>
                  <span class="identical" *ngIf="hasPhoneInputError">
                    {{ checkPhoneErrorMessage }}
                  </span>
                </mat-error>
              </div>
 
              <div class="purpose md:col-span-2" *ngIf="showTransferReasonInput">
                <div class="grid grid-flow-col gap-4 grid-cols grid-rows">
                  <div class="label">
                    <label for="" class="label">{{"Purpose of disbursement" | translate}} </label>
                  </div>
                </div>
                <mat-form-field class="">
                  <input matInput maxlength="20" type="text" formControlName="object" #input>
                </mat-form-field>
              </div>


            </div>

            <div class="" *ngIf="confirmedTransfer">
              <div class="approve-row">
                <div class="custom-content ">
                  <div class="flex approve-label">
                    <span class="">{{"Select the country" | translate}} </span>
                  </div>
                  <div class="approve-info">
                    <span>{{mobileTransfer?.country }}</span>
                  </div>
                </div>

                <div class="custom-content">
                  <div class="flex approve-label">
                    <span class="">{{"How much to disburse?" | translate}} </span>
                  </div>
                  <div class="approve-info">
                    <span><span>{{mobileTransfer?.amount}}</span> {{currency ? currency : "XOF"}}</span>
                  </div>
                </div>
              </div>

              <div class="approve-row">
                <div class="custom-content ">
                  <div class="flex approve-label">
                    <span class="">{{"Mobile wallet number" | translate}} </span>
                  </div>
                  <div class="approve-info">
                    <span>{{mobileTransfer?.mobileNumber}}</span>
                  </div>
                  <div class="network-displayed mt-1">
                    <div class="small-green-circle approve-green-circle"></div>
                    <span>{{ transferForm?.get('operator').value }}</span>
                  </div>
                </div>
                <div class="custom-content ">
                  <div class="flex approve-label">
                    <span class="">{{"Wallet Name" | translate}} </span>
                  </div>
                  <div class="approve-info">
                    <span *ngIf="isFetchingName">Fetching Client Name...</span>

                    <span *ngIf="clientName">
                      {{ clientName }}
                    </span>

                    <span *ngIf="!clientName && !isFetchingName">
                     {{" Warning:" | translate}} {{noNameErrorMessage}}, {{"Please try again" | translate}}
                    </span>
                  </div>
                </div>
              </div>
            </div>

          </mat-dialog-content>
          <mat-divider class="-mx-6 text-border"></mat-divider>
          <mat-dialog-actions class="btn-bloc">

            <button *ngIf="selectBenefeficiary && !confirmedTransfer" mat-flat-button type="button" class="cancel-btn"
              (click)="cancelSelectBenef()">
              {{"Cancel" | translate}}
            </button>

            <button *ngIf="confirmedTransfer" mat-flat-button type="button" class="cancel-btn" (click)="cancelAction()">
              {{"Back" | translate}}
            </button>


            <!-- 
              <button *ngIf="!selectBenefeficiary" mat-flat-button type="button" class="confirm-btn-raduis m-2 confirm-btn" (click)="onSelectBeneficiary()">
                        Select a beneficiary
                      </button>
            -->

            <button mat-flat-button type="submit"
              [disabled]="transferForm.invalid || hasPhoneInputError || isDisbursing" class="confirm-btn-raduis"
              [class.confirm-btn]="transferForm.valid && !hasPhoneInputError && !isDisbursing" (click)="submitAction()">
              {{submitBtnText | translate}}
            </button>
          </mat-dialog-actions>
        </form>

      </div>

    </div>
  </vex-page-layout-content>
</vex-page-layout>