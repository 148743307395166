import { Component, OnInit } from '@angular/core';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from '@angular/material/form-field';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';

import icClose from '@iconify/icons-ic/twotone-close';
import { TransactionsService } from 'src/app/services/transactions.service';
import { take, takeUntil } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { BUSINESS_DATA_KEY, COUNTRIES, USER_SESSION_KEY } from 'src/app/Models/constants';
import { BeneficiariesService } from 'src/app/services/beneficiaries.service';
import { color } from '@alyle/ui/color';
import { MatDialogRef } from '@angular/material/dialog';
//import { MobileTransfer } from '../../../disburse-cash/disburse-cash.component';
import { MerchantData, SummaryData, User } from 'src/app/Models/models.interface';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';

@Component({
  selector: 'vex-add-mobile-beneficiary-v2',
  templateUrl: './add-mobile-beneficiary-v2.component.html',
  styleUrls: ['./add-mobile-beneficiary-v2.component.scss']
})
export class AddMobileBeneficiaryV2Component implements OnInit {

  isProcessing: boolean = false
  unsubscribe$ = new Subject();
  icClose = icClose;
  form: FormGroup;
  countries = COUNTRIES;
  //bankTransfer: BankTransferData;
  banks = ['BSIC', 'NSIA', 'BOA', 'ECOBANK'];
  availableCountries = [
    { code: 'BJ', label: 'BENIN' },
    { code: 'CI', label: `COTE D'IVOIRE` },
    { code: 'TG', label: 'TOGO' },
    { code: 'SN', label: 'SENEGAL' },
    // { code: 'GH',  label: 'GHANA'},
    { code: 'ML', label: 'MALI' },
    { code: 'GW', label: 'GUINEE B.' },
    { code: 'NE', label: 'NIGER' },
    { code: 'BF', label: 'BURKINA FASO' },
  ];
  countryData = {
    BJ: {
      currency: 'XOF',
      code: '+229',
      operators: [
        { name: 'MTN', value: 'mtn' },
        { name: 'MOOV', value: 'moov' },
      ],
    },
    CI: {
      currency: 'XOF',
      code: '+225',
      operators: [
        { name: 'MTN', value: 'mtn' },
        { name: 'ORANGE', value: 'orange' },
        { name: 'MOOV', value: 'moov' },
      ],
    },
    GH: {
      currency: 'GHS',
      code: '+233',
      operators: [
        { name: 'MTN', value: 'mtn' },
        { name: 'VODAFONE', value: 'vodafone' },
        { name: 'AIRTEL-TIGO', value: 'airtel-tigo' },
      ],
    },
    TG: {
      currency: 'XOF',
      code: '+227',
      operators: [{ name: 'MOOV', value: 'moov' }],
    },
    SN: {
      currency: 'XOF',
      code: '+221',
      operators: [
        { name: 'MTN', value: 'MTN' },
        { name: 'ORANGE', value: 'orange' },
      ],
    },
    NG: {
      currency: 'NGN',
      code: '+234',
      operators: [
        { name: 'MTN', value: 'MTN' },
        { name: 'AIRTEL-TIGO', value: 'airtel-tigo' },
      ],
    },
  };

  operators = [
    { name: 'MTN', value: 'mtn' },
    { name: 'MOOV', value: 'moov' },
    { name: 'VODAFONE', value: 'vodafone' },
    { name: 'AIRTEL-TIGO', value: 'airtel-tigo' },
  ];
  networkProviders: any[];
  currencies = ['GHS', 'XOF', 'XAF', 'NGN'];
  bankBranches = [];
  confirmedTransfer = false;
  submitBtnText = 'Save Beneficiary';

  currency = 'XOF';
  dailingCode: string = '+229';
  maxLength: number = 8;
  transferData: any;
  userData: User;
  module_id: any = '102';
  moduleData: Object[];
  userBusinessData: MerchantData;
  isDisbursing: boolean;
  credentials: string;
  hasError: boolean;
  errorMessage: string;
  phoneNumberValidationPattern = /^[0-9]{8,10}$/;
  hasPhoneInputError: boolean;
  checkPhoneErrorMessage: string;

  validationMessages = {
    repeat_phone_no: {
      pattern: 'Only digits allowed starting with ',
      required: 'Receiver\'s Phone Field  is required.',
      min: 'Please provide a correct phone number',
    },
    phone_no: {
      pattern: 'Only digits allowed starting with ',
      required: 'Receiver\'s Phone Field  is required.',
      min: 'Please provide a correct phone number',
      invalidPrefix: 'Invalid phone number prefix, Operator not found!',
    },
    amount: {
      pattern: 'Only number allowed',
      required: 'Amount This Field  is required.',
      amountExceededLimit: 'Amount in XOF should not exceed 1 700 000',
      hasComma: 'XOF does not support decimals',
    },
  };
  merchantSummaryData: SummaryData;
  placeHolder = '96040522';

  country: any;
  operatorPrefixData: any;

  isMerchantNumber: boolean = false;
  //mobileTransfer: MobileTransfer = new MobileTransfer();
  mobileNumber: string; 



  constructor(
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    //private dialogRef: MatDialogRef<AddMobileBeneficiaryComponent>,
    private transactionsService: TransactionsService,
    private beneficiaryService: BeneficiariesService,
    private router: Router,
  ) {
    const businessData = localStorage.getItem(BUSINESS_DATA_KEY);
    if (businessData !== 'undefined') {
      this.userBusinessData = JSON.parse(businessData);
    }

    const sessionData = JSON.parse(localStorage.getItem(USER_SESSION_KEY));
    this.userData = sessionData;
    this.credentials = `${this.userData?.api_secret_key_live}:${this.userData?.api_public_key_live}`;

  }


  ngOnInit(): void {
    this.form = this.formBuilder.group({
      country: ['BJ', Validators.required],
      phone_no: [
        '',
        [
          Validators.required,
          Validators.pattern(this.phoneNumberValidationPattern),
          Validators.min(8),
        ],
      ],
      repeat_phone_no: [''], //,  [ Validators.required, Validators.pattern(this.phoneNumberValidationPattern), Validators.min(8), ],
      is_merchant: [false],
      operator: ['mtn'],
      object: [''],
      user_id: this.userBusinessData?.user_id,
    }, //{ validators: this.phoneNumbersMustMatch }
  );
    this.setOperatorDialingCodes();
    this.getModulesData(this.credentials);
    this.form.get('country').valueChanges.subscribe((value) => {
      this.form.get('operator').patchValue('');
    });
    this.form.get('phone_no').valueChanges.subscribe((value) => {

      this.form.updateValueAndValidity();

      /*
      const prefixesData = this.operatorPrefixData.filter(
          (data) => data.country === this.form.get('country').value
      );
      let isValidPrefix = prefixesData.some((data) =>
          data.prefixes.includes(value.substring(0, 2))
      );

      if (this.form.get('country').value === 'GH') {
          isValidPrefix = prefixesData.some((data) =>
              data.prefixes.includes(value.substring(0, 3))
          );
      }
      if (isValidPrefix) {
          let prefixData = prefixesData.find((data) =>
              data.prefixes.includes(value.substring(0, 2))
          );

          if (this.form.get('country').value === 'GH') {
              prefixData = prefixesData.find((data) =>
                  data.prefixes.includes(value.substring(0, 3))
              );
          }
          const operator = prefixData?.operator;
          
          this.form.get('operator').patchValue(operator);
      } else {
         this.form.get('phone_no').setErrors({invalidPrefix: true});
      }
      */
      //this.form.updateValueAndValidity();
    });
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


  getMaxLength(country) {
    if (country == 'BJ') {
      return 8;
    } else {
      return 10;
    }
  }
  setCurrency(option) {
    this.country = option.value;
    this.currency = this.countryData[option.value].currency;
    this.dailingCode = this.countryData[option.value].code;
    this.maxLength = this.getMaxLength(option.value);
    this.placeHolder = option.value === 'BJ' ? '96040522' : '0544990518';
    // this.transferForm.get("phone_no")?.setValue(this.dailingCode);

    if (option.value === 'BJ') {
      /*
        this.mobileTransfer.networkProviders = this.networkProviders?.filter(
            (provider) => provider !== 'vodafone' && provider !== 'airtel-tigo'
        );
        */
    }
    if (option.value === 'GH') {
      this.module_id = 103;
      this.form.get('operator').setValue('mtn');
    }


  }

  getModulesData(credentials) {
    this.transactionsService
      .getModulesData(credentials)
      .pipe(take(1))
      .subscribe((data) => {
        this.moduleData = data;
        this.networkProviders = this.moduleData.map(
          (data: any) => data.operator
        );
      });
  }

  get formControls() {
    return this.form.controls;
  }

  get isFormReady(): boolean {
    return true;
  }

  cancelAction() {
    this.confirmedTransfer = false;
    this.submitBtnText = 'Save Beneficiary';
  }

  //96040522
  submitAction() {
    if (!this.form.valid) {
      this.errorMessage = 'Please fill up required fields';
      return;
    }
    this.isProcessing = true
    this.confirmedTransfer = true;
    this.beneficiaryService.addMobileBeneficiary(this.credentials, this.form.value).pipe(take(1))
      .subscribe((data) => {
        this.isProcessing = false
        if (data['status'] == true) {
          this.openSnackbar(data['message'])
          this.router.navigate(['/dashboards/beneficiary'])
        } else {
          this.openSnackbar(data['message'])
        }
        console.log("retour de la fonction")
        console.log(data)
        //this.dialogRef.close(data)
      })
  }

  confirmTransfer() {
    this.confirmedTransfer = true;
    this.submitBtnText = 'Approve Transfer';
  }

  cancelConfirmTransfer() {
    this.form.reset();
    this.confirmedTransfer = false;
  }

  close() {
    //this.dialogRef.close();
  }

  onCheckConfirmNumber = () => {
    if (this.form.value['phone_no'] === this.form.value['repeat_phone_no']) {
      this.hasPhoneInputError = false;
      return true;
    } else {
      this.hasPhoneInputError = true;
      this.checkPhoneErrorMessage = 'phone numbers must be identical';
      return false
    }
  };
  phoneNumbersMustMatch(control: AbstractControl): { [key: string]: any } | null {
    const phoneNo = control.get('phone_no').value;
    const repeatPhoneNo = control.get('repeat_phone_no').value;

    if (phoneNo === repeatPhoneNo) {
      return null;
    } else {
      return { 'phoneMismatch': true }; // Validation échouée, retourne un objet avec une erreur 'phoneMismatch'
    }
  }

  get phoneNumber(): AbstractControl {
    return this.form.controls['phone_no'];
  }

  get confirmPhoneNumber(): AbstractControl {
    return this.form.controls['repeat_phone_no'];
  }

  public toggle(event: MatSlideToggleChange) {
    this.isMerchantNumber = event.checked;
  }

  validatePrefix(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const prefixesData = this.operatorPrefixData.filter(
        (data) => data.country === this.form.get('country').value
      );

      prefixesData.forEach((prefixeData) => {
        if (
          !prefixeData.prefixes
            .split(',')
            .includes(control.value.substring(0, 2))
        ) {
          return { invalidPrefix: true };
        }
      });
      return null;
    };
  }
  setOperatorDialingCodes() {
    this.transactionsService
      .getOperatorDailingPrefixes(this.credentials)
      .pipe(take(1))
      .subscribe((response) => {
        if (response && response?.status === true) {
          this.operatorPrefixData = response.data;
        }
      });
  }

  openSnackbar(message) {
    this.snackBar.open(message, 'CLOSE', {
      duration: 5000,
      horizontalPosition: 'right',
    });
  }

}
