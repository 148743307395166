<mat-toolbar class="">
    <div class="primary-btns">
      <button mat-button *ngFor="let option of this.menu.options"
        color="{{option.value==this.menu.current.value?'primary':''}}" (click)="selectOption(option)">{{option.name |
        translate}}</button>
    </div>
  </mat-toolbar>
  <mat-divider class="-mx-6 text-border toolbar-mat-divider"></mat-divider>
  <div>
    <!-- <vex-curency-balance></vex-curency-balance> -->
    <!-- <hr /> -->
    <vex-page-layout>
  
  
      <vex-page-layout-content [class.container]="layoutCtrl.value === 'boxed'"
        [class.px-gutter]="layoutCtrl.value === 'fullwidth'" class="-mt-6">

        <div > 
          <div [@stagger]="true" class="w-full" vexContainer>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="30px">
              <div @fadeInUp class="card" fxFlex="auto">
                <div class="table-btns-bar mt-7 ml-0 pl-0">
  
                  <button (click)="exportAsXlsx()" class="px-1 border" mat-button>
                    {{"Download" |translate}} <mat-icon class="ml-1 mb-1" color="black"
                      [icIcon]="icCloudDownload"></mat-icon>
                  </button>
  
  
                </div>
                <div class="mt-3">
                  <div class="bg-card rounded-full border px-4" fxFlex="250px" fxFlex.lt-md="auto" fxLayout="row"
                    fxLayoutAlign="start center"> 
                  </div>
                </div>
              </div>
            </div>
          </div>
  
  
  
          <table  mat-table [dataSource]="dataSource" class="mat-elevation-z8 w-full mt-6" @stagger
            matSort matTableExporter #exporter="matTableExporter">
  
  
            <!-- currency Column -->
            <ng-container matColumnDef="reference">
              <th mat-header-cell *matHeaderCellDef>{{"Reference"| translate}} </th>
              <td mat-cell *matCellDef="let element">{{ element.reference }}</td>
              <!-- <td mat-cell *matCellDef="">FCFA</td> -->
            </ng-container>
  
            <!-- created_at Column -->
            <ng-container matColumnDef="created_at">
              <th mat-header-cell *matHeaderCellDef>{{"Date & Time"| translate}} </th>
              <td mat-cell *matCellDef="let element">{{ element.created_at }}</td>
              <!-- <td mat-cell *matCellDef="">25 jun 2021</td> -->
            </ng-container>
  
          
  
            <!-- currency Column -->
            <ng-container matColumnDef="account">
              <th mat-header-cell *matHeaderCellDef>{{"Account"| translate}} </th>
              <td mat-cell *matCellDef="let element">{{ element.account }}</td>
              <!-- <td mat-cell *matCellDef="">FCFA</td> -->
            </ng-container>
          
            <ng-container matColumnDef="credit">
              <th mat-header-cell *matHeaderCellDef>{{"Credit"| translate}} </th>
              <td mat-cell *matCellDef="let element">{{ element.credit }}</td>
            </ng-container>
            <ng-container matColumnDef="debit">
              <th mat-header-cell *matHeaderCellDef>{{"Debit"| translate}} </th>
              <td mat-cell *matCellDef="let element">{{ element.debit }}</td>
            </ng-container>
  
            <ng-container matColumnDef="libelle">
              <th mat-header-cell *matHeaderCellDef>{{"Libelle"| translate}} </th>
              <td mat-cell *matCellDef="let element">{{ element.type_transaction }}</td>
            </ng-container>
  
  
   
  
            <ng-container matColumnDef="end_balance">
              <th mat-header-cell *matHeaderCellDef>{{"End Balance"| translate}} </th>
              <td mat-cell *matCellDef="let element">{{ element.end_balance }}</td>
            </ng-container>
  
  
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>{{"Status" | translate}} </th>
              <td mat-cell *matCellDef="let element">
                <div [ngClass]="element.state?.cssClasses" class="px-2 py-1 my-4 text-xs font-medium rounded"
                  fxFlex="none">
                  <span> {{ element.state?.text }}</span>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
   
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">
                {{"No Transaction Data Found Or Matching The filter" | translate}}
              </td>
            </tr>
  
  
          </table>  
  
   
          <div *ngIf="isLoading"  class="card overflow-auto -mt-16">
            <div style=" display: flex;
                justify-content: center;
                align-items: center;
                background: white;
              "> 
              <mat-spinner color="primary"> </mat-spinner>
            </div> 
          </div>
  
  
          <mat-paginator   [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" class="sticky left-0"></mat-paginator>
  
  
  
  
        </div>
  
  
      </vex-page-layout-content>
    </vex-page-layout>
  </div>