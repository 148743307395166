import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { BUSINESS_DATA_KEY, USER_SESSION_KEY } from 'src/app/Models/constants';
import { BusinessService } from 'src/app/services/business.service';
import { takeUntil, take } from 'rxjs/operators';
import { Location } from '@angular/common';
@Component({
  selector: 'vex-recharge-by-bank',
  templateUrl: './recharge-by-bank.component.html',
  styleUrls: ['./recharge-by-bank.component.scss']
})
export class RechargeByBankComponent implements OnInit {
 
 
  bankDeposittopUpForm: FormGroup
  recieptFile: File
  isUploading: boolean = false

  topUpForm: FormGroup;
  currency = '';
  dailingCode = '+229';
  module_id = 102;
  data: any;
  userData: any;
  userBusinessData: any;
  isProcessing: boolean = false;
  unsubscribe$ = new Subject();
  credentials: string;
  hasError: boolean;
  errorMessage: string;


  userCountry: string = ""
  avalaibleOperators = []

  idDocumentFile: File;
  UploadedFileName: string = ''


  networks: any
  collectionParameters: any
  collectionParametersDataSelected: any

  browserLanguage: string


  constructor(  
    private router: Router, 
    private fb: FormBuilder, 
    private businessService: BusinessService,
    private snackBar: MatSnackBar,  
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private translate: TranslateService,
    private location: Location
  ) { 


    const sessionData = localStorage.getItem(USER_SESSION_KEY);
    this.userData = JSON.parse(sessionData);
    this.credentials = `${this.userData?.api_secret_key_live}:${this.userData?.api_public_key_live}`;

    const businessData = localStorage.getItem(BUSINESS_DATA_KEY);
    this.userBusinessData = JSON.parse(businessData);

    this.userCountry = this.userBusinessData.created_country
    this.currency = this.setCurrencyBasedOnBusinessCreatedCountry(this.userBusinessData.created_country)


    // vtx TRADUCTION 
    this.browserLanguage = window.navigator.language;
    let lang = this.browserLanguage.split('-')
    if (lang[0] == 'en') {
    }
    if (lang[0] == 'fr') {
      translate.setDefaultLang(lang[0]);
      this.translate.use(lang[0]);
    }

    iconRegistry.addSvgIcon(
      'docs',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/docs.svg')
    );
    iconRegistry.addSvgIcon(
      'arrow2',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/arrow2.svg')
    );
    iconRegistry.addSvgIcon(
      'close',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/close.svg')
    );
    iconRegistry.addSvgIcon(
      'copy',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/copy.svg')
    );


  }

  ngOnInit(): void {

    this.topUpForm = this.fb.group({
      currency: [this.currency, Validators.required],
      amount: ['', [Validators.required, Validators.pattern(/^[0-9]+$/)]],
      operator: ['mtn', Validators.required],
    });
     
    this.bankDeposittopUpForm = this.fb.group({
      currency: [this.currency, Validators.required],
      amount: ['', [Validators.required, Validators.pattern(/^[0-9]+$/)]],
      operator: ['bank', Validators.required],
    });






  }

  uploadID0(event) {
    this.recieptFile = event.target.files[0];
    this.isUploading = true;
    //const reader: FileReader = new FileReader();
    //reader.onload = () => {
    //this.reciept = reader.result as string;
    //};
    //reader.readAsDataURL(this.recieptFile);

  }
  uploadID(event) { 
    this.isUploading = event.target && event.target.files && event.target.files.length > 0;
    this.recieptFile = event.target.files[0]; 

  }
  setCurrencyBasedOnBusinessCreatedCountry(country) {
    if (country == 'BJ' || country == 'CI' || country == 'SN') return 'XOF'
    if (country == 'CM') return 'XAF'
    if (country == 'GH') return 'GHS'

    return ''
  }
  rechargeAccount() {
    this.isProcessing = true; 
    let charges: number = 0
     
    this.topUpForm = this.fb.group({
      currency: [this.bankDeposittopUpForm.value.currency],
      amount: [this.bankDeposittopUpForm.value.amount],
      operator: [this.bankDeposittopUpForm.value.operator],
    }); 
    this.data = {
      ...this.topUpForm.value,
      country: this.userCountry,
      //currency: this.currency,
      //module_id: this.module_id,
      user_id: this.userData.user_id,
      status: 'Pending', 
      charges: charges,
      reciept: this.recieptFile
    };  
    this.businessService.requestTopUp(this.data, this.credentials)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(response => {
        this.isProcessing = false;
        if (response && response['status'] === true) {
          this.openSnackbar(response['message']);
          this.bankDeposittopUpForm = this.fb.group({
            currency: [this.currency, Validators.required],
            amount: ['', [Validators.required, Validators.pattern(/^[0-9]+$/)]],
            operator: ['merchant', Validators.required],
          });
          this.router.navigate(['/dashboards/balance', this.currency])
        } else {
          if (response['status'] === false) {
            this.hasError = true;
            this.errorMessage = response['message'];
            //VTXthis.router.navigate(['/dashboards/cash-in-transactions'])
          } else {
            this.hasError = true;
            this.showInternalServerErrorMessage()
          }
        }
      }),
      error => {
        this.hasError = true;
        this.errorMessage = error.message;
        this.showInternalServerErrorMessage()
      } 
  }
  showInternalServerErrorMessage() {
    this.errorMessage = "Error. Please retry again later."
    this.openSnackbar("Error. Please retry again later.");
  }
  openSnackbar(message) {
    this.snackBar.open(message, 'CLOSE', {
      duration: 3000,
      horizontalPosition: 'right'
    });
  }

  goBack() {
    this.location.back();  // Retourne à la page précédente
  }

  

}
