<!-- (ngSubmit)="save()" -->

<div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
  <h2 class="m-0 headline" fxFlex="auto">CONFIRMATION</h2>
  <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
    <mat-icon [icIcon]="icClose"></mat-icon>
  </button>
</div>
<mat-dialog-content fxLayout="column" class="content-part">
  <div
    class=" grid  respon   grid-flow-col    md:grid-cols-2 md:grid-rows-2    gap-8   xs:grid-cols-4 xs:grid-rows-4  sm:grid-rows-2 sm:grid-cols-2">
    <h6>You're about to send {{ transferData['amount']}} {{ transferData['currency'] }} to {{transferData['phone_no']}} in {{contry}}</h6>
  </div>
  <div>
    <h6 *ngIf="clientName">Client Name: {{ clientName }}</h6>
    <h6 *ngIf="isFetchingName">Fetching Client Name...</h6>
    <h6 *ngIf="!clientName && !isFetchingName"> Warning: {{ noNameErrorMessage }}</h6>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="grid grid-cols-4 gap-2 sm:grid-cols-4 md:grid-cols-4  button">
    <div class="flex justify-end">
      <button color="primary" mat-button type="button" (click)="createTransfer()" class="send-button button1" [disabled]="isDisbursing">
        {{ isDisbursing === true ? "Processing..." : "SEND CASH" }}
      </button>
    </div>
  </div>
</mat-dialog-actions>