import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import icClose from '@iconify/icons-ic/twotone-close';
import { UserNotificationService } from 'src/app/services/user-notification.service';
import { BUSINESS_DATA_KEY, SUMMARY_DATA_KEY, USER_SESSION_KEY, } from 'src/app/Models/constants';
import { User } from 'src/app/Models/models.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateTime } from 'luxon';
@Component({
  selector: 'vex-user-notification-modal',
  templateUrl: './user-notification-modal.component.html',
  styleUrls: ['./user-notification-modal.component.scss']
})
export class UserNotificationModalComponent implements OnInit {

  notifications: any
  notificationToShow:  any
  icClose = icClose;
  userData: User;
  credentials: string;
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private userNotificationService: UserNotificationService,
    private snackBar: MatSnackBar,
  ) {

    const sessionData = localStorage.getItem(USER_SESSION_KEY);
    this.userData = JSON.parse(sessionData);
    this.credentials = `${this.userData?.api_secret_key_live}:${this.userData?.api_public_key_live}`;



  }

  ngOnInit() {
    this.getUserNotifications()
  }

  redirect() {
    this.dialog.closeAll();
    this.router.navigate(['/dashboards/profile']);
  }
  getUserNotifications() {

    let res = this.userNotificationService.getUserNotifications(this.userData.user_id).subscribe(
      (res) => { 
        this.notifications = res["data"].reverse() 

        this.notificationToShow = this.notifications.map((notification) => {


          const createdAtDateTime = DateTime.fromISO(notification.created_at);
          const hoursElapsed = DateTime.local().diff(createdAtDateTime).as('hours');
          

          return {
            ...notification,
            datetime: DateTime.local().minus({ hour: hoursElapsed })
          }
        }) 
        //this.notificationToShow = this.notifications




      }
    )
  }
  markReaded(id: any) {

    let res = this.userNotificationService.markReadedNotification(this.userData.user_id, id).subscribe(
      (res) => {
        if (res["status"] = true) {
          this.openSnackbar(res['message']);
          this.getUserNotifications()
        } 
      }
    )


  }
  openSnackbar(message) {
    this.snackBar.open(message, 'CLOSE', {
      duration: 3000,
      horizontalPosition: 'right',
    });
  }

}
