<hr style="border-color: rgba(0, 0, 0, 0.169);">


<div class="container">

    <div style="display: flex; flex-wrap: wrap; margin-left: 20px; margin-right: 20px; ">



        <div style="flex: 1  ; margin-right: 10px;  "> </div>

        <div style="flex: 1  ; margin-right: 10px; margin-top: 50px;">
            <div class="card">


                <div style="padding: 20px;">
                    <div style="display: flex;">
                        <div style="flex: 2">
                            <h3><strong>Que desiriez vous faire ?</strong></h3>
                        </div>
                        <div style="flex: 1;">
                            <mat-icon [svgIcon]="'close'"
                                style="position: relative; left: 80%; height: 30px; width: 30px; cursor: pointer;"
                                class="menu-item-icon mr-1" matListIcon aria-hidden="false" (click)="goBack()">
                            </mat-icon>
                        </div>
                    </div>
                </div>
                <hr>


                <div *ngFor="let collection of collectionParameters">
                    <div class="hoverable-div" style="padding: 20px; height: 110px; cursor: pointer;"
                        (click)="selectCurrency()" *ngIf="collection?.currency =='XOF'">
                        <div style="display: flex;">
                            <div style="flex: 2">
                                <strong>DEPOSER DU XOF</strong> <br>
                                Approvisionnez votre compte en XOF
                            </div>
                            <div style="flex: 1; margin-top: 10px;">
                                <mat-icon [svgIcon]="'arrow2'"
                                    style="position: relative; left: 80%; color: rgba(0, 0, 0, 0.634); height: 50px; width: 50px;"
                                    class="menu-item-icon mr-1" matListIcon aria-hidden="false">
                                </mat-icon>
                            </div>
                        </div>
                    </div>
                    <hr *ngIf="collection?.currency =='XOF'">


                    <div class="hoverable-div" style="padding: 20px; height: 110px; cursor: pointer;"
                        (click)="selectCurrency()" *ngIf="collection?.currency =='XAF'">
                        <div style="display: flex;">
                            <div style="flex: 2">
                                <strong>DEPOSER DU XAF</strong> <br>
                                Approvisionnez votre compte en XAF
                            </div>
                            <div style="flex: 1; margin-top: 10px;">
                                <mat-icon [svgIcon]="'arrow2'"
                                    style="position: relative; left: 80%; color: rgba(0, 0, 0, 0.634); height: 50px; width: 50px;"
                                    class="menu-item-icon mr-1" matListIcon aria-hidden="false">
                                </mat-icon>
                            </div>
                        </div>
                    </div>
                    <hr *ngIf="collection?.currency =='XAF'">


                    <div class="hoverable-div" style="padding: 20px; height: 110px; cursor: pointer;"
                        (click)="selectCurrency()" *ngIf="collection?.currency =='GHS'">
                        <div style="display: flex;">
                            <div style="flex: 2">
                                <strong>DEPOSER DU GHS</strong> <br>
                                Approvisionnez votre compte en GHS
                            </div>
                            <div style="flex: 1; margin-top: 10px;">
                                <mat-icon [svgIcon]="'arrow2'"
                                    style="position: relative; left: 80%; color: rgba(0, 0, 0, 0.634); height: 50px; width: 50px;"
                                    class="menu-item-icon mr-1" matListIcon aria-hidden="false">
                                </mat-icon>
                            </div>
                        </div>
                    </div>
                    <hr *ngIf="collection?.currency =='GHS'">



                    <!--ACCOUNT NOT EXIST-->
                    <div class="hoverable-div" style="padding: 20px;  height: 110px;  cursor: pointer;"
                        (click)="createVirtualAccount()" *ngIf="collection?.currency =='NGN' && !hasVirtualAccount">

                        <div style="display: flex;">
                            <div style="flex: 2">
                                <strong>NGN VIRTUAL ACCOUNT</strong> <br>
                                Create your virtual account for receiving instant Naira to your PAL's account.
                            </div>
                            <div style="flex: 1; margin-top: 15px;">
                                <mat-icon [svgIcon]="'arrow2'"
                                    style="position: relative; left: 80%; color: rgba(0, 0, 0, 0.634); height: 50px; width: 50px;"
                                    class="menu-item-icon mr-1" matListIcon aria-hidden="false">
                                </mat-icon>
                            </div>
                        </div>
                    </div>
                    <!--ACCOUNT EXIST-->
                    <div style="padding: 20px;   cursor: pointer;" *ngIf="collection?.currency =='NGN' && hasVirtualAccount">

                        <div>
                            <strong>NGN VIRTUAL ACCOUNT</strong> <br>
                        </div>

                        <div style="display: flex;">
                            <div style="flex: 1">
                            </div>
                            <div style="flex: 1; margin-top: 15px; text-align: right">
                                <strong>{{userVirtualAccount?.account_name}}</strong>
                            </div>
                        </div>
                        <div style="display: flex; background-color: #f0f0f0; ">
                            <div style="flex: 2; margin-left: 10px;" >
                                <br>
                                {{userVirtualAccount?.bank | uppercase}} <br>
                                <h2><strong id="account-number">{{userVirtualAccount?.account_number}}</strong></h2>
                            </div>
                            <div style="flex: 1; margin-top: 15px;">
                                <mat-icon [svgIcon]="'copy'"
                                    style="position: relative; left: 80%; height: 30px; width: 30px;"
                                    class="menu-item-icon mr-1" matListIcon aria-hidden="false" (click)="copyAccountNumber()">
                                </mat-icon>
                            </div>
                        </div>
                        <div>
                            <br>
                            Tell anyone to transfer to your <strong>account number</strong> below.  
                            It reflects <strong>immediately</strong> on your PAL balance
                        </div>

                    </div>




                </div>










            </div>
        </div>


        <div style="flex: 1  ; margin-right: 10px; ">

        </div>



    </div>
</div>