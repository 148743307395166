import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vex-add-funds',
  templateUrl: './add-funds.component.html',
  styleUrls: ['./add-funds.component.scss']
})
export class AddFundsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
