<hr style="border-color: rgba(0, 0, 0, 0.169);">


 

<div class="container mt-5 pt-3 ms-5 me-5 pl-5 pr-5 bg-gray-100 ">

    <div style="display: flex; flex-wrap: wrap; margin-left: 20px; margin-right: 20px; ">



        <div style="flex: 1 300px; margin-right: 10px;">
            <div style="width: 300px; height: 280px; border: 1px solid #ccc; border-radius: 4px; margin-top: 20px;">
                <div style="padding: 10px;">
                    <strong>{{"Mobile Money Deposit" | translate}} </strong>
                </div>
                <div style="padding: 10px; height: 140px;"><br><br>
                    Lorem ipsum, dolor sit amet usdamiciendis voluptas opt  voluptas optio maiores
                </div>
                <hr style="border-color: rgba(0, 0, 0, 0.201); margin-top: 35px">
                <div style="padding: 10px; background-color: #f5f5f5; cursor: pointer;" (click)="chooseDeposit('mobile-money')">
    
                    <div style="display: flex; ">
                        <div style="flex:1; padding-top: 10px;">
                            <strong>{{"Deposit" | translate}} </strong>
                        </div>
                        <div style="flex:1">
                            <mat-icon [svgIcon]="'arrow'" class="menu-item-icon mr-1 " style="position: relative; top:5px; left: 121px;" matListIcon aria-hidden="false"></mat-icon>
                        </div>
                    </div>
                      
                </div>
            </div>
        </div>


        <div style="flex: 1 300px; margin-right: 10px;">
            <div style="width: 300px; height: 280px; border: 1px solid #ccc; border-radius: 4px; margin-top: 20px;">
                <div style="padding: 10px;">
                    <strong>{{"Merchant Deposit" | translate}} </strong>
                </div>
                <div style="padding: 10px; height: 140px;"><br><br>
                    Lorem ipsum, dolor sit amet usdamiciendis voluptas opt  voluptas optio maiores
                </div>
                <hr style="border-color: rgba(0, 0, 0, 0.201); margin-top: 35px">
                <div style="padding: 10px; background-color: #f5f5f5; cursor: pointer;" (click)="chooseDeposit('merchant')" >
    
                    <div style="display: flex; ">
                        <div style="flex:1; padding-top: 10px;">
                            <strong>{{"Deposit" | translate}} </strong>
                        </div>
                        <div style="flex:1">
                            <mat-icon [svgIcon]="'arrow'" class="menu-item-icon mr-1 " style="position: relative; top:5px; left: 121px;" matListIcon aria-hidden="false"></mat-icon>
                        </div>
                    </div>
                      
                </div>
            </div>
        </div>


        <div style="flex: 1 300px; margin-right: 10px;">
            <div style="width: 300px; height: 280px; border: 1px solid #ccc; border-radius: 4px; margin-top: 20px;">
                <div style="padding: 10px;">
                    <strong>{{"Bank Deposit" | translate}} </strong>
                </div>
                <div style="padding: 10px; height: 140px;"><br><br>
                    Lorem ipsum, dolor sit amet usdamiciendis voluptas opt  voluptas optio maiores
                </div>
                <hr style="border-color: rgba(0, 0, 0, 0.201); margin-top: 35px">
                <div style="padding: 10px; background-color: #f5f5f5; cursor: pointer;" (click)="chooseDeposit('bank-deposit')">
    
                    <div style="display: flex; ">
                        <div style="flex:1; padding-top: 10px;">
                            <strong>{{"Deposit" | translate}} </strong>
                        </div>
                        <div style="flex:1">
                            <mat-icon [svgIcon]="'arrow'" class="menu-item-icon mr-1 " style="position: relative; top:5px; left: 121px;" matListIcon aria-hidden="false"></mat-icon>
                        </div>
                    </div>
                      
                </div>
            </div>
        </div>



    </div> 
</div>