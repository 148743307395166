import {LOCALE_ID, NgModule} from '@angular/core';
import {CommonModule, registerLocaleData} from '@angular/common';
import {LayoutModule} from './layout/layout.module';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions} from '@angular/material/form-field';
import {LocalizedNumericInputDirective} from './directives/localized-numeric-input.directive';
import {CurrentyFormatPipe} from './pipes/custom-format.pipe';

export const DEFAULT_LOCALE = 'en-US';

export function initializeLocale() {
    // const locale = 'en-US';
    // registerLocaleData(localeEn, locale);
    // const locale = 'fr';
    // registerLocaleData(localeFr, locale);
    // const locale = 'zh';
    // registerLocaleData(localeZh, locale);
    const locale = DEFAULT_LOCALE;
    registerLocaleData(locale);
    return locale;
}


@NgModule({
    imports: [
        CommonModule,
        LayoutModule,
    ],
    exports: [
        LayoutModule,
        LocalizedNumericInputDirective,
        CurrentyFormatPipe,
    ],
    providers: [
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'fill'
            } as MatFormFieldDefaultOptions
        },
        {
            provide: LOCALE_ID,
            useFactory: initializeLocale,
        },
    ],
    declarations: [
        LocalizedNumericInputDirective,
        CurrentyFormatPipe,
    ]
})
export class VexModule {
}
