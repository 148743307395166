import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators'; 
import { throwError as observableThrowError } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ExchangeService {

  constructor(private http: HttpClient) { }


  getUserCollectionsBalances(user_id) {
    const url = `${environment.getUserCollectionsBalancesUrl}${user_id}`;
    return this.http.get(url).pipe(
      map(response => {
        return response;
      }),

      catchError((error: HttpErrorResponse) => {
        console.error("Error:", error.message);
        return observableThrowError(error);
      })
    )
  }
  
  getExchangeRates() {
    const url = `${environment.exchangeRateUrl}`;
    return this.http.get(url).pipe(
      map(response => {
        return response;
      }), 
      catchError((error: HttpErrorResponse) => {
        console.error("Error:", error.message);
        return observableThrowError(error);
      })
    )
  }
  processExchange(exchangeDate,credentials) {
    const url = environment.processExchangeUrl; 
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${credentials}`,
    });
    return this.http
      .post(url, exchangeDate, {headers,  responseType: 'json', })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          //console.log('Error:', error.message);
          return observableThrowError(error);
        })
      );
  }

  getUserExchanges(credentials,user_id) {
    const url = `${environment.userExchangeUrl}${user_id}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${credentials}`,
    }); 
    return this.http.get(url, {headers,  responseType: 'json', }).pipe(
      map(response => {
        return response;
      }), 
      catchError((error: HttpErrorResponse) => {
        console.error("Error:", error.message);
        return observableThrowError(error);
      })
    )
  }


}
