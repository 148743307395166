
import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import icPhone from "@iconify/icons-ic/twotone-phone";
import icMail from "@iconify/icons-ic/twotone-mail";
import icMap from "@iconify/icons-ic/twotone-map";
import icEdit from "@iconify/icons-ic/twotone-edit";
import icDelete from "@iconify/icons-ic/twotone-delete";
import icSearch from "@iconify/icons-ic/twotone-search";
import icAdd from "@iconify/icons-ic/twotone-add";
import icFilterList from "@iconify/icons-ic/twotone-filter-list";
import icMoreHoriz from "@iconify/icons-ic/twotone-more-horiz";
import icFolder from "@iconify/icons-ic/twotone-folder";
import icRefresh from "@iconify/icons-ic/twotone-refresh";
import icCloudDownload from '@iconify/icons-ic/twotone-cloud-download';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { Subject } from "rxjs";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import {
  BUSINESS_DATA_KEY,
  TRANSACTION_TABLE_LABELS,
  USER_SESSION_KEY,
} from "src/app/Models/constants";
import { ActivatedRoute, Router } from "@angular/router";
import { TransactionsService } from "src/app/services/transactions.service";
import { takeUntil } from "rxjs/operators";
import { BusinessService } from "src/app/services/business.service";
import moment from "moment";
import { MatTableExporterDirective } from "mat-table-exporter";

@Component({
  selector: 'vex-financial-statment-report',
  templateUrl: './financial-statment-report.component.html',
  styleUrls: ['./financial-statment-report.component.scss']
})
export class FinancialStatmentReportComponent implements OnInit, AfterViewInit {

  menu: { current: { name: string, value: string }, options: { name: string, value: string }[] } = {
    current: { name: 'Financial Statment', value: "topup" },
    options: [
      { name: 'Financial Statment', value: "topup" }
    ]
  }

  displayedColumns: string[] = [
    // "id",
    "reference",
    'libelle',
    'account',
    
    
    'debit',
    'credit',
    
    'end_balance',
    
    'status',
    "created_at",
    
  ];
  dataSource = new MatTableDataSource();
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  layoutCtrl = new FormControl("boxed");
  searchCtrl = new FormControl();

  icPhone = icPhone;
  icMail = icMail;
  icMap = icMap;
  icEdit = icEdit;
  icSearch = icSearch;
  icDelete = icDelete;
  icAdd = icAdd;
  icFilterList = icFilterList;
  icMoreHoriz = icMoreHoriz;
  icFolder = icFolder;
  icRefresh = icRefresh;
  icCloudDownload = icCloudDownload;

  statusLabels = TRANSACTION_TABLE_LABELS;
  userData: any;
  unsubscribe$ = new Subject();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  transactionsData: any;
  transactionType: any;
  hasNoTransactions: boolean;
  userBusinessData: any;
  credentials: string;
  isLoading: boolean;

  @ViewChild(MatTableExporterDirective)
  matTableExporter: MatTableExporterDirective;

  defaultFilterCurrency = ""
  defaultDateStart = ""
  defaultDateEnd = ""
  showFinancialStatment : boolean = false
 
  currentCurrencyBalance = { currency: "", balance: 0, collectionBalance: 0 }
  currencyBalance = [
    { currency: "XOF", balance: 0, collectionBalance: 0 },
    { currency: "XAF", balance: 0, collectionBalance: 0 },
    { currency: "NGN", balance: 0, collectionBalance: 0 },
    { currency: "GHS", balance: 0, collectionBalance: 0 }
  ]
  currenctBalance : string = ''


  constructor(
    private router: Router,
    private transactionsService: TransactionsService,
    private businessService: BusinessService,
    private route: ActivatedRoute,
    private fb: FormBuilder, 
  ) {

     

    const sessionData = JSON.parse(localStorage.getItem(USER_SESSION_KEY));
    this.userData = sessionData;
    this.credentials = `${this.userData?.api_secret_key_live}:${this.userData?.api_public_key_live}`;

    const businessData = localStorage.getItem(BUSINESS_DATA_KEY);
    if (businessData !== "undefined") {
      this.userBusinessData = JSON.parse(businessData);
    }

    if (!sessionData) {
      router.navigate(["/auth/login"]);
    }
    //this.credentials = `${this.userBusinessData?.api_secret_key_live}:${this.userBusinessData?.api_public_key_live}`;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnInit(): void {
     

    this.route.params.subscribe(params => {
      this.defaultFilterCurrency = params['currency'];
      this.defaultDateStart = params['date_start'];
      this.defaultDateEnd = params['date_end'];
    });
    this.loadTransactions()

     
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit() {
    //this.dataSource.paginator = this.paginator;
    //this.dataSource.sort = this.sort;
  }

  refreshData() {
    this.loadTransactions();
  }

  getStatusLabel(status: string) {
    return this.statusLabels.find((label) => label.text === status);
  }

  loadTransactions() {
    this.showFinancialStatment = true
    this.isLoading = true; 

  
    this.dataSource = new MatTableDataSource([]);
    this.businessService
      .getUserFinancialStament(this.userData.user_id,this.defaultFilterCurrency, this.defaultDateStart,this.defaultDateEnd, this.credentials)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        async (transactions) => { 
          //this.isLoading = false;  
          if (transactions.transactions) {
            this.isLoading= true  

            
            await new Promise<void>(resolve => setTimeout(resolve, 3000));

           

            this.transactionsData = transactions.transactions.map((details) => {
              details.created_at = moment(details.created_at).format('Y-MM-DD HH:mm:ss');
              details.state = this.getStatusLabel(details.status);
              return details;
            }); 
            //FILTRER AVEC LA CURRENCY RECUPERE DEPUIS L'URL
            if (this.defaultFilterCurrency != "") {
              this.transactionsData = this.transactionsData.filter(item => item.currency == this.defaultFilterCurrency);
            } 
            this.hasNoTransactions = transactions.transactions.length === 0 ? true : false;
            this.dataSource = new MatTableDataSource(this.transactionsData);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort; 
            this.isLoading = false 

          } else {
            this.isLoading = false
            /* 
            this.hasNoTransactions = true;
            const data = [];
            this.dataSource = new MatTableDataSource(data);
            */
          }
        },
        (error) => {
          this.isLoading = false;
          //console.log(error.message);
        }
      );
  }

 

 


























  exportAsXlsx() {
    if (this.isAllowed("7")) {
      this.matTableExporter.exportTable('xlsx', {
        fileName: 'Financial Statment',
        sheet: 'report',
        Props: { Author: 'PAL Africa' },
      });
    } else {
      //this.openSnackbar("You are not allowed");
    }

  }







  isAllowed(habl: string) {

    let habilitations = []
    let isAllow = false
    if (this.userData.secondaryAccount) {
      if (this.userData.habilitations != undefined) {
        habilitations = this.userData.habilitations.split(',')
        for (let hab of habilitations) {
          if (hab == habl) {
            isAllow = true
          }
        }
      }
    } else {
      isAllow = true
    }
    return isAllow
  }


  selectOption(current: { name: string, value: string }) {
    this.menu.current = current;
  }









}
