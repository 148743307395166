import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { USER_SESSION_KEY } from '../Models/constants';
import { AuthserviceService } from '../services/authservice.service';
import { BUSINESS_DATA_KEY} from 'src/app/Models/constants';
@Injectable({
  providedIn: 'root'
})
export class AuthService {




  constructor(
    private router: Router,
    private authService: AuthserviceService
  ) {
  }




  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const sessionData = localStorage.getItem(USER_SESSION_KEY);
    let userData = JSON.parse(sessionData);
    const token = userData?.token

    let authReq = req;
    if (token) {
      authReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    // Passer la requête suivante
    return next.handle(authReq).pipe(
      catchError((error: HttpErrorResponse) => {



        if (error.status === 401 || error.status === 419) {
          // Gérer les erreurs 401 et 419
          //localStorage.removeItem('token');
          //this.router.navigate(['/login']);
          this.logout()


          //REFRESH TOKEN
          if (error.status === 419) {

            this.logout()
            /*
            let res: any = this.authService.refreshToken().subscribe(
              (res) => {
                if (res['status'] == true) {

                  userData.token = res['data']
                  localStorage.setItem(
                    USER_SESSION_KEY,
                    JSON.stringify(userData)
                  );


                  authReq = req.clone({
                    setHeaders: {
                      Authorization: `Bearer ${res['data']}`
                    }
                  });

                  //return next.handle(authReq)
                  return next.handle(authReq).pipe(
                    catchError((error: HttpErrorResponse) => {
                      return throwError(error);
                    })
                  );


                }
              },
              (error) => {
                //console.log(error)
                if (error.status == 419) {
                  this.router.navigate(['/login']);
                }
                this.router.navigate(['/login']);
            })
            */

          }


          //this.router.navigate(['/login']);


        }
        return throwError(error);


      })
    );
  }

  logout() {
    localStorage.removeItem(BUSINESS_DATA_KEY);
    localStorage.removeItem(USER_SESSION_KEY);
    sessionStorage.clear();
    //this.popoverRef.close();
    this.router.navigate(['auth/login']);
}


  /*
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = req;

    // Ajouter l'en-tête d'autorisation 
    const sessionData = localStorage.getItem(USER_SESSION_KEY);
    let userData = JSON.parse(sessionData);
    const token = userData?.token


    if (token) {
      authReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    return next.handle(authReq).pipe(
      catchError((error: HttpErrorResponse) => { 
        if (error.status === 401 || error.status === 419) {
          // Gérer les erreurs 401 et 419
          if (error.status === 419) {
            return this.authService.refreshToken().pipe(
              switchMap((res: any) => {
                if (res.status === true) {
                  const userData = JSON.parse(localStorage.getItem(USER_SESSION_KEY));
                  userData.token = res.data;
                  localStorage.setItem(USER_SESSION_KEY, JSON.stringify(userData));

                  authReq = req.clone({
                    setHeaders: {
                      Authorization: `Bearer ${res.data}`
                    }
                  });

                  // Relancer la requête d'origine avec le nouveau jeton
                  return next.handle(authReq);
                } else {
                  this.router.navigate(['/login']);
                  return throwError(error);
                }
              }),
              catchError((refreshError: HttpErrorResponse) => {
                console.error(refreshError);
                if (refreshError.status === 419) {
                  this.router.navigate(['/login']);
                }
                return throwError(refreshError);
              })
            );
          } else {
            //this.router.navigate(['/login']);
          }
        }

        return throwError(error);
      })
    );
  }
  */

 

  /*
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      let authReq = req;
  
      // Add the authorization header
      const token = localStorage.getItem('token');
      if (token) {
        authReq = req.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        });
      }
  
      return next.handle(authReq).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401 || error.status === 419) {
            // Handle 401 and 419 errors
            if (error.status === 419) {
              return this.authService.refreshToken().pipe(
                switchMap((res: any) => {
                  if (res.status === true) {
                    const userData = JSON.parse(localStorage.getItem(USER_SESSION_KEY));
                    userData.token = res.data;
                    localStorage.setItem(USER_SESSION_KEY, JSON.stringify(userData));
  
                    authReq = req.clone({
                      setHeaders: {
                        Authorization: `Bearer ${res.data}`
                      }
                    });
  
                    // Retry the original request with the new token
                    return next.handle(authReq);
                  } else {
                    //this.router.navigate(['/login']);
                    return throwError(error);
                  }
                }),
                catchError((refreshError) => {
                  console.error(refreshError);
                  this.router.navigate(['/login']);
                  return throwError(refreshError);
                })
              );
            } else {
              //this.router.navigate(['/login']);
            }
          }
  
          return throwError(error);
        })
      );
    }
    */












}
