<div (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()" [class.collapsed]="collapsed"
  [class.open]="collapsed && collapsedOpen$ | async" class="flex flex-col sidenav">
  <div class="flex items-center flex-none sidenav-toolbar">
    <h6 *ngIf="hasBusinessRegistered" class="flex-auto select-none ltr:pl-4 rtl:pr-4">

      <span *ngIf="businessUserData?.business_legal_name">
        {{ businessUserData?.business_legal_name }}
      </span>
      <span *ngIf="!businessUserData?.business_legal_name">
        {{ businessUserData?.owner_full_name }}
      </span>
      <br>
      <span>{{ businessUserData?.id }}</span>
    </h6>
    <h6 *ngIf="!hasBusinessRegistered" class="flex-auto select-none ltr:pl-4 rtl:pr-4">
      <a class="item" [href]="'/dashboards/profile'">
        <span>{{"Add Business" | translate}} </span>
      </a>
      <br>
      <p>{{"[No Business Registered]"| translate}} </p>
    </h6>
  </div>
  <vex-scrollbar class="flex-auto">
    <div class="sidenav-items">
      <a class="item" [routerLink]="['/dashboards/analytics']" routerLinkActive="router-link-active" fxLayout="row"
        matRipple>
        <div class="flex items-center flex-none speci-nav sidenav-toolbar">
          <mat-icon [svgIcon]="'side-home'" class="menu-item-icon mr-1" matListIcon aria-hidden="false"></mat-icon>

          <span class="item-label text-md" fxFlex="auto">{{'Home'| translate}} </span>
        </div>
      </a>
      <a class="item" [routerLink]="['/dashboards/wallet']" routerLinkActive="router-link-active" fxLayout="row"
        matRipple>
        <div class="flex items-center flex-none speci-nav sidenav-toolbar">
          <mat-icon [svgIcon]="'side-all-transfers'" class="menu-item-icon mr-1" matListIcon
            aria-hidden="false"></mat-icon>
          <span class="item-label text-md" fxFlex="auto"> {{"Wallets" | translate}} </span>
        </div>
      </a> <br>

      <!--<div class="group-title">
        <span class="text-grey ">
          {{ 'Mobile Wallet' | translate}}
        </span>
      </div>
-->


      <!-- <a class="item" [routerLink]="['/dashboards/cash-in-transactions']" routerLinkActive="router-link-active"
        fxLayout="row" matRipple>
        <div class="flex items-center flex-none speci-nav sidenav-toolbar">
          <mat-icon [svgIcon]="'side-cash-in'" class="menu-item-icon mr-1" matListIcon aria-hidden="false"></mat-icon>
          <span class="item-label text-md" fxFlex="auto"> {{"Transactions" | translate}} </span>
        </div>
      </a>
      <a class="item" [routerLink]="['/dashboards/cash-out-transactions']" routerLinkActive="router-link-active"
        fxLayout="row" matRipple>
        <div class="flex items-center flex-none speci-nav sidenav-toolbar">
          <mat-icon [svgIcon]="'side-cash-out'" class="menu-item-icon mr-1" matListIcon aria-hidden="false"></mat-icon>
          <span class="item-label text-md" fxFlex="auto"> {{'Payouts' | translate}} </span>
        </div>
      </a>
      <a class="item" [routerLink]="['/dashboards/bulk-disbursement']" routerLinkActive="router-link-active"
        fxLayout="row" matRipple>
        <div class="flex items-center flex-none speci-nav sidenav-toolbar">
          <mat-icon [svgIcon]="'profile-group'" class="menu-item-icon mr-1" matListIcon aria-hidden="false"></mat-icon>
          <span class="item-label text-md" fxFlex="auto"> {{"Bulk Disbursment" | translate}} </span>
        </div>
      </a> -->


      <a class="item" [routerLink]="['/dashboards/make-transfer-c']" routerLinkActive="router-link-active"
        fxLayout="row" matRipple>
        <div class="flex items-center flex-none speci-nav sidenav-toolbar">
          <mat-icon [svgIcon]="'side-make-transfer'" class="menu-item-icon mr-1" matListIcon
            aria-hidden="false"></mat-icon>
          <span class="item-label text-md" fxFlex="auto"> {{"Make Transfer" | translate}} </span>
        </div>
      </a>
      <a class="item" [routerLink]="['/dashboards/transactions']" routerLinkActive="router-link-active" fxLayout="row"
        matRipple>
        <div class="flex items-center flex-none speci-nav sidenav-toolbar">
          <mat-icon [svgIcon]="'docs'" class="menu-item-icon mr-1" matListIcon aria-hidden="false"></mat-icon>
          <span class="item-label text-md" fxFlex="auto"> {{'View Transactions' | translate}} </span>
        </div>
      </a>
      <!-- <a class="item" [routerLink]="['/dashboards/bulk-disbursement']" routerLinkActive="router-link-active"
        fxLayout="row" matRipple>
        <div class="flex items-center flex-none speci-nav sidenav-toolbar">
          <mat-icon [svgIcon]="'profile-group'" class="menu-item-icon mr-1" matListIcon aria-hidden="false"></mat-icon>
          <span class="item-label text-md" fxFlex="auto"> {{"Bulk Disbursment" | translate}} </span>
        </div>
      </a>-->
      <hr> <br>

      <!--<div class="group-title">
        <span class="text-grey ">
          {{'Bank' | translate}}
        </span>
      </div>-->

      <a class="item" [routerLink]="['/dashboards/beneficiary']" routerLinkActive="router-link-active" fxLayout="row"
        matRipple>
        <div class="flex items-center flex-none speci-nav sidenav-toolbar">
          <mat-icon [svgIcon]="'benef'" class="menu-item-icon mr-1" matListIcon aria-hidden="false"></mat-icon>
          <span class="item-label text-md" fxFlex="auto"> {{'Beneficiary' | translate}} </span>
        </div>
      </a>
      <a class="item" [routerLink]="['/dashboards/team']" routerLinkActive="router-link-active" fxLayout="row"
        matRipple>
        <div class="flex items-center flex-none speci-nav sidenav-toolbar">
          <mat-icon [svgIcon]="'people'" class="menu-item-icon mr-1" matListIcon aria-hidden="false"></mat-icon>
          <span class="item-label text-md" fxFlex="auto"> {{ "Team" | translate}} </span>
        </div>
      </a> <br>

      <!-- LISTE DES BANK TRANSFERS ET LEUR BENEFICIARY
      <a class="item" [routerLink]="['/dashboards/all-transactions']" routerLinkActive="router-link-active"
        fxLayout="row" matRipple>
        <div class="flex items-center flex-none speci-nav sidenav-toolbar">
          <mat-icon [svgIcon]="'side-all-transfers'" class="menu-item-icon mr-1" matListIcon
            aria-hidden="false"></mat-icon>
          <span class="item-label text-md" fxFlex="auto"> {{ "All Transfers" | translate}} </span>
        </div>
      </a>-->

      <!-- <div class="group-title">
        <span class="text-grey ">
          For developers
        </span>
      </div>-->
      <a *ngIf="userData.business_type == 'business'" class="item" [routerLink]="['/dashboards/api']" routerLinkActive="router-link-active" fxLayout="row" matRipple>
        <div class="flex items-center flex-none speci-nav sidenav-toolbar">
          <mat-icon [svgIcon]="'api'" class="menu-item-icon mr-1" matListIcon aria-hidden="false"></mat-icon>
          <span class="item-label text-md" fxFlex="auto">API</span>
        </div>
      </a>

      <a class="item" [routerLink]="['/dashboards/profile']" routerLinkActive="router-link-active" fxLayout="row"
        matRipple>
        <div class="flex items-center flex-none speci-nav sidenav-toolbar">
          <mat-icon [svgIcon]="'settings'" class="menu-item-icon mr-1" matListIcon aria-hidden="false"></mat-icon>
          <span class="item-label text-md" fxFlex="auto"> {{ "Settings" | translate}} </span>
        </div>
      </a>


    </div>
  </vex-scrollbar>
  <div class="px-1 profil">
    <div class="user-profil-info">
      <div class="dropdown-heading">
        {{ businessUserData?.business_legal_name }}
      </div>
      <div class="dropdown-heading">
        {{ userData?.first_name }} {{ userData?.last_name }}
      </div>
    </div>
    <vex-toolbar-user class="tools">

    </vex-toolbar-user>



  </div>
</div>