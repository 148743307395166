<vex-page-layout>




    <mat-toolbar class="" color="primary">
        <div class="primary-btns">
            <button mat-button *ngFor="let option of this.menu.options"
                color="{{option.value==this.menu.current.value?'primary':''}}"
                (click)="selectOption(option)">{{option.name | translate}}</button>
        </div>
        <div class="secondary-btns">
            <div *ngIf="this.beneficiaryOperationData.message" class="message">
                {{this.beneficiaryOperationData.message | translate}}
            </div>
        </div>
    </mat-toolbar>
    <mat-divider class="-mx-6 text-border toolbar-mat-divider"></mat-divider>






    

    <vex-page-layout-content [class.container]="layoutCtrl.value === 'boxed'"
        [class.px-gutter]="layoutCtrl.value === 'fullwidth'" class="-mt-6"
        *ngIf="this.menu.current.value=='beneficiaries'">

        <div>

            <div class="table-btns-bar mt-7 ml-4">
                <div class="text-bold text-primary mr-4 mb-4">
                    {{numberOfBeneficiaries}} {{"Beneficiaries"| translate}}
                </div>
                <button mat-button type="submit" class="disburs" 
                    (click)="openAddBeneficiaryPopup()">
                    <mat-icon class="mr-3 text-white" matPrefix>add_circle_outlined</mat-icon>
                    {{"Add Beneficiary"| translate}} </button>
            </div>
        </div>
        <div   class="w-full" vexContainer>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="30px">
                <div  class="card" fxFlex="auto">
                    <div *ngIf="!isFormReady" class="card overflow-auto -mt-16">
                        <div style="
                                      display: flex;
                                      justify-content: center;
                                      align-items: center;
                                      background: white;">
                            <mat-spinner color="primary"> </mat-spinner>
                        </div>
                    </div>


                    <div class="mt-3">
                        <div class="bg-card rounded-full border px-4" fxFlex="250px" fxFlex.lt-md="auto" fxHide.xs
                            fxLayout="row" fxLayoutAlign="start center">
                            <ic-icon [icIcon]="icSearch" size="28px"></ic-icon>
                            <input [formControl]="searchCtrlBeneficiary"
                                class="px-4 py-3 border-0 outline-none w-full bg-transparent"
                                placeholder="{{'Enter trans ID to find...'| translate}} " type="search">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <br>
        <br>
        <br>

        <div class="card overflow-auto -mt-16">
            <div *ngIf="isLoading" class="card overflow-auto -mt-16">
                <div style="
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background: white;
                            height: 20rem;
                          ">
                    <mat-spinner color="primary"> </mat-spinner>
                </div>
            </div>

            <table *ngIf="!isLoading" mat-table [dataSource]="beneficiariesDataSource" class="w-full mat-elevation-z"
                @stagger matSort matTableExporter #exporter="matTableExporter">
                <ng-container matColumnDef="beneficiary_name">
                    <th mat-header-cell *matHeaderCellDef>{{"Beneficiaries" | translate}} </th>
                    <td mat-cell *matCellDef="let element">{{ element.beneficiary_name }}</td>
                </ng-container>

                <!-- created_at Column -->
                <ng-container matColumnDef="created_at">
                    <th mat-header-cell *matHeaderCellDef>Date</th>
                    <mat-cell matTooltipPosition="after" [matTooltip]="element.formatedDate" *matCellDef="let element">
                        {{ element.created_at }}
                    </mat-cell>
                </ng-container>


                <!-- Symbol Column -->
                <ng-container matColumnDef="bank_name">
                    <th mat-header-cell *matHeaderCellDef>{{"Bank Name"| translate}} </th>
                    <td mat-cell *matCellDef="let element">{{ element.bank_name }}</td>
                </ng-container>

                <ng-container matColumnDef="account_number">
                    <th mat-header-cell *matHeaderCellDef>{{"Account"| translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ element.account_number }}</td>
                </ng-container>

                <!-- <ng-container matColumnDef="fee">
                    <th mat-header-cell *matHeaderCellDef>Pal Fee</th>
                    <td mat-cell *matCellDef="let element">{{ element.charges }}</td>
                </ng-container> -->

                <ng-container matColumnDef="narration">
                    <th mat-header-cell *matHeaderCellDef>Description</th>
                    <td mat-cell *matCellDef="let element">{{ element.narration }}</td>
                </ng-container>

                <ng-container matColumnDef="swift_code">
                    <th mat-header-cell *matHeaderCellDef>{{"Swift Code"| translate}} </th>
                    <td mat-cell *matCellDef="let element">{{ element.swift_code || 'N/A' }}</td>
                </ng-container>

                <ng-container matColumnDef="amount">
                    <th mat-header-cell *matHeaderCellDef>{{"Amount"| translate}} </th>
                    <td mat-cell *matCellDef="let element">{{ element.amount }}</td>
                </ng-container>

                <ng-container matColumnDef="currency">
                    <th mat-header-cell *matHeaderCellDef>{{"Currency"| translate}} </th>
                    <td mat-cell *matCellDef="let element">{{ element.currency }}</td>
                </ng-container>

                <ng-container matColumnDef="beneficiary_country">
                    <th mat-header-cell *matHeaderCellDef>{{"Country"|translate}} </th>
                    <td mat-cell *matCellDef="let element">

                        {{ element.beneficiary_country }}

                        <!-- <span *ngIf="element.beneficiary_country != undefined">
                            <span [innerHTML]="getFlag(element.beneficiary_country)"> 
                            </span>
                        </span>
                        -->


                    </td>
                </ng-container>

                <!--<ng-container matColumnDef="network_transaction_ref">
                    <th mat-header-cell *matHeaderCellDef>Provider Reference</th>
                    <td mat-cell *matCellDef="let element">{{ element.network_transaction_ref }}</td>
                </ng-container>-->

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>{{"Status"| translate}} </th>
                    <td mat-cell *matCellDef="let item">
                        <div [ngClass]="item.state?.cssClasses" class="px-2 py-1 my-4 text-xs font-medium rounded"
                            fxFlex="none">
                            <span> {{ item.state?.text }}</span>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="options">
                    <th mat-header-cell *matHeaderCellDef>Option</th>
                    <td mat-cell *matCellDef="let element">
                        <button (click)="selectBankBeneficiary(element.id)"
                            [matMenuTriggerFor]="beneficiaryMenu"><mat-icon [ngStyle]="{'color':'black'}"
                                matPrefix>more_horiz</mat-icon></button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="beneficiariesDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: beneficiariesDisplayedColumns"></tr>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">
                        {{
                        errorMessage || "No Transaction Data Found Or Matching The filter" | translate
                        }}
                    </td>
                </tr>
            </table>

            <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize"
                class="sticky left-0"></mat-paginator>
        </div>
    </vex-page-layout-content>



    <!--MOBILE BENEFICIARY-->
    <vex-page-layout-content [class.container]="MlayoutCtrl.value === 'boxed'"
        [class.px-gutter]="MlayoutCtrl.value === 'fullwidth'" class="-mt-6"
        *ngIf="this.menu.current.value=='mb_beneficiaries'">

        <div> 
            <div class="table-btns-bar mt-7 ml-4">
                <div class="text-bold text-primary mr-4 mb-4">
                    {{MnumberOfBeneficiaries}} {{"Beneficiaries" | translate}}
                </div>
                <button mat-button type="submit" class="disburs" 
                    (click)="MopenAddBeneficiaryPopup()">
                    <mat-icon class="mr-3 text-white" matPrefix>add_circle_outlined</mat-icon>
                    {{"Add Beneficiary" | translate}} </button>
            </div> 
        </div>
        <div   class="w-full" vexContainer>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="30px">
                <div   class="card" fxFlex="auto">


                    <div *ngIf="!isFormReady" class="card overflow-auto -mt-16">
                        <div style="
                                      display: flex;
                                      justify-content: center;
                                      align-items: center;
                                      background: white;">
                            <mat-spinner color="primary"> </mat-spinner>
                        </div>
                    </div> 
                    <div class="mt-3">
                        <div class="bg-card rounded-full border px-4" fxFlex="250px" fxFlex.lt-md="auto" fxHide.xs
                            fxLayout="row" fxLayoutAlign="start center">
                            <ic-icon [icIcon]="icSearch" size="28px"></ic-icon>
                            <input [formControl]="MsearchCtrlBeneficiary"
                                class="px-4 py-3 border-0 outline-none w-full bg-transparent"
                                placeholder="{{'Enter wallet to find...'| translate}}" type="search">
                        </div>
                    </div>


                </div>
            </div>
        </div> <br>  <br>  <br> <br>

        <div class="card overflow-auto -mt-16">
            <div *ngIf="MisLoading" class="card overflow-auto -mt-16">
                <div style="
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background: white;
                            height: 20rem;
                          ">
                    <mat-spinner color="primary"> </mat-spinner>
                </div>
            </div>

            <table *ngIf="!MisLoading" mat-table [dataSource]="MbeneficiariesDataSource" class="w-full mat-elevation-z"
                @stagger matSort matTableExporter #exporter="matTableExporter">
                <ng-container matColumnDef="phone_no">
                    <th mat-header-cell *matHeaderCellDef> {{"Mobile Wallet" | translate}} </th>
                    <td mat-cell *matCellDef="let element">{{ element.phone_no}}</td>
                </ng-container> 
                <!-- created_at Column -->
                <ng-container matColumnDef="country">
                    <th mat-header-cell *matHeaderCellDef>{{"Country" | translate}} </th>
                    <mat-cell matTooltipPosition="after" [matTooltip]="element.formatedDate" *matCellDef="let element">
                        {{ element.country }}
                    </mat-cell>
                </ng-container> 
                <!-- Symbol Column -->
                <ng-container matColumnDef="bank_name">
                    <th mat-header-cell *matHeaderCellDef>{{"Name" | translate}} </th>
                    <td mat-cell *matCellDef="let element">{{ element.full_name }}</td>
                </ng-container>

                <ng-container matColumnDef="beneficiary_country">
                    <th mat-header-cell *matHeaderCellDef>{{"Country" | translate}} </th>
                    <td mat-cell *matCellDef="let element">{{ element.country }} </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>{{"Status" | translate}} </th>
                    <td mat-cell *matCellDef="let item">
                        <div [ngClass]="item.state?.cssClasses" class="px-2 py-1 my-4 text-xs font-medium rounded"
                            fxFlex="none">
                            <span> {{ item.state?.text }}</span>
                        </div>
                    </td>
                </ng-container> 
                <ng-container matColumnDef="options">
                    <th mat-header-cell *matHeaderCellDef>{{"Option" | translate}} </th>
                    <td mat-cell *matCellDef="let element">
                        <button (click)="MselectMobileBeneficiary(element.id)"
                            [matMenuTriggerFor]="MbeneficiaryMenu"><mat-icon [ngStyle]="{'color':'black'}"
                                matPrefix>more_horiz</mat-icon></button>
                    </td>
                </ng-container> 
                <tr mat-header-row *matHeaderRowDef="MbeneficiariesDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: MbeneficiariesDisplayedColumns"></tr>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">
                        {{
                        errorMessage || "No Transaction Data Found Or Matching The filter" | translate
                        }}
                    </td>
                </tr>
            </table>

            <mat-paginator [pageSizeOptions]="MpageSizeOptions" [pageSize]="MpageSize"
                class="sticky left-0"></mat-paginator>
        </div>
    </vex-page-layout-content>






</vex-page-layout>

 

<mat-menu #beneficiaryMenu="matMenu" id="disburse-popup"
    class="z-10 w-50 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600 block"
    style="position: absolute; inset: 0 auto auto 0; margin: 0; transform: translate(23px, 70px);"
    data-popper-reference-hidden="" data-popper-escaped="" data-popper-placement="bottom">

    <ul class="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownInformationButton">
        <li>
            <a (click)="deleteBankBeneficiary(this.beneficiaryOperationData.selectedBankBeneficiary)" mat-button
                class="block py-0 px-1 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                <button><mat-icon beneficiaryMenu matPrefix>delete</mat-icon></button>
                <div class="disburse-content">
                    <p>{{"Delete"| translate}} </p>
                </div>
            </a>
        </li>
    </ul>
</mat-menu>
<mat-menu #MbeneficiaryMenu="matMenu" id="disburse-popup"
  class="z-10 w-50 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600 block"
  style="position: absolute; inset: 0 auto auto 0; margin: 0; transform: translate(23px, 70px);"
  data-popper-reference-hidden="" data-popper-escaped="" data-popper-placement="bottom">

  <ul class="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownInformationButton">
    <li>
      <a (click)="MdeleteMobileBeneficiary(this.MbeneficiaryOperationData.selectedMobileBeneficiary)" mat-button
        class="block py-0 px-1 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
        <button><mat-icon beneficiaryMenu color="primary" matPrefix>delete</mat-icon></button>
        <div class="disburse-content">
          <p>{{"Delete" | translate}} </p>
        </div>
      </a>
    </li>
  </ul>
</mat-menu>