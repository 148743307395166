<div class="w-full toolbar px-gutter" fxLayout="row" fxLayoutAlign="start center" *ngIf="businessDataLoaded"
    vexContainer>
    <button (click)="openSidenav()" [fxHide]="!mobileQuery" mat-icon-button type="button">
        <mat-icon [icIcon]="icMenu"></mat-icon>
    </button>
    <div *ngIf="(pageTitle$ | async); else navBarBtn"
        style="color: var(--sidenav-item-dropdown-background); font-size: 25px; font-weight: bold;">
        {{(pageTitle$ | async)?.title | translate}}
    </div>

    <ng-template #navBarBtn>

        <a *ngIf="isVerticalLayout$ | async" [routerLink]="['/']" class="block ltr:mr-4 rtl:ml-4" fxLayout="row"
            fxLayoutAlign="start center">
            <img class="w-8 select-none" src="assets/img/demo/logo.svg" alt="">
            <h1 [fxHide]="mobileQuery" class="m-0 select-none title ltr:pl-4 rtl:pr-4">
                VEX
            </h1>
        </a>


        <!-- for responssive -->

        <div class="p-10 reponssivebutton">
            <div class="dropdown inline-block relative">
                <button (click)="toggleMenu()"
                    class="bg text-gray-700 font-semibold moreplus py-2 px-4 rounded inline-flex items-center">
                    <span class="mr-1">More +</span>
                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                </button>
                <ul class="dropdown-menu absolute text-gray-700 pt-1 respbutton" *ngIf="isOpen">
                    <div class="btn">
                        <button class="disburs" mat-raised-button type="button" (click)="openDisburseMobilePopup()">
                            {{ "Disburse cash" |translate}}
                        </button>
                    </div>
                    <div class="btn">
                        <button class="apiDoc" (click)="onViewApiPart()" mat-raised-button type="button">
                            API
                        </button>
                    </div>
                </ul>
            </div>
        </div>
        <!-- Dropdown menu -->




 



       <!-- <mat-menu #menu="matMenu" id="disburse-popup" (close)="closeDisburseMenu()" (closed)="closeDisburseMenu()"
            class="z-10 w-50 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600 block"
            style="position: absolute; inset: 0 auto auto 0; margin: 0; transform: translate(23px, 70px);"
            data-popper-reference-hidden="" data-popper-escaped="" data-popper-placement="bottom">

            <ul class="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownInformationButton">

                <li (click)="openDisburseMobilePopup()">
                    <a mat-button
                        class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                        <span class="icon menu-item-icon">
                            <mat-icon class="" svgIcon="side-balance" color="primary"></mat-icon>
                        </span>
                        <div class="disburse-content">
                            <h4>
                                {{ "Disburse to Mobile wallet" | translate}}
                            </h4>
                            <p>{{"Send to mobile wallet or merchant account" | translate}} </p>
                        </div>
                    </a>
                </li>

                <mat-divider></mat-divider>

                <li (click)="openDisburseBankPopup()">
                    <a mat-button
                        class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                        <span class="icon menu-item-icon">
                            <mat-icon class="" svgIcon="bank-account" color="primary"></mat-icon>
                        </span>
                        <div class="disburse-content">
                            <h4>
                                {{"Disburse to Bank account" |translate}}
                            </h4>
                            <p>{{"Send to bank account" | translate}} </p>
                        </div>
                    </a>
                </li>

                <mat-divider></mat-divider>

                <li>
                    <a (click)="toggleBulkDisbursePopup()" mat-button
                        class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white ">
                        <span class="icon menu-item-icon">
                            <mat-icon class="" [icIcon]="icon_peapole"></mat-icon>
                        </span>
                        <div class=" disburse-content">
                            <h4>
                                {{"Bulk disbursement" | translate}}
                            </h4>
                            <p>{{ "Send to multiple wallet at once" | translate}} </p>
                        </div>
                    </a>
                </li>



            </ul>


        </mat-menu>
        <div class="grid grid-cols-2 gap-4 noresponssive">
            <div [matMenuTriggerFor]="menu">
                <button class="disburs" mat-raised-button type="button" (click)="toggleDisbursePopup()">
                    {{ "Disburse" | translate}}
                    <mat-icon class="arrow">{{ !isOpenDisbursePopup ? 'arrow_drop_down' : 'arrow_drop_up'}}</mat-icon>
                </button>
            </div>
        </div>-->



 

        <div>
            <button class="disburs2" mat-raised-button type="button" (click)="openDisburseMobilePopup()">
                {{ "Disburse cash" | translate}}
            </button>
        </div>

        <div class="p-10"></div>
        <mat-menu #addNewMenu="matMenu" [overlapTrigger]="false" xPosition="after" yPosition="below">
        </mat-menu>
        <mat-menu #documentMenu="matMenu"></mat-menu>
        <div *ngIf="(isVerticalLayout$ | async) && isNavbarInToolbar$ | async" [fxHide]="mobileQuery" class="px-gutter"
            fxFlex="none" fxLayout="row" fxLayoutAlign="start center">
            <vex-navigation-item *ngFor="let item of navigationItems" [item]="item"></vex-navigation-item>
        </div>
    </ng-template>

    <span fxFlex></span>




    




    <div class="flex items-center -mx-1 rigthside">
        <button mat-stroked-button color="primary" class="add-funds" type="button" (click)="openRechargePopup()">
            <mat-icon class="mr-2">add_circle_outlined</mat-icon> {{"Add Funds" | translate }}
        </button>
        <div class=" mx-3">
            <vex-toolbar-notifications></vex-toolbar-notifications>
        </div>
        <div class="px-1 profil">
            <vex-toolbar-user></vex-toolbar-user>
        </div>
    </div>


    
</div>

<vex-navigation *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async" [fxHide]="mobileQuery">
</vex-navigation>