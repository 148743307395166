<mat-divider class="-mx-6 text-border toolbar-mat-divider"></mat-divider>

<div class="container">


    

    <div class="card" style="width: 350px;">

        <div style=" padding: 30px;">
            <h4>Bal: <b>{{(currentCurrencyBalance.balance | number: "1.2-2")|| "0.00" }}
                    {{exchangeForm.value.start_currency}}</b> </h4>
            <hr style="color: black;">
        </div>

        <form action="" [formGroup]="exchangeForm" (ngSubmit)="processExchange()">
            <div style="display: flex; padding: 30px; ">
                <div style="flex: 1;">
                    <select mat-select class="account-select" name="" id="" formControlName="start_currency">
                        <option value="">{{"Start currency" |translate}} </option>
                        <option value="{{currency}}" *ngFor="let currency of startCurrencies">{{currency}}</option>
                    </select>
                </div>
                
                <div style="flex: 1; text-align: right;">
                    <select mat-select class="account-select" name="" id="" formControlName="end_currency">
                        <option value="">{{"End currency" |translate}} </option>
                        <option value="{{currency}}" *ngFor="let currency of endCurrencies">{{currency}}</option>
                    </select>
                </div>
            </div>

            <div style="display: flex; padding-right: 30px; padding-left: 30px;" class="mt-5">
                <div style="flex: 1;">
                    <input type="number" placeholder="  {{exchangeForm.value.start_currency}}" class="customInput"
                        formControlName="amount" [max]="maxAmountToExchange">
                    <!--<div matPrefix class="currency-prefix">
                        <span>XOF</span>
                    </div>
                    <input type="number" class="customInput" formControlName="amount" [maxValue]="maxAmountToExchange">-->
                </div>
            </div>
            <div style="display: flex; padding-right: 30px; padding-left: 30px;">
                <span *ngIf="amountToExchangeIsExceded" style="color: red;">
                    {{"Amount limit" |translate }} {{currentCurrencyBalance.balance}}
                </span>
            </div>



            <div class="mt-5" style="display: flex; padding-right: 30px; padding-left: 30px;">
                <div style="flex: 1;" class="text-center">

                    <span *ngIf="exchangeRateExist">
                        <b>{{exchangeForm.value.start_currency}} {{(exchangeForm.value.amount | number: "1.2-2")|| "0.00" }} =
                            {{(exchangeForm.value.amount*exchangeRate | number: "1.2-2")|| "0.00"}}
                            {{exchangeForm.value.end_currency}}</b>
                        <br>
                        <ul>
                            <li>
                                {{"Daily rate:" |translate }} {{exchangeRate}}
                            </li>
                        </ul>
                    </span>
                    <span *ngIf="!exchangeRateExist" style="color:red">
                        {{"Exchange not available" |translate}}
                    </span>

                </div>
            </div>

            <div style="display: flex; padding-right: 30px; padding-left: 30px;" class="mt-5 mb-5 pb-5">
               <!-- <button [disabled]="exchangeForm.invalid || !exchangeRateExist" mat-button type="button"
                    [ngClass]="exchangeForm.invalid? 'unvalid-send-button' : 'valid-send-button' "
                    (click)="processExchange()">
                    Approved
                </button>-->
                <button class="authButton" mat-raised-button type="button"
                    [disabled]="exchangeForm.invalid || !exchangeRateExist" (click)="processExchange()">
                    {{"Approved" | translate}}
                </button>

            </div>
        </form>


    </div>


    <!--
         <div class="card">

        <div style=" padding: 30px;">
            <h4>Bal: <b>{{(currentCurrencyBalance.balance | number: "1.2-2")|| "0.00" }}
                    {{exchangeForm.value.start_currency}}</b> </h4>
            <hr style="color: black;">
        </div>

        <form action="" [formGroup]="exchangeForm" (ngSubmit)="processExchange()">
            <div style="display: flex; padding: 30px;">
                <div style="flex: 1;">
                    <select mat-select class="account-select" name="" id="" formControlName="start_currency">
                        <option value="">{{"Start currency" |translate}} </option>
                        <option value="{{currency}}" *ngFor="let currency of startCurrencies">{{currency}}</option>
                    </select>
                </div>
                <div style="flex: 1; text-align: center; margin-top: 10px;">
                    {{"--------------"}}
                </div>
                <div style="flex: 1; text-align: right;">
                    <select mat-select class="account-select" name="" id="" formControlName="end_currency">
                        <option value="">{{"End currency" |translate}} </option>
                        <option value="{{currency}}" *ngFor="let currency of endCurrencies">{{currency}}</option>
                    </select>
                </div>
            </div>

            <div style="display: flex; padding-right: 30px; padding-left: 30px;" class="mt-5">
                <div style="flex: 1;">
                    <input type="number" placeholder="  {{exchangeForm.value.start_currency}}" class="customInput"
                        formControlName="amount" [max]="maxAmountToExchange">
                     
                </div>
            </div>
            <div style="display: flex; padding-right: 30px; padding-left: 30px;">
                <span *ngIf="amountToExchangeIsExceded" style="color: red;">
                    {{"Amount limit" |translate }} {{currentCurrencyBalance.balance}}
                </span>
            </div>



            <div class="mt-5" style="display: flex; padding-right: 30px; padding-left: 30px;">
                <div style="flex: 1;" class="text-center">

                    <span *ngIf="exchangeRateExist">
                        <b>{{exchangeForm.value.start_currency}} {{exchangeForm.value.amount}} =
                            {{(exchangeForm.value.amount*exchangeRate | number: "1.2-2")|| "0.00"}}
                            {{exchangeForm.value.end_currency}}</b>
                        <br>
                        <ul>
                            <li>
                                {{"Daily rate:" |translate }} {{exchangeRate}}
                            </li>
                        </ul>
                    </span>
                    <span *ngIf="!exchangeRateExist" style="color:red">
                        {{"Exchange not available" |translate}}
                    </span>

                </div>
            </div>

            <div style="display: flex; padding-right: 30px; padding-left: 30px;" class="mt-5 mb-5 pb-5"> 
                <button class="authButton" mat-raised-button type="button"
                    [disabled]="exchangeForm.invalid || !exchangeRateExist" (click)="processExchange()">
                    {{"Approved" | translate}}
                </button>

            </div>
        </form>


    </div>




    -->

  
</div>