import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from '@angular/material/form-field';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import icClose from '@iconify/icons-ic/twotone-close';
import { TransactionsService } from 'src/app/services/transactions.service';
import { take, takeUntil } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { BUSINESS_DATA_KEY, COUNTRIES, COUNTRY_CODES, USER_SESSION_KEY } from 'src/app/Models/constants';
import { BeneficiariesService } from 'src/app/services/beneficiaries.service';
import { color } from '@alyle/ui/color';
import { MatDialogRef } from '@angular/material/dialog';
import { User } from 'src/app/Models/models.interface';
import { Router } from '@angular/router';


@Component({
  selector: 'vex-add-bank-beneficiary-v2',
  templateUrl: './add-bank-beneficiary-v2.component.html',
  styleUrls: ['./add-bank-beneficiary-v2.component.scss']
})
export class AddBankBeneficiaryV2Component implements OnInit, OnDestroy {

  unsubscribe$ = new Subject();
  icClose = icClose;
  form: FormGroup;
  countries = COUNTRIES;
  country_codes = COUNTRY_CODES;
  //bankTransfer: BankTransferData;
  banks = ['BSIC', 'NSIA', 'BOA', 'ECOBANK'];
  availableCountries = [
    { code: 'BJ', label: 'BENIN', type: "" },
    { code: 'CI', label: `COTE D'IVOIRE`, type: "" },
    { code: 'TG', label: 'TOGO', type: "" },
    { code: 'SN', label: 'SENEGAL', type: "" },
    // { code: 'GH',  label: 'GHANA'},
    { code: 'ML', label: 'MALI', type: "" },
    { code: 'GW', label: 'GUINEE B.', type: "" },
    { code: 'NE', label: 'NIGER', type: "" },
    { code: 'BF', label: 'BURKINA FASO', type: "" },
    { code: 'NG', label: 'NIGERIA', type: "finpesa" },
  ];

  networkProviders = ['mtn', 'orange'];
  currencies = ['GHS', 'XOF', 'XAF', 'NGN'];
  bankBranches = [];
  confirmedTransfer = false;
  submitBtnText = 'Save Beneficiary';
  errorMessage = '';
  banksData: any;
  userBusinessData: any;
  credentials: string;
  isDisbursing: boolean;
  hasError: boolean;
  isSwiftCodeNeeded = false;
  isBankBranchNeeded = false;
  isFetchingBankBranches: boolean;
  isFetchingBanksData: boolean;
  bankTransferApi: string;
  userData: User


  saveBeneficiary: boolean = true
  phoneNumberValidationPattern = /^[+][0-9]{0,15}$/;

  validationMessages = {
    repeat_phone_no: {
      pattern: 'Only digits allowed starting with ',
      required: 'Receiver\'s Phone Field  is required.',
      min: 'Please provide a correct phone number',
    },
    phone_no: {
      pattern: 'Only digits allowed starting with ',
      required: 'Receiver\'s Phone Field  is required.',
      min: 'Please provide a correct phone number',
      invalidPrefix: 'Invalid phone number prefix, Operator not found!',
    },
    amount: {
      pattern: 'Only number allowed',
      required: 'Amount This Field  is required.',
      amountExceededLimit: 'Amount in XOF should not exceed 1 700 000',
      hasComma: 'XOF does not support decimals',
    },
  };

  dailingCode: string = '+229';
  currency: string = 'XOF'
  accountNumberLength: number = 24;
  maxAccountNumberLength: number = 24;

  isProcessing: boolean = false

  constructor(
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    //private dialogRef: MatDialogRef<AddBeneficiaryComponent>,
    private transactionService: TransactionsService,
    private beneficiaryService: BeneficiariesService,
    private router: Router,
  ) {
    const businessData = localStorage.getItem(BUSINESS_DATA_KEY);
    if (businessData !== 'undefined') {
      this.userBusinessData = JSON.parse(businessData);
    }

    const sessionData = JSON.parse(localStorage.getItem(USER_SESSION_KEY));
    this.userData = sessionData;
    this.credentials = `${this.userData?.api_secret_key_live}:${this.userData?.api_public_key_live}`;


  }


  isLoadingBankAccountName: boolean = false;
  bankAccountName: string = '';

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      account_number: [
        '',
        [Validators.required, Validators.pattern(`^[a-zA-Z0-9]{${this.accountNumberLength}}$`)],
      ],
      beneficiary_name: ['TEST'],//, [Validators.required]
      beneficiary_country: [this.availableCountries[1].code, [Validators.required]],
      currency: ['XOF', [Validators.required]],
      bank_branch: ['',],
      bank_name: [''],
      bank_code: [''],
      phone_no: [''], //[Validators.required, Validators.min(8), Validators.pattern(this.phoneNumberValidationPattern)]
      email: ['', [Validators.email]],
      user_id: this.userBusinessData?.user_id,
      swift_code: [],
    });

    this.form.get('beneficiary_country').patchValue(this.availableCountries[1].code);
    this.getBankList(this.credentials, this.form.get('beneficiary_country').value, this.bankTransferApi);

    this.form.get('beneficiary_country').valueChanges.subscribe((value) => {
      if (value == 'GH') {
        this.currency = 'GHS';

      } else if (value == 'NG') {
        this.currency = 'NGN';

      } else {
        this.currency = 'XOF';

      }
      this.form.get('currency').setValue(this.currency);
      this.setAccountNumberLength(value)
      this.setBankTransferApi(value);
      this.setIsSwiftCodeNeeded(value);
      this.setIsBankBranchNeeded(value);
      this.dailingCode = this.country_codes.find(code => code.code == value).dial_code;
      this.form.get('phone_no').setValue(this.dailingCode);
      let bankGetParam = ''
      bankGetParam = this.bankTransferApi == 'finpesa' ? this.currency : value;
      this.getBankList(this.credentials, bankGetParam, this.bankTransferApi);
    });
    this.form.get('bank_code').valueChanges.subscribe((value) => {
      this.setBankName(value);
      this.getBankBranches(this.credentials, value);

      let currency = 'NGN';
      let account_number = this.form.get('account_number').value;
      let bank_id = value;
      if (bank_id != '' && currency != '' && account_number != '') {
        this.getBankAccountBeneficiary(account_number, bank_id, currency)
      }


    });
    this.form.get('account_number').valueChanges.subscribe((value) => {
      let currency = 'NGN';
      let account_number = value;
      let bank_id = this.form.get('bank_code').value;
      if (bank_id != '' && currency != '' && account_number != '') {
        this.getBankAccountBeneficiary(account_number, bank_id, currency)
      }
    });





  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getBankAccountBeneficiary(account_number, bank_id, currency) {
    this.isLoadingBankAccountName = true
    this.bankAccountName = ''
    this.transactionService
      .getBankAccountBeneficiary({ account_number: account_number, bank_id: bank_id, currency: currency }, this.credentials)
      .pipe(take(1))
      .subscribe(
        (response) => { 
          this.isLoadingBankAccountName = false
          if (response['status'] == true) {
            this.bankAccountName = response['account_name']
            this.form.get('beneficiary_name').setValue(this.bankAccountName);
          } else {
            this.bankAccountName = 'NA'
          }
        },
        (error) => {
          this.isLoadingBankAccountName = false
          this.bankAccountName = 'NA'
        }
      );

  }





  setBankName(bankCode) {
    const bank = this.banksData.find(value => value.code === bankCode || value.id === bankCode);
    this.form.get('bank_name').patchValue(bank.name);
  }

  setIsSwiftCodeNeeded(country: string) {
    if (country === 'BJ' || country === 'CI' || country === 'SN' || country === 'NG') {
      this.isSwiftCodeNeeded = false;
    } else {
      this.isSwiftCodeNeeded = true;
    }
  }
  setIsBankBranchNeeded(country: string) {
    if (country === 'NG') {
      this.isBankBranchNeeded = false;
    } else {
      this.isBankBranchNeeded = true;
    }
  }
  setBankTransferApi(country: string) {
    const bankTransaferApi = this.availableCountries.find((data) => data.code === country)
    if (bankTransaferApi) {
      this.bankTransferApi = bankTransaferApi.type
    } else {
      this.bankTransferApi = ''
    }
  }


  setCurrency(country: string) {
    let currency;
    if (country !== 'GH') {
      currency = 'XOF';
    }
    this.form.get('currency').setValue(currency);
  }

  get formControls() {
    return this.form.controls;
  }

  get isFormReady(): boolean {
    return true;
  }

  cancelAction() {
    this.confirmedTransfer = false;
    this.submitBtnText = 'Confirm Transfer';
  }

  submitAction() {
    if (!this.form.valid) {
      this.errorMessage = 'Please fill up required fields';
      return;
    }
    this.isProcessing = true
    this.saveBeneficiary = false;
    this.beneficiaryService.addBankBeneficiary(this.credentials, this.form.value).pipe(take(1))
      .subscribe((data) => {
        this.isProcessing = false
        if (data['status'] == true) {
          this.openSnackbar(data['message'])
          this.router.navigate(['/dashboards/beneficiary'])
        } else {
          this.openSnackbar(data['message'])
        } 
        //this.dialogRef.close(data) 
        //this.dialogRef.close(data);
      });
  }

  confirmTransfer() {
    this.confirmedTransfer = true;
    this.submitBtnText = 'Approve Transfer';
  }

  cancelConfirmTransfer() {
    this.form.reset();
    this.confirmedTransfer = false;
  }

  approveTransfer() {
    this.confirmedTransfer = false;
    this.submitBtnText = 'Confirm Transfer';
  }

  cancelApprovedTransfer() {
    this.confirmedTransfer = false;
    this.submitBtnText = 'Confirm Transfer';
  }

  getBankName() {
    if (!this.isSwiftCodeNeeded) {
      const bank = this.banksData.find(
        (data) => data.code === this.form.get('bank_name').value
      );
      return bank.name || 'N/A';
    } else {
      return this.form.get('bank_name').value;
    }
  }
  getBankList(credentials, data, type) {
    this.isFetchingBanksData = true;
    this.transactionService
      .getBankDetails(credentials, data, type)
      .pipe(take(1))
      .subscribe((bankDetails) => {
        this.isFetchingBanksData = false;
        this.banksData = bankDetails;
        if (bankDetails != null && bankDetails != undefined) {
          const selectedValue = bankDetails[0]?.code ?? bankDetails[0]?.id ?? '';
          this.form.get('bank_name').setValue(selectedValue);
        } else {
          this.form.get('bank_name').setValue('');
        }
      },
        (error) => {
          this.isFetchingBanksData = false;
        });
  }


  setAccountNumberLength(country: string) {
    country === 'NG' ? this.accountNumberLength = 10 : this.accountNumberLength = 24
    country === 'NG' ? this.maxAccountNumberLength = 10 : this.maxAccountNumberLength = 24
    const accountNumberControl = this.form.get('account_number');
    if (accountNumberControl) {
      accountNumberControl.setValidators([
        Validators.required,
        Validators.pattern(`^[a-zA-Z0-9]{${this.accountNumberLength}}$`)
      ]);
      accountNumberControl.updateValueAndValidity();
    }
  }

  getBankBranches(credentials, bankCode) {
    this.isFetchingBankBranches = true;
    const selectedBank = this.banksData?.find((bank) => bank.code === bankCode);
    this.transactionService
      .getBankBranchDetails(credentials, selectedBank?.id)
      .pipe(take(1))
      .subscribe((data) => {
        this.isFetchingBankBranches = false;
        this.bankBranches = data;

        this.bankBranches !== null ? this.form.get('bank_branch').patchValue(this.bankBranches[0].branch_name) : this.form.get('bank_branch').patchValue('');


      });
  }
  openSnackbar(message) {
    this.snackBar.open(message, 'CLOSE', {
      duration: 5000,
      horizontalPosition: 'right',
    });
  }

}
