<!-- (ngSubmit)="save()" -->

    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
      <h2 class="m-0 headline" fxFlex="auto">Filter</h2>
      <button
        class="text-secondary"
        mat-dialog-close
        mat-icon-button
        type="button"
      >
        <mat-icon class="icon" [icIcon]="icClose"></mat-icon>
      </button>
    </div>
    <mat-divider class="-mx-6 text-border"></mat-divider>
    <mat-dialog-content fxLayout="column" class="content-part mx-50">
        <form  [formGroup]="form">
            <div class="input-bloc w-full">
                <div class="input-row">
                    <div class="customInput">
                        <mat-form-field appearance="outline" class="sm:ml-6 w-full">
                            <mat-label>From</mat-label>
                            <input
                                    formControlName="dateFrom"
                                    [matDatepicker]="datepickerRefa"
                                    matInput
                            >
                            <mat-datepicker-toggle
                                    [for]="datepickerRefa"
                                    matSuffix
                            ></mat-datepicker-toggle>
                            <mat-datepicker #datepickerRefa></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="customInput flex justify-end">
                        <mat-form-field appearance="outline" class="sm:ml-6 w-full">
                            <mat-label>To</mat-label>
                            <input
                                    [matDatepicker]="datepickerRefb"
                                    formControlName="dateTo"
                                    matInput
                            >
                            <mat-datepicker-toggle
                                    [for]="datepickerRefb"
                                    matSuffix
                            ></mat-datepicker-toggle>
                            <mat-datepicker #datepickerRefb></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div  class="input-row">
                    <div class="customInput customSelect">
                        <div class="flex input-label">
                          <span class="">Select the recipient's country</span>
                        </div>
                        <mat-form-field appearance="outline" class="sm:ml-6 flex-auto w-full mat-form-field-full">
                          <mat-select placeholder="Select a Country" formControlName="country">
                            <mat-option *ngFor="let country of availableCountries" [value]="country.code">
                              {{ country.label }}
                            </mat-option>
                          </mat-select>
                          <!-- <mat-error *ngIf="formControls?.country?.errors?.required">This field is required</mat-error> -->
                        </mat-form-field>
                      </div>
                </div>
                  <div class="customInput customSelect">
                    <div class="flex input-label">
                      <span class="">Select the status</span>
                    </div>
                    <mat-form-field appearance="outline" class="sm:ml-6 flex-auto w-full mat-form-field-full">
                      <mat-select placeholder="Select the status" formControlName="status">
                        <mat-option *ngFor="let status of statuses" [value]="status.value">
                          {{ status.name }}
                        </mat-option>
                      </mat-select>
                      <!-- <mat-error *ngIf="formControls?.country?.errors?.required">This field is required</mat-error> -->
                    </mat-form-field>
                  </div>
            </div>
        </form>
    </mat-dialog-content>
    <mat-divider class="-mx-6 text-border"></mat-divider>
    <mat-dialog-actions class="btn-bloc">
        <div class="">
            <button
        class="cancel-btn m-2"
        mat-dialog-close
        mat-raised-button
        type="button"
      >
      Clear
        </button>
            <button (click)="close()" mat-raised-button class="confirm-btn m-2">
                Apply Filter
            </button>
        </div>
    </mat-dialog-actions>