 
<hr style="border-color: rgba(0, 0, 0, 0.169);">


<div class="container">

    <div style="display: flex; flex-wrap: wrap; margin-left: 20px; margin-right: 20px; ">

        <div style="flex: 1  ; margin-right: 10px;  "> </div>

        <div style="flex: 1  ; margin-right: 10px; margin-top: 50px;">
            <div class="card">

                <form [formGroup]="bankDeposittopUpForm"> 


                    <div style="padding: 20px;">
                        <div style="display: flex;">
                            <div style="flex: 2">
                                <h3><strong>Bank Deposit</strong></h3>
                            </div>
                            <div style="flex: 1;">
                                <mat-icon [svgIcon]="'close'"
                                    style="position: relative; left: 80%; height: 30px; width: 30px;  cursor: pointer;"
                                    class="menu-item-icon mr-1" matListIcon aria-hidden="false" (click)="goBack()">
                                </mat-icon>
                            </div>
                        </div>
                    </div>
                    <hr>

                    <div style="padding-bottom: 30px;padding-left: 30px;padding-right: 30px; padding-top: 25px;">


                        <mat-dialog-content fxLayout="column"> 
                            <div style="color: red;">
                                Please upload the receipt for the amount deposited at the bank. Our team will proceed with the approval of your recharge.
                            </div>
                            <br>  
                            <div>
                                <div class="label">
                                    <label for="" class="label">{{"Amount deposited."| translate}} </label>
                                </div>
                            </div>
                            <mat-form-field appearance="outline" class="custom-input">
                                <input matInput formControlName="amount" placeholder="" type="number">
                                <mat-error>
                                    <!--<span *ngFor="let error of mobileMoneytopUpForm.get('amount').errors | keyvalue ">
                                    {{ validationMessages.amount[error.key] | translate }}
                                </span>-->
                                </mat-error>
                            </mat-form-field> 
                             
                            <br>  
                            <!--<div fxLayout="row" fxLayoutAlign="start">
                                <mat-file-upload [labelText]="''"
                                    style="height: 50px; width: 450px; border: dotted 1px #ccc;" class="text-center"
                                    [uploadButtonText]="'upload'" [uploadButtonType]="'button'"
                                    [showUploadButton]="false" [selectButtonText]="'Upload reciept by Choose a file*'"
                                    [acceptedTypes]="'.doc, .pdf, .jpg, .jpeg, .png'" (uploadClicked)="uploadID($event)"
                                    (change)="uploadID($event)">
                                </mat-file-upload>
                            </div>-->
                            <div fxLayout="row" fxLayoutAlign="start">
                                <mat-file-upload [labelText]="'Upload reciept by'"
                                    style="height: 50px; width: 450px; border: dotted 1px #ccc; padding-top:10px;" class="text-center"
                                    [ngStyle]="{'padding-top': !isUploading ? '10px' : '0px'}"
                                    [uploadButtonText]="'upload'" [uploadButtonType]="'button'"
                                    [showUploadButton]="false" [selectButtonText]="'Choose a file*'"
                                    [acceptedTypes]="'.doc, .pdf, .jpg, .jpeg, .png'" (uploadClicked)="uploadID($event)"
                                    (change)="uploadID($event)">
                                </mat-file-upload>
                            </div>






                        </mat-dialog-content>

                    </div>
                    <hr>

 
                    <div class="hoverable-div" style="padding: 20px;cursor: pointer;"> 
                        <button color="primary" mat-button type="button" (click)="rechargeAccount()"
                        [ngClass]="(isProcessing || bankDeposittopUpForm.invalid || !isUploading)? 'invalid-send-button': 'valid-send-button'"
                        [disabled]="isProcessing || !bankDeposittopUpForm.valid || !isUploading ">
                        
                        
                        {{ isProcessing === true? 'Processing...' :'' | translate}} 
                        {{ (isProcessing === false && (isUploading == false || bankDeposittopUpForm.valid==false) ) ? 'Approve' :'' | translate}}
                        {{ (isProcessing === false && isUploading == true && bankDeposittopUpForm.valid==true) ? 'We will credit you : '+(bankDeposittopUpForm.value.amount | number:'1.0-0') +' '+currency :''| translate}}


                    </button> 

                       
                    </div>


                </form>


            </div>
        </div>

        <div style="flex: 1  ; margin-right: 10px; "> </div>

    </div>

 

</div>