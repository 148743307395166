import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { USER_SESSION_KEY, BUSINESS_DATA_KEY } from 'src/app/Models/constants';
import { TransactionsService } from 'src/app/services/transactions.service';
import { CustomerCreateUpdateComponent } from '../../apps/aio-table/customer-create-update/customer-create-update.component';
import icMoreVert from '@iconify/icons-ic/twotone-more-vert';
import icClose from '@iconify/icons-ic/twotone-close';
import { BusinessService } from 'src/app/services/business.service';
import outlineUpload from '@iconify/icons-ic/outline-upload';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { RechargeAccountComponent } from '../recharge-account/recharge-account.component';
@Component({
  selector: 'vex-recharge-account-methods-choosed',
  templateUrl: './recharge-account-methods-choosed.component.html',
  styleUrls: ['./recharge-account-methods-choosed.component.scss']
})
export class RechargeAccountMethodsChoosedComponent implements OnInit, OnDestroy {
  countryData = {
    BJ: { currency: 'XOF', code: '+229' },
    CI: { currency: 'XOF', code: '+225' },
    GH: { currency: 'GHS', code: '+233' },
    TG: { currency: 'XOF', code: '+227' },
    SN: { currency: 'XOF', code: '+221' },
    NG: { currency: 'NGN', code: '+234' },
  };
  operatorPrefixData: any;
  icClose = icClose;
  topUpForm: FormGroup;
  mobileMoneytopUpForm: FormGroup
  merchantDeposittopUpForm: FormGroup
  bankDeposittopUpForm: FormGroup
  virtualAccountForm: FormGroup
  currency = '';
  dailingCode = '+229';
  module_id = 102;
  data: any;
  userData: any;
  moduleData: Object[];
  userBusinessData: any;
  isProcessing: boolean;
  unsubscribe$ = new Subject();
  credentials: string;
  hasError: boolean;
  errorMessage: string;

  validationMessages = {

    phone_no: {
      pattern: 'Only 8 to 10 digits allowed ',
      required: 'Phone Field  is required.',
      min: 'Please provide a correct phone number',
      invalidPrefix: 'Invalid phone number prefix, Operator not found!',
    },
    amount: {
      pattern: 'Only number allowed',
      required: 'Amount This Field  is required.',
      amountExceededLimit: 'Amount in XOF should not exceed 1 700 000',
      hasComma: 'XOF does not support decimals',
    },
  };



  country: any = "BJ";
  userCountry: string = ""
  avalaibleOperators = []
  avalaibleOperatorsBJ = ["mtn"]
  placeHolder: string = ""
  maxLength: number = 0
  isPrefixValid = true


  icOutlineUpload = outlineUpload
  isUploading: boolean = false
  idDocumentFile: File;
  UploadedFileName: string = ''


  countriesData = [
    {
      country: 'BJ',
      currency: 'XOF',
      networks: [{ code: "mtn", name: 'MTN' },],
      merchant_number: '54593063',
      merchant_account_name: 'Noworri Sarl',
      networkfee: 1.25,

      bank_account_number: '505090008032',
      bank_account_name: 'Noworri Sarl',
      bank_code: 'BJ067',
      branch_code: '01505',
      bank_rib: '90'
    },
    {
      country: 'GH',
      currency: 'GHS',
      networks: [{ code: "mtn", name: 'MTN' }, { code: 'airtel', name: 'AIRTEL' }, { code: 'VODARFONE', name: 'VODARFONE' }, { code: 'tigo', name: 'TIGO' }],

      merchant_number: '0597392915',
      merchant_account_name: 'Noworri Financial technology',
      networkfee: 0,

      bank_account_number: '505090008032',
      bank_account_name: 'Noworri Sarl',
      bank_code: 'BJ067',
      branch_code: '01505',
      bank_rib: '90'
    },
  ]

  networks: any
  networkfee: any
  merchantNumber: any
  merchantAccountName: any

  bankAccountNumber: any
  bankAccountName: any
  bankCode: any
  branchCode: any
  bankRib: any

  collectionParameters: any
  userVirtualAccount: any
  selectedCollectionMethod: any


  mobileMoneyTopUpOption: boolean = false
  merchantDepositTopUpOption: boolean = false
  bankDepositTopUpOption: boolean = false
  createVirtualAccountOption: boolean = false


 

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private transactionService: TransactionsService,
    private businessService: BusinessService,
    private snackBar: MatSnackBar,
    private router: Router,
    private transactionsService: TransactionsService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {
    const sessionData = localStorage.getItem(USER_SESSION_KEY);
    this.userData = JSON.parse(sessionData);
    this.credentials = `${this.userData?.api_secret_key_live}:${this.userData?.api_public_key_live}`;

    const businessData = localStorage.getItem(BUSINESS_DATA_KEY);
    this.userBusinessData = JSON.parse(businessData);

    this.userCountry = this.userBusinessData.created_country
    this.currency = this.setCurrencyBasedOnBusinessCreatedCountry(this.userBusinessData.created_country)

    this.networks = this.countriesData.find(countryData => {
      return countryData.country === this.userCountry;
    });

    if (this.networks) {
      this.networks = this.networks.networks;
    } else {
      this.networks = [];
    }


    let ctryData = this.countriesData.find(data => {
      return data.country === this.userCountry
    })

    if (ctryData) {
      this.currency = ctryData.currency
      this.networkfee = ctryData.networkfee
      this.merchantNumber = ctryData.merchant_number
      this.merchantAccountName = ctryData.merchant_account_name

      this.bankAccountNumber = ctryData.bank_account_number
      this.bankAccountName = ctryData.bank_account_name
      this.bankCode = ctryData.bank_code
      this.branchCode = ctryData.branch_code
      this.bankRib = ctryData.bank_rib
    }
    /*
    this.countries.forEach(element => { 
    });
    */


    iconRegistry.addSvgIcon(
      'docs',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/docs.svg')
    );
    iconRegistry.addSvgIcon(
      'arrow2',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/arrow2.svg')
    );
  }
  reciept: string;
  recieptFile: File

  choice: string;
  collectionParametersDataSelected: any = null

  ngOnInit() {

    this.getBusinessParameters(this.userData.user_id)


    this.choice = this.route.snapshot.paramMap.get('choice'); 
    if (this.choice == "mobile-money") {
      this.mobileMoneyTopUpOption = true
    }
    if (this.choice == "merchant") {
      this.merchantDepositTopUpOption = true
    }
    if (this.choice == "bank-deposit") {
      this.bankDepositTopUpOption = true
    }






    this.getVirtualAccount(this.userData.user_id)
    this.setOperatorDialingCodes()

    this.topUpForm = this.fb.group({
      currency: [this.currency, Validators.required],
      amount: ['', [Validators.required, Validators.pattern(/^[0-9]+$/)]],
      operator: ['mtn', Validators.required],
    });
    this.mobileMoneytopUpForm = this.fb.group({
      phone_no: ['', Validators.required],
      currency: [this.currency, Validators.required],
      amount: ['', [Validators.required, Validators.pattern(/^[0-9]+$/)]],
      operator: ['mtn', Validators.required],
    })
    this.mobileMoneytopUpForm.get('phone_no').valueChanges.subscribe((value) => {
      this.mobileMoneytopUpForm.updateValueAndValidity();
      const prefixesData = this.operatorPrefixData?.filter(
        (data) => data.country == this.userCountry
      );
      let isValidPrefix = prefixesData?.some((data) =>
        data.prefixes.includes(value.substring(0, 2))
      );
      this.isPrefixValid = this.isPrefixValid
      if (isValidPrefix) {
        let prefixData = prefixesData.find((data) =>
          data.prefixes.includes(value.substring(0, 2))
        );
        prefixData = prefixesData.find((data) =>
          data.prefixes.includes(value.substring(0, 2))
        );
      } else {
        //this.mobileMoneytopUpForm.get('phone_no').setErrors({ invalidPrefix: true });
      }
      this.mobileMoneytopUpForm.updateValueAndValidity();
    });



    this.merchantDeposittopUpForm = this.fb.group({
      currency: [this.currency, Validators.required],
      amount: ['', [Validators.required, Validators.pattern(/^[0-9]+$/)]],
      operator: ['merchant', Validators.required],
    });


    this.bankDeposittopUpForm = this.fb.group({
      currency: [this.currency, Validators.required],
      amount: ['', [Validators.required, Validators.pattern(/^[0-9]+$/)]],
      operator: ['bank', Validators.required],
    });


    this.virtualAccountForm = this.fb.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      bvn: ['', Validators.required],
      user_id: [this.userData.user_id],
    });





  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  rechargeAccount() {
    this.isProcessing = true;
    let phone_no = ""
    let charges: number = 0
    if (this.mobileMoneyTopUpOption) {

      this.topUpForm = this.fb.group({
        currency: [this.mobileMoneytopUpForm.value.currency],
        amount: [this.mobileMoneytopUpForm.value.amount],
        operator: [this.mobileMoneytopUpForm.value.operator],
      });
      phone_no = this.mobileMoneytopUpForm.value.phone_no
      charges = ((this.mobileMoneytopUpForm.value.amount * 1.25) / 100)


    }
    if (this.merchantDepositTopUpOption) {
      this.topUpForm = this.fb.group({
        currency: [this.merchantDeposittopUpForm.value.currency],
        amount: [this.merchantDeposittopUpForm.value.amount],
        operator: [this.merchantDeposittopUpForm.value.operator],
      });
    }
    if (this.bankDepositTopUpOption) {
      this.topUpForm = this.fb.group({
        currency: [this.bankDeposittopUpForm.value.currency],
        amount: [this.bankDeposittopUpForm.value.amount],
        operator: [this.bankDeposittopUpForm.value.operator],
      });
    }

    this.data = {
      ...this.topUpForm.value,
      country: this.country,
      //currency: this.currency,
      //module_id: this.module_id,
      user_id: this.userData.user_id,
      status: 'Pending',
      phone_no: phone_no,
      charges: charges,
      reciept: this.recieptFile
    };
    this.businessService.requestTopUp(this.data, this.credentials)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(response => {
        this.isProcessing = false;
        if (response && response['status'] === true) {
          this.openSnackbar(response['message']);
          //this.dialogRef.close();
          //VTXwindow.location.reload();
        } else {
          if (response['status'] === false) {
            this.hasError = true;
            this.errorMessage = response['message'];
            //VTXthis.router.navigate(['/dashboards/cash-in-transactions'])
          } else {
            this.hasError = true;
            this.showInternalServerErrorMessage()
          }
        }
      }),
      error => {
        this.hasError = true;
        this.errorMessage = error.message;
        this.showInternalServerErrorMessage()
      }
  } 
  createVirtaulAccountt() {
    this.isProcessing = true;
    this.businessService.createVirtualAccount(this.virtualAccountForm.value, this.credentials)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(response => {
        this.isProcessing = false;
        if (response && response['status'] === true) {
          this.openSnackbar(response['message']);
          //this.dialogRef.close();
          //VTXwindow.location.reload();
        } else {
          if (response['status'] === false) {
            this.hasError = true;
            this.errorMessage = response['message'];
            this.openSnackbar(response['message']);
            //VTXthis.router.navigate(['/dashboards/cash-in-transactions'])
          } else {
            this.hasError = true;
            this.showInternalServerErrorMessage()
          }
        }
      }),
      error => {
        this.hasError = true;
        this.errorMessage = error.message;
        this.showInternalServerErrorMessage()
      }
  }
  setOperatorDialingCodes() {
    this.transactionsService
      .getOperatorDailingPrefixes(this.credentials)
      .pipe(take(1))
      .subscribe((response) => {
        if (response && response?.status === true) {
          this.operatorPrefixData = response.data;
        }
      });
  }
  showInternalServerErrorMessage() {
    this.errorMessage = "Error. Please retry again later."
    this.openSnackbar("Error. Please retry again later.");
  }


  openSnackbar(message) {
    this.snackBar.open(message, 'CLOSE', {
      duration: 3000,
      horizontalPosition: 'right'
    });
  }
  getModulesData(credentials) {
    this.transactionService
      .getModulesData(credentials)
      .pipe(take(1))
      .subscribe((data) => {
        this.moduleData = data;

      });
  }

  getBusinessParameters(user_id: any) {

    this.businessService.getBusinessPrameters(user_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(response => {
        if (response && response['status'] === true) { 
          this.collectionParameters = response['data']['collection']
          
          this.collectionParametersDataSelected = this.collectionParameters.find(
            collection => collection.code === this.userBusinessData?.created_country 
          );
 
          if (this.collectionParametersDataSelected && this.collectionParametersDataSelected?.operators.length > 0) {
            this.mobileMoneytopUpForm.patchValue({
              operator: this.collectionParametersDataSelected.operators[0].name
            });
          }


          
         

        } else {
          if (response['status'] === false) {
            this.hasError = true;
            this.errorMessage = response['message'];
          } else {
            this.hasError = true;
            //this.showInternalServerErrorMessage()
          }
        }
      })
  }
  getVirtualAccount(user_id: any) {

    this.businessService.getVirtualAccount(user_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(response => {
        if (response && response['status'] === true) {
          this.userVirtualAccount = response['data']
        } else {
          if (response['status'] === false) {
            this.hasError = true;
            this.errorMessage = response['message'];
          } else {
            this.hasError = true;
            //this.showInternalServerErrorMessage()
          }
        }
      })
  }

  setCurrency(option) { //OLD
    this.currency = this.countryData[option.value].currency; // option.value //VTX
    /*
    const selectedModule = this.moduleData.find((data) => {
      return (
        data['country'] === option.value && data['currency'] === this.currency
      );
    });
    */
    //this.module_id = selectedModule['id']; 
  }

  setCurrencyBasedOnBusinessCreatedCountry(country) {
    if (country == 'BJ' || country == 'CI' || country == 'SN') return 'XOF'
    if (country == 'CM') return 'XAF'
    if (country == 'GH') return 'GHS'

    return ''
  }


  setCountry(event) {
    this.currency = event.value
    if (event.value === "XOF") {
      this.country = "BJ";
    }
    if (event.value === "GHS") {
      this.country = "GH"
    }
    if (event.value === "NGN") {
      this.country = "NG"
    }
  }

  createCustomer() {
    const customer = this.topUpForm.value;

    if (!customer.imageSrc) {
      customer.imageSrc = 'assets/img/avatars/1.jpg';
    }

    //this.dialogRef.close(customer);
  }

  close() {
    //this.dialogRef.close();
  }

  onCheckConfirmNumber() {


  }
  selectmobileMoneyTopUp(collectionParameter: any) {
    this.mobileMoneyTopUpOption = true
    this.merchantDepositTopUpOption = false
    this.bankDepositTopUpOption = false
    this.createVirtualAccountOption = false
 

    this.selectedCollectionMethod = collectionParameter
    if (this.selectedCollectionMethod && this.selectedCollectionMethod.length > 0) {
      this.mobileMoneytopUpForm.patchValue({
        operator: this.selectedCollectionMethod[0].name
      });
    }



  }
  selectmerchantDepositTopUp(collectionParameter: any) {
    this.mobileMoneyTopUpOption = false
    this.merchantDepositTopUpOption = true
    this.bankDepositTopUpOption = false
    this.createVirtualAccountOption = false
    this.selectedCollectionMethod = collectionParameter
  }
  selectbankDepositTopUp(collectionParameter: any) {
    this.mobileMoneyTopUpOption = false
    this.merchantDepositTopUpOption = false
    this.bankDepositTopUpOption = true
    this.createVirtualAccountOption = false
    this.selectedCollectionMethod = collectionParameter
  }
  selectCreateVirtualAccount() {
    this.mobileMoneyTopUpOption = false
    this.merchantDepositTopUpOption = false
    this.bankDepositTopUpOption = false
    this.createVirtualAccountOption = true
  }



  /*
    uploadID(event) { 
      this.idDocumentFile = event.target.files[0];
      this.isUploading = true; 
    }*/
  //
  /**
   * const files = event.target.files;
   this.companyDocumentFile = files;
   */
  uploadID(event) {
    this.recieptFile = event.target.files[0];
    this.isUploading = true;
    //const reader: FileReader = new FileReader();
    //reader.onload = () => {
    //this.reciept = reader.result as string;
    //};
    //reader.readAsDataURL(this.recieptFile);

  }

  /**
   *  mobileMoneyTopUp : boolean = false
  merchantDepositTopUp : boolean  = false
  bankDepositTopUp : boolean = false
   */


}
