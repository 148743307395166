import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError as observableThrowError } from "rxjs";
@Injectable({
  providedIn: 'root'
})
export class CollaborationTeamService {

  constructor(private http: HttpClient) {
  }

  sendInvitation(first_name, last_name,email, permission_ids, credentials): Observable<any> {
    //const url = environment.loginUrl;

    const url = `${environment.inviteBySmsUrl}`;
    const body = {
      first_name,
      last_name,
      email,
      permission_ids,
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${credentials}`,
    });

    return this.http.post(url, body, { headers, responseType: 'json' }).pipe(
      map((response: Response) => {
        return response;
      }),
      catchError((error: any) => {
        console.error('Error', error);
        if (error.status === 401) {
          return [{ status: false, message: error.error }];
        }
        return error;
      })
    );
  }
  updateAccountHabilitations(email, permission_ids, credentials): Observable<any> {
    //const url = environment.loginUrl;

    const url = `${environment.updateAccountHabilitationsUrl}`;
    const body = {
      email,
      permission_ids,
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${credentials}`,
    });

    return this.http.put(url, body, { headers, responseType: 'json' }).pipe(
      map((response: Response) => {
        return response;
      }),
      catchError((error: any) => {
        console.error('Error', error);
        if (error.status === 401) {
          return [{ status: false, message: error.error }];
        }
        return error;
      })
    );
  }
  getSecondariesAccount2(credentials): Observable<any> {
    //const url = environment.loginUrl;

    const url = `${environment.getSecondariesAccountUrl}`;
    return this.http.get(url).pipe(
      map((response) => {
        if (response['data']) {

        }
        return response['data'];
      }),

      catchError((error: HttpErrorResponse) => {
        console.error("Error:", error.message);
        return observableThrowError(error);
      })
    );

  }
  getSecondariesAccount(credentials) {

    const url = `${environment.getSecondariesAccountUrl}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${credentials}`,
    });
    return this.http
      .get(url, { headers, responseType: 'json' })
      .pipe(
        map((response) => { 
          return response; 
        }),
        catchError((error: HttpErrorResponse) => {
          console.error('Error', error.message);
          return observableThrowError(error);
        })
      );
  }
  getSecondaryUserHabilitation(email,credentials): Observable<any> {
    //const url = environment.loginUrl;

    const url = `${environment.getSecondaryUserHabilitationUrl}`;
    const body = {
      email,
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${credentials}`,
    });

    return this.http.post(url, body, { headers, responseType: 'json' }).pipe(
      map((response: Response) => {
        return response;
      }),
      catchError((error: any) => {
        console.error('Error', error);
        if (error.status === 401) {
          return [{ status: false, message: error.error }];
        }
        return error;
      })
    );
  }
  getSecondaryUserEmail(user_id): Observable<any> {
    //const url = environment.loginUrl;

    const url = `${environment.getSecoundaryAccountEmailUrl}`;
    const body = {
      user_id,
    }; 
    return this.http.post(url, body, {   responseType: 'json' }).pipe(
      map((response: Response) => {
        return response;
      }),
      catchError((error: any) => {
        console.error('Error', error);
        if (error.status === 401) {
          return [{ status: false, message: error.error }];
        }
        return error;
      })
    );
  }
  
  removeSecondaryUser(email, credentials): Observable<any> {
    //const url = environment.loginUrl;

    const url = `${environment.removeSecondaryUserUrl}`;
    const body = {
      email,
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${credentials}`,
    });

    return this.http.put(url, body, { headers, responseType: 'json' }).pipe(
      map((response: Response) => {
        return response;
      }),
      catchError((error: any) => {
        console.error('Error', error);
        if (error.status === 401) {
          return [{ status: false, message: error.error }];
        }
        return error;
      })
    );
  }





  joinInvitation(userData): Observable<any> {
    //const url = environment.loginUrl;
    const url = "https://api.pals.africa/api/finalisation";

    return this.http
      .post(url, userData, { responseType: 'json' })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          console.error('Error', error.message);
          return observableThrowError(error);
        })
      );

  }

  verifyCode(body): Observable<any> {
    //const url = environment.loginUrl;
    const url = "https://api.pals.africa/api/verification";

    return this.http
      .post(url, body, { responseType: 'json' })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          console.error('Error', error.message);
          return observableThrowError(error);
        })
      );

  }
}
