<hr style="border-color: rgba(0, 0, 0, 0.169);">


<div class="container">

    <div style="display: flex; flex-wrap: wrap; margin-left: 20px; margin-right: 20px; ">



        <div style="flex: 1  ; margin-right: 10px;  "> </div>

        <div style="flex: 1  ; margin-right: 10px; margin-top: 50px;">
            <div class="card">


                <div style="padding: 20px;">
                    <div style="display: flex;">
                        <div style="flex: 2">
                            <h3><strong>Choisissez une option</strong></h3>
                        </div>
                        <div style="flex: 1;">
                            <mat-icon [svgIcon]="'close'"
                                style="position: relative; left: 80%; height: 30px; width: 30px;  cursor: pointer;"
                                class="menu-item-icon mr-1" matListIcon aria-hidden="false" (click)="goBack()">
                            </mat-icon>
                        </div>
                    </div>
                </div>
                <hr>

                <div *ngFor="let collection of collectionParameters">
                    <div *ngIf="collection?.currency == 'XOF' || collection?.currency == 'XAF' || collection?.currency == 'GHS' " >

                        
                        <div class="hoverable-div" style="padding: 20px; height: 140px; cursor: pointer;"
                            (click)="chooseMobileDeposit()" *ngIf="collection?.operators">
                            <div style="display: flex;">
                                <div style="flex: 2">
                                    <strong style="color: blue;">Mobile money </strong> <br>
                                    Approvisionne automatiquement ton compte PAL en
                                    utilisant ton compte mobile money.
                                </div>
                                <div style="flex: 1; margin-top: 15px;">
                                    <mat-icon [svgIcon]="'arrow2'"
                                        style="position: relative; left: 80%; color: rgba(0, 0, 0, 0.634); height: 50px; width: 50px;"
                                        class="menu-item-icon mr-1" matListIcon aria-hidden="false">
                                    </mat-icon>
                                </div>
                            </div>
                        </div>
                        <hr *ngIf="collection?.operators">
                        


                        <div class="hoverable-div" style="padding: 20px;  cursor: pointer;"
                            (click)="chooseMerchantDeposit()" *ngIf="collection?.merchant">
                            <div style="display: flex;">
                                <div style="flex: 2">
                                    <strong style="color: rgb(55, 175, 55);">Dépôt marchand </strong> <br>
                                    Rends toi chez un super merchant MTN et fait un Dépôt sur notre Numéro marchant.
                                    <br>
                                    <br>

                                    <ul style="list-style-type: disc; padding-left: 20px;">
                                        <li> Numéro marchand : <strong>{{collection?.merchant?.merchant_number}}</strong> </li>
                                        <li>Nom : <strong>{{collection?.merchant?.merchant_name}}</strong> </li>
                                    </ul>
                                </div>
                                <div style="flex: 1; margin-top: 15px;">
                                    <mat-icon [svgIcon]="'arrow2'"
                                        style="position: relative; left: 80%; color: rgba(0, 0, 0, 0.634); height: 50px; width: 50px;"
                                        class="menu-item-icon mr-1" matListIcon aria-hidden="false">
                                    </mat-icon>
                                </div>
                            </div>
                        </div>
                        <hr *ngIf="collection?.merchant">




                        <div class="hoverable-div" style="padding: 20px;cursor: pointer;"
                            (click)="chooseBankDeposit()" *ngIf="collection?.bank">
                            <div style="display: flex;">
                                <div style="flex: 2">
                                    <strong style="color: red;">Dépôt Bancaire </strong> <br>
                                    Rends toi chez un super merchant MTN et fait un Dépôt sur notre compte bancaire.
                                    <br>
                                    <br>
                                    <ul style="list-style-type: disc; padding-left: 20px;">
                                        <li>Code banque : <strong>{{collection?.bank?.code}} </strong> - Code guichet : <strong>{{collection?.bank?.branch_code}}</strong>
                                        </li>
                                        <li>Numéro de compte : <strong>{{collection?.bank?.account_number}}</strong> - Clé RIB :
                                            <strong>{{collection?.bank?.rib}}</strong>
                                        </li>
                                        <li> Intitule du compte : <strong>{{collection?.bank?.account_name}}</strong> </li>
                                    </ul>
                                </div>
                                <div style="flex: 1; margin-top: 15px;">
                                    <mat-icon [svgIcon]="'arrow2'"
                                        style="position: relative; left: 80%; color: rgba(0, 0, 0, 0.634); height: 50px; width: 50px;"
                                        class="menu-item-icon mr-1" matListIcon aria-hidden="false">
                                    </mat-icon>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>







            </div>
        </div>


        <div style="flex: 1  ; margin-right: 10px; "></div>



    </div>
</div>