<mat-toolbar class="" >
  <div class="primary-btns">
      <button mat-button *ngFor="let option of this.menu.options" color="{{option.value==this.menu.current.value?'primary':''}}" (click)="selectOption(option)">{{option.name | translate}}</button>
  </div>
</mat-toolbar>
<mat-divider class="-mx-6 text-border toolbar-mat-divider"></mat-divider>
<div>
  <!-- <vex-curency-balance></vex-curency-balance> -->
<!-- <hr /> -->
<vex-page-layout>
  <!-- <vex-page-layout-header
    class="pb-16"
    fxLayout="column"
    fxLayoutAlign="center start"
  >
    <div
      [class.container]="layoutCtrl.value === 'boxed'"
      [class.px-gutter]="layoutCtrl.value === 'fullwidth'"
      class="w-full flex flex-col sm:flex-row justify-between"
    ></div>
  </vex-page-layout-header> -->

  <vex-page-layout-content
    [class.container]="layoutCtrl.value === 'boxed'"
    [class.px-gutter]="layoutCtrl.value === 'fullwidth'"
    class="-mt-6">









  <br> <br> <br>

    <div>
      <form action="" [formGroup]="filterForm" > <!--[formGroup]="filterForm" -->
        <div style="display: flex; ">
          <div style=" text-align: left;"> 
            {{"Date Start" | translate}} : <br>
            <input type="date" class="date-input" placeholder="Date Start" formControlName="start_date">
          </div>

          <div style=" padding-left:30px ; text-align: left;"> 
            {{"Date End" | translate}} : <br>
            <input type="date" class="date-input" placeholder="Date End" formControlName="end_date">
          </div>

          <div style=" padding-left:30px ; text-align: left;" class="mt-3">
            <button (click)="exportAsXlsx()" class="px-1 border" mat-button>
              {{"Download" |translate}} <mat-icon class="ml-1 mb-1" color="black"
                [icIcon]="icCloudDownload"></mat-icon>
            </button>
          </div>
        </div>

         

        <div style="display: flex; " class="mt-5 mb-5 pb-5"> 
          <button class="authButton" mat-raised-button type="button" (click)="filter()" [disabled]="filterForm.invalid" > 
            {{"Apply" | translate}}
          </button>
        </div>
      </form>
    </div>































    <table
      *ngIf="!isLoading"
      mat-table
      [dataSource]="dataSource"
      class="mat-elevation-z8 w-full mt-6"
      @stagger
      matSort
      matTableExporter #exporter="matTableExporter">
      <!-- <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>ID</th>
        <td mat-cell *matCellDef="let element">{{ element.id }}</td>
      </ng-container> -->

      <!-- created_at Column -->
      <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef>{{"Date & Time"| translate}} </th>
        <td mat-cell *matCellDef="let element">{{ element.created_at }}</td>
        <!-- <td mat-cell *matCellDef="">25 jun 2021</td> -->
      </ng-container>

      <!-- currency Column -->
      <ng-container matColumnDef="currency">
        <th mat-header-cell *matHeaderCellDef>{{"Currency"| translate}} </th>
        <td mat-cell *matCellDef="let element">{{ element.currency }}</td>
        <!-- <td mat-cell *matCellDef="">FCFA</td> -->
      </ng-container>

      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>{{"Amount Top up"| translate}} </th>
        <td mat-cell *matCellDef="let element">{{ element.amount }}</td>
        <!-- <td mat-cell *matCellDef="">1200</td> -->
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>{{"Status" | translate}} </th>
        <td mat-cell *matCellDef="let element">
          <div
            [ngClass]="element.state?.cssClasses"
            class="px-2 py-1 my-4 text-xs font-medium rounded"
            fxFlex="none"
          >
            <span> {{ element.state?.text }}</span>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">
          {{"No Transaction Data Found Or Matching The filter" | translate}}
        </td>
      </tr>
    </table>
    <div *ngIf="isLoading" class="card overflow-auto -mt-16">
      <br> <br> <br>
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          background: white;
        "
      >
        <mat-spinner color="primary"> </mat-spinner>
      </div>
    </div>
    <mat-paginator
      [pageSizeOptions]="pageSizeOptions"
      [pageSize]="pageSize"
      class="sticky left-0"
    ></mat-paginator>
  </vex-page-layout-content>
</vex-page-layout>
</div>