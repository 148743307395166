<mat-toolbar class="">
  <div class="primary-btns">
    <button mat-button *ngFor="let option of this.menu.options"
      color="{{option.value==this.menu.current.value?'primary':''}}" (click)="selectOption(option)">{{option.name |
      translate}}</button>
  </div>
</mat-toolbar>
<mat-divider class="-mx-6 text-border toolbar-mat-divider"></mat-divider>
<div>
  <!-- <vex-curency-balance></vex-curency-balance> -->
  <!-- <hr /> -->
  <vex-page-layout>


    <vex-page-layout-content [class.container]="layoutCtrl.value === 'boxed'"
      [class.px-gutter]="layoutCtrl.value === 'fullwidth'" class="-mt-6">




      <div *ngIf="!showFinancialStatment">

        <div class="text-center" style="position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%);">
          <div class="card2  " style="width: 450px;">

            <div style=" padding: 30px;">
              <h4>Bal: <b><!--{{(currentCurrencyBalance.balance | number: "1.2-2")|| "0.00" }}--> {{currenctBalance}}
                  {{defaultFilterCurrency}} </b> </h4>
              <hr style="color: black;">
            </div>

            <form action="" [formGroup]="filterForm"> <!--[formGroup]="filterForm" -->
              <div style="display: flex; padding: 30px; ">
                <div style="flex: 1; text-align: left;"> 
                  {{"Date Start" | translate}} : 
                  <input type="date" class="date-input" placeholder="Date Start" formControlName="start_date">
                </div>

                <div style="flex: 1; text-align: left;"> 
                  {{"Date End" | translate}} :
                  <input type="date" class="date-input" placeholder="Date End" formControlName="end_date">
                </div>
              </div>

              <div style="display: flex; padding-right: 30px; padding-left: 30px;" class="mt-5">
                <div style="flex: 1;"> 
                </div>
              </div>
              <div style="display: flex; padding-right: 30px; padding-left: 30px;">

              </div> 

              <div class="mt-5" style="display: flex; padding-right: 30px; padding-left: 30px;">
                <div style="flex: 1;" class="text-center">


                </div>
              </div>

              <div style="display: flex; padding-right: 30px; padding-left: 30px;" class="mt-5 mb-5 pb-5"> 
                <button class="authButton" mat-raised-button type="button"
                  (click)="navigateToFinancialStatmentReportComponent()" [disabled]="filterForm.invalid"><!---->
                  {{"Approved" | translate}}
                </button>

              </div>
            </form>

          </div>
        </div>
      </div>

    </vex-page-layout-content>
  </vex-page-layout>
</div>