 
<hr style="border-color: rgba(0, 0, 0, 0.169);">


<div class="container">

    <div style="display: flex; flex-wrap: wrap; margin-left: 20px; margin-right: 20px; ">

        <div style="flex: 1  ; margin-right: 10px;  "> </div>

        <div style="flex: 1  ; margin-right: 10px; margin-top: 50px;">
            <div class="card">

                <form [formGroup]="virtualAccountForm">


                    <div style="padding: 20px;">
                        <div style="display: flex;">
                            <div style="flex: 2">
                                <h3><strong>Create Virtual Account</strong> </h3>
                            </div>
                            <div style="flex: 1;">
                                <mat-icon [svgIcon]="'close'"
                                    style="position: relative; left: 80%; height: 30px; width: 30px;  cursor: pointer;"
                                    class="menu-item-icon mr-1" matListIcon aria-hidden="false" (click)="goBack()">
                                </mat-icon>
                            </div>
                        </div>
                    </div>
                    <hr>

                    <div style="padding-bottom: 30px;padding-left: 30px;padding-right: 30px;">

                        <div>
                            <br>  
                            To enhance the security of your account and enable the creation of a virtual account for instant deposits to your PAL's account, we require your (BVN).
                        </div>

                        <br>
                        <mat-dialog-content fxLayout="column" > 
                            <div>
                                <div class="label"><label for="" class="label">{{"Enter your BVN."| translate}}
                                    </label></div>
                            </div>
                            <mat-form-field appearance="outline" class="custom-input">
                                <input matInput formControlName="bvn" placeholder="" maxlength="11" type="text" placeholder="Enter your Bank Verification Number">
                                <mat-error>
                                    <!--<span *ngFor="let error of mobileMoneytopUpForm.get('amount').errors | keyvalue ">
                                        {{ validationMessages.amount[error.key] | translate }}
                                    </span>-->
                                </mat-error>
                            </mat-form-field>  
                        </mat-dialog-content> 


                        <br>
                        <button color="primary" mat-button type="button" (click)="createVirtaulAccountt()"
                            [ngClass]="(isProcessing || virtualAccountForm.invalid )? 'invalid-send-button': 'valid-send-button'"
                            [disabled]="isProcessing || virtualAccountForm.invalid ">{{ isProcessing === true?
                            'Processing...' :'Create virtual account' | translate}}
                        </button> 


                    </div> 

                    <!--<div class="hoverable-div" style="padding-left: 20px;padding-right: 20px; padding-bottom: 20px;cursor: pointer;"> 
                        <button color="primary" mat-button type="button" (click)="createVirtaulAccountt()"
                            [ngClass]="(isProcessing || virtualAccountForm.invalid )? 'invalid-send-button': 'valid-send-button'"
                            [disabled]="isProcessing || virtualAccountForm.invalid ">{{ isProcessing === true?
                            'Processing...' :'Create virtual account' | translate}}
                        </button> 
                    </div>--> 
                </form>
            </div>
        </div>

        <div style="flex: 1  ; margin-right: 10px; "> </div>

    </div>



 

</div>