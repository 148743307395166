 

 

 <form [formGroup]="transferForm">
  <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
    <h2 class="m-0 headline" fxFlex="auto">{{"Mobile Transfer" | translate}} </h2>
    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon class="icon" [icIcon]="icClose"></mat-icon>
    </button>
  </div>
  <mat-divider class="-mx-6 text-border"></mat-divider>
  <mat-dialog-content fxLayout="column" class="content-part mx-50">

    <div *ngIf="!confirmedTransfer"> 
      <div class="input-row" *ngIf="!selectBenefeficiary">
        <mat-radio-group (change)="toggle($event)" [(ngModel)]="isMerchantNumber" formControlName="is_merchant_transfer"
          color="primary" style="width: 100%;" aria-label="Select an option">
          <mat-radio-button class="" checked [value]="false">{{"Mobile Wallet"| translate}} </mat-radio-button>
          <mat-radio-button class="" [value]="true">{{"Merchant Wallet"| translate}} </mat-radio-button>
        </mat-radio-group>
      </div> 
      <div class="customInput customSelect mt-5" *ngIf="selectBenefeficiary && !afterBeneficiarySelected">
        <span class="confirm-form-title flex-auto">{{"Select the beneficiary"| translate}} </span>
        <mat-form-field>
          <mat-select placeholder="Select a Beneficiary" formControlName="beneficiary_id">
            <mat-option *ngFor="let beneficiary of beneficiariesFormated" [value]="beneficiary.id">
              {{beneficiary.full_name}} {{beneficiary.phone_no}} | {{beneficiary.country}}
            </mat-option>
          </mat-select>
        </mat-form-field> 

      </div>




      <div class="country" *ngIf="!selectBenefeficiary">
        <div class="grid grid-flow-col gap-4 grid-cols grid-rows">
          <div class="label">
            <label for="">{{"Select the country"| translate}} </label>
          </div>
        </div>
        <mat-form-field class=""> 
          <mat-select placeholder="Select a country" (selectionChange)="setCurrency($event)" formControlName="country">
         
            <mat-option value="BJ">BENIN</mat-option>
            <mat-option value="GH">GHANA</mat-option>
            <mat-option value="CI">COTE D'IVOIRE</mat-option> 
          </mat-select>
        </mat-form-field>
      </div>
 
      <div class="input-row" *ngIf="showAmountInput">
        <div class="customInput">
          <div class="flex input-label">
            <label for="">{{"How much to disburse?"| translate}} </label>
          </div>
          <mat-form-field class=" flex-auto w-full">
            <div matPrefix class="currency-prefix">
              <span>{{ currency }}</span>
            </div>
            <input matInput formControlName="amount" placeholder="">
            <mat-error>
              <span *ngFor="let error of transferForm.get('amount').errors | keyvalue">
                {{ validationMessages.amount[error.key] }}
              </span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>



      <div class="mobile-number1" *ngIf="!selectBenefeficiary">
        <div class="grid grid-flow-col gap-4 grid-cols grid-rows">
          <div class="flex input-label" style="justify-content:space-between ;">
            <label for="">{{isMerchantNumber?"Merchant Number":"Mobile wallet number" | translate}}</label>
            <div class="text-primary">{{ this.transferForm.get('operator').value }}</div>
          </div>
        </div>
        <mat-form-field class="">
          <input (ngModelChange)="onCheckConfirmNumber()" matInput formControlName="phone_no" #input type="tel"
            pattern="\d*" placeholder="{{ placeHolder }}" maxlength="{{ maxLength }}">
          <mat-error>
            <span *ngFor="
                let error of transferForm?.get('phone_no').errors | keyvalue
              ">
              {{ validationMessages.phone_no[error.key] }}
            </span>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="mobile-number2" *ngIf="!selectBenefeficiary">
        <div class="">
          <div class="flex input-label" style="justify-content:space-between ;">
            <label for="">Repeat {{isMerchantNumber?"Merchant Number":"Mobile wallet number" | translate}}</label>
            <div class="text-primary">{{ this.transferForm.get('operator').value }}</div>
          </div>
        </div>
        <mat-form-field class="">
          <input (ngModelChange)="onCheckConfirmNumber()" matInput formControlName="repeat_phone_no" #input type="tel"
            pattern="\d*" placeholder="{{ placeHolder }}" maxlength="{{ maxLength }}">
          <mat-error>
            <span *ngFor="
                let error of transferForm.get('repeat_phone_no').errors
                  | keyvalue
              ">
              {{ validationMessages.repeat_phone_no[error.key] }}
            </span>
          </mat-error>
        </mat-form-field>
        <mat-error>
          <span class="identical" *ngIf="hasPhoneInputError">
            {{ checkPhoneErrorMessage }}
          </span>
        </mat-error>
      </div>
      <div class="customInput customSelect mt-5" *ngIf="!selectBenefeficiary">
        <span class="confirm-form-title flex-auto">{{"Select the operator" | translate}} </span>
        <mat-form-field>
          <mat-select placeholder="Select a Operator" formControlName="operator">
            <mat-option *ngFor="let operator of avalaibleOperators" [value]="operator">
              {{operator | uppercase}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="purpose md:col-span-2" *ngIf="showTransferReasonInput">
        <div class="grid grid-flow-col gap-4 grid-cols grid-rows">
          <div class="label">
            <label for="" class="label">{{"Purpose of disbursement" | translate}} </label>
          </div>
        </div>
        <mat-form-field class="">
          <input matInput maxlength="20" type="text" formControlName="object" #input>
        </mat-form-field>
      </div>
 

    </div>

    <div class="" *ngIf="confirmedTransfer">
      <div class="approve-row">
        <div class="custom-content ">
          <div class="flex approve-label">
            <span class="">{{"Select the country" | translate}} </span>
          </div>
          <div class="approve-info">
            <span>{{mobileTransfer?.country }}</span>
          </div>
        </div>

        <div class="custom-content">
          <div class="flex approve-label">
            <span class="">{{"How much to disburse?"| translate}} </span>
          </div>
          <div class="approve-info">
            <span><span>{{mobileTransfer?.amount}}</span> {{currency ? currency : "XOF"}}</span>
          </div>
        </div>
      </div>

      <div class="approve-row">
        <div class="custom-content ">
          <div class="flex approve-label">
            <span class="">{{"Mobile wallet number"| translate}} </span>
          </div>
          <div class="approve-info">
            <span>{{mobileTransfer?.mobileNumber}}</span>
          </div>
          <div class="network-displayed mt-1">
            <div class="small-green-circle approve-green-circle"></div>
            <span>{{ transferForm?.get('operator').value }}</span>
          </div>
        </div>
        <div class="custom-content ">
          <div class="flex approve-label">
            <span class="">{{"Wallet Name"| translate}} </span>
          </div>
          <div class="approve-info">
            <span *ngIf="isFetchingName">{{"Fetching Client Name..."| translate}} </span>

            <span *ngIf="clientName">
              {{ clientName }}
            </span>

            <span *ngIf="!clientName && !isFetchingName">
              Warning: {{noNameErrorMessage}}, {{"Please try again"| translate}}
            </span>
          </div>
        </div>
      </div>
    </div>

  </mat-dialog-content>
  <mat-divider class="-mx-6 text-border"></mat-divider>
  <mat-dialog-actions class="btn-bloc">

    <button *ngIf="selectBenefeficiary && !confirmedTransfer" mat-flat-button type="button" class="cancel-btn"
      (click)="cancelSelectBenef()">
      {{"Cancel"| translate}}
    </button>
    

    <button *ngIf="!selectBenefeficiary" mat-flat-button type="button" class="confirm-btn-raduis m-2 confirm-btn" (click)="onSelectBeneficiary()">
      {{"Select a beneficiary"| translate}}
    </button>
 
    <button mat-flat-button type="submit" [disabled]="transferForm.invalid || hasPhoneInputError || isDisbursing"
      class="confirm-btn-raduis m-2" [class.confirm-btn]="transferForm.valid && !hasPhoneInputError && !isDisbursing"
      (click)="submitAction()">
      {{submitBtnText | translate}}
    </button>
  </mat-dialog-actions>
</form> 