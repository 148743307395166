import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { BusinessService } from 'src/app/services/business.service';
import { takeUntil, take } from 'rxjs/operators';
import { USER_SESSION_KEY, BUSINESS_DATA_KEY } from 'src/app/Models/constants';

@Component({
  selector: 'vex-recharge-account-methods-currency',
  templateUrl: './recharge-account-methods-currency.component.html',
  styleUrls: ['./recharge-account-methods-currency.component.scss']
})
export class RechargeAccountMethodsCurrencyComponent implements OnInit {

  unsubscribe$ = new Subject();
  credentials : string
  userData:any
  userBusinessData : any
  collectionParameters: any
  collectionParametersDataSelected: any = null
  hasError: boolean;
  errorMessage: string;
  userCountry: string = ""
  currency: string = ""
  networks: any

  depositCurrencyChoosed : boolean = false



  
  countriesData = [
    {
      country: 'BJ',
      currency: 'XOF',
      networks: [{ code: "mtn", name: 'MTN' },],
      merchant_number: '54593063',
      merchant_account_name: 'Noworri Sarl',
      networkfee: 1.25,

      bank_account_number: '505090008032',
      bank_account_name: 'Noworri Sarl',
      bank_code: 'BJ067',
      branch_code: '01505',
      bank_rib: '90'
    },
    {
      country: 'GH',
      currency: 'GHS',
      networks: [{ code: "mtn", name: 'MTN' }, { code: 'airtel', name: 'AIRTEL' }, { code: 'VODARFONE', name: 'VODARFONE' }, { code: 'tigo', name: 'TIGO' }],

      merchant_number: '0597392915',
      merchant_account_name: 'Noworri Financial technology',
      networkfee: 0,

      bank_account_number: '505090008032',
      bank_account_name: 'Noworri Sarl',
      bank_code: 'BJ067',
      branch_code: '01505',
      bank_rib: '90'
    },
  ]



  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private router: Router,
    private businessService: BusinessService,
  ) {



    const sessionData = localStorage.getItem(USER_SESSION_KEY);
    this.userData = JSON.parse(sessionData);
    this.credentials = `${this.userData?.token}`;

    const businessData = localStorage.getItem(BUSINESS_DATA_KEY);
    this.userBusinessData = JSON.parse(businessData);

    this.userCountry = this.userBusinessData.created_country
    this.currency = this.setCurrencyBasedOnBusinessCreatedCountry(this.userBusinessData.created_country)

    this.networks = this.countriesData.find(countryData => {
      return countryData.country === this.userCountry;
    });

    if (this.networks) {
      this.networks = this.networks.networks;
    } else {
      this.networks = [];
    }





    iconRegistry.addSvgIcon(
      'arrow',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/arrow.svg')
    ); 
   }

  ngOnInit(): void {
  }




  getBusinessParameters(user_id: any) {

    this.businessService.getBusinessPrameters(user_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(response => {
        if (response && response['status'] === true) {
          console.log(response)
          this.collectionParameters = response['data']['collection']
          
          this.collectionParametersDataSelected = this.collectionParameters.find(
            collection => collection.code === this.userBusinessData?.created_country 
          );


          console.log("contenu selectionné")
          console.log(this.collectionParametersDataSelected.operators)

          /*
          if (this.collectionParametersDataSelected && this.collectionParametersDataSelected?.operators.length > 0) {
            this.mobileMoneytopUpForm.patchValue({
              operator: this.collectionParametersDataSelected.operators[0].name
            });
          }
          */


          
         

        } else {
          if (response['status'] === false) {
            this.hasError = true;
            this.errorMessage = response['message'];
          } else {
            this.hasError = true;
            //this.showInternalServerErrorMessage()
          }
        }
      })
  }












  chooseCurrencyDeposit(){ 
    this.router.navigate(['/dashboards/recharge-methods-details']).then(); 
  }


  setCurrencyBasedOnBusinessCreatedCountry(country) {
    if (country == 'BJ' || country == 'CI' || country == 'SN') return 'XOF'
    if (country == 'CM') return 'XAF'
    if (country == 'GH') return 'GHS'

    return ''
  } 

}
