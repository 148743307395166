<div class="container">
    


    <div class="scrollable-content">
        <div class="text-center mt-5 pt-5">
            <h2><b>Suppression de Compte sur PAL</b></h2>
        </div>
        <div class="p-6 pb-0 mb-5" fxLayout="column" fxLayoutAlign="center center">
            <div class="text-center fill-current">
                <img class="w-28" src="assets\logo\logo_pal-removebg-preview.png" />
            </div>
        </div>


        <div class="p-5 m-5">

            <b>Cher utilisateur de PAL,</b> <br>

            Nous comprenons que vos besoins évoluent et que vous pourriez envisager de supprimer votre compte PAL. Nous
            sommes là pour vous guider à travers ce processus et vous fournir toutes les informations nécessaires.
            <br><br>
            <br><b>*Processus de Suppression de Compte :*</b> <br>
            1. Remplissez le formulaire sous-dessous. <br>
            2. Demandez le code OTP qui vous sera envoyé par mail et saisissez le. <br>
            3. cliquez sur le bouton de suppression...
            <br><br>
            <b>*Délais de Conservation des Données :*</b><br>
            1. Vos informations personnelles seront conservées pendant une période de 90 jours après la suppression du
            compte.<br>
            2. Les historiques de transactions seront archivés conformément à nos politiques de rétention des données.
            <br><br>
            <b>*Conséquences de la Suppression de Compte :*</b><br>
            1. Vous ne pourrez plus accéder à votre compte PAL. <br>
            2. Toutes les informations associées à votre compte seront définitivement supprimées.<br>
            <br><br>
            <b>*Questions Fréquentes :*</b><br>
            1. Puis-je annuler la suppression de mon compte pendant la période de conservation ?<br>
            Non, une fois le processus initié, il est irréversible.<br><br>
            2. Que se passe-t-il si j'ai des fonds sur mon compte ? <br>
            Assurez-vous de retirer tous les fonds avant de supprimer votre compte.<br>
            <br><br>
            <b>*Assistance :*</b><br>
            Si vous avez des questions supplémentaires ou avez besoin d'assistance pendant ce processus, notre équipe
            d'assistance est là pour vous aider. Contactez-nous à [adresse e-mail ou numéro de téléphone].

            Nous apprécions votre confiance en PAL et sommes reconnaissants pour le temps que vous avez passé avec nous.
            Si
            jamais vous décidez de revenir, nous serons là pour vous accueillir à bras ouverts.
            <br><br>
            <input type="checkbox" name="" id="">
            En cochant cette case, je confirme avoir pris connaissance et accepté les conditions du processus de
            suppression
            de mon compte PAL.
            <br><br><br>
            <form action="">
                <label for="">Votre Email:</label><br>
                <input type="email" name="" id="" class="form-control form-control-lg border" placeholder="Email"
                    style="border: solid 1px;">
                <br><br>
                <label for="">Votre Mot de Passe:</label><br>
                <input type="password" placeholder="Password" style="border: solid 1px;">


                <label>Raison:</label><br>
                <textarea name="" id="" cols="30" rows="10" class="form-control"></textarea>
            </form>


        </div>
    </div>

</div>