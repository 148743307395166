<hr style="border-color: rgba(0, 0, 0, 0.169);">

 

<div class="card overflow-auto mt-10 mx-5">
  <table mat-table [dataSource]="dataSource" matSort>
    
    <ng-container matColumnDef="currency">
      <th mat-header-cell *matHeaderCellDef>{{"Currency" | translate}} </th>
      <td mat-cell *matCellDef="let element"> 
        <mat-icon style="position: relative; top: 5px;" [svgIcon]="element.currency" class="menu-item-icon mr-1" aria-hidden="false"></mat-icon> 
        <b>{{element.currency}}</b>
      </td>
    </ng-container>
    <ng-container matColumnDef="balance">
      <th mat-header-cell *matHeaderCellDef>{{"Balance"| translate}} </th>
      <td mat-cell *matCellDef="let element"> 
        <span *ngIf="isAllowed('3') || isAllowed('1')">
          <b>{{(element.balance + element.collectionBalance | number: "1.2-2") || "0.00"}}</b>
        </span>
        <span *ngIf="!isAllowed('3') && !isAllowed('1')">
          ********
        </span> 
      </td>
    </ng-container> 
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" style="text-align: right;">


        <button mat-icon-button [matMenuTriggerFor]="columnFilterMenu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #columnFilterMenu="matMenu">
          <button mat-menu-item (click)="openBalanceTopUpsPopup(element.currency)" >
            <!--<mat-icon color="primary"> dialpad </mat-icon>-->
            <mat-icon color="primary"> library_books </mat-icon> 
            <span color="primary">{{"Top ups"| translate}} </span>
          </button> 

          <button mat-menu-item (click)="openFinancialStatmentPopup(element.currency)" >
            <mat-icon color="primary"> receipt_long </mat-icon>  
            <span color="primary">{{"Financial Statment"| translate}} </span>
          </button> 

        </mat-menu>

 


      </td>
    </ng-container> 

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr> 
  </table>
</div>







<!--<div class="card" *ngFor="let element of currencyBalance">
  <div class="card-header" >  
    <div class="row">
      <div class="col-4">
        <mat-icon style="position: relative; top: 5px;" [svgIcon]="element.currency" class="menu-item-icon mr-1" aria-hidden="false"></mat-icon> 
        <b>{{element.currency}}</b>
      </div>
      <div class="col-4">
        {{(element.balance + element.collectionBalance | number: "1.2-2") || "0.00"}}
      </div>
    </div>
    
  </div>
  <div class="card-body">
   
  </div>
</div>
-->