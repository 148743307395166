<div mat-dialog-title class="">
  <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
    <h2 class="m-0 headline" fxFlex="auto">{{"Add Beneficiary"| translate}} </h2>
    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon class="icon" [icIcon]="icClose"></mat-icon>
    </button>
  </div>
</div>

<mat-divider class="-mx-6 text-border"></mat-divider>
<div mat-dialog-content class="">
  <form [formGroup]="form" *ngIf="availableCountries.length">
    <div class="">
      <div class="input-row">
        <mat-radio-group [(ngModel)]="isMerchantNumber" formControlName="is_merchant" color="primary"
          style="width: 100%;" aria-label="Select an option">
          <mat-radio-button checked [value]="0">{{"Mobile Wallet" | translate}} </mat-radio-button>
          <mat-radio-button [value]="1">{{"Merchant Wallet" | translate}} </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="input-row">
        <div class="customInput customSelect">
          <div class="flex input-label">
            <label for="">{{"Select the country" | translate}} </label>
          </div>
          <mat-form-field appearance="outline" class="sm:ml-6 flex-auto w-full mat-form-field-full">
            <!-- <mat-label>Select Country You're disbursing to</mat-label> -->
            <mat-select placeholder="Select a country" (selectionChange)="setCurrency($event)"
              formControlName="country">
              <!-- <mat-option value="SN">SENEGAL</mat-option> -->
              <mat-option value="BJ">
                <div style="display: flex; align-items: center;">
                  <img src="assets/contryFlg/flags/1/bj.png" style="height: 20px; width: auto; margin-right: 10px;">
                  BENIN
                </div>
              </mat-option>
              <mat-option value="GH">
                <div style="display: flex; align-items: center;">
                  <img src="assets/contryFlg/flags/1/gh.png" style="height: 20px; width: auto; margin-right: 10px;">
                  GHANA
                </div>
              </mat-option>
              <mat-option value="CI">
                <div style="display: flex; align-items: center;">
                  <img src="assets/contryFlg/flags/1/ci.png" style="height: 20px; width: auto; margin-right: 10px;">
                  COTE D'IVOIRE
                </div>
              </mat-option>

              <mat-option value="SN">
                <div style="display: flex; align-items: center;">
                  <img src="assets/contryFlg/flags/1/sn.png" style="height: 20px; width: auto; margin-right: 10px;">
                  SENEGAL
                </div>
              </mat-option>



            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="input-row">
        <div class="customInput customSelect">
          <div class="flex input-label" style="justify-content:space-between ;">
            <label for="">{{isMerchantNumber?"Merchant Number":"Mobile wallet number" | translate}}</label>
            <div class="text-primary">{{ this.form.get('operator').value }}</div>
          </div>
          <mat-form-field appearance="outline" class="sm:ml-6 flex-auto w-full mat-form-field-full">
            <input (ngModelChange)="onCheckConfirmNumber()" matInput formControlName="phone_no" #input type="tel"
              pattern="\d*" placeholder="{{ placeHolder }}" maxlength="{{ maxLength }}">
            <mat-error>
              <span *ngFor="
                  let error of form?.get('phone_no').errors | keyvalue
                ">
                {{ validationMessages.phone_no[error.key] }}
              </span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="input-row">
        <div class="customInput customSelect">
          <div class="flex input-label" style="justify-content:space-between ;">
            <label for="">Repeat {{isMerchantNumber?"Merchant Number":"Mobile wallet number"}}</label>
            <div class="text-primary">{{ this.form.get('operator').value }}</div>
          </div>
          <mat-form-field appearance="outline" class="sm:ml-6 flex-auto w-full mat-form-field-full">
            <input (ngModelChange)="onCheckConfirmNumber()" matInput formControlName="repeat_phone_no" #input type="tel"
              pattern="\d*" placeholder="{{ placeHolder }}" maxlength="{{ maxLength }}">
            <mat-error>
              <span *ngFor="
                  let error of form.get('repeat_phone_no').errors
                    | keyvalue
                ">
                {{ validationMessages.repeat_phone_no[error.key] }}
              </span>
            </mat-error>
          </mat-form-field>
        </div>
        <mat-error>
          <span class="identical" *ngIf="hasPhoneInputError">
            {{ checkPhoneErrorMessage }}
          </span>
        </mat-error>
      </div>

    </div>
  </form>
</div>
<mat-divider class="-mx-6 text-border"></mat-divider>

<div mat-dialog-actions class="btn-bloc">
  <button mat-flat-button mat-dialog-close class="cancel-btn m-2" type="button" (click)="cancelAction()">
    {{"Cancel" | translate}}
  </button>

  <button style="border-radius: 1rem;" class="m-2" mat-flat-button [class.confirm-btn]="form.valid"
    [disabled]="!form.valid" type="button" (click)="submitAction()">
    {{ submitBtnText | translate}}
  </button>
</div>