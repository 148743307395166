import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { USER_SESSION_KEY } from 'src/app/Models/constants';
import { BusinessService } from 'src/app/services/business.service';
import { takeUntil, take } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';


@Component({
  selector: 'vex-create-virtual-account',
  templateUrl: './create-virtual-account.component.html',
  styleUrls: ['./create-virtual-account.component.scss']
})
export class CreateVirtualAccountComponent implements OnInit {

  virtualAccountForm: FormGroup
  recieptFile: File
  isUploading: boolean = false
  userData : any
  isProcessing :boolean = false
  hasError: boolean = false;
  unsubscribe$ = new Subject();
  credentials : string = ''

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private router: Router,
    private location: Location,
    private fb: FormBuilder,
    private businessService: BusinessService,
    private snackBar: MatSnackBar, 
  ) { 


    const sessionData = localStorage.getItem(USER_SESSION_KEY);
    this.userData = JSON.parse(sessionData); 
    this.credentials = `${this.userData?.api_secret_key_live}:${this.userData?.api_public_key_live}`;

    


    iconRegistry.addSvgIcon(
      'docs',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/docs.svg')
    );
    iconRegistry.addSvgIcon(
      'arrow2',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/arrow2.svg')
    );
    iconRegistry.addSvgIcon(
      'close',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/close.svg')
    );
    iconRegistry.addSvgIcon(
      'copy',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/copy.svg')
    );


  }

  ngOnInit(): void {
    this.virtualAccountForm = this.fb.group({
      first_name: [this.userData.first_name , Validators.required],
      last_name: [this.userData.last_name, Validators.required],
      bvn: ['', Validators.required],
      user_id: [this.userData.user_id],
    }); 
  }

  uploadID(event) {
    this.recieptFile = event.target.files[0];
    this.isUploading = true;
    //const reader: FileReader = new FileReader();
    //reader.onload = () => {
    //this.reciept = reader.result as string;
    //};
    //reader.readAsDataURL(this.recieptFile);

  }

  goBack() {
    this.location.back();  // Retourne à la page précédente
  }
  createVirtaulAccountt() {
    this.isProcessing = true;
    this.businessService.createVirtualAccount(this.virtualAccountForm.value, this.credentials)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(response => {
        this.isProcessing = false;
        if (response && response['status'] == true) {
          this.openSnackbar("Virtual account created successfully.");  
          this.router.navigate(['/dashboards/analytics'])  
          //this.goBack()  
        } else {
          if (response['status'] === false) {
            this.hasError = true;
            //this.errorMessage = response['message'];
            this.openSnackbar(response['message']);

            if(response['message'] == 'User already have a virtual account'){ 
              this.router.navigate(['/dashboards/analytics'])
            }
            //VTXthis.router.navigate(['/dashboards/cash-in-transactions'])
          } else {
            this.hasError = true;
            this.showInternalServerErrorMessage()
          }
        }
      }),
      error => {
        this.hasError = true;
        //this.errorMessage = error.message;
        this.showInternalServerErrorMessage()
      }
  }
  openSnackbar(message) {
    this.snackBar.open(message, 'CLOSE', {
      duration: 3000,
      horizontalPosition: 'right'
    });
  }
  showInternalServerErrorMessage() {
    //this.errorMessage = "Error. Please retry again later."
    this.openSnackbar("Error. Please retry again later.");
  } 

  

}
