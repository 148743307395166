import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import {
  BUSINESS_DATA_KEY,
  SUMMARY_DATA_KEY,
  USER_SESSION_KEY,
} from 'src/app/Models/constants';
import { BusinessService } from 'src/app/services/business.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'vex-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss']
})
export class WalletComponent implements OnInit {

  unsubscribe$ = new Subject();
  userData: any;
  credentials: string;
  collectionsBalanceData: any;
  balanceData: any;
  dataLoaded = false;
  isLoading: boolean = true;
  dataSource: MatTableDataSource<any> | null;
  displayedColumns = ['currency', 'balance', 'action'];
  currencyBalance = [
    { currency: "XOF", balance: 0, collectionBalance: 0 },
    { currency: "XAF", balance: 0, collectionBalance: 0 },
    { currency: "NGN", balance: 0, collectionBalance: 0 },
    { currency: "GHS", balance: 0, collectionBalance: 0 }
  ]

  showDetails: boolean = true

  constructor(
    private sanitizer: DomSanitizer,
    private iconRegistry: MatIconRegistry,
    private service: BusinessService,
    private router: Router,
    private snackBar: MatSnackBar,
  ) {

    const sessionData = JSON.parse(localStorage.getItem(USER_SESSION_KEY));
    this.userData = sessionData;
    this.credentials = `${this.userData?.api_secret_key_live}:${this.userData?.api_public_key_live}`;



    iconRegistry.addSvgIcon(
      'more',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/more.svg')
    );
    iconRegistry.addSvgIcon(
      'XAF',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/xaf-icon.svg')
    );
    iconRegistry.addSvgIcon(
      'XOF',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/xof1.svg')
    );
    iconRegistry.addSvgIcon(
      'NGN',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/naira.svg')
    );
    iconRegistry.addSvgIcon(
      'GHS',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/cedis-icon.svg')
    );
  }

  ngOnInit(): void {
    this.getUserBalances();
    this.getUserCollectionsBalances();
  }


  getUserCollectionsBalances() {
    this.service
      .getUserCollectionsBalances(this.userData.user_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response: any) => {
          if (response.data.length) {
            const balanceDataMap = this.toMap(response.data);
            Object.keys(balanceDataMap).forEach((currency) => {
              const balances = balanceDataMap[currency].map(
                (data) => data.balance
              );
              const result = balances?.reduce((acc: any, cur: any) => {
                return (balanceDataMap[currency] = acc + Number(cur));
              }, 0) as number;
              return result.toFixed(2);
            });
            this.collectionsBalanceData = balanceDataMap;
            for (let currencyB of this.currencyBalance) {
              if (currencyB.currency == "XOF") {
                if (!isNaN(this.collectionsBalanceData.XOF)) {
                  currencyB.collectionBalance = this.collectionsBalanceData.XOF
                }
              }
              if (currencyB.currency == "XAF") {
                if (!isNaN(this.collectionsBalanceData.XAF)) {
                  currencyB.collectionBalance = this.collectionsBalanceData.XAF
                }
              }
              if (currencyB.currency == "NGN") {
                if (!isNaN(this.collectionsBalanceData.NGN)) {
                  currencyB.collectionBalance = this.collectionsBalanceData.NGN
                }
              }
              if (currencyB.currency == "GHS") {
                if (!isNaN(this.collectionsBalanceData.GHS)) {
                  currencyB.collectionBalance = this.collectionsBalanceData.GHS
                }
              }
            }
            this.dataSource = new MatTableDataSource(this.currencyBalance)

          }
        },
        (error) => {
          this.showInternalServerErrorMessage()
        });
  }
  showInternalServerErrorMessage() {
    this.openSnackbar("Error. Please retry again later.");
  }
  getUserBalances() {
    this.isLoading = true;
    this.service
      .getUserBalances(this.userData.user_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response: any) => { 
        if (response.data.length) {
          const balanceDataMap = this.toMap(response.data);
          Object.keys(balanceDataMap).forEach((currency) => {
            const countries = balanceDataMap[currency].map(
              (data) => data.country
            );
            const filtered = balanceDataMap[currency].filter(
              ({ country }, index) => !countries.includes(country, index + 1)
            );
            const balances = filtered.map((data) => data.balance);

            const result = balances?.reduce((acc: any, cur: any) => {
              return (balanceDataMap[currency] = acc + Number(cur));
            }, 0) as number;
            return result.toFixed(2);
          });
          this.balanceData = balanceDataMap; 
          for (let currencyB of this.currencyBalance) {
            if (currencyB.currency == "XOF") {
              if (!isNaN(this.balanceData.XOF)) {
                currencyB.balance = this.balanceData.XOF
              }
            }
            if (currencyB.currency == "XAF") {
              if (!isNaN(this.balanceData.XAF)) {
                currencyB.balance = this.balanceData.XAF
              }
            }
            if (currencyB.currency == "NGN") {
              if (!isNaN(this.balanceData.NGN)) {
                currencyB.balance = this.balanceData.NGN
              }
            }
            if (currencyB.currency == "GHS") {
              if (!isNaN(this.balanceData.GHS)) {
                currencyB.balance = this.balanceData.GHS
              }
            }
          }
          this.dataSource = new MatTableDataSource(this.currencyBalance)
        }
      },
        (error) => {
          this.dataLoaded = false;
          this.isLoading = false; 
          this.showInternalServerErrorMessage()
        });
  }
  isAllowed(habl: string) {

    let habilitations = []
    let isAllow = false
    if (this.userData.secondaryAccount) {
      if (this.userData.habilitations != undefined) {
        habilitations = this.userData.habilitations.split(',')
        for (let hab of habilitations) {
          if (hab == habl) {
            isAllow = true
          }
        }
      }
    } else {
      isAllow = true
    }
    return isAllow
  }
  private toMap(balanceData) {
    const result = balanceData.reduce((dataMap, item) => {
      dataMap[item.currency] = dataMap[item.currency] || [];
      dataMap[item.currency].push(item);
      return dataMap;
    }, Object.create(null));

    return result;
  }

  openBalanceTopUpsPopup(currency) {
    if (this.isAllowed('5') || this.isAllowed('1')) {
      this.router.navigate(['/dashboards/balance', currency]).then();
    } else {
      this.openSnackbar("You are not allowed");
    }
  }
  openFinancialStatmentPopup(currency) {
    if (this.isAllowed('5') || this.isAllowed('1')) {
      this.router.navigate(['/dashboards/financial-statment', currency]).then();
    } else {
      this.openSnackbar("You are not allowed");
    }
  }
  //<   
  openSnackbar(message) {
    this.snackBar.open(message, 'CLOSE', {
      duration: 5000,
      horizontalPosition: 'right',
    });
  }



  showDetails2(row: { currency: '', balance: '', collectionBalance: 0, details: any }) {

    this.hidePreviousDetails();
    const detailsElement = document.createElement('div');
    detailsElement.classList.add('details-container'); // Add your desired styling
    detailsElement.style.position = 'absolute';
    detailsElement.style.top = `${100}px`;
    const detailsContent = `
      <p>Description: ${row.details.description}</p>
      <p>Additional Value 1: ${row.details.additionalValue1}</p>
      <p>Additional Value 2: ${row.details.additionalValue2}</p>
      `;
    detailsElement.innerHTML = detailsContent;

    // Append the element to the table container
    document.querySelector('.mat-table').appendChild(detailsElement);
  }

  // Optional function to hide previously displayed details
  hidePreviousDetails() {
    const existingDetails = document.querySelectorAll('.details-container');
    for (const element of Array.from(existingDetails)) {
      element.parentNode.removeChild(element);
    }
  }




}
