<div class="card">
  <div class="border-b py-4 px-6" fxLayout="row" fxLayoutAlign="start center">
    <h2 class="m-0 title" fxFlex="auto">{{"Transactions Overview" | translate}} </h2>

    <!-- <button mat-icon-button type="button">
      <mat-icon [icIcon]="icCloudDownload" class="text-secondary"></mat-icon>
    </button>

    <button mat-icon-button type="button">
      <mat-icon [icIcon]="icMoreHoriz" class="text-secondary"></mat-icon>
    </button> -->
  </div>

  <div class="py-6 px-6" fxLayout="row" fxLayout.lt-md="column">
    <div class="pb-6 pt-4 px-6" fxFlex="auto" fxLayout="column">
      <h2 class="display-2 font-bold m-0">{{ total }}</h2>
      <h3 class="title font-medium mt-2 mb-0">{{'Transactions this month' | translate}} </h3>
      <!-- <h3 class="display-2 font-bold m-0">{{ totalAmount }}</h3>
      <h6 class="title font-medium mt-2 mb-0">Amount disbursed this month</h6> -->
      <div class="mt-6" fxFlex="auto" fxLayout="column" fxLayoutAlign="end">
        <button color="primary" (click)="viewTransactionsReport()" mat-raised-button type="button">
          {{"VIEW DETAILS" | translate}}
        </button>
      </div>
    </div>

    <div fxFlex="50%" fxFlexOffset="48px">
      <vex-chart [options]="options" [series]="series"></vex-chart>
    </div>
  </div>
</div>
