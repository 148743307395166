<hr style="border-color: rgba(0, 0, 0, 0.169);">





<div class="container mt-5 pt-3 bg-gray-100 " *ngIf="!depositCurrencyChoosed"> 

    <div style="display: flex; margin-left: 80px;"> 
        <div style="flex: 1 400px; margin-right: 10px;">
            <div style="width: 400px; height: 290px; border: 1px solid #ccc; border-radius: 4px; margin-top: 20px;">
                <div style="padding: 10px;">
                    <strong>{{"Make a deposit in XOF" | translate}} </strong>
                </div>
                <div style="padding: 10px;  height: 150px;"><br><br>
                    Lorem ipsum, dolor sit amet usdamiciendis voluptas optio maiores rerum alias! amiciendis voluptas optio maiores
                </div>
                <hr style="border-color: rgba(0, 0, 0, 0.201); margin-top: 35px">
                <div style="padding: 10px; background-color: #f5f5f5; cursor: pointer;" (click)="chooseCurrencyDeposit()">
    
                    <div style="display: flex; ">
                        <div style="flex:1; padding-top: 10px;">
                            <strong>{{"Deposit" | translate}} </strong>
                        </div>
                        <div style="flex:1">
                            <mat-icon [svgIcon]="'arrow'" class="menu-item-icon mr-1 " style="position: relative; top:5px; left: 171px;" matListIcon aria-hidden="false"></mat-icon>
                        </div>
                    </div>
                      
                </div>
            </div>
        </div>



        <div style="flex: 1 400px; margin-right: 10px;">
            <div style="width: 400px; height: 290px; border: 1px solid #ccc; border-radius: 4px; margin-top: 20px;">
                <div style="padding: 10px;">
                    <strong>{{"Make a deposit in NGN" | translate}} </strong>
                </div>
                <div style="padding: 10px; height: 150px;"><br><br>
                    Lorem ipsum, dolor sit amet usdamiciendis voluptas optio maiores rerum alias! amiciendis voluptas optio maiores
                </div>
                <hr style="border-color: rgba(0, 0, 0, 0.201); margin-top: 35px">
                <div style="padding: 10px; background-color: #f5f5f5; cursor: pointer;">
    
                    <div style="display: flex; ">
                        <div style="flex:1; padding-top: 10px;">
                            <strong>{{"Deposit" | translate}} </strong>
                        </div>
                        <div style="flex:1">
                            <mat-icon [svgIcon]="'arrow'" class="menu-item-icon mr-1 " style="position: relative; top:5px; left: 171px;" matListIcon aria-hidden="false"></mat-icon>
                        </div>
                    </div>
                      
                </div>
            </div>
        </div>



    </div> 
</div>

  
 