<vex-page-layout>

    <mat-divider class="-mx-6 text-border toolbar-mat-divider"></mat-divider>

    

    <vex-page-layout-content [class.container]="layoutCtrl.value === 'boxed'"
        [class.px-gutter]="layoutCtrl.value === 'fullwidth'" class="-mt-6" >

        <div>

            <div class="table-btns-bar mt-7 ml-4">
                <div class="text-bold text-primary mr-4 mb-4">
                    {{numberOfBeneficiaries}} {{"Beneficiaries"| translate}}
                </div>
                <button mat-button type="submit" class="disburs" 
                    (click)="openAddBeneficiaryPopup()">
                    <mat-icon class="mr-3 text-white" matPrefix>add_circle_outlined</mat-icon>
                    {{"Add Beneficiary"| translate}} </button>
            </div>
        </div>
        <div   class="w-full" vexContainer>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="30px">
                <div  class="card" fxFlex="auto">
                    <div *ngIf="!isFormReady" class="card overflow-auto -mt-16">
                        <div style="
                                      display: flex;
                                      justify-content: center;
                                      align-items: center;
                                      background: white;">
                            <mat-spinner color="primary"> </mat-spinner>
                        </div>
                    </div>


                    <div class="mt-3">
                        <div class="bg-card rounded-full border px-4" fxFlex="250px" fxFlex.lt-md="auto" fxHide.xs
                            fxLayout="row" fxLayoutAlign="start center">
                            <ic-icon [icIcon]="icSearch" size="28px"></ic-icon>
                            <input [formControl]="searchCtrlBeneficiary"
                                class="px-4 py-3 border-0 outline-none w-full bg-transparent"
                                placeholder="{{'Enter trans ID to find...'| translate}} " type="search">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <br>
        <br>
        <br>

        <div class="card overflow-auto -mt-16">
            <div *ngIf="isLoading" class="card overflow-auto -mt-16">
                <div style="
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background: white;
                            height: 20rem;
                          ">
                    <mat-spinner color="primary"> </mat-spinner>
                </div>
            </div>

            <table *ngIf="!isLoading" mat-table [dataSource]="beneficiariesDataSource" class="w-full mat-elevation-z"
                @stagger matSort matTableExporter #exporter="matTableExporter">
                <ng-container matColumnDef="beneficiary">
                    <th mat-header-cell *matHeaderCellDef>{{"Beneficiaries" | translate}} </th>
                    <td mat-cell *matCellDef="let element">{{ element.beneficiary | uppercase }}</td>
                </ng-container>

              
               


                <!-- Symbol Column -->
                <ng-container matColumnDef="operator">
                    <th mat-header-cell *matHeaderCellDef>{{"Operator"| translate}} </th>
                    <td mat-cell *matCellDef="let element">{{ element.operator | uppercase }}</td>
                </ng-container>

                <ng-container matColumnDef="account">
                    <th mat-header-cell *matHeaderCellDef>{{"Account"| translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ element.account }}</td>
                </ng-container>

                 

                <ng-container matColumnDef="country">
                    <th mat-header-cell *matHeaderCellDef>{{"Currency"| translate}} </th>
                    <td mat-cell *matCellDef="let element">{{ element.country }}</td>
                </ng-container>

                <ng-container matColumnDef="created_at">
                    <th mat-header-cell *matHeaderCellDef>Date</th>
                    <mat-cell matTooltipPosition="after" [matTooltip]="element.formatedDate" *matCellDef="let element">
                        {{ element.created_at }}
                    </mat-cell>
                </ng-container>

                 

                <ng-container matColumnDef="options">
                    <th mat-header-cell *matHeaderCellDef>Option</th>
                    <td mat-cell *matCellDef="let element">
                        <button (click)="selectBankBeneficiary(element)"
                            [matMenuTriggerFor]="beneficiaryMenu"><mat-icon [ngStyle]="{'color':'black'}" matPrefix>more_horiz</mat-icon></button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="beneficiariesDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: beneficiariesDisplayedColumns"></tr>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">
                        {{
                        errorMessage || "No Transaction Data Found Or Matching The filter" | translate
                        }}
                    </td>
                </tr>
            </table>

            <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize"
                class="sticky left-0"></mat-paginator>
        </div>
    </vex-page-layout-content>






</vex-page-layout>

 

<mat-menu #beneficiaryMenu="matMenu" id="disburse-popup"
    class="z-10 w-50 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600 block"
    style="position: absolute; inset: 0 auto auto 0; margin: 0; transform: translate(23px, 70px);"
    data-popper-reference-hidden="" data-popper-escaped="" data-popper-placement="bottom">

    <ul class="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownInformationButton">
        <li>
            <a (click)="deleteBeneficiary(this.beneficiaryOperationData.selectedBankBeneficiary)" mat-button
                class="block py-0 px-1 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                <button><mat-icon beneficiaryMenu matPrefix>delete</mat-icon></button>
                <div class="disburse-content">
                    <p>{{"Delete"| translate}} </p>
                </div>
            </a>
        </li>
    </ul>
</mat-menu> 