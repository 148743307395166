import { Component, OnInit } from "@angular/core";
import icCopy from "@iconify/icons-ic/twotone-content-copy";
import { BUSINESS_DATA_KEY , USER_SESSION_KEY,} from "src/app/Models/constants";
import { MerchantData } from "src/app/Models/models.interface";
import icEdit from "@iconify/icons-ic/twotone-edit";
import icHidden from "@iconify/icons-ic/twotone-visibility-off";
import icVisible from "@iconify/icons-ic/twotone-visibility";
import { take } from "rxjs/operators";
import { BusinessService } from "src/app/services/business.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from "@angular/material/form-field";
@Component({
  selector: "vex-api",
  templateUrl: "./api.component.html",
  styleUrls: ["./api.component.scss"],
  providers: [
    {
        provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
        useValue: {
            appearance: 'outline',
        } as MatFormFieldDefaultOptions,
    },
],
})
export class ApiComponent implements OnInit {
  icCopy = icCopy;
  icEdit = icEdit;
  icHidden = icHidden;
  icVisible = icVisible;
  userBusinessData: MerchantData;
  hide = true;
  pubhide = true;
  userhide = true;
  testsecrethide = true;
  testpubhide = true;
  edit = false;
  isUpdating: boolean = false;
  errorMessage: any;
  hasError: boolean;
  callbackURL: string;
  userData: any;
  menu:{current:{name:string, value:string}, options:{name:string, value:string}[]} = {
    current: { name: 'Live API Keys', value: "live_api_keys" },
    options: [
      { name: 'Live API Keys', value: "live_api_keys" },
      { name: 'Test API Keys', value: "test_api_keys" },
      { name: 'PAL API Doc', value: "pal_api_doc" },
    ]
  };

  constructor(
    private businessService: BusinessService,
    private snackBar: MatSnackBar
  ) {
    const sessionData = JSON.parse(localStorage.getItem(USER_SESSION_KEY));
    this.userData = sessionData;
    //this.credentials = `${this.userData?.api_secret_key_live}:${this.userData?.api_public_key_live}`;



    const businessData = localStorage.getItem(BUSINESS_DATA_KEY);
    this.userBusinessData = JSON.parse(businessData);
    this.callbackURL = this.userBusinessData?.callback_url;
 
  }

  ngOnInit(): void {}

  copyInputMessage(inputElement) {
    inputElement.select();
    document.execCommand("copy");
    inputElement.setSelectionRange(0, 0);
  }

  updateCallbackUrl(callback_url: string) {
    this.isUpdating = true;
    const businessData = { ...this.userBusinessData };
    businessData.callback_url = callback_url
    this.businessService
      .updateBusinessData(businessData, this.userBusinessData.id)
      .pipe(take(1))
      .subscribe((response: any) => {
        this.isUpdating = false;
        if ((response.status = true && response.data)) {
          this.openSnackbar(response["message"]);
          localStorage.setItem(
            BUSINESS_DATA_KEY,
            JSON.stringify(response.data)
          );
          const parsedUrl = new URL(window.location.href);
          const baseUrl = parsedUrl.origin;
          window.location.replace(`${baseUrl}/dashboards/api`);
        } else {
          this.errorMessage = response.message || "something went wrong";
          this.hasError = true;
        }
      }),
      (error) => {
        this.isUpdating = false;
        this.hasError = true;
      };
  }
  changeIsibility(hide){
    if(this.isAllowed("8") || this.isAllowed("1")){
      return !hide
    }else{
      this.openSnackbar("You are not allowed");
      return hide
    }
    
  }
  
  isAllowed(habl: string) {

    let habilitations = []
    let isAllow = false
    if (this.userData.secondaryAccount) {
      if (this.userData.habilitations != undefined) {
        habilitations = this.userData.habilitations.split(',')
        for (let hab of habilitations) {
          if (hab == habl) {
            isAllow = true
          }
        }
      }
    } else {
      isAllow = true
    }
    return isAllow
  }
  

  openSnackbar(message) {
    this.snackBar.open(message, "CLOSE", {
      duration: 3000,
      horizontalPosition: "right",
    });
  }

  selectOption(current:{name:string, value:string}){
    this.menu.current = current;
   }
}
