import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentRef, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PopoverService } from '../../../components/popover/popover.service';
import { ToolbarNotificationsDropdownComponent } from './toolbar-notifications-dropdown/toolbar-notifications-dropdown.component';
import icNotificationsActive from '@iconify/icons-ic/twotone-notifications-active';
import Pusher from 'pusher-js'; 
import { PopoverRef } from 'src/@vex/components/popover/popover-ref';
@Component({
  selector: 'vex-toolbar-notifications',
  templateUrl: './toolbar-notifications.component.html',
  styleUrls: ['./toolbar-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarNotificationsComponent implements OnInit {

  @ViewChild('originRef', { static: true, read: ElementRef }) originRef: ElementRef;

  dropdownOpen: boolean;
  icNotificationsActive = icNotificationsActive;

  constructor(private popover: PopoverService,
              private cd: ChangeDetectorRef) {



                var pusher = new Pusher('15132291b18382341eff', {
                  cluster: 'mt1',
                });
                var channel = pusher.subscribe('user-notification-channel');
                /*
                channel.bind('user_notification', function (data: any) {
                  //this.openSnackbar(data.data["message"])
                  //this.router.navigate(['/dashboards/user-notification']);
                  //this.router.navigate(["/dashboards/user-notification"]);
                  this.openNotificationPopup( data.data)
                  alert("New notification \n \n" + data.data['titre'] + '\n' + data.data["message"]);
      
                });
                */
      
                channel.bind('user_notification', (data: any) => { // Utilisation d'une fonction fléchée
                  this.showPopover()

                  // ATTENDRE UN PEU ET CLOSE 
                });


              }

  ngOnInit() {}

  showPopover() {
    this.dropdownOpen = true;
    this.cd.markForCheck();

    const popoverRef = this.popover.open({
      content: ToolbarNotificationsDropdownComponent,
      origin: this.originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });

     
/*
    popoverRef.instance.closeButtonClicked.subscribe(() => {
      // Réagir lorsque le bouton est cliqué en fermant le popover
      popoverRef.close();
    });
    */

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      this.cd.markForCheck();
    });
  }

  closeNotification(){ 
    this.dropdownOpen = false
  }



  


















  
}
