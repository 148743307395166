<!-- (ngSubmit)="save()" -->



<div *ngFor="let collectionParameter of collectionParameters">
 

    <div *ngIf="collectionParameter?.code == 'BJ'"> 
      <div *ngIf="!mobileMoneyTopUpOption && !merchantDepositTopUpOption && !bankDepositTopUpOption && !createVirtualAccountOption">
        <div class="text-center">
          <strong>{{"Recharge via:"| translate}} </strong>
        </div>
        <hr>


        <div *ngIf="collectionParameter?.operators" class="inline-block-container" style="cursor: pointer;" (click)="selectmobileMoneyTopUp(collectionParameter?.operators)">
          <div class="inline-block1">
  
            <h5><strong>{{"Mobile Money"| translate}} </strong> </h5>
            {{"Automatically fund your PAL account using your linked account"| translate}} mobile money. <br> 
            <span> {{"Fee:"| translate}}  </span>
            <span *ngFor="let operator of collectionParameter?.operators"> {{" "+operator?.name}}: {{operator?.charges}}%.</span>
            <span>{{" The fees are deducted by the network."}} </span>

          </div>
          <div class="inline-block2">
            <mat-icon [svgIcon]="'arrow2'" style="position: relative;  left: 50%;" class="menu-item-icon mr-1" matListIcon
              aria-hidden="false"></mat-icon>
          </div>
        </div> 
        <hr *ngIf="collectionParameter?.operators">


        <div *ngIf="collectionParameter?.merchant" class="inline-block-container" style="cursor: pointer;" (click)="selectmerchantDepositTopUp(collectionParameter?.merchant)">
          <div class="inline-block1">
            <h5> <strong>{{"Merchant Deposit" | translate}} </strong> </h5>
            {{"Go to an MTN super merchant and make a deposit into our" | translate}} <br>
            <ul>
              <li>{{"Merchant Number:"| translate}} <strong>{{collectionParameter?.merchant?.merchant_number}}</strong> </li>
              <li>{{"Name :"| translate}} <strong>{{collectionParameter?.merchant?.merchant_name}}</strong> </li>
            </ul>
            {{collectionParameter?.merchant?.charges| translate}}
          </div>
          <div class="inline-block2">
            <mat-icon [svgIcon]="'arrow2'" style="position: relative;  left: 50%;" class="menu-item-icon mr-1" matListIcon
              aria-hidden="false"></mat-icon>
          </div>
        </div> 
        <hr *ngIf="collectionParameter?.merchant">




        <div *ngIf="collectionParameter?.bank" class="inline-block-container" style="cursor: pointer;" (click)="selectbankDepositTopUp(collectionParameter?.bank)">
          <div class="inline-block1">
            <h5><strong>{{"Deposit or Bank transfer"| translate}} </strong> </h5>
            {{"Make a deposit or bank transfer to our UBA account."}} <br>
            <ul>
              <li>{{"Bank code:" | translate}} <strong>{{collectionParameter?.bank?.code}} </strong> - {{"Branch code:"|translate}} <strong> {{collectionParameter?.bank?.branch_code}} </strong>
              </li>
              <li>{{"Account number:"|translate}} <strong>{{collectionParameter?.bank?.account_number}}</strong> - {{"RIB key:"| translate}}<strong>{{collectionParameter?.bank?.rib}}</strong>
              </li>
              <li>{{"Account Title:"| translate}} <strong>{{collectionParameter?.bank?.account_name}} </strong> </li>
            </ul>
            {{"Free"|translate}}
          </div>
          <div class="inline-block2">
            <mat-icon [svgIcon]="'arrow2'" style="position: relative;  left: 50%;" class="menu-item-icon mr-1" matListIcon
              aria-hidden="false"></mat-icon>
          </div>
        </div>


  
      </div>
    </div>
 

    <div *ngIf="collectionParameter?.code == 'CI'"> 
      <div *ngIf="!mobileMoneyTopUpOption && !merchantDepositTopUpOption && !bankDepositTopUpOption && !createVirtualAccountOption">
        <div class="text-center">
          <strong>{{"Recharge via:"| translate}}  </strong>
        </div>
        <hr>


        <div *ngIf="collectionParameter?.operators" class="inline-block-container" style="cursor: pointer;" (click)="selectmobileMoneyTopUp(collectionParameter.operators)">
          <div class="inline-block1">
  
            <h5><strong>{{"Mobile Money"| translate}} </strong> </h5>
            {{"Automatically fund your PAL account using your linked account"| translate}} mobile money. <br> 
            <span> {{"Fee:"| translate}}  </span>
            <span *ngFor="let operator of collectionParameter?.operators"> {{" "+operator?.name}}: {{operator?.charges}}%.</span>
            <span>{{" The fees are deducted by the network."}} </span>

          </div>
          <div class="inline-block2">
            <mat-icon [svgIcon]="'arrow2'" style="position: relative;  left: 50%;" class="menu-item-icon mr-1" matListIcon
              aria-hidden="false"></mat-icon>
          </div>
        </div> 
        <hr *ngIf="collectionParameter?.operators">


        <div *ngIf="collectionParameter?.merchant" class="inline-block-container" style="cursor: pointer;" (click)="selectmerchantDepositTopUp(collectionParameter?.merchant)">
          <div class="inline-block1">
            <h5> <strong>{{"Merchant Deposit" | translate}} </strong> </h5>
            {{"Go to an MTN super merchant and make a deposit into our" | translate}} <br>
            <ul>
              <li>{{"Merchant Number:"| translate}} <strong>{{collectionParameter?.merchant?.merchant_number}}</strong> </li>
              <li>{{"Name :"| translate}} <strong>{{collectionParameter?.merchant?.merchant_name}}</strong> </li>
            </ul>
            {{collectionParameter?.merchant?.charges| translate}}
          </div>
          <div class="inline-block2">
            <mat-icon [svgIcon]="'arrow2'" style="position: relative;  left: 50%;" class="menu-item-icon mr-1" matListIcon
              aria-hidden="false"></mat-icon>
          </div>
        </div> 
        <hr *ngIf="collectionParameter?.merchant">




        <div *ngIf="collectionParameter?.bank" class="inline-block-container" style="cursor: pointer;" (click)="selectbankDepositTopUp(collectionParameter?.bank)">
          <div class="inline-block1">
            <h5><strong>{{"Deposit or Bank transfer"| translate}} </strong> </h5>
            {{"Make a deposit or bank transfer to our UBA account."}} <br>
            <ul>
              <li>{{"Bank code:" | translate}} <strong>{{collectionParameter?.bank?.code}} </strong> - {{"Branch code:"|translate}} <strong> {{collectionParameter?.bank?.branch_code}} </strong>
              </li>
              <li>{{"Account number:"|translate}} <strong>{{collectionParameter?.bank?.account_number}}</strong> - {{"RIB key:"| translate}}<strong>{{collectionParameter?.bank?.rib}}</strong>
              </li>
              <li>{{"Account Title:"| translate}} <strong>{{collectionParameter?.bank?.account_name}} </strong> </li>
            </ul>
            {{"Free"|translate}}
          </div>
          <div class="inline-block2">
            <mat-icon [svgIcon]="'arrow2'" style="position: relative;  left: 50%;" class="menu-item-icon mr-1" matListIcon
              aria-hidden="false"></mat-icon>
          </div>
        </div>

        
  
      </div>
    </div>
 

    <div *ngIf="collectionParameter?.code == 'GH'"> 
      <div *ngIf="!mobileMoneyTopUpOption && !merchantDepositTopUpOption && !bankDepositTopUpOption && !createVirtualAccountOption">
        <div class="text-center">
          <strong>{{"Recharge via:"| translate}}  </strong>
        </div>
        <hr>


        <div *ngIf="collectionParameter?.operators" class="inline-block-container" style="cursor: pointer;" (click)="selectmobileMoneyTopUp(collectionParameter?.operators)">
          <div class="inline-block1">
  
            <h5><strong>{{"Mobile Money"| translate}} </strong> </h5>
            {{"Automatically fund your PAL account using your linked account"| translate}} mobile money. <br> 
            <span> {{"Fee:"| translate}}  </span>
            <span *ngFor="let operator of collectionParameter?.operators"> {{" "+operator?.name}}: {{operator?.charges}}%.</span>
            <span>{{" The fees are deducted by the network."}} </span>

          </div>
          <div class="inline-block2">
            <mat-icon [svgIcon]="'arrow2'" style="position: relative;  left: 50%;" class="menu-item-icon mr-1" matListIcon
              aria-hidden="false"></mat-icon>
          </div>
        </div> 
        <hr *ngIf="collectionParameter?.operators">


        <div *ngIf="collectionParameter?.merchant" class="inline-block-container" style="cursor: pointer;" (click)="selectmerchantDepositTopUp(collectionParameter?.merchant)">
          <div class="inline-block1">
            <h5> <strong>{{"Merchant Deposit" | translate}} </strong> </h5>
            {{"Go to an MTN super merchant and make a deposit into our" | translate}} <br>
            <ul>
              <li>{{"Merchant Number:"| translate}} <strong>{{collectionParameter?.merchant?.merchant_number}}</strong> </li>
              <li>{{"Name :"| translate}} <strong>{{collectionParameter?.merchant?.merchant_name}}</strong> </li>
            </ul>
            {{collectionParameter?.merchant?.charges| translate}}
          </div>
          <div class="inline-block2">
            <mat-icon [svgIcon]="'arrow2'" style="position: relative;  left: 50%;" class="menu-item-icon mr-1" matListIcon
              aria-hidden="false"></mat-icon>
          </div>
        </div> 
        <hr *ngIf="collectionParameter?.merchant">

 
        <div *ngIf="collectionParameter?.bank" class="inline-block-container" style="cursor: pointer;" (click)="selectbankDepositTopUp(collectionParameter?.bank)">
          <div class="inline-block1">
            <h5><strong>{{"Deposit or Bank transfer"| translate}} </strong> </h5>
            {{"Make a deposit or bank transfer to our UBA account."}} <br>
            <ul>
              <li>{{"Bank code:" | translate}} <strong>{{collectionParameter?.bank?.code}} </strong> - {{"Branch code:"|translate}} <strong> {{collectionParameter?.bank?.branch_code}} </strong>
              </li>
              <li>{{"Account number:"|translate}} <strong>{{collectionParameter?.bank?.account_number}}</strong> - {{"RIB key:"| translate}}<strong>{{collectionParameter?.bank?.rib}}</strong>
              </li>
              <li>{{"Account Title:"| translate}} <strong>{{collectionParameter?.bank?.account_name}} </strong> </li>
            </ul>
            {{"Free"|translate}}
          </div>
          <div class="inline-block2">
            <mat-icon [svgIcon]="'arrow2'" style="position: relative;  left: 50%;" class="menu-item-icon mr-1" matListIcon
              aria-hidden="false"></mat-icon>
          </div>
        </div>


  
      </div>
    </div>

 
    <div *ngIf="collectionParameter?.code == 'NG'"> 
      <div *ngIf="!mobileMoneyTopUpOption && !merchantDepositTopUpOption && !bankDepositTopUpOption && !createVirtualAccountOption">
        <div class="text-center">
          <strong>{{"Recharge NGN via your virtual bank account"| translate}} </strong>
        </div>
        <hr>


        <div *ngIf="collectionParameter?.operators && !userVirtualAccount?.account_number" class="inline-block-container" style="cursor: pointer;" (click)="selectCreateVirtualAccount()">
          <div class="inline-block1">
            <h5><strong>{{"Deposit or Bank transfer"| translate}} </strong> </h5>
            <br> <br> 
            <span style="color: red;" class="text-danger">{{"You haven't yet created your virtual account to rechager per NGN. Please click to create."}}</span> 
            <br> <br>
             
            {{"Free"|translate}}
          </div>
          <div class="inline-block2">
            <mat-icon [svgIcon]="'arrow2'" style="position: relative;  left: 50%;" class="menu-item-icon mr-1" matListIcon aria-hidden="false"></mat-icon>
          </div>
        </div> 


        <div *ngIf="collectionParameter?.operators && userVirtualAccount?.account_number" class="inline-block-container" style="cursor: pointer;">
          <div class="inline-block1">
            <h5><strong>{{"Deposit or Bank transfer"| translate}} </strong> </h5>
            {{"Make a deposit or bank transfer to your virtual account."}} <br>
            <ul>
              <li>{{"Bank :" | translate}} <strong>{{userVirtualAccount?.bank | uppercase}} </strong> 
              </li>
              <li>{{"Account number:"|translate}} <strong>{{userVirtualAccount?.account_number}}</strong>  
              </li>
              <li>{{"Account Name:"| translate}} <strong>{{userVirtualAccount?.account_name}} </strong> </li>
            </ul>
            {{"Free"|translate}}
          </div>
          <div class="inline-block2">
            <mat-icon [svgIcon]="'arrow2'" style="position: relative;  left: 50%;" class="menu-item-icon mr-1" matListIcon aria-hidden="false"></mat-icon>
          </div>
        </div> 
        <hr *ngIf="collectionParameter?.operators">


 
      </div>
    </div>
 
</div>






 
  <div style="  border-radius: 4px;">
 
    <div *ngIf="mobileMoneyTopUpOption">
      <div class="text-center">
        <strong> {{"Mobile Money"| translate}} </strong>
      </div>
      <hr>
      <div>
 

        <form [formGroup]="mobileMoneytopUpForm">

          <mat-dialog-content fxLayout="column" class="content-part">
            <div class="grid grid-flow-col grid-cols-1 grid-rows-2 gap-8">
              <div>
                <div class="grid grid-flow-col gap-4 grid-cols grid-rows">
                  <div class="label"><label for="" class="label">{{"Enter the amount."| translate}} </label></div>
                </div>
                <mat-form-field appearance="outline" style="width: 370px;">
                  <input matInput formControlName="amount" placeholder="" type="number">
                  <mat-icon matSuffix style="width: 70px;">
                    <h3>{{ currency }}</h3>
                  </mat-icon>
                  <mat-error>
                    <span *ngFor="let error of mobileMoneytopUpForm.get('amount').errors | keyvalue ">
                      {{ validationMessages.amount[error.key] | translate }}
                    </span>
                  </mat-error>
                </mat-form-field>
                <div class="grid grid-flow-col gap-4 grid-cols grid-rows "> 
                  <div class="text-center">
                    <span *ngFor="let network of selectedCollectionMethod">
                      <span *ngIf="network.name == mobileMoneytopUpForm.value.operator">
                        {{"Network fees:"| translate}} {{(mobileMoneytopUpForm.value.amount*network.charges) / 100}} - {{"Net à payer:" | translate}}
                      {{((mobileMoneytopUpForm.value.amount*network.charges) / 100) + mobileMoneytopUpForm.value.amount}}
                      </span>
                    </span>
                  </div> 
                </div>
              </div> 
              <div> 
                <div class="grid grid-flow-col gap-4 grid-cols grid-rows">
                  <div class="label"><label for="" class="label">{{"Enter phone number."| translate}} </label></div>
                </div>
                <div class="flex">


                  <div style="width: 60%;">
                    <mat-form-field appearance="outline" class="flex-auto w-full">
                      <input matInput formControlName="phone_no" placeholder="" maxlength="8" min="8" type="text">
                      <mat-error>
                        <span *ngFor=" let error of mobileMoneytopUpForm.get('phone_no').errors | keyvalue ">
                          {{ validationMessages.phone_no[error.key] | translate }}
                        </span>
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div style="width: 120px;">
                    <mat-form-field appearance="outline" style="width: 150px; height: 100px;">
                      <mat-select placeholder="Select an Operator" formControlName="operator">
                        <mat-option value=""> Select operator </mat-option>
                        <mat-option value="{{network.name}}" *ngFor="let network of selectedCollectionMethod"> {{network.name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div> 
                </div>
              </div> 
            </div> 
          </mat-dialog-content>
          <br> <br> 
          <button color="primary" mat-button type="button" (click)="rechargeAccount()"
            [ngClass]="(isProcessing || mobileMoneytopUpForm.invalid )? 'invalid-send-button': 'valid-send-button'"
            [disabled]="isProcessing || mobileMoneytopUpForm.invalid ">{{ isProcessing === true?
            'Processing...' :'Approve' | translate}}
          </button> 
        </form>




      </div>
    </div>

    <div *ngIf="merchantDepositTopUpOption">
      <div class="text-center">
        <strong> {{"Merchant Deposit"| translate}} </strong>
      </div>
      <hr>
      <div class="mt-5" style="text-align: center; ">
        {{"Please upload the receipt for the deposited amount, thank you."| translate}} <br>
        {{"At the super merchant. Our team will proceed to"| translate}} <br>
        {{"approve your recharge."| translate}}
      </div>
      <div>
        <form [formGroup]="merchantDeposittopUpForm">
          <mat-divider class="-mx-6 text-border"></mat-divider>
          <mat-dialog-content fxLayout="column" class="content-part">
            <div class="grid grid-flow-col grid-cols-1 grid-rows-3 gap-8">


              <!--
              <div>
                <div class="grid grid-flow-col gap-4 grid-cols grid-rows">
                  <div class="label"><label for="" class="label">{{"Select Account"| translate}} </label></div>
                </div>
                <select mat-select formControlName="currency" name="" id="" style="width: 400px; height: 40px;">
                  <option value="">{{ "Select currency" | translate }}</option>
                  <option value="XOF">{{ 'XOF ACCOUNT' | translate }}</option>
                  <option value="XAF">{{ 'XAF ACCOUNT' | translate }}</option>
                  <option value="GHS">{{ 'GHS ACCOUNT' | translate }}</option>
                  <option value="NGN">{{ 'NGN ACCOUNT' | translate }}</option>
                </select>
              </div>
              --> 
              <div>
                <div class="grid grid-flow-col gap-4 grid-cols grid-rows">
                  <div class="label"><label for="" class="label">{{"Deposited amount."| translate}} </label></div>
                </div>
                <mat-form-field appearance="outline" style="width: 400px;">
                  <input matInput formControlName="amount" placeholder="" type="number">
                  <mat-icon matSuffix style="width: 70px;">
                    <h3>{{ merchantDeposittopUpForm.value.currency }}</h3>
                  </mat-icon>
                  <mat-error>
                    <span *ngFor="
                    let error of merchantDeposittopUpForm.get('amount').errors
                      | keyvalue
                  ">
                      {{ validationMessages.amount[error.key] | translate }}
                    </span>
                  </mat-error>
                </mat-form-field>
              </div>

              <div fxLayout="row" fxLayoutAlign="start">
                <mat-file-upload [labelText]="''" style="height: 50px; width: 400px; border: solid 1px #ccc;"
                  class="text-center" [uploadButtonText]="'upload'" [uploadButtonType]="'button'"
                  [showUploadButton]="false" [selectButtonText]="'Upload your proof*'"
                  [acceptedTypes]="'.doc, .pdf, .jpg, .jpeg, .png'" (uploadClicked)="uploadID($event)"
                  (change)="uploadID($event)">
                </mat-file-upload>
              </div>


            </div>
          </mat-dialog-content>

          <button color="primary" mat-button type="button" (click)="rechargeAccount()"
            [ngClass]="(isProcessing || merchantDeposittopUpForm.invalid || !isUploading)? 'invalid-send-button2' : 'valid-send-button2' "
            [disabled]="isProcessing || merchantDeposittopUpForm.invalid || !isUploading">{{ isProcessing === true?
            'Processing...' :
            'Approve' | translate}}
          </button>


        </form>
      </div>
    </div>

    <div *ngIf="bankDepositTopUpOption">
      <div class="text-center">
        <strong> {{"Deposit or bank transfer"| translate}} </strong>
      </div>
      <hr>
      <div class="mt-5" style="text-align: center; ">
        {{"Please upload the receipt for the amount."| translate}} <br>
        {{"Deposit made or bank transfer to our account." | translate}} <br>
        {{"Our team will proceed with the approval of your recharge."| translate}}
      </div>
      <div>
        <form [formGroup]="bankDeposittopUpForm">
          <mat-divider class="-mx-6 text-border"></mat-divider>
          <mat-dialog-content fxLayout="column" class="content-part">
            <div class="grid grid-flow-col grid-cols-1 grid-rows-2 gap-8">
              <div>
                <div class="grid grid-flow-col gap-4 grid-cols grid-rows">
                  <div class="label"><label for="" class="label">{{"Deposited or transferred amount."| translate}}
                    </label></div>
                </div>
                <mat-form-field appearance="outline" style="width: 400px;">
                  <input matInput formControlName="amount" placeholder="" type="number">
                  <mat-icon matSuffix style="width: 70px; float: right;">
                    <h3>{{ bankDeposittopUpForm.value.currency }}</h3>
                  </mat-icon>
                  <mat-error>
                    <span *ngFor="
                    let error of bankDeposittopUpForm.get('amount').errors
                      | keyvalue
                  ">
                      {{ validationMessages.amount[error.key] | translate }}
                    </span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div>
                <div fxLayout="row" fxLayoutAlign="start">
                  <mat-file-upload [labelText]="''" style="height: 50px; width: 400px; border: solid 1px #ccc;"
                    class="text-center" [uploadButtonText]="'upload'" [uploadButtonType]="'button'"
                    [showUploadButton]="false" [selectButtonText]="'Upload your proof*'"
                    [acceptedTypes]="'.doc, .pdf, .jpg, .jpeg, .png'" (uploadClicked)="uploadID($event)"
                    (change)="uploadID($event)">
                  </mat-file-upload>
                </div>
              </div>
            </div>
          </mat-dialog-content>
          <button color="primary" mat-button type="button" (click)="rechargeAccount()"
            [ngClass]="(isProcessing || bankDeposittopUpForm.invalid || !isUploading)? 'invalid-send-button2': 'valid-send-button2'"
            [disabled]="isProcessing || bankDeposittopUpForm.invalid || !isUploading">{{ isProcessing === true?
            'Processing...' :
            'Approve' | translate}}
          </button>


        </form>
      </div>
    </div>

    <div *ngIf="createVirtualAccountOption">
      <div class="text-center">
        <strong> {{"Create your virtual account"| translate}} </strong>
      </div>
      <hr>
      <div class="mt-5" style="text-align: center; ">
        
      </div>
      <div>
        <form [formGroup]="virtualAccountForm">
          <mat-divider class="-mx-6 text-border"></mat-divider>
          <mat-dialog-content fxLayout="column" class="content-part">
            <div class="grid grid-flow-col grid-cols-1 grid-rows-3 gap-8">

              <div>
                <div class="grid grid-flow-col gap-4 grid-cols grid-rows">
                  <div class="label"><label for="" class="label">{{"Firstname"| translate}}
                    </label></div>
                </div>
                <mat-form-field appearance="outline" style="width: 400px;">
                  <input matInput formControlName="first_name" placeholder="" type="text"> 
                </mat-form-field>
              </div>
              <div> 

                <div class="grid grid-flow-col gap-4 grid-cols grid-rows">
                  <div class="label"><label for="" class="label">{{"Lastname"| translate}}
                    </label></div>
                </div>
                <mat-form-field appearance="outline" style="width: 400px;">
                  <input matInput formControlName="last_name" placeholder="" type="text"> 
                </mat-form-field>
              </div>

              <div> 
                <div class="grid grid-flow-col gap-4 grid-cols grid-rows">
                  <div class="label"><label for="" class="label">{{"BVN"| translate}}
                    </label></div>
                </div>
                <mat-form-field appearance="outline" style="width: 400px;">
                  <input matInput formControlName="bvn" placeholder="" type="text"> 
                </mat-form-field>
              </div>
               
            </div>
          </mat-dialog-content>
          <br> <br>  
          <button color="primary" mat-button type="button" (click)="createVirtaulAccountt()"
            [ngClass]="(isProcessing || virtualAccountForm.invalid)? 'invalid-send-button2': 'valid-send-button2'"
            [disabled]="isProcessing || virtualAccountForm.invalid">{{ isProcessing === true?
            'Processing...' :
            'Approve' | translate}}
          </button>


        </form>
      </div>
    </div>
 
  </div>