import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions, } from '@angular/material/form-field';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';

import icEdit from '@iconify/icons-ic/twotone-edit';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icSearch from '@iconify/icons-ic/twotone-search';
import icAdd from '@iconify/icons-ic/twotone-add';
import icFilterList from '@iconify/icons-ic/twotone-filter-list';
import icPhone from '@iconify/icons-ic/twotone-phone';
import icMail from '@iconify/icons-ic/twotone-mail';
import icMap from '@iconify/icons-ic/twotone-map';
import icMoreHoriz from '@iconify/icons-ic/twotone-more-horiz';
import icFolder from '@iconify/icons-ic/twotone-folder';
import icDateRange from '@iconify/icons-ic/twotone-date-range';
import icPerson from '@iconify/icons-ic/twotone-person';
import icRefresh from '@iconify/icons-ic/twotone-refresh';
import icBook from '@iconify/icons-ic/twotone-book';
import icCloudDownload from '@iconify/icons-ic/twotone-cloud-download';
import icAttachMoney from '@iconify/icons-ic/twotone-attach-money';
import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { MatTableExporterDirective } from 'mat-table-exporter';
import moment from 'moment';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import {
  BUSINESS_DATA_KEY,
  COUNTRIES,
  SUMMARY_DATA_KEY,
  TRANSACTION_TABLE_LABELS,
  USER_SESSION_KEY,
} from 'src/app/Models/constants';
import { SummaryData } from 'src/app/Models/models.interface';
import { Customer } from 'src/app/pages/apps/aio-table/interfaces/customer.model';
import { aioTableData, aioTableLabels } from 'src/static-data/aio-table-data';




import peopole from '@iconify/icons-ic/people-outline';
import { MatMenu } from '@angular/material/menu';
import { BeneficiariesService } from 'src/app/services/beneficiaries.service';
import { ExchangeService } from 'src/app/services/exchange.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TransactionsService } from 'src/app/services/transactions.service';
import { TableUtil } from '../reports/transactions-report/tableUtil';
import { TransactionReportFilterFormComponent } from '../reports/transactions-report/transaction-report-filter-form/transaction-report-filter-form.component';
import { PendingBusinessModalComponent } from '../pending-business-modal/pending-business-modal.component';
import { SuspendedBusinessModalComponent } from '../suspended-business-modal/suspended-business-modal.component';
import { NoBusinessModalComponent } from '../no-business-modal/no-business-modal.component';
import { AddMobileBeneficiaryComponent } from '../reports/transactions-report/add-mobile-beneficiary/add-mobile-beneficiary.component';


@Component({
  selector: 'vex-historical-transactions',
  templateUrl: './historical-transactions.component.html',
  styleUrls: ['./historical-transactions.component.scss']
})
export class HistoricalTransactionsComponent implements OnInit {



  //PAYOUTS
  layoutCtrl = new FormControl('boxed'); M
  subject$: ReplaySubject<Customer[]> = new ReplaySubject<Customer[]>(1);
  unsubscribe$ = new Subject();
  Mdata$: Observable<Customer[]> = this.subject$.asObservable();
  transactionColumns: TableColumn<Customer>[] = [
    { label: 'ID', property: 'id', type: 'text', visible: true },
    { label: 'Type transaction', property: 'type_transaction', type: 'text', visible: true },


    //
    {
      label: 'date',
      property: 'created_at',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Network',
      property: 'provider',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Wallet Number',
      property: 'wallet',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    // { label: "Contact", property: "phone_no", type: "button", visible: true },
    {
      label: 'Currency',
      property: 'currency',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Country',
      property: 'country',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Reference',
      property: 'network_transaction_ref',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Pal fee',
      property: 'charges',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Amount Sent',
      property: 'amount',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Start Balance',
      property: 'business_start_balance',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'End Balance',
      property: 'business_end_balance',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Status',
      property: 'status',
      type: 'badge',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Filter',
      property: 'filter',
      type: 'badge',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
  ];
  MpageSize = 10;
  MpageSizeOptions: number[] = [5, 10, 20, 50];
  dataSource: MatTableDataSource<any> | null;
  MbeneficiariesDataSource: MatTableDataSource<any> | null;
  Mselection = new SelectionModel<any>(true, []);
  MsearchCtrl = new FormControl();
  searchCtrlBeneficiary = new FormControl();
  MexportOptions = {
    fileName: 'test',
    sheet: { reportsProps: { Author: 'PAL' } },
  };
  Mlabels = aioTableLabels;
  icon_peapole = peopole;
  Mform: FormGroup;
  MstatusLabels = TRANSACTION_TABLE_LABELS;
  MdisplayedColumns: string[] = [];
  MtransactionColsOrder = [];
  MbeneficiariesDisplayedColumns: string[] = [
    'phone_no',
    'country',
    'bank_name',
    'options',
  ];

  Mstatuses = [
    { name: 'Pending', value: 1 },
    { name: 'Completed', value: 3 },
    { name: 'Error', value: 4 },
    { name: 'Networ Error', value: 6 },
    { name: 'Processing', value: 2, },
    { name: 'Processing', value: 20, },
    { name: 'Cancelled', value: 0 },
  ];
  Mmenu: { current: { name: string, value: string }, options: { name: string, value: string }[] } = {
    current: { name: 'Payouts', value: "payouts" },
    options: [
      { name: 'Payouts', value: "payouts" },
      { name: 'Beneficiaries', value: "beneficiaries" },
    ]
  };
  MbeneficiaryOperationData: {
    message: string;
    selectedMobileBeneficiary: any
  } = { message: null, selectedMobileBeneficiary: null };
  Mcountries = COUNTRIES;
  MavailableCountries = ['GH', 'BJ', 'CI'];
  MnetworkProviders = ['mtn', 'orange'];
  Mcurrencies = ['GHS', 'XOF', 'XAF', 'NGN'];
  MuserData: any;
  MnumberOfBeneficiaries = 0;
  @ViewChild("MmenuMakeTransfer", { static: true })
  MmenuMakeTransfer: MatMenu;
 
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTableExporterDirective)
  matTableExporter: MatTableExporterDirective;
  MtransactionsData: any;
  MtransactionType: any;
  hasNoTransactions: boolean;
  MpalFee = 0;
  MisLoading: boolean;
  MmerchantSummaryData: SummaryData;
  MbeneficiariesData: any;
  MuserSessionData: any;
  MhasError = false;
  errorMessage: string;
  Mcustomers: any;
  Musers: any;
  Mproducts: any;
  Mtransactions: any;
  MuserBusinessData: any;
  Mcredentials: string;
  MisOpenDisbursePopup = false;
  @Input()
  MtransactionColumns: TableColumn<Customer>[] = [
    { label: 'ID', property: 'id', type: 'text', visible: true },
    { label: 'Type transaction', property: 'type_transaction', type: 'text', visible: true },
   
    {
      label: 'Network',
      property: 'provider',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Wallet Number',
      property: 'wallet',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Currency',
      property: 'currency',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Country',
      property: 'country',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Reference',
      property: 'network_transaction_ref',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },

    {
      label: 'Amount Sent',
      property: 'amount',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Pal fee',
      property: 'charges',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    }, 
    {
      label: 'Start Balance',
      property: 'business_start_balance',
      type: 'text',
      visible:false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'End Balance',
      property: 'business_end_balance',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'date',
      property: 'created_at',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },

    {
      label: 'Status',
      property: 'status',
      type: 'badge',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Filter',
      property: 'filter',
      type: 'badge',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
  ];
  browserLanguage: string
  userData: any;
  merchantSummaryData: SummaryData;
  credentials: string;
  userBusinessData: any;
  isLoading: boolean;



  icPhone = icPhone;
  icMail = icMail;
  icMap = icMap;
  icAttachMoney = icAttachMoney;
  icEdit = icEdit;
  icSearch = icSearch;
  icPerson = icPerson;
  icDelete = icDelete;
  icAdd = icAdd;
  icFilterList = icFilterList;
  icMoreHoriz = icMoreHoriz;
  icFolder = icFolder;
  icDateRange = icDateRange;
  icBook = icBook;
  icRefresh = icRefresh;
  icCloudDownload = icCloudDownload;






  constructor(
    //PAY OUTS       
    private transactionService: TransactionsService,
    private beneficiariesService: BeneficiariesService,
    private exchangeService: ExchangeService,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private fb: FormBuilder,
  ) {


    const user = localStorage.getItem('current_user');
    const sessionData = JSON.parse(localStorage.getItem(USER_SESSION_KEY));
    this.userData = sessionData;
    this.credentials = `${this.userData?.api_secret_key_live}:${this.userData?.api_public_key_live}`;
    this.merchantSummaryData = JSON.parse(localStorage.getItem(SUMMARY_DATA_KEY));
    const businessData = localStorage.getItem(BUSINESS_DATA_KEY);
    if (businessData !== 'undefined') {
      this.userBusinessData = JSON.parse(businessData);
    }
    if (!sessionData) {
      router.navigate(['/auth/login']);
    }


    //PAYOUTS 
    this.MtransactionColumns.forEach(
      e => {
        if (e.visible) {
          this.MdisplayedColumns.push(e.property);
        }
      }
    )
    const summaryData = JSON.parse(localStorage.getItem(SUMMARY_DATA_KEY));
    this.MmerchantSummaryData = summaryData;
    if (businessData !== 'undefined') {
      this.MuserBusinessData = JSON.parse(businessData);
    }
    if (!sessionData) {
      router.navigate(['/auth/login']);
    }


  }

  ngOnInit(): void {

    if (this.isAllowed('6') || this.isAllowed('1')) {

      //PAYOUTS 
      this.Mform = this.fb.group({
        status: [''],
        dateFrom: [''],
        dateTo: [''],
        currency: [''],
        operator: ['']
      });
      this.dataSource = new MatTableDataSource();
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      //this.Msearch();
      this.getHistoricalTransactionList()
      this.MsearchCtrl.valueChanges
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((value) => this.MonFilterChange(value));
      this.searchCtrlBeneficiary.valueChanges
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((value) => this.MonFilterChangeBeneficiary(value));



    } else {
      this.openSnackbar("You are not allowed");
    }





  }


  getvisibleTransactionColumns() {
    return this.transactionColumns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }
  MgetData() {
    return of(aioTableData.map((customer) => new Customer(customer)));
  }
  gethasExceededFreeTransfers(): boolean {
    return this.merchantSummaryData?.totalTransactionsAmount > 560000;
  }
  exportAsXlsx() { 
    if (this.isAllowed("7")) {
      this.matTableExporter.exportTable('xlsx', {
        fileName: 'Transactions Report',
        sheet: 'report',
        Props: { Author: 'PAL Africa' },
      });
    } else {
      this.openSnackbar("You are not allowed");
    }

  }
  MrefreshData() {
    this.getHistoricalTransactionList();
  }
  MresetForm() {
    this.Mform = this.fb.group({
      status: [''],
      dateFrom: [''],
      dateTo: [''],
      currency: [''],
      operator: ['']
    });
    this.getHistoricalTransactionList();
  }
  /*
  MgetTransactionsList(filteredData = null) {
    
    this.isLoading = true;
    if (filteredData) {
      const transactions = filteredData;
      this.isLoading = false;
      this.MtransactionsData = transactions?.map((details) => {
        details.state = this.MgetStatusLabel(details.state);
        details.formatedDate = moment(details.created_at).fromNow();
        details.country = this.MgetCountryName(details.country);
        return details;
      });
      this.hasNoTransactions = transactions.length === 0 ? true : false;
      this.dataSource = new MatTableDataSource(this.MtransactionsData);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    } else {
      this.transactionService
        .getUserTransactions(this.userData?.user_id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (transactions) => {
            this.isLoading = false;
            this.MtransactionsData = transactions?.map((details) => {
              details.state = this.MgetStatusLabel(details.state);
              details.formatedDate = moment(details.created_at).fromNow();
              details.country = this.MgetCountryName(details.country);
              return details;
            });
            this.hasNoTransactions = transactions.length === 0 ? true : false;
            this.dataSource = new MatTableDataSource(this.MtransactionsData);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          },
          (error) => {
            this.isLoading = false;
            console.error(error.message);
            this.showInternalServerErrorMessage()
          }
        );
    }
  }
    */

  getHistoricalTransactionList(filteredData = null) { 
    this.isLoading = true;
    if (filteredData) { 
      const transactions = filteredData;
      this.isLoading = false;


      this.MtransactionsData = transactions?.map((details) => {
        details.state = this.MgetStatusLabel(details.state);
        details.formatedDate = moment(details.created_at).fromNow();
        details.country = this.MgetCountryName(details.country);
        return details;
      });


      this.hasNoTransactions = transactions.length === 0 ? true : false;
      this.dataSource = new MatTableDataSource(this.MtransactionsData);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    } else { 
      this.transactionService
        .getUserHistoricalTransactions(this.userData?.user_id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (transactions) => { 

            if (transactions["status"] == true) {
              transactions = transactions["data"]["data"]


              transactions = transactions?.map((details) => { 
               
                details[details.type_transaction].status = this.MgetStatusLabel(details[details.type_transaction].status);
                details.created_at = moment(details.created_at).fromNow();
                //details.country = this.MgetCountryName(details.country);
                return details;
              });


 
              this.isLoading = false;

              this.hasNoTransactions = transactions.length === 0 ? true : false;
              //this.dataSource = new MatTableDataSource(this.MtransactionsData);
              this.dataSource = new MatTableDataSource(transactions);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
            }




            /*
            this.MtransactionsData = transactions?.map((details) => {
              details.state = this.MgetStatusLabel(details.state);
              details.formatedDate = moment(details.created_at).fromNow();
              details.country = this.MgetCountryName(details.country);
              return details;
            });
            this.hasNoTransactions = transactions.length === 0 ? true : false;
            this.dataSource = new MatTableDataSource(this.MtransactionsData);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            */
          },
          (error) => {
            this.isLoading = false;
            console.error(error.message);
            this.showInternalServerErrorMessage()
          }
        );
    }
  }



  get isFormReady(): boolean {
    return true;
  }
  MgetCountryName(countryCode: string): string {
    const countryData = this.Mcountries.find(
      (country) => country.code === countryCode
    );
    return countryData.name;
  }
  MgetSalesRepName(user_id) {
    const salesRep = this.Musers?.find((user) => user.user_id === user_id);
    if (salesRep) {
      return salesRep.full_name;
    } else {
      return 'N/A';
    }
  }


  Msearch() { 
    if (this.Mform.value['dateFrom'] && this.Mform.value['dateTo']) {
      const from = new Date(this.Mform.value['dateFrom']).toISOString().slice(0, 19).replace('T', ' ');
      const to = new Date(this.Mform.value['dateTo']).toISOString().slice(0, 19).replace('T', ' ');
      this.Mform.value['dateFrom'] = from;
      this.Mform.value['dateTo'] = to;
    }

    this.isLoading = true;
    this.transactionService
      .searchTransactions(
        this.credentials,
        this.userBusinessData?.user_id,
        this.Mform.value,
        'mobile_transfers'
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => {
          this.isLoading = false;
          if (response['status'] === true) {


            this.Mtransactions = response['data'];
            this.getHistoricalTransactionList(response['data']);
            this.dataSource = new MatTableDataSource();
            this.dataSource.data = this.Mtransactions;
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            if (!this.dataSource.data.length) {
              this.MhasError = true;
              this.errorMessage =
                'No data Found for the specified search criteria. Please try with different data';
            } else {
              this.MhasError = false;
              this.errorMessage = '';
            }


            
          } else {
            this.MhasError = true;
            this.errorMessage =
              'No data Found for the specified search criteria. Please try with different data';
          }
        },
        (erro) => {
          this.isLoading = false;
          this.MhasError = true;
          this.errorMessage =
            'No data Found for the specified search criteria. Please try with different data';
        }
      );
  }
  MviewOrderDetails(order: any) {
    this.router.navigate(['/dashboards/orders/order-details/' + order.id]);
  }
  MgetStatusLabel(status: string) {
    return this.MstatusLabels.find((label) => label.text === status);
  }
  MdeleteOrder(order: any) {
  }
  MdeleteCustomers(customers: Customer[]) {
    customers.forEach((c) => this.MdeleteOrder(c));
  }
  MonFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.dataSource.filter = value;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

  }
  MonFilterChangeBeneficiary(value: string) {
    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.MbeneficiariesDataSource.filter = value;
  }
  MtoggleColumnVisibility(column, event) {

    if (!column.visible) {
      this.MdisplayedColumns = this.MdisplayedColumns.filter(col => column.property !== col)
    } else {
      let columns = [column.property, ...this.MdisplayedColumns]
      this.MdisplayedColumns = this.transactionColumns.map(
        ordoredCol => columns.find(col => col == ordoredCol.property)
      ).filter(col => col);
    }
  }
  MisAllSelected() {
    const numSelected = this.Mselection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  MmasterToggle() {
    this.MisAllSelected()
      ? this.Mselection.clear()
      : this.dataSource.data.forEach((row) => this.Mselection.select(row));
  }
  MtrackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }
  MonLabelChange(change: MatSelectChange, row: Customer) {
  }
  MexportTable() {
    TableUtil.exportTableToExcel('ExampleMaterialTable');
  }
  MexportNormalTable() {
    TableUtil.exportTableToExcel('ExampleNormalTable');
  }
  MexportArray() {
    const onlyNameAndSymbolArr: Partial<any>[] = this.dataSource.data.map(
      (x) => ({
        name: x.name,
        status: x.status,
      })
    );
    TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, 'ExampleArray');
  }



  MopenFilterFormPopup() {
    const config: MatDialogConfig = {
      width: '600px'
    };
    const dialogRef = this.dialog.open(TransactionReportFilterFormComponent, config);
    dialogRef.afterClosed().subscribe(form => {
      this.Mform = form;
      this.Msearch();
    })
  }




  MopenDisburseMobilePopup() {

    if (this.isAllowed('6') || this.isAllowed('1')) {
      if (this.userData.hasBusiness || !!this.userBusinessData) {
        if (this.userBusinessData?.status === 'pending') {
          this.dialog.open(PendingBusinessModalComponent);
        } else if (this.userBusinessData?.status === 'suspended') {
          this.dialog.open(SuspendedBusinessModalComponent);
        } else {
          this.router.navigate(['/dashboards/make-mobile-disbursment']).then();
        }
        this.MisOpenDisbursePopup = false;
      } else {
        this.dialog.open(NoBusinessModalComponent);
      }
    } else {
      this.openSnackbar("You are not allowed");
    }




  }
  closeDisburseMenu() {
    this.MisOpenDisbursePopup = false;
  }
  toggleDisbursePopup() {
    this.MisOpenDisbursePopup = !this.MisOpenDisbursePopup;
  }
  MselectOption(current: { name: string, value: string }) {
    this.Mmenu.current = current;
  }
  deleteMobileBeneficiary(id: number) {
    this.beneficiariesService.deleteMobileBeneficiary(this.credentials, { user_id: this.userData?.user_id, id: id })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => {
          if (response.status == true) {
            this.openSnackbar(response.message);
            this.getBeneficiariesList();
          } else {
            if (response.status == false) {
              this.openSnackbar(response.message);
            } else {
              this.showInternalServerErrorMessage()
            }
          }
        },
        (error) => {
          this.isLoading = false;
          //console.error(error.message);
          this.showInternalServerErrorMessage()
        }
      );
  }
  getBeneficiariesList(filteredData = null) {
    this.isLoading = true;

    this.beneficiariesService.getMobileBeneficiaries(this.userData?.user_id, this.credentials)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (beneficiaries) => {
          this.MbeneficiariesData = beneficiaries?.map((details) => {
            details.formatedDate = moment(details.created_at).fromNow();

            details.country = this.MgetCountryName(details.country);

            return details;
          });
          this.isLoading = false;
          this.MbeneficiariesDataSource = new MatTableDataSource(this.MbeneficiariesData);
          this.MbeneficiariesDataSource.paginator = this.paginator;
          this.MbeneficiariesDataSource.sort = this.sort;
          this.MnumberOfBeneficiaries = this.MbeneficiariesDataSource.data.length;
        },
        (error) => {
          this.isLoading = false;
          console.error(error.message);
        }
      );

  }
  selectMobileBeneficiary(id) {
    this.MbeneficiaryOperationData.selectedMobileBeneficiary = id;
  }
  openAddBeneficiaryPopup() {
    const config: MatDialogConfig = {
      width: '600px',
    };
    const dialogRef = this.dialog.open(AddMobileBeneficiaryComponent, config);
    dialogRef.afterClosed().subscribe(
      (response) => {
        if (response) {
          if (response.status == true) {
            this.openSnackbar(response.message);
            this.getBeneficiariesList();
          } else {
            if (response.status == false) {
              this.openSnackbar(response.message);
            } else {
              this.showInternalServerErrorMessage()
            }
          }
        }
      }),
      (error) => {
        this.showInternalServerErrorMessage()
      }
  }

  showInternalServerErrorMessage() {
    this.errorMessage = "Error. Please retry again later."
    this.openSnackbar("Error. Please retry again later.");
  }
  openSnackbar(message) {
    this.snackBar.open(message, 'CLOSE', {
      duration: 3000,
      horizontalPosition: 'right',
    });
  }
  isAllowed(habl: string) {

    let habilitations = []
    let isAllow = false
    if (this.userData.secondaryAccount) {
      if (this.userData.habilitations != undefined) {
        habilitations = this.userData.habilitations.split(',')
        for (let hab of habilitations) {
          if (hab == habl) {
            isAllow = true
          }
        }
      }
    } else {
      isAllow = true
    }
    return isAllow
  }

}
