
import { IconModule } from "@visurel/iconify-angular";

import { BrowserModule } from '@angular/platform-browser';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from '../@vex/vex.module';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';
import { HomeComponent } from './pages/dashboards/home/home.component';
import { TopUpTransactionComponent } from './pages/dashboards/top-up-transaction/top-up-transaction.component';
import { MatTableModule } from '@angular/material/table';
import { CurencyBalanceComponent } from './pages/dashboards/curency-balance/curency-balance.component';
import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DisburseCashComponent } from './pages/dashboards/disburse-cash/disburse-cash.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { MatDividerModule } from '@angular/material/divider';
import { ApiComponent } from './pages/dashboards/api/api.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TransactionsComponent } from './pages/dashboards/transactions/transactions.component';
import { RechargeAccountComponent } from './pages/dashboards/recharge-account/recharge-account.component';
import { NoBusinessModalComponent } from './pages/dashboards/no-business-modal/no-business-modal.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MomentModule } from 'angular2-moment';
import { ConfirmTransfersComponent } from './pages/dashboards/confirm-transfers/confirm-transfers.component';
import { AuthTimeoutModalComponent } from './pages/dashboards/auth-timeout-modal/auth-timeout-modal.component';
import { ReportsComponent } from './pages/dashboards/reports/reports.component';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { TransactionsReportComponent } from './pages/dashboards/reports/transactions-report/transactions-report.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CommonModule, DatePipe } from '@angular/common';
import { DashboardAnalyticsModule } from './pages/dashboards/dashboard-analytics/dashboard-analytics.module';
import { CollectionsReportComponent } from './pages/dashboards/reports/collections-report/collections-report.component';
import { BulkDisbursementComponent } from './pages/dashboards/disburse-cash/bulk-disbursement/bulk-disbursement.component';
import { AddUpdateDisbursementModalComponent } from './pages/dashboards/disburse-cash/bulk-disbursement/add-update-disbursement-modal/add-update-disbursement-modal.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PageLayoutModule } from '../@vex/components/page-layout/page-layout.module';
import { MatSortModule } from '@angular/material/sort';
import { AllTransfersComponent } from './pages/dashboards/reports/all-transfers/all-transfers.component';
import { ContainerModule } from '../@vex/directives/container/container.module';
import { MakeTransferComponent } from './pages/dashboards/make-transfer/make-transfer.component';
import { DisburseBankComponent } from './pages/dashboards/disburse-cash/disburse-bank/disburse-bank.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ChangePasswordComponent } from './pages/dashboards/change-password/change-password.component';
import { FilterFormComponent } from './pages/dashboards/reports/all-transfers/filter-form/filter-form.component';
import { AddBeneficiaryComponent } from './pages/dashboards/reports/all-transfers/add-beneficiary/add-beneficiary.component';
import { TransactionReportFilterFormComponent } from './pages/dashboards/reports/transactions-report/transaction-report-filter-form/transaction-report-filter-form.component';
import { AddMobileBeneficiaryComponent } from './pages/dashboards/reports/transactions-report/add-mobile-beneficiary/add-mobile-beneficiary.component';
import { CollectionReportFilterFormComponent } from "./pages/dashboards/reports/collections-report/collection-report-filter-form/collection-report-filter-form.component";
import { AuthorizationConfimationComponent } from "./pages/dashboards/authorization-confimation/authorization-confimation.component";
import { SuspendedBusinessModalComponent } from './pages/dashboards/suspended-business-modal/suspended-business-modal.component';
import { PendingBusinessModalComponent } from './pages/dashboards/pending-business-modal/pending-business-modal.component';
import { MakeMobileDisbursmentComponent } from './pages/dashboards/make-mobile-disbursment/make-mobile-disbursment.component';
//import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
 


import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MakeTransferChoiceComponent } from "./pages/dashboards/make-transfer-choice/make-transfer-choice.component";
import { WalletComponent } from './pages/dashboards/wallet/wallet.component';
import { ViewTransactionsComponent } from './pages/dashboards/view-transactions/view-transactions.component';
import { BeneficiariesComponent } from './pages/dashboards/beneficiaries/beneficiaries.component';
import { AddFundsComponent } from './pages/dashboards/add-funds/add-funds.component';
import { MatFileUploadComponent, MatFileUploadModule } from "mat-file-upload";
import { SwapComponent } from './pages/dashboards/swap/swap.component';
import { FormWizardRoutingModule } from "./pages/ui/forms/form-wizard/form-wizard-routing.module";
import { MatStepperModule } from "@angular/material/stepper";
import { SecondaryToolbarModule } from "src/@vex/components/secondary-toolbar/secondary-toolbar.module";
import { BreadcrumbsModule } from "src/@vex/components/breadcrumbs/breadcrumbs.module";
import { DeleteAccountComponent } from './pages/pages/auth/delete-account/delete-account.component';
import { RejectedBusinessModalComponent } from './pages/dashboards/rejected-business-modal/rejected-business-modal.component';
import { FinancialStatmentComponent } from './pages/dashboards/financial-statment/financial-statment.component';
import { UserNotificationModalComponent } from './pages/dashboards/user-notification-modal/user-notification-modal.component';
import { FinancialStatmentReportComponent } from "./pages/dashboards/financial-statment-report/financial-statment-report.component";
import { UserNotificationModalPopComponent } from './pages/dashboards/user-notification-modal-pop/user-notification-modal-pop.component';
import { RelativeDateTimeModule } from "src/@vex/pipes/relative-date-time/relative-date-time.module";
import { CollectionsComponent } from './pages/dashboards/view-transactions/collections/collections.component';
import { MobileTransfersComponent } from './pages/dashboards/view-transactions/mobile-transfers/mobile-transfers.component';
import { BankTransfersComponent } from './pages/dashboards/view-transactions/bank-transfers/bank-transfers.component';
import { SwapsComponent } from './pages/dashboards/view-transactions/swaps/swaps.component';
import { AuthService } from "./interceptors/auth.service";
import { HistoricalTransactionsComponent } from './pages/dashboards/historical-transactions/historical-transactions.component';
import { RechargeAccountMethodsComponent } from './pages/dashboards/recharge-account-methods/recharge-account-methods.component';
import { RechargeAccountMethodsChoosedComponent } from './pages/dashboards/recharge-account-methods-choosed/recharge-account-methods-choosed.component';
import { RechargeAccountMethodsCurrencyComponent } from './pages/dashboards/recharge-account-methods-currency/recharge-account-methods-currency.component';
import { BeneficiariesV1Component } from './pages/dashboards/beneficiaries-v1/beneficiaries-v1.component'; 
import { RechargeAccountV2Component } from "./pages/dashboards/recharge-account-v2/recharge-account-v2.component";
import { MethodsByCurrencyChoosedComponent } from "./pages/dashboards/recharge-account-v2/methods-by-currency-choosed/methods-by-currency-choosed.component"; 
import { CreateVirtualAccountComponent } from './pages/dashboards/recharge-account-v2/create-virtual-account/create-virtual-account.component';
import { RechargeByMobileMoneyComponent } from './pages/dashboards/recharge-account-v2/recharge-by-mobile-money/recharge-by-mobile-money.component';
import { RechargeByMerchantComponent } from './pages/dashboards/recharge-account-v2/recharge-by-merchant/recharge-by-merchant.component';
import { RechargeByBankComponent } from './pages/dashboards/recharge-account-v2/recharge-by-bank/recharge-by-bank.component';
import { AddBeneficiaryV2Component } from './pages/dashboards/add-beneficiary-v2/add-beneficiary-v2.component';
import { AddBankBeneficiaryV2Component } from './pages/dashboards/add-beneficiary-v2/add-bank-beneficiary-v2/add-bank-beneficiary-v2.component';
import { AddMobileBeneficiaryV2Component } from './pages/dashboards/add-beneficiary-v2/add-mobile-beneficiary-v2/add-mobile-beneficiary-v2.component'; 
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        TopUpTransactionComponent,
        CurencyBalanceComponent,
        DisburseCashComponent,
        BulkDisbursementComponent,
        TransactionsComponent,
        RechargeAccountComponent,
        ApiComponent,
        NoBusinessModalComponent,
        ConfirmTransfersComponent,
        AuthTimeoutModalComponent,
        ReportsComponent,
        TransactionsReportComponent,
        CollectionsReportComponent,
        AddUpdateDisbursementModalComponent,
        AllTransfersComponent,
        MakeTransferComponent,
        DisburseBankComponent,
        // ChangePasswordComponent,
        FilterFormComponent,
        AddBeneficiaryComponent,
        TransactionReportFilterFormComponent,
        AddMobileBeneficiaryComponent,
        CollectionReportFilterFormComponent,
        AuthorizationConfimationComponent,
        SuspendedBusinessModalComponent,
        PendingBusinessModalComponent,
        MakeMobileDisbursmentComponent,
        MakeTransferChoiceComponent,
        WalletComponent,
        ViewTransactionsComponent,
        BeneficiariesComponent,
        AddFundsComponent,
        SwapComponent,
        DeleteAccountComponent,
        RejectedBusinessModalComponent,
        FinancialStatmentComponent,
        UserNotificationModalComponent,
        FinancialStatmentReportComponent,
        UserNotificationModalPopComponent,
        CollectionsComponent,
        MobileTransfersComponent,
        BankTransfersComponent,
        SwapsComponent,
        HistoricalTransactionsComponent,
        RechargeAccountMethodsComponent,
        RechargeAccountMethodsChoosedComponent,
        RechargeAccountMethodsCurrencyComponent,
        BeneficiariesV1Component,
        //AddBankBeneficiaryComponent,

        RechargeAccountV2Component,
        MethodsByCurrencyChoosedComponent, 
        CreateVirtualAccountComponent,
        RechargeByMobileMoneyComponent,
        RechargeByMerchantComponent,
        RechargeByBankComponent,



       AddBeneficiaryV2Component,
        AddBankBeneficiaryV2Component,
        AddMobileBeneficiaryV2Component,
 

    ],
    providers: [
      DatePipe,

      {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthService,
        multi: true
      }
    
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,


        HttpClientModule,
        MatTableModule,
        VexModule,
        FlexLayoutModule,
        CustomLayoutModule,
        RouterModule,
        MatCardModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        FormsModule,
        MatSelectModule,
        MatOptionModule,
        MatFormFieldModule,
        IconModule,
        MatPaginatorModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MatDialogModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatRadioModule,
        MatMenuModule,
        MatDividerModule,
        MatSnackBarModule,
        MatDatepickerModule,
        MatTableExporterModule,
        MatCheckboxModule,
        MatNativeDateModule,
        MatProgressSpinnerModule,
        NgIdleKeepaliveModule.forRoot(),
        MomentModule,
        DashboardAnalyticsModule,
        MatTooltipModule,
        PageLayoutModule,
        MatSortModule,
        ContainerModule,
        MatButtonToggleModule,
        MatSlideToggleModule,
        MatFileUploadModule,
        CommonModule,
        FormWizardRoutingModule,
        MatStepperModule,
        SecondaryToolbarModule,
        BreadcrumbsModule,
        ContainerModule,
        RelativeDateTimeModule,
        //ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        
    ]
})
export class AppModule { }


