<!-- (ngSubmit)="save()" -->


<div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
     
</div>
<mat-divider class="-mx-6 text-border"></mat-divider>

<br> <br>








<div class="ms-5" style="margin-left: 100px; margin-right: 100px;">

    <div class="row card p-5 mb-5" *ngFor="let notification of notificationToShow">
        <div class="col-8 m-5" style="width:75%;">
            <h3>
                <b>{{notification.titre}}</b>
            </h3>
            <br>
            <span >
                {{notification.message}}
            </span>
            <br> <br>
            <span>
                {{notification.datetime | relativeDateTime}}
            </span>
        </div>
        <div class="col-4 m-5" style=" width:25%">
            <button class="btn btn-primary bg-primary" color="primary" style="height: 40px; width: 100px;" (click)="markReaded(notification.id)">{{"Ok, Close" | translate}} </button>
        </div>
    </div>

    <!--notifications-->
</div>