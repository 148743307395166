import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from '@angular/material/form-field';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  BANK_BRANCHES,
  BUSINESS_DATA_KEY,
  COUNTRIES,
  USER_SESSION_KEY
} from '../../../../Models/constants';
import {
  BankTransfer,
  //BankTransferXOFData
  BankTransferData,
} from '../../make-transfer/make-transfer.component';
import icClose from '@iconify/icons-ic/twotone-close';
import { TransactionsService } from 'src/app/services/transactions.service';
import { take, takeUntil } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';

@Component({
  selector: 'vex-disburse-bank',
  templateUrl: './disburse-bank.component.html',
  styleUrls: ['./disburse-bank.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
      } as MatFormFieldDefaultOptions,
    },
  ],
})
export class DisburseBankComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject();
  icClose = icClose;
  form: FormGroup;
  countries = COUNTRIES;
  bankTransfer: BankTransferData;
  banks = ['BSIC', 'NSIA', 'BOA', 'ECOBANK'];
  availableCountries = [
    { code: 'BJ', label: 'BENIN' },
    { code: 'CI', label: `COTE D'IVOIRE` },
    { code: 'TG', label: 'TOGO' },
    { code: 'SN', label: 'SENEGAL' },
    // { code: 'GH',  label: 'GHANA'},
    { code: 'ML', label: 'MALI' },
    { code: 'GW', label: 'GUINEE B.' },
    { code: 'NE', label: 'NIGER' },
    { code: 'BF', label: 'BURKINA FASO' },
  ];

  networkProviders = ['mtn', 'orange'];
  currencies = ['GHS', 'XOF', 'XAF', 'NGN'];
  bankBranches = [];
  confirmedTransfer = false;
  submitBtnText = 'Confirm Transfer';
  errorMessage = '';
  banksData: any;
  userBusinessData: any;
  credentials: string;
  isDisbursing: boolean;
  hasError: boolean;
  isSwiftCodeNeeded = false;
  isFetchingBankBranches: boolean;
  isFetchingBanksData: boolean;
  userData: any;

  constructor(
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private transactionService: TransactionsService
  ) {
    const businessData = localStorage.getItem(BUSINESS_DATA_KEY);
    if (businessData !== 'undefined') {
      this.userBusinessData = JSON.parse(businessData);
    }
    //this.credentials = `${this.userBusinessData?.api_secret_key_live}:${this.userBusinessData?.api_public_key_live}`;

    const sessionData = JSON.parse(localStorage.getItem(USER_SESSION_KEY));
    this.userData = sessionData;
    this.credentials = `${this.userData?.api_secret_key_live}:${this.userData?.api_public_key_live}`;
  }


  ngOnInit(): void {
    this.form = this.formBuilder.group({
      country: ['', [Validators.required]],
      amount: [0, [Validators.required, Validators.pattern(/^[0-9]*$/)]],
      account_bank: ['', [Validators.required]],
      narration: ['', [Validators.required]],
      account_number: [
        '',
        [Validators.required, Validators.pattern(/^[0-9]{24}$/)],
      ],
      beneficiary_name: ['', [Validators.required]],
      currency: ['XOF'],
      bank_branch: [''],
      phone_no: ['', [Validators.required, Validators.pattern(/^[0-9]*$/)]],
      swift_code: [],
    });


    this.form.get('country').valueChanges.subscribe((value) => {
      this.setCurrency(value);
      this.setIsSwiftCodeNeeded(value);
      this.getBankList(this.credentials, value);
    });

    this.form.get('account_bank').valueChanges.subscribe((value) => {
      this.getBankBranches(this.credentials, value);
    });

    // this.form.get('bank_branch').valueChanges.subscribe((value) => {
    //   const bankBranch = this.bankBranches.find(branch => branch.branch_name === value);
    //   this.form.get('bank_code').setValue(bankBranch.branch_code);
    // });
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setIsSwiftCodeNeeded(country: string) {
    if (country === 'BJ' || country === 'CI' || country === 'SN') {
      this.isSwiftCodeNeeded = false;
    } else {
      this.isSwiftCodeNeeded = true;
    }
  }

  setCurrency(country: string) {
    let currency;
    if (country !== 'GH') {
      currency = 'XOF';
    }
    // switch (country) {
    //   case 'GH':
    //     currency = 'GHS';
    //     break;
    //   case 'BJ':
    //     currency = 'XOF';
    //     break;
    //   case 'CI':
    //     currency = 'XOF';
    //     break;
    //   case 'SG':
    //     currency = 'XOF';
    //     break;
    // }
    this.form.get('currency').setValue(currency);
  }

  get formControls() {
    return this.form.controls;
  }

  get isFormReady(): boolean {
    return true;
  }

  cancelAction() {
    this.confirmedTransfer = false;
    this.submitBtnText = 'Confirm Transfer';
  }

  submitAction() {
    if (!this.form.valid) {
      this.errorMessage = 'Please fill up required fields';
      return;
    }
    this.confirmedTransfer = true;
    this.submitBtnText = 'Approve Transfer';
  }

  confirmTransfer() {
    this.confirmedTransfer = true;
    this.submitBtnText = 'Approve Transfer';
  }

  cancelConfirmTransfer() {
    this.form.reset();
    this.confirmedTransfer = false;
  }

  approveTransfer() {
    this.confirmedTransfer = false;
    this.submitBtnText = 'Confirm Transfer';
  }

  cancelApprovedTransfer() {
    this.confirmedTransfer = false;
    this.submitBtnText = 'Confirm Transfer';
  }

  getBankName(accountBank: any) { 
    if (!this.isSwiftCodeNeeded) {
      const bank = this.banksData.find(
        (data) => data.code === this.form.get('account_bank').value
      ); 
      return bank?.name || 'N/A';
    } else {
      return this.form.get('account_bank').value;
    }
  }

  getCountryName() {
    const country = this.availableCountries.find(
      (data) => data.code === this.form.get('country').value
    );
    return country?.label || 'N/A';
  }

  getBankList(credentials, country) {
    this.isFetchingBanksData = true;
    this.transactionService
      .getBankDetails(credentials, country, '')
      .pipe(take(1))
      .subscribe((data) => {
        this.isFetchingBanksData = false;
        this.banksData = data;
      });
  }

  getBankBranches(credentials, bankCode) {
    this.isFetchingBankBranches = true;
    const selectedBank = this.banksData?.find((bank) => bank?.code === bankCode);
    this.transactionService
      .getBankBranchDetails(credentials, selectedBank?.id)
      .pipe(take(1))
      .subscribe((data) => {
        this.isFetchingBankBranches = false;
        this.bankBranches = data;
      });
  }

  initiateBankTransfer() {

    if (this.isAllowed('6') || this.isAllowed('1')) {
      this.submitAction();
      if (this.confirmedTransfer) {
        const transactiondetails: BankTransferData = {
          account_bank: this.form.value.account_bank,
          account_number: this.form.value.account_number,
          beneficiary_name: this.form.value.beneficiary_name,
          amount: this.form.value.amount,
          currency: this.form.value.currency,
          narration: this.form.value.narration,
          user_id: this.userBusinessData.user_id,
          country: this.form.value.country,
          bank_branch: this.form.value.bank_branch,
          // bank_code: this.form.value.bank_code,
          phone_no: this.form.value.phone_no,
          swift_code: this.form.value.swift_code,
          email: '',
          bank_transfer_api: '',
          bank_id: '',
        };

        this.transactionService
          .createBankTransfer(transactiondetails, this.credentials)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            (response) => {
              this.isDisbursing = false;
              if (response && response['status'] === 'success') {
                this.openSnackbar(response['message']);
                window.location.reload();
              } else {
                this.hasError = true;
                this.errorMessage = response['message'];
                this.openSnackbar(response['message']);
              }
            },
            (error) => {
              this.isDisbursing = false;
              this.hasError = true;
              this.errorMessage =
                error.message ||
                'Something went wrong please try again or contact support';
              this.openSnackbar(this.errorMessage);
            }
          );
      }
    } else {
      this.openSnackbar("You are not allowed");
    }


  }
  isAllowed(habl: string) {

    let habilitations = []
    let isAllow = false
    if (this.userData.secondaryAccount) {
      if (this.userData.habilitations != undefined) {
        habilitations = this.userData.habilitations.split(',')
        for (let hab of habilitations) {
          if (hab == habl) {
            isAllow = true
          }
        }
      }
    } else {
      isAllow = true
    }
    return isAllow
  }


  openSnackbar(message) {
    this.snackBar.open(message, 'CLOSE', {
      duration: 5000,
      horizontalPosition: 'right',
    });
  }
}
