import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Observable, Subject, throwError as observableThrowError, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { USER_SESSION_KEY } from '../Models/constants';

@Injectable({
  providedIn: 'root',
})
export class AuthserviceService {

  private userLoggedIn = new Subject<boolean>();

  constructor(private http: HttpClient) {

    this.userLoggedIn.next(false);
  }

  login(email, password, otp?): Observable<any> {
    const url = environment.loginUrl;
    //const url = "http://127.0.0.1:8000/api/login";
    const body = {
      email,
      password,
      otp
    };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, GET, PUT, DELETE, OPTIONS',
        'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
      }),

      withCredentials: true

    };
    return this.http.post(url, body).pipe(
      map((response: Response) => { 
        this.setUserLoggedIn(true);
        return response;
      }),
      catchError((error: any) => {
        console.error('Error', error);
        if (error.status === 401) {
          return [{ status: false, message: error.error }];
        }
        return error;
      })
    );
  }

  refreshToken(): Observable<any> {
    const url = environment.refreshUrl;
    const sessionData = localStorage.getItem(USER_SESSION_KEY);
    let userData = JSON.parse(sessionData); 
    const token = userData?.refresh_token 

    const body = {
      refresh_token: token,
    };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, GET, PUT, DELETE, OPTIONS',
        'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
      }),
      withCredentials: true
    }; 
    return this.http.post(url, body).pipe(
      map((response: Response) => {
        return response;
      }),
      catchError((error: any) => {
        return error;
      })


    );
  }

  


  deleteAccountRequest(email, password): Observable<any> {
    const url = environment.deleteAccountRequestUrl; 
    const body = {
      email,
      password
    };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, GET, PUT, DELETE, OPTIONS',
        'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
      }),
      withCredentials: true
    };
    return this.http.post(url, body).pipe(
      map((response: Response) => {
        return response;
      }),
      catchError((error: any) => {
        console.error('Error', error);
        if (error.status === 401) {
          return [{ status: false, message: error.error }];
        }
        return error;
      })
    );
  }
  validateDeleteAccountRequest(email, reason): Observable<any> {
    const url = environment.validateDeleteAccountRequestUrl; 
    const body = {
      email,
      reason
    };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, GET, PUT, DELETE, OPTIONS',
        'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
      }),
      withCredentials: true
    };
    return this.http.post(url, body).pipe(
      map((response: Response) => {
        return response;
      }),
      catchError((error: any) => {
        console.error('Error', error);
        if (error.status === 401) {
          return [{ status: false, message: error.error }];
        }
        return error;
      })
    );
  }
  enableTwoFactorAuth(user_id, credentials) {

    const body = {
      user_id
    }
    const url = environment.enableTwoFactorAuthUrl;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${credentials}`,
    });
    return this.http
      .post(url, body, { headers, responseType: 'json' })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          console.error('Error', error.message);
          return observableThrowError(error);
        })
      );

  }
  disableTwoFactorAuth(user_id, credentials) {

    const body = {
      user_id
    }
    const url = environment.disableTwoFactorAuthUrl;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${credentials}`,
    });
    return this.http
      .post(url, body, { headers, responseType: 'json' })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          console.error('Error', error.message);
          return observableThrowError(error);
        })
      );

  }

  sendCodeForTwoFactorAuth(email): Observable<any> {

    const url = environment.sendCodeForTwoFactorAuthUrl;
    const body = {
      email
    };

    return this.http.post(url, body).pipe(
      map((response: Response) => {
        return response;
      }),
      catchError((error: any) => {
        console.error('Error', error);
        if (error.status === 401) {
          return [{ status: false, message: error.error }];
        }
        return error;
      })
    );


  }
  verifyCodeForTwoFactorAuth(email, otp): Observable<any> {

    const url = environment.verifyCodeForTwoFactorAuthUrl;
    const body = {
      email,
      otp,
    };

    return this.http.post(url, body).pipe(
      map((response: Response) => {
        return response;
      }),
      catchError((error: any) => {
        console.error('Error', error);
        if (error.status === 401) {
          return [{ status: false, message: error.error }];
        }
        return error;
      })
    );


  }

  register(userData): Observable<any> {
    const url = environment.registerUserUrl;
    return this.http
      .post(url, userData, { responseType: 'json' })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          console.error('Error', error.message);
          return observableThrowError(error);
        })
      );
  }

  registerPalUser(userData): Observable<any> {
    const url = environment.registerUserUrl;
    return this.http
      .post(url, userData, { responseType: 'json' })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          //console.log('Error', error.message);
          return observableThrowError(error);
        })
      );
  }

  uploadPhoto(file: File, uid: string) {
    const url = `https://api.pals.africa/api/uploadpp`;
    const formData: FormData = new FormData();
    formData.append('photo', file);
    formData.append('uid', uid);

    return this.http.post(url, formData, { responseType: 'json' }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        //console.log('Error', error.message);
        return observableThrowError(error);
      })
    );
  }

  mapUploadedFiles(user_id, path) {
    const url = `https://api.pals.africa/api/matchuserupload`;
    let params = new HttpParams();
    params = params.append('path', path);
    params = params.append('transaction_id', user_id);

    return this.http
      .post(url, null, { responseType: 'json', params: params })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          //console.log('Error', error.message);
          return observableThrowError(error);
        })
      );
  }

  getUserDetails(phoneNumber): Observable<any> {
    const url = 'https://api.pals.africa/api/getuserbyphone';
    let params = new HttpParams();
    params = params.append('user_phone', phoneNumber);

    return this.http
      .post(url, null, { responseType: 'json', params: params })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          //console.log('Error', error.message);
          return observableThrowError(error);
        })
      );
  }

  getUserDetailsById(uid): Observable<any> {
    const url = 'https://api.pals.africa/api/getuserbyid';
    let params = new HttpParams();
    params = params.append('uid', uid);

    return this.http
      .post(url, null, { responseType: 'json', params: params })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          //console.log('Error', error.message);
          return observableThrowError(error);
        })
      );
  }

  aupDateUserAuth(userData) {
    const url = 'https://api.pals.africa/api/register';
    let params = new HttpParams();
    params = params.append('user_uid', userData.user_uid);
    params = params.append('email', userData.email);

    return this.http
      .post(url, null, { responseType: 'json', params: params })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          //console.log('Error', error.message);
          return observableThrowError(error);
        })
      );
  }

  updateEmail(userData) {
    const url = `https://api.pals.africa/api/updateemail`;
    let params = new HttpParams();
    params = params.append('id', userData.id);
    params = params.append('email', userData.email);

    return this.http.post(url, null, { responseType: 'json', params }).pipe(
      map((response) => {
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        //console.log('error', error.message);
        return observableThrowError(error);
      })
    );
  }

  updatePassword(userData) {
    const url = environment.updatePasswordUrl;
    // const url = `http://127.0.0.1:8000/api/forgotpassupdate`;
    let params = new HttpParams();
    params = params.append('user_id', userData.user_id);
    params = params.append('password', userData.password);

    return this.http.post(url, null, { responseType: 'json', params }).pipe(
      map((response) => {
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        //console.log('error', error.message);
        return observableThrowError(error);
      })
    );
  }

  updatePwd(userData, token) {
    const url = environment.updatePswd;
    let params = new HttpParams();
    params = params.append('old_password', userData.oldPassword);
    params = params.append('new_password', userData.newPassword);
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    });
    return this.http.post(url, null, { responseType: 'json', params, headers }).pipe(
      map((response) => {
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        //console.log('error', error.message);
        return observableThrowError(error);
      })
    );
  }

  resetPwd(userData) {
    const url = environment.resetPswd;
    let params = new HttpParams();
    params = params.append('new_password', userData.new_password);
    params = params.append('otp', userData.otp);
    params = params.append('user_id', userData.user_id);

    return this.http.post(url, null, { responseType: 'json', params }).pipe(
      map((response) => {
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        //console.log('error', error.message);
        return observableThrowError(error);
      })
    );
  }

  verifyUserPassword(userData) {
    const url = `https://api.pals.africa/api/verifyuserpassword`;
    return this.http.post(url, userData, { responseType: 'json' }).pipe(
      map((response) => {
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        //console.log('error', error.message);
        return observableThrowError(error);
      })
    );
  }

  verifyEmail(otp: string, userID: string) {
    const url = environment.verifyEmailUrl;;
    // const url = `http://127.0.0.1:8000/api/verifyemail`;
    let params = new HttpParams();
    params = params.append('user_id', userID);
    params = params.append('otp', otp);

    return this.http.post(url, null, { responseType: 'json', params }).pipe(
      map((response) => {
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        //console.log('error', error.message);
        return observableThrowError(error);
      })
    );
  }

  sendEmailVerificationCode(userData) {
    const url = environment.sendOTPtoEmailForRegisterUrl;
    let params = new HttpParams();
    params = params.append('user_id', userData.user_id);
    params = params.append('email', userData.email);

    return this.http.post(url, null, { responseType: 'json', params }).pipe(
      map((response) => {
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        //console.log('error', error.message);
        return observableThrowError(error);
      })
    );
  }

  sendOTPToEmail(data): Observable<any> {
    const url = environment.sendOTPtoEmailUrl;
    // const url = `http://127.0.0.1:8000/api/initresetpwd`
    return this.http.post(url, data).pipe(
      map(response => {
        return response;
      })
    )
  }

  sendSMSVerificationCode(userData) {
    const url = `https://api.pals.africa/api/sendotptomobile`;

    return this.http.post(url, userData, { responseType: 'json' }).pipe(
      map((response) => {
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        ////console.log('error', error.message);
        return observableThrowError(error);
      })
    );
  }

  verifyUserByOTP(userData) {
    const url = `https://api.pals.africa/api/verifyotp`;

    return this.http.post(url, userData, { responseType: 'json' }).pipe(
      map((response) => {
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        ////console.log('error', error.message);
        return observableThrowError(error);
      })
    );
  }


  setUserLoggedIn(userLoggedIn: boolean) {
    this.userLoggedIn.next(userLoggedIn);
  }

  getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }

}
