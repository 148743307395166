import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import icPhone from "@iconify/icons-ic/twotone-phone";
import icMail from "@iconify/icons-ic/twotone-mail";
import icMap from "@iconify/icons-ic/twotone-map";
import icEdit from "@iconify/icons-ic/twotone-edit";
import icDelete from "@iconify/icons-ic/twotone-delete";
import icCloudDownload from '@iconify/icons-ic/twotone-cloud-download';
import icSearch from "@iconify/icons-ic/twotone-search";
import icAdd from "@iconify/icons-ic/twotone-add";
import icFilterList from "@iconify/icons-ic/twotone-filter-list";
import icMoreHoriz from "@iconify/icons-ic/twotone-more-horiz";
import icFolder from "@iconify/icons-ic/twotone-folder";
import icRefresh from "@iconify/icons-ic/twotone-refresh";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { Subject } from "rxjs";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import {
  BUSINESS_DATA_KEY,
  TRANSACTION_TABLE_LABELS,
  USER_SESSION_KEY,
} from "src/app/Models/constants";
import { ActivatedRoute, Router } from "@angular/router";
import { TransactionsService } from "src/app/services/transactions.service";
import { takeUntil } from "rxjs/operators";
import { BusinessService } from "src/app/services/business.service";
import moment from "moment";
import { MatTableExporterDirective } from "mat-table-exporter";
const ELEMENT_DATA: PeriodicElement[] = [
  {
    position: "BG452515",
    name: "10 min ago",
    weight: "Togo",
    symbol: "MTN",
    walletNumber: 96060855,
    walFee: 5.0,
    amountReceived: 400,
    status: "Sent",
  },
  {
    position: "BG452515",
    name: "1 hours ago",
    weight: "Togo",
    symbol: "MTN",
    walletNumber: 96060855,
    walFee: 5.0,
    amountReceived: 400,
    status: "Sent",
  },
  {
    position: "BG452515",
    name: "1 days ago",
    weight: "Togo",
    symbol: "MTN",
    walletNumber: 96060855,
    walFee: 5.0,
    amountReceived: 400,
    status: "Sent",
  },
];
export interface PeriodicElement {
  name: string;
  position: string;
  weight: string;
  symbol: string;
  walletNumber: number;
  walFee: number;
  amountReceived: number;
  status: string;
}
@Component({
  selector: "vex-top-up-transaction",
  templateUrl: "./top-up-transaction.component.html",
  styleUrls: ["./top-up-transaction.component.scss"],
})
export class TopUpTransactionComponent implements OnInit, AfterViewInit {

  menu: { current: { name: string, value: string }, options: { name: string, value: string }[] } = {
    current: { name: 'Top up', value: "topup" },
    options: [
      { name: 'Top up', value: "topup" }
    ]
  }

  displayedColumns: string[] = [
    // "id",
    "created_at",
    "currency",
    "amount",
    "status",
  ];
  dataSource = new MatTableDataSource();
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  layoutCtrl = new FormControl("boxed");
  searchCtrl = new FormControl();

  icPhone = icPhone;
  icMail = icMail;
  icMap = icMap;
  icEdit = icEdit;
  icSearch = icSearch;
  icDelete = icDelete;
  icAdd = icAdd;
  icFilterList = icFilterList;
  icMoreHoriz = icMoreHoriz;
  icFolder = icFolder;
  icRefresh = icRefresh;
  icCloudDownload = icCloudDownload;

  statusLabels = TRANSACTION_TABLE_LABELS;
  userData: any;
  unsubscribe$ = new Subject();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild(MatTableExporterDirective)
  matTableExporter: MatTableExporterDirective;

  transactionsData: any;
  transactionType: any;
  hasNoTransactions: boolean;
  userBusinessData: any;
  credentials: string;
  isLoading: boolean;

  defaultFilterCurrency = ""
  constructor(
    private router: Router,
    private transactionsService: TransactionsService,
    private businessService: BusinessService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
  ) {

    this.route.paramMap.subscribe((params) => {
      const currency = params.get('currency');
      if (currency) {
        this.defaultFilterCurrency = currency
      }
    });


    const sessionData = JSON.parse(localStorage.getItem(USER_SESSION_KEY));
    this.userData = sessionData;
    this.credentials = `${this.userData?.api_secret_key_live}:${this.userData?.api_public_key_live}`;

    const businessData = localStorage.getItem(BUSINESS_DATA_KEY);
    if (businessData !== "undefined") {
      this.userBusinessData = JSON.parse(businessData);
    }

    if (!sessionData) {
      router.navigate(["/auth/login"]);
    }
    //this.credentials = `${this.userBusinessData?.api_secret_key_live}:${this.userBusinessData?.api_public_key_live}`;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  filterForm: FormGroup;
  ngOnInit(): void {
    this.loadTransactions(this.userData.user_id);

    this.filterForm = this.fb.group({
      start_date: ['', Validators.required],
      end_date: ['', Validators.required],
    });

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  refreshData() {
    this.loadTransactions(this.userData.user_id);
  }

  getStatusLabel(status: string) {
    return this.statusLabels.find((label) => label.text === status);
  }

  loadTransactions(userId: string) {
    this.isLoading = true;
    // userId = 'a9twRK1JpPPQDrB6hNvfAr2ju682' this is a test User_id
    this.dataSource = new MatTableDataSource([]);
    this.businessService
      .getUserTopUps(userId, this.credentials)// this.defaultFilterCurrency, this.filterForm.get('start_date').value,this.filterForm.get('end_date').value,
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (transactions) => {
          this.isLoading = false;
          if (transactions.data) {
            this.transactionsData = transactions.data.map((details) => {
              details.created_at = moment(details.created_at).format('Y-MM-DD HH:mm:ss');
              details.state = this.getStatusLabel(details.status);
              return details;
            });
            //FILTRER AVEC LA CURRENCY RECUPERE DEPUIS L'URL
            if (this.defaultFilterCurrency != "") {
              this.transactionsData = this.transactionsData.filter(item => item.currency == this.defaultFilterCurrency);
            }


            this.hasNoTransactions =
              transactions.data.length === 0 ? true : false;
            this.dataSource = new MatTableDataSource(this.transactionsData);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          } else {
            this.hasNoTransactions = true;
            const data = [];
            this.dataSource = new MatTableDataSource(data);
          }
        },
        (error) => {
          this.isLoading = false;
          //console.log(error.message);
        }
      );
  }
  selectOption(current: { name: string, value: string }) {
    this.menu.current = current;
  }

  exportAsXlsx() {
    if (this.isAllowed("7")) {
      this.matTableExporter.exportTable('xlsx', {
        fileName: 'Top ups',
        sheet: 'report',
        Props: { Author: 'PAL Africa' },
      });
    } else {
      //this.openSnackbar("You are not allowed");
    }

  }

  isAllowed(habl: string) {

    let habilitations = []
    let isAllow = false
    if (this.userData.secondaryAccount) {
      if (this.userData.habilitations != undefined) {
        habilitations = this.userData.habilitations.split(',')
        for (let hab of habilitations) {
          if (hab == habl) {
            isAllow = true
          }
        }
      }
    } else {
      isAllow = true
    }
    return isAllow
  }

  filter() {
    
    let filterTransaction = []
    for (let transaction of this.transactionsData) {
      let date_start = new Date(this.filterForm.get('start_date').value);
      let end_date = new Date(this.filterForm.get('end_date').value);
      const transactionDate = new Date(transaction.created_at);

      // Vérifier si la date de la transaction est comprise entre date_start et end_date
      if (transactionDate >= date_start && transactionDate <= end_date) {
        filterTransaction.push(transaction);
      }
    }


    this.hasNoTransactions = filterTransaction.length === 0 ? true : false;
    this.dataSource = new MatTableDataSource(filterTransaction);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.filterForm = this.fb.group({
      start_date: ['', Validators.required],
      end_date: ['', Validators.required],
    });




  }







}
