import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import icClose from '@iconify/icons-ic/twotone-close';
import icVisibility from '@iconify/icons-ic/twotone-visibility';
import icVisibilityOff from '@iconify/icons-ic/twotone-visibility-off';

@Component({
  selector: 'vex-authorization-confimation',
  templateUrl: './authorization-confimation.component.html',
  styleUrls: ['./authorization-confimation.component.scss'],
  providers: [
    {
        provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
        useValue: {
            appearance: 'outline',
        } as MatFormFieldDefaultOptions,
    },
  ],
})
export class AuthorizationConfimationComponent implements OnInit {

  icVisibility = icVisibility;
  icVisibilityOff = icVisibilityOff;

  icClose = icClose;
  inputType = 'password';
  visible = false;

  form: FormGroup;
  password=null;
  constructor(private cd: ChangeDetectorRef,private formBuilder: FormBuilder,private dialogRef: MatDialogRef<AuthorizationConfimationComponent>) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      confirmPassword: ['', [Validators.required]],
  });
  }

  toggleVisibility() {
    if (this.visible) {
        this.inputType = 'password';
        this.visible = false;
        this.cd.markForCheck();
    } else {
        this.inputType = 'text';
        this.visible = true;
        this.cd.markForCheck();
    }
}

  get f() {
    return this.form.controls;
  }

  accepteAuthorisation(status="ACCEPTED"){
    this.dialogRef.close({status:status});
  }


}
