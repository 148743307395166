<div mat-dialog-title class="">
  <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
    <h2 class="m-0 headline" fxFlex="auto">{{"Add Beneficiary"| translate}} </h2>
    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon class="icon" [icIcon]="icClose"></mat-icon>
    </button>
  </div>
</div>

<mat-divider class="-mx-6 text-border"></mat-divider>
<div mat-dialog-content class="">
  <form [formGroup]="form" *ngIf="availableCountries.length" >
    <div class="input-bloc w-full" >
      <div class="input-row">
        <div class="customInput">
          <div class="customInput customSelect">
            <div class="flex input-label">
              <span class="">{{"Select the recipient's country" | translate}} </span>
            </div>
            <mat-form-field class="sm:ml-6 flex-auto w-full" appearance="outline">
              <mat-select placeholder="Select a Country" formControlName="beneficiary_country">
                <mat-option *ngFor="let country of availableCountries" [value]="country.code">
                   
                  <div style="display: flex; align-items: center;"> 
                    <img src="assets/contryFlg/flags/1/{{country.code | lowercase}}.png" style="height: 20px; width: auto; margin-right: 10px;">
                    {{ country.label }}
                  </div>

                </mat-option>
              </mat-select>
              <mat-error *ngIf="formControls?.beneficiary_country?.errors?.required">{{"This field is required"| translate}} </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div *ngIf="!isSwiftCodeNeeded && !isFetchingBanksData" class="input-row">
        <div class="customInput">
          <div class="flex input-label">
            <span class="">{{"Bank name"| translate}} </span>
          </div>
          <mat-form-field class="sm:ml-6 flex-auto w-100" appearance="outline">
            <mat-select placeholder="Select a Bank" formControlName="bank_code" class="flex-auto w-100">
              <mat-option *ngFor="let bank of banksData"  
              [value]="bank?.code ? bank?.code : bank?.id">
                {{ bank.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="formControls?.bank_name?.errors?.required">{{"This field is required"| translate}}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <mat-spinner style="font-size: small;" *ngIf="!isSwiftCodeNeeded && isFetchingBanksData" color="primary"> </mat-spinner>

      <div *ngIf="!isSwiftCodeNeeded && !isFetchingBankBranches && isBankBranchNeeded" class="input-row">
        <div class="customInput">
          <div class="flex input-label">
            <span class="">{{"Bank Branch" | translate}} </span>
          </div>
          <mat-form-field class="sm:ml-6 flex-auto w-full" appearance="outline">
            <mat-select placeholder="Select a Branch" formControlName="bank_branch">
              <mat-option *ngFor="let bank of bankBranches" [value]="bank.branch_name">
                {{ bank.branch_name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="formControls?.bank_branch?.errors?.required">{{"This field is required"| translate}}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <mat-spinner style="font-size: small;" *ngIf="!isSwiftCodeNeeded &&  isFetchingBankBranches && isBankBranchNeeded" color="primary">
      </mat-spinner>

      <div *ngIf="isSwiftCodeNeeded" class="input-row">
        <div class="customInput">
          <div class="flex input-label">
            <span class="">{{"Bank Name"| translate}} </span>
          </div>
          <mat-form-field class="sm:ml-6 flex-auto w-full" appearance="outline">
            <input matInput type="text" formControlName="bank_name" />
            <mat-error *ngIf="formControls?.bank_name?.errors?.required">{{"This field is required"| translate}}</mat-error>
          </mat-form-field>
        </div>
      </div>
      
      <div *ngIf="isSwiftCodeNeeded"  class="input-row">
        <div class="customInput">
          <div class="flex input-label">
            <span class="">{{"Swift Code"| translate}} </span>
          </div>
          <mat-form-field class="sm:ml-6 flex-auto w-full" appearance="outline">
            <input matInput type="text" formControlName="swift_code" />
            <mat-error *ngIf="formControls?.swift_code?.errors?.required">{{"This field is required"| translate}}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="input-row">
        <div class="customInput" >
          <div class="flex input-label">
            <span class="">{{"Account number"| translate}} </span>
          </div>
          <mat-form-field class="sm:ml-6 flex-auto w-full" appearance="outline">
            <!--placeholder="Ex: BJ0670150550509788803290" -->
            <input [maxlength]="maxAccountNumberLength" matInput type="text" formControlName="account_number" />
            <mat-error *ngIf="formControls?.account_number?.errors?.required">{{"This field is required"| translate}}</mat-error>
      
            <mat-error *ngIf="formControls?.account_number?.errors?.pattern">
              {{"This field must contain exactly"| translate}} {{accountNumberLength}} {{"characters"| translate}}
          </mat-error>

          </mat-form-field>
        </div>
      </div>

      <div class="input-row">
        <div class="customInput">
          <div class="flex input-label">
            <span class="">{{"Account name"| translate}} </span>
          </div>
          <mat-form-field class="sm:ml-6 flex-auto w-full" appearance="outline">
            <input matInput type="text" formControlName="beneficiary_name" />
            <mat-error *ngIf="formControls?.beneficiary_name?.errors?.required">{{"This field is required"| translate}}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <!--<div class="customInput">
        <div class="flex input-label">
          <span class="">Phone Number</span>
        </div>
        <mat-form-field class="sm:ml-6 flex-auto w-full" appearance="outline">
          <input matInput type="text" placeholder="+" formControlName="phone_no" />
          <mat-error>
            <span
              *ngFor="
                let error of form?.get('phone_no').errors | keyvalue
              "
            >
              {{ error.key=='pattern'?validationMessages.phone_no[error.key] +" correct number length":validationMessages.phone_no[error.key]}}
            </span>
          </mat-error>
        </mat-form-field>
      </div>-->

      <div class="customInput">
        <div class="flex input-label">
          <span class="">Email</span>
        </div>
        <mat-form-field class="sm:ml-6 flex-auto w-full" appearance="outline">
          <input matInput type="email" placeholder="@" formControlName="email" />
           
        </mat-form-field>
      </div>
      
      <div class="2xl:alert-error error-bloc" *ngIf="errorMessage && !form.valid">
        <span>{{ errorMessage| translate }}</span>
      </div>
    </div>

    <!-- <div class="input-bloc" *ngIf="confirmedTransfer">
      <div class="approve-row">
        <div class="custom-content">
          <div class="flex input-label w-full">
            <span class="">Recipient's country</span>
          </div>
          <div class="approve-info">
            <span>{{ form.get("beneficiary_country").value }}</span>
          </div>
        </div>
        <div class="custom-content">
          <div class="flex input-label w-full">
            <span class="">How much to disburse?</span>
          </div>
          <div class="approve-info">
            <span>{{ form.get("currency").value }}
              <span>{{ form.get("amount").value }}</span></span>
          </div>
        </div>


      </div>

      <div class="approve-row">
        <div class="custom-content">
          <div class="flex input-label w-full">
            <span class="">Bank name</span>
          </div>
          <div class="approve-info">
            <span>{{ getBankName() }}</span>
          </div>
        </div>

        <div *ngIf="!isSwiftCodeNeeded" class="custom-content">
          <div class="flex input-label w-full">
            <span class="">Bank Branch</span>
          </div>
          <div class="approve-info">
            <span>{{ form.get("bank_branch").value }}</span>
          </div>
        </div>
        <div *ngIf="isSwiftCodeNeeded" class="custom-content">
          <div class="flex input-label w-full">
            <span class="">Swift Code</span>
          </div>
          <div class="approve-info">
            <span>{{ form.get("swift_code").value }}</span>
          </div>
        </div>
      </div>
      <div class="approve-row">
        <div class="custom-content">
          <div class="flex input-label w-full">
            <span class="">Account number</span>
          </div>
          <div class="approve-info">
            <span>{{ form.get("account_number").value }}</span>
          </div>
        </div>
      </div>

      <div class="approve-row">
        <div class="custom-content">
          <div class="flex input-label w-full">
            <span class="">Account name</span>
          </div>
          <div class="approve-info">
            <span>{{ form.get("beneficiary_name").value }}</span>
          </div>
        </div>
        <div class="custom-content">
          <div class="flex input-label w-full">
            <span class="">Recipient Phone Number</span>
          </div>
          <div class="approve-info">
            <span>{{ form.get("phone_no").value }}</span>
          </div>
        </div>
      </div>

      <div class="approve-row">
        <div class="custom-content">
          <div class="flex input-label w-full">
            <span class="">Description</span>
          </div>
          <div class="approve-info">
            <span>{{ form.get("narration").value }}</span>
          </div>
        </div>
      </div>

    </div> -->
  </form>
</div>
<mat-divider class="-mx-6 text-border"></mat-divider>

<div mat-dialog-actions class="btn-bloc -mx-3">
  <!--
    <button mat-flat-button mat-dialog-close *ngIf="!confirmedTransfer" class="cancel-btn m-2" type="button" (click)="cancelAction()">
    Cancel
  </button>
-->
  
  <button  mat-flat-button [disabled]="!form.valid && saveBeneficiary" class="confirm-btn m-2" type="button" (click)="submitAction()"
    >
    {{ submitBtnText | translate}}
  </button>
</div>