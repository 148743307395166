import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  BUSINESS_DATA_KEY,
  BUSINESS_PARAMETERS_V2,
  SUMMARY_DATA_KEY,
  USER_SESSION_KEY,
} from 'src/app/Models/constants';
import { DomSanitizer } from '@angular/platform-browser';
import { NoBusinessModalComponent } from '../no-business-modal/no-business-modal.component';
import { RejectedBusinessModalComponent } from '../rejected-business-modal/rejected-business-modal.component';
import { SuspendedBusinessModalComponent } from '../suspended-business-modal/suspended-business-modal.component';
import { PendingBusinessModalComponent } from '../pending-business-modal/pending-business-modal.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'vex-make-transfer-choice',
  templateUrl: './make-transfer-choice.component.html',
  styleUrls: ['./make-transfer-choice.component.scss']
})
export class MakeTransferChoiceComponent implements OnInit {

  userData: any;
  businessData :  any;

  businessParameters : any

  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog, 
  ) { 
    const sessionData = JSON.parse(localStorage.getItem(USER_SESSION_KEY));
    this.userData = sessionData;
    //his.credentials = `${this.userData?.api_secret_key_live}:${this.userData?.api_public_key_live}`;


    let businessDataString = localStorage.getItem(BUSINESS_DATA_KEY); 
    if(businessDataString != undefined && businessDataString != 'undefined'){ 
      const businessData = JSON.parse(businessDataString);
      this.businessData = businessData; 
    }

    const businessParameters = localStorage.getItem(BUSINESS_PARAMETERS_V2); 
    if(businessParameters != undefined){
      this.businessParameters = JSON.parse(businessParameters)  
    }




    iconRegistry.addSvgIcon(
      'arrow',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/arrow.svg')
    );
  }

  ngOnInit(): void {
  }




  //
  openDisburseBankPopup() {  
    if (this.isAllowed('6') || this.isAllowed('1')) {

      if (this.userData.hasBusiness || !!this.businessData) {
        if (this.businessData?.status === 'pending') {
          this.dialog.open(PendingBusinessModalComponent);
        } else if (this.businessData?.status === 'suspended') {
          this.dialog.open(SuspendedBusinessModalComponent);
        }
        else if (this.businessData?.status === 'rejected') {
          this.dialog.open(RejectedBusinessModalComponent);
        } else {

          if(this.businessParameters.disbursment_status == 'allowed'){
            this.router.navigate(['/dashboards/make-transfer']).then();
          }else{
            this.openSnackbar("You are not allowed2")
          }
          
        }
      } else {
        this.dialog.open(NoBusinessModalComponent);
      }


      
    } else {
      this.openSnackbar("You are not allowed");
    }
  }
  

  toggleBulkDisbursePopup() {
    if (this.isAllowed('6') || this.isAllowed('1')) {

      if (this.userData.hasBusiness || !!this.businessData) {
        if (this.businessData?.status === 'pending') {
          this.dialog.open(PendingBusinessModalComponent);
        } else if (this.businessData?.status === 'suspended') {
          this.dialog.open(SuspendedBusinessModalComponent);
        }
        else if (this.businessData?.status === 'rejected') {
          this.dialog.open(RejectedBusinessModalComponent);
        } else {
          
          if(this.businessParameters.disbursment_status == 'allowed'){
            this.router.navigate(['/dashboards/bulk-disbursement']).then();
          }else{
            this.openSnackbar("You are not allowed")
          }

        }
      } else {
        this.dialog.open(NoBusinessModalComponent);
      }


     
    } else {
      this.openSnackbar("You are not allowed");
    }
  }
  openDisburseMobilePopup() { 
    if (this.isAllowed('6') || this.isAllowed('1')) {

      if (this.userData.hasBusiness || !!this.businessData) {
        if (this.businessData?.status === 'pending') {
          this.dialog.open(PendingBusinessModalComponent);
        } else if (this.businessData?.status === 'suspended') {
          this.dialog.open(SuspendedBusinessModalComponent);
        }
        else if (this.businessData?.status === 'rejected') {
          this.dialog.open(RejectedBusinessModalComponent);
        } else {
          
          if(this.businessParameters.disbursment_status == 'allowed'){
            this.router.navigate(['/dashboards/make-mobile-disbursment']).then();
          }else{
            this.openSnackbar("You are not allowed")
          }


        }
      } else {
        this.dialog.open(NoBusinessModalComponent);
      } 

      
    } else {
      this.openSnackbar("You are not allowed");
    }


  }
  isAllowed(habl: string) {

    let habilitations = []
    let isAllow = false
    if (this.userData.secondaryAccount) {
      if (this.userData.habilitations != undefined) {
        habilitations = this.userData.habilitations.split(',')
        for (let hab of habilitations) {
          if (hab == habl) {
            isAllow = true
          }
        }
      }
    } else {
      isAllow = true
    }
    return isAllow
  }
  openSnackbar(message) {
    this.snackBar.open(message, 'CLOSE', {
      duration: 5000,
      horizontalPosition: 'right',
    });
  }


}
