<!--<div style="margin-top: 100px" class="grid two-cards xs:grid-cols-1  md:grid-cols-2 gap-4">
  <div class="card1">
    <div class="p-2 max-w-md py-4 bg-white rounded-lg border border-gray-200  dark:bg-gray-800 dark:border-gray-700">
      <div class="cards">
        <div class="card-circle">
          <span>1</span>
        </div>
        <span class="text">
          {{"Download the excel file and add many recipients payment information as you wish"| translate}}
        </span>
      </div>

      <div fxLayout="row" class="flex file-input" fxLayoutAlign="start center">
        <div fxLayout="column" class="card" fxFlex="auto">
          <div>
            <label for="dropzone-file" class="flex flex-col w-40 h-10 bg-gray-50 rounded-lg -2 -dashed cursor-pointer">
              <div class="flex flex-col justify-center pt-5 pb-6">
                <mat-icon>cloud_download</mat-icon>
                <p class="mb-8 text-gray-500">
                  <a href='assets\exel-file\PAL bulk disbursement.xlsx' download>
                    {{"Download Exel filee"| translate}}
                  </a>
                </p>
              </div>
              <input type="file" class="hidden" />
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="two-cards">
    <div
      class="p-2 cards max-w-md py-18 bg-white rounded-lg border border-gray-200  dark:bg-gray-800 dark:border-gray-700">
      <div class="card-circle2">
        <span>2</span>
      </div>
      <span class="text">
        {{"Select the country for the bulk disbursement and upload the excel file filed with recipients' payment
        information."|translate}}
        <div fxLayout="row" class="" fxLayoutAlign="start center">
          <form [formGroup]="form">
            <div class="card cardss">
              <div>
                <mat-form-field class="sm:ml-6 flex-auto">
                  <mat-label>{{"Country"| translate}} </mat-label>
                  <mat-select (ngModelChange)="setCurrency($event)" placeholder="Country" formControlName="country"
                    required>
                    <mat-option [country]=""> {{"Select Country"| translate}} </mat-option>
                    <mat-option *ngFor="let country of availableCountries" id="newAttributeProduct" [value]="country">
                      {{ country }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </form>


          <div fxLayout="row" fxLayoutAlign="start center">
            <div fxLayout="column" class="card" fxFlex="auto">
              <div class="flex file-input">
                <label for="dropzone-file"
                  class="flex flex-col w-40 h-10 bg-gray-50 rounded-lg -2 -dashed cursor-pointer">
                  <div class="flex flex-col justify-center pt-5 pb-6">
                    <mat-icon>cloud_upload</mat-icon>
                    <p class="mb-8 text-gray-500">
                      <span class="font-semibold">{{"upload Exel File"| translate}} </span>
                    </p>
                  </div>
                  <input [disabled]="form.invalid" #disbursementFile (change)="addfile($event)" id="dropzone-file"
                    type="file" class="hidden" />
                </label>

              </div>

            </div>

          </div>

        </div>

      </span>
    </div>
    <mat-error class="no-country-error" *ngIf="form.invalid">
      {{"Please select a country before uploading a file or adding a disbursement"| translate}}
    </mat-error>
  </div>

</div>
-->

<mat-divider class="-mx-6 text-border toolbar-mat-divider"></mat-divider>

<br>

 
<div *ngIf="ProcessTransactions">

  <vex-page-layout>

    <div class="info" style="margin-left: 30px;">
      <div style="display: flex;">
        <div style="flex:1">
          <h2>{{ totalTransactions || 0 }}</h2>
          <span>
            {{"Total transactions"| translate}}
          </span>
        </div>
        <div style="flex:1">
          <h2>{{ totalAmount || 0 }}</h2>
          <span>
            {{"Total Amount"| translate}}
          </span>
        </div>
        <div style="flex:1">
          <h2>{{ verificationCountMessage || "N/A" }}</h2>
          <span>
            {{"Name verification"| translate}}
          </span>
        </div>
        <div style="flex:1">

        </div>
        <div style="flex:1">

        </div>
        <div style="flex:1">

        </div>
      </div>
    </div>



    <vex-page-layout-content class="-mt-6">
      <div style="margin-top: 30px" class="card overflow-auto -mt-16">
        <div class="bg-app-bar px-6 h-16 border-b sticky left-0" fxLayout="row" fxLayoutAlign="start center">
          <h2 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l" fxFlex="none" fxHide.xs>
            <span *ngIf="selection.isEmpty()">{{"Disbursements List"| translate}} </span>
            <span *ngIf="selection.hasValue()">{{ selection.selected.length }} {{"Disbursement"| translate}} <span
                *ngIf="selection.selected.length > 1">s</span>
              {{"selected" | translate}} </span>
          </h2>

          <div *ngIf="selection.hasValue()" class="mr-4 pr-4 border-r" fxFlex="none">
            <button (click)="deleteDisbursements(selection.selected)" color="primary" mat-icon-button
              matTooltip="Delete selected" type="button">
              <mat-icon [icIcon]="icDelete"></mat-icon>
            </button>
          </div>

          <span fxFlex></span>
          <form (ngSubmit)="disburse()" [formGroup]="uploadFileForm">
            <div class="grid xs:grid-rows-1 md:grid-rows-1 grid-flow-col gap-2">
              <div>
                <mat-form-field class="sm:ml-6 flex-auto">
                  <mat-label>{{"Purpose"| translate}} </mat-label>
                  <input matInput formControlName="object" #input type="text" placeholder="Purpose" maxlength="20" />
                </mat-form-field>
              </div>
              <div>
                <mat-form-field class="sm:ml-6 flex-auto">
                  <mat-label>{{"Country"| translate}} </mat-label>
                  <mat-select (ngModelChange)="setCurrency($event)" placeholder="Country" formControlName="country"
                    required>
                    <mat-option [country]=""> {{"Select Country"| translate}} </mat-option>
                    <mat-option *ngFor="let country of availableCountries" id="newAttributeProduct" [value]="country['code']">
                      {{ country["name"] }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </form>

          <button [disabled]="uploadFileForm.invalid" (click)="addDisbursement()" class="ml-4" color="primary"
            fxFlex="none" mat-mini-fab matTooltip="Add Disbursement" type="button">
            <mat-icon [icIcon]="icAdd"></mat-icon>
          </button>
        </div>

        <table @stagger [dataSource]="dataSource" class="w-full" mat-table matSort>
          <ng-container matColumnDef="checkbox">
            <th *matHeaderCellDef mat-header-cell>
              <mat-checkbox (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()" color="primary">
              </mat-checkbox>
            </th>
            <td *matCellDef="let row" class="w-4" mat-cell>
              <mat-checkbox (change)="$event ? selection.toggle(row) : null" (click)="$event.stopPropagation()"
                [checked]="selection.isSelected(row)" color="primary">
              </mat-checkbox>
            </td>
          </ng-container>

          <!-- Image Column -->
          <ng-container matColumnDef="image">
            <th *matHeaderCellDef mat-header-cell></th>
            <td *matCellDef="let row" class="w-8 min-w-8 pr-0" mat-cell>
              <img [src]="row['imageSrc']" class="avatar h-8 w-8 align-middle" />
            </td>
          </ng-container>

          <!-- Text Columns -->
          <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
            <ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property">
              <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header>
                {{ column.label | translate }}
              </th>
              <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
                {{ row[column.property] }}
              </td>
            </ng-container>
          </ng-container>

          <!-- Contact Column -->
          <ng-container matColumnDef="contact">
            <th *matHeaderCellDef mat-header-cell mat-sort-header></th>
            <td *matCellDef="let row" mat-cell>
              <div class="flex">
                <a (click)="$event.stopPropagation()"
                  class="w-8 h-8 leading-none flex items-center justify-center hover:bg-hover text-primary bg-primary-light"
                  mat-icon-button>
                  <mat-icon [icIcon]="icPhone" size="18px"></mat-icon>
                </a>

                <a (click)="$event.stopPropagation()"
                  class="w-8 h-8 leading-none flex items-center justify-center ml-1 hover:bg-hover text-teal bg-teal-light"
                  mat-icon-button>
                  <mat-icon [icIcon]="icMail" size="18px"></mat-icon>
                </a>

                <a (click)="$event.stopPropagation()"
                  class="w-8 h-8 leading-none flex items-center justify-center ml-1 hover:bg-hover text-green bg-green-light"
                  mat-icon-button>
                  <mat-icon [icIcon]="icMap" size="18px"></mat-icon>
                </a>
              </div>
            </td>
          </ng-container>

          <!-- Action Column -->
          <ng-container matColumnDef="actions">
            <th *matHeaderCellDef mat-header-cell mat-sort-header></th>
            <td *matCellDef="let row" class="w-10 text-secondary" mat-cell>
              <button (click)="$event.stopPropagation()" [matMenuTriggerData]="{ disbursement: row }"
                [matMenuTriggerFor]="actionsMenu" mat-icon-button type="button">
                <mat-icon [icIcon]="icMoreHoriz"></mat-icon>
              </button>
            </td>
          </ng-container>

          <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
          <tr (click)="updateDisbursement(row)" *matRowDef="let row; columns: visibleColumns" @fadeInUp
            class="hover:bg-hover trans-ease-out cursor-pointer" mat-row></tr>
        </table>

        <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" class="sticky left-0"></mat-paginator>
      </div>
    </vex-page-layout-content>
  </vex-page-layout>

  <mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
    <button (click)="toggleColumnVisibility(column, $event)" *ngFor="let column of columns"
      class="checkbox-item mat-menu-item">
      <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="column.visible" color="primary" ngDefaultControl>
        {{ column.label }}
      </mat-checkbox>
    </button>
  </mat-menu>

  <mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
    <ng-template let-disbursement="disbursement" matMenuContent>
      <button (click)="deleteDisbursement(disbursement)" mat-menu-item>
        <mat-icon [icIcon]="icDelete"></mat-icon>
        <span>{{"Delete"| translate}} </span>
      </button>
    </ng-template>
  </mat-menu>

  <div class="overflow-x-auto table-full relative">
    <button type="button" mat-raised-button   class="btn-primary" [disabled]="isDisbursing"  (click)="cancelBulkDisbursment()"   >
      {{"Cancel"| translate}}
    </button>
    <button type="button" mat-raised-button color="primary" class="btn-primary" (click)="disburse()"
      [disabled]="form.invalid || !disbursementData?.length || isDisbursing">
      {{"Approve payment"| translate}}
    </button>
  </div>
</div>





<div *ngIf="!ProcessTransactions">

  <div class="container">
    <div @fadeInUp class="card22 overflow-hidden">
      <mat-vertical-stepper #verticalStepper="matVerticalStepper" [linear]="true">
        <ng-template matStepperIcon="edit">
          <mat-icon [icIcon]="icDoneAll"></mat-icon>
        </ng-template>
        <ng-template matStepperIcon="done">
          <mat-icon [icIcon]="icDoneAll"></mat-icon>
        </ng-template>

        <!--isDownloadExecFile-->
        <mat-step [stepControl]="">
          <form [formGroup]="verticalAccountFormGroup">
            <ng-template matStepLabel>{{"Download the excel file" | translate}} </ng-template>

            <div class="subheading-1">{{"Download the excel file and add many recipients payment information as you wish." | translate}} </div>

            <div class="mt-4" fxLayout="column" fxLayoutGap="8px">
              <div fxLayout="row" class="flex file-input" fxLayoutAlign="start center">
                <div fxLayout="column" class="card" fxFlex="auto">
                  <div>
                    <label for="dropzone-file"
                      class="flex flex-col w-40 h-10 bg-gray-50 rounded-lg -2 -dashed cursor-pointer">
                      <div class="flex flex-col justify-center pt-5 pb-6">

                        <div class="mb-8 text-gray-500">
                          <!--<a href='assets\exel-file\PAL bulk disbursement.xlsx' download (click)="downloadExcelFile()">
                            {{"Download Exel file"| translate}}
                          </a>-->

               
                          <div style="border: solid #ccc 1px; width: 280px; height: 40px; ">
                            <a href='assets\exel-file\PAL bulk disbursement.xlsx' download (click)="downloadExcelFile()">
                              <mat-icon style="position: relative; top: 5px; left: 4px ;">cloud_download</mat-icon>
                              {{"Download Exel file"| translate}}
                            </a>
                          </div>
                       

                        <!--<button class="send-button2" style="border: solid #ccc 1px; ">
                          <a href='assets\exel-file\PAL bulk disbursement.xlsx' download (click)="downloadExcelFile()">
                            <mat-icon style="position: relative; top: 5px; left: 4px ;">cloud_download</mat-icon>
                            {{"Download Exel file"| translate}}
                          </a>
                        </button>-->


                        <br> <br>


                      </div>
                      </div>

                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="actions" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px"> 
              <button color="primary" mat-raised-button matStepperNext>
                {{"NEXT" | translate}}
              </button>
            </div>
          </form>
        </mat-step>

        <mat-step [stepControl]="form">
          <form [formGroup]="form">
            <ng-template matStepLabel>Select a Country</ng-template>

            <div class="title m-0">Select a Country</div>
            <div class="subheading-1">Select the country and upload the excel file filed with recipients' payment
              information.
            </div>

            <div class="mt-4" fxLayout="column" fxLayoutGap="8px">
              <div style="text-align: center;">

                <select name="" id="" style="border: solid 1px #ccc; height: 40px; width: 280px;"
                  (change)="setCurrency($event.target.value)" formControlName="country">
                  <option value="">{{"Select Country"| translate}}</option>
                  <option *ngFor="let country of availableCountries" id="newAttributeProduct" [value]="country['code']">{{
                    country['name'] }}</option>
                </select>
                <br> <br>
              </div>
            </div>

            <div class="actions" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
              <button color="primary" mat-button matStepperPrevious type="button">PREVIOUS</button>
              <button [disabled]="form.invalid" color="primary" mat-raised-button matStepperNext>
                NEXT
              </button>
            </div>
          </form>
        </mat-step>



        <mat-step [stepControl]="isUploadExecFile">
          <form [formGroup]="verticalConfirmFormGroup">
            <ng-template matStepLabel>Upload Excel File</ng-template>
            <div fxLayout="row" fxLayoutAlign="start center">
              <div fxLayout="column" class="card" fxFlex="auto">
                <div class="flex file-input">
                  <label for="dropzone-file"
                    class="flex flex-col w-40 h-10 bg-gray-50 rounded-lg -2 -dashed cursor-pointer">
                    <div class="flex flex-col justify-center pt-5 pb-6">

                      <span style="border: solid 1px #ccc; height: 40px; width: 300px;">
                        <mat-icon style="position: relative;top: 5px;">cloud_upload</mat-icon>
                      </span>
                      <p class="mb-8 text-gray-500">
                        <span class="font-semibold">{{"upload Exel File"| translate}} </span>
                      </p>
                    </div>
                    <input style="border: solid 1px #ccc;" [disabled]="form.invalid" #disbursementFile
                      (change)="addfile($event)" id="dropzone-file" type="file" class="hidden" />
                  </label>

                </div>

              </div>

            </div>
            <br> <br> <br>

            <div class="actions" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
              <button color="primary" mat-button matStepperPrevious type="button">PREVIOUS</button>
              <button [disabled]="!isUploadExecFile" color="primary" mat-raised-button matStepperNext>
                NEXT
              </button>
            </div>
          </form>
        </mat-step>
      </mat-vertical-stepper>
    </div>
  </div>

  <div style="text-align: center;">
    <br> <br> <br>
    If you wish to make bulk payment without usign excel file <a style="cursor: pointer; color: green;"
      (click)="processWithoutExcelFile()">click here</a>
  </div>
</div>