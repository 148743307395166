import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import icClose from '@iconify/icons-ic/twotone-close';
@Component({
  selector: 'vex-user-notification-modal-pop',
  templateUrl: './user-notification-modal-pop.component.html',
  styleUrls: ['./user-notification-modal-pop.component.scss']
})
export class UserNotificationModalPopComponent implements OnInit {

  icClose = icClose;
   titre : String = ""
   message: String = ""
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {  
    this.titre =  this.data.data['titre']
    this.message = this.data.data['message']
  }

  ngOnInit(): void {
  }

}
