import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';


import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';


import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableExporterDirective } from 'mat-table-exporter';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import moment from 'moment';

import icEdit from '@iconify/icons-ic/twotone-edit';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icSearch from '@iconify/icons-ic/twotone-search';
import icAdd from '@iconify/icons-ic/twotone-add';
import icFilterList from '@iconify/icons-ic/twotone-filter-list';
import icPhone from '@iconify/icons-ic/twotone-phone';
import icMail from '@iconify/icons-ic/twotone-mail';
import icMap from '@iconify/icons-ic/twotone-map';
import icMoreHoriz from '@iconify/icons-ic/twotone-more-horiz';
import icFolder from '@iconify/icons-ic/twotone-folder';
import icDateRange from '@iconify/icons-ic/twotone-date-range';
import icPerson from '@iconify/icons-ic/twotone-person';
import icRefresh from '@iconify/icons-ic/twotone-refresh';
import icBook from '@iconify/icons-ic/twotone-book';
import icCloudDownload from '@iconify/icons-ic/twotone-cloud-download';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';



import { MatMenu } from '@angular/material/menu';
import peopole from '@iconify/icons-ic/people-outline';
import { BeneficiariesService } from 'src/app/services/beneficiaries.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Customer } from '../../apps/aio-table/interfaces/customer.model';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { aioTableData, aioTableLabels } from 'src/static-data/aio-table-data';
import { SummaryData } from 'src/app/Models/models.interface';
import { AuthserviceService } from 'src/app/services/authservice.service';
import { TransactionsService } from 'src/app/services/transactions.service';
import { PendingBusinessModalComponent } from '../pending-business-modal/pending-business-modal.component';
import { SuspendedBusinessModalComponent } from '../suspended-business-modal/suspended-business-modal.component';
import { NoBusinessModalComponent } from '../no-business-modal/no-business-modal.component';
import { DisburseCashComponent } from '../disburse-cash/disburse-cash.component';
import { FilterFormComponent } from '../reports/all-transfers/filter-form/filter-form.component';
import { AddBeneficiaryComponent } from '../reports/all-transfers/add-beneficiary/add-beneficiary.component';
import { BUSINESS_DATA_KEY, COUNTRIES, SUMMARY_DATA_KEY, TRANSACTION_TABLE_LABELS, USER_SESSION_KEY } from 'src/app/Models/constants';
import { AddMobileBeneficiaryComponent } from '../reports/transactions-report/add-mobile-beneficiary/add-mobile-beneficiary.component';
import { TableUtil } from '../reports/transactions-report/tableUtil';
import { LOADING_BAR_CONFIG } from '@ngx-loading-bar/core';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { RejectedBusinessModalComponent } from '../rejected-business-modal/rejected-business-modal.component';


@Component({
  selector: 'vex-beneficiaries-v1',
  templateUrl: './beneficiaries-v1.component.html',
  styleUrls: ['./beneficiaries-v1.component.scss']
})
export class BeneficiariesV1Component implements OnInit, OnDestroy {
  layoutCtrl = new FormControl('boxed');
  subject$: ReplaySubject<Customer[]> = new ReplaySubject<Customer[]>(1);
  data$: Observable<Customer[]> = this.subject$.asObservable();
  unsubscribe$ = new Subject();
  @Input()

  beneficiariesDisplayedColumns: string[] = [ 
    'beneficiary',
    'operator',
    'account', 
    'country',
    'created_at',
    'options'
  ];
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  beneficiariesDataSource: MatTableDataSource<any> | null;
  selection = new SelectionModel<any>(true, []);
  searchCtrl = new FormControl();
  searchCtrlBeneficiary = new FormControl();
  exportOptions = {
    fileName: 'test',
    sheet: { reportsProps: { Author: 'PAL' } },
  };

  labels = aioTableLabels;
  form: FormGroup;

  statusLabels = TRANSACTION_TABLE_LABELS;
  countries = COUNTRIES;
  availableCountries = ['GH', 'BJ', 'CI', 'NGN'];
  networkProviders = ['mtn', 'orange'];
  currencies = ['GHS', 'XOF', 'XAF', 'NGN'];
  userData: any;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild(MatTableExporterDirective)
  matTableExporter: MatTableExporterDirective;

  @ViewChild('menuMakeTransfer', { static: true })
  menuMakeTransfer: MatMenu;

  beneficiariesData: any;
  isLoading: boolean = false;

  userSessionData: any;
  hasError = false;
  errorMessage: string;

  customers: any;
  users: any;
  products: any;
  userBusinessData: any;
  credentials: string;
/*
  icPhone = icPhone;
  icMail = icMail;
  icMap = icMap;
  icEdit = icEdit;
  
  icPerson = icPerson;
  icDelete = icDelete;
  icAdd = icAdd;
  icFilterList = icFilterList;
  icMoreHoriz = icMoreHoriz;
  icFolder = icFolder;
  icDateRange = icDateRange;
  icBook = icBook;
  icRefresh = icRefresh;
  icCloudDownload = icCloudDownload;
  icon_peapole = peopole;
  */
  icSearch = icSearch;



  numberOfBeneficiaries = 0;
  beneficiaryOperationData: {
    message: string;
    selectedBankBeneficiary: any
  } = { message: null, selectedBankBeneficiary: null };

 
  // MOBILE BENEFICIARY

  MlayoutCtrl = new FormControl('boxed');
  Msubject$: ReplaySubject<Customer[]> = new ReplaySubject<Customer[]>(1);
  Mdata$: Observable<Customer[]> = this.subject$.asObservable();
  Munsubscribe$ = new Subject();
  @Input() 
  MexportOptions = {
    fileName: 'test',
    sheet: { reportsProps: { Author: 'PAL' } },
  };
  Mlabels = aioTableLabels; 
 
  
  MbeneficiaryOperationData: {
    message: string;
    selectedMobileBeneficiary: any
  } = { message: null, selectedMobileBeneficiary: null };
   
    
  constructor( 
    private router: Router, 
    private beneficiariesService: BeneficiariesService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
    const user = localStorage.getItem('current_user');
    const sessionData = JSON.parse(localStorage.getItem(USER_SESSION_KEY));
    this.userData = sessionData;
    this.credentials = `${this.userData?.api_secret_key_live}:${this.userData?.api_public_key_live}`;

    //this.merchantSummaryData = JSON.parse(localStorage.getItem(SUMMARY_DATA_KEY));

    const businessData = localStorage.getItem(BUSINESS_DATA_KEY);
    if (businessData !== 'undefined') {
      this.userBusinessData = JSON.parse(businessData);
    }
    //this.credentials = `${this.userBusinessData?.api_secret_key_live}:${this.userBusinessData?.api_public_key_live}`;

    if (!sessionData) {
      router.navigate(['/auth/login']).then();
    } 
  } 
  getData() {
    return of(aioTableData.map((customer) => new Customer(customer)));
  } 
  exportAsXlsx() {

    if (this.isAllowed("7")) {
      this.matTableExporter.exportTable('xlsx', {
        fileName: 'Transactions Report',
        sheet: 'report',
        Props: { Author: 'PAL Africa' },
      });
    } else {
      this.openSnackbar("You are not allowed");
    }

  }
  isAllowed(habl: string) {

    let habilitations = []
    let isAllow = false
    if (this.userData.secondaryAccount) {
      if (this.userData.habilitations != undefined) {
        habilitations = this.userData.habilitations.split(',')
        for (let hab of habilitations) {
          if (hab == habl) {
            isAllow = true
          }
        }
      }
    } else {
      isAllow = true
    }
    return isAllow
  } 
  refreshData() {
    //vtxthis.getTransactionsList(); 
  } 
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit() {

    this.getBeneficiariesListV1();
    this.searchCtrlBeneficiary.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => this.onFilterChangeBeneficiary(value));
 


  }


  get isFormReady(): boolean {
    return true;
  } 
  onFilterChangeBeneficiary(value: string) {
    if (!this.beneficiariesDataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.beneficiariesDataSource.filter = value;
  } 
  getFlag(country) {
    switch (country.toLowerCase()) {

      case "benin":
        return "<img src='assets/contryFlg/flags/1/bj.png'>"
      case "ghana":
        return "<img src='assets/contryFlg/flags/1/gh.png'>"
      case "togo":
        return "<img src='assets/contryFlg/flags/1/tg.png'>"
      case "nigeria":
        return "<img src='assets/contryFlg/flags/1/ng.png'>"
      case "cote d'ivoire":
        return "<img src='assets/contryFlg/flags/1/ci.png'>"
      case "senegal":
        return "<img src='assets/contryFlg/flags/1/se.png'>"
      case "mali":
        return "<img src='assets/contryFlg/flags/1/ml.png'>"
      case "niger":
        return "<img src='assets/contryFlg/flags/1/ne.png'>"
      case "côte d'ivoire":
        return "<img src='assets/contryFlg/flags/1/ci.png'>"
      case "guinea-bissau":
        return "<img src='assets/contryFlg/flags/1/gw.png'>"
      case "burkina faso":
        return "<img src='assets/contryFlg/flags/1/bf.png'>"
      default:
        return ''

    }

  }
 
  openAddBeneficiaryPopup() {

    if (this.isAllowed('6') || this.isAllowed('1')) {


      if (this.userData.hasBusiness || !!this.userBusinessData) {
        if (this.userBusinessData?.status === 'pending') {
          this.dialog.open(PendingBusinessModalComponent);
        } else if (this.userBusinessData?.status === 'suspended') {
          this.dialog.open(SuspendedBusinessModalComponent);
        }
        else if (this.userBusinessData?.status === 'rejected') {
          this.dialog.open(RejectedBusinessModalComponent);
        } else {
          this.router.navigate(['/dashboards/add-beneficiary']).then();



          //dashboards/add-beneficiary
          /*
          const config: MatDialogConfig = {
            width: '600px',
          };
          const dialogRef = this.dialog.open(AddBeneficiaryComponent, config);
          dialogRef.afterClosed().subscribe(
            (response) => {
              if (response) {
                this.openSnackbar(response.message);
                this.getBeneficiariesListV1();
              }
            },
            (error) => {
              this.showInternalServerErrorMessage()
          });
          */






        }
      } else {
        this.dialog.open(NoBusinessModalComponent);
      }



    } else {
      this.openSnackbar("You are not allowed");
    }
  }

  openSnackbar(message) {
    this.snackBar.open(message, 'CLOSE', {
      duration: 3000,
      horizontalPosition: 'right',
    });
  }

  selectBankBeneficiary(id) {
    console.log(id)
    this.beneficiaryOperationData.selectedBankBeneficiary = id;
    console.log("Apres la selection d'un beneficiaire")
    console.log(this.beneficiaryOperationData)
  }
  
  deleteBeneficiary(beneficiary:any) { 
    console.log("donnees en train d'envoyer=====================") 
    let deleteData = {user_id: this.userData?.user_id, beneficiary_data: beneficiary }
    console.log(deleteData) 
    this.beneficiariesService.deleteBeneficiary(this.credentials, deleteData)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => {
          if(response['status'] == true){
            this.getBeneficiariesListV1();
          } 
          this.openSnackbar(response.message);  
        },
        (error) => {
          this.isLoading = false;
          //console.error(error.message);
          this.showInternalServerErrorMessage()
        }
      ); 
  }
 
  getBeneficiariesListV1(filteredData = null) {
    this.isLoading = true;
    this.beneficiariesData = []
    this.beneficiariesDataSource = new MatTableDataSource(this.beneficiariesData); 

    this.beneficiariesService.getBankBeneficiariesV1(this.userData?.user_id, this.credentials)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (beneficiaries) => { 
          if (beneficiaries['status'] == true) {
            this.beneficiariesData = beneficiaries['data'].map((details) => { 
              details.created_at = moment(details.created_at).fromNow();
              details.country = this.getCountryName(details.country); 
              return details;
            });
          } 
          this.isLoading = false;
          this.beneficiariesDataSource = new MatTableDataSource(this.beneficiariesData);
          this.beneficiariesDataSource.paginator = this.paginator;
          this.beneficiariesDataSource.sort = this.sort;
          this.numberOfBeneficiaries = this.beneficiariesDataSource.data.length; 
        },
        (error) => {
          this.isLoading = false;
          console.error(error.message);
        }
      );

  }
 
  getCountryName(countryCode: string): string {
    const countryData = this.countries.find(
      (country) => country.code === countryCode
    );
    return countryData.name;
  }
  
 
   
  
    


  deleteMobileBeneficiary(id: number) {
    this.beneficiariesService.deleteMobileBeneficiary(this.credentials, { user_id: this.userData?.user_id, id: id })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => {
          this.openSnackbar(response.message);
          this.getBeneficiariesListV1();
        },
        (error) => {
          this.isLoading = false;
          //console.error(error.message);
          this.showInternalServerErrorMessage()
        }
      );
  }

  
  


  showInternalServerErrorMessage() {
    this.errorMessage = "Error. Please retry again later."
    this.openSnackbar("Error. Please retry again later.");
  }









}
