<div class="dropdown">
  <div class="dropdown-header" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="dropdown-heading-icon" fxLayout="row" fxLayoutAlign="center center">
        <mat-icon *ngIf="!userBusinessData?.business_logo" [icIcon]="icPerson"></mat-icon>
        <img *ngIf="userBusinessData?.business_logo" class="rounded-full h-9 w-9 " [src]="userPhoto" alt="" >
      </div>
      <div class="dropdown-heading">{{ userData.first_name }} {{ userData.last_name }}</div>
    </div>
  </div>
  <div class="dropdown-content">

    <a (click)="close()" *ngFor="let  businessSettingItem of  businessSettingItems; trackBy: trackById" [routerLink]="businessSettingItem.route" class="notification"
      fxLayout="row" fxLayoutAlign="start center" matRipple>
      <mat-icon [icIcon]="businessSettingItem.icon" [ngClass]="businessSettingItem.colorClass" class="notification-icon" fxFlex="none"></mat-icon>
      <div fxFlex="auto">
        <div class="notification-label">{{ businessSettingItem.label | translate }}</div>
        <div class="notification-description">{{ businessSettingItem.description }}</div>
      </div>
      <mat-icon [icIcon]="icChevronRight" class="notification-chevron" fxFlex="none"></mat-icon>
    </a>

 
   <div class="pal-api" >
    <a     (click)="close()" *ngFor="let  palApiItem of  palApiItems; trackBy: trackById" [routerLink]="palApiItem.route" class="notification"
    fxLayout="row" fxLayoutAlign="start center" matRipple>
    <mat-icon [icIcon]="palApiItem.icon" [ngClass]="palApiItem.colorClass" class="notification-icon" fxFlex="none"></mat-icon>
    <div fxFlex="auto">
      <div class="notification-label">{{ palApiItem.label  | translate }}</div>
      <div class="notification-description">{{ palApiItem.description }}</div>
    </div>
    <mat-icon [icIcon]="icChevronRight" class="notification-chevron" fxFlex="none"></mat-icon>
  </a>
   </div>
 
  </div>
  <div class="dropdown-footer" fxLayout="row" fxLayoutAlign="space-between center">
    <a (click)="logout()" color="primary" mat-button>{{"LOGOUT" | translate}} </a>
  </div>
</div>
<mat-menu #statusMenu="matMenu" xPosition="before" yPosition="below">
</mat-menu>
<mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
</mat-menu>
