import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { USER_SESSION_KEY, BUSINESS_DATA_KEY, BUSINESS_PARAMETERS, VIRTUAL_BANK_ACCOUNT } from 'src/app/Models/constants';
import { TransactionsService } from 'src/app/services/transactions.service';
import { CustomerCreateUpdateComponent } from '../../apps/aio-table/customer-create-update/customer-create-update.component';
import icMoreVert from '@iconify/icons-ic/twotone-more-vert';
import icClose from '@iconify/icons-ic/twotone-close';
import icPrint from '@iconify/icons-ic/twotone-print';
import icDownload from '@iconify/icons-ic/twotone-cloud-download';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icPhone from '@iconify/icons-ic/twotone-phone';
import icPerson from '@iconify/icons-ic/twotone-person';
import icMyLocation from '@iconify/icons-ic/twotone-my-location';
import icLocationCity from '@iconify/icons-ic/twotone-location-city';
import icEditLocation from '@iconify/icons-ic/twotone-edit-location';
import { BusinessService } from 'src/app/services/business.service';
import outlineUpload from '@iconify/icons-ic/outline-upload';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { RechargeAccountComponent } from '../recharge-account/recharge-account.component';
import { Location } from '@angular/common';




@Component({
  selector: 'vex-recharge-account-v2',
  templateUrl: './recharge-account-v2.component.html',
  styleUrls: ['./recharge-account-v2.component.scss']
})
export class RechargeAccountV2Component implements OnInit, OnDestroy {
  

  collectionParameters: any
  userVirtualAccount: any
  selectedCollectionMethod: any
  userBusinessData :any
  credentials : string
  userData :any
  userCountry : string =''
  unsubscribe$ = new Subject();
  hasVirtualAccount : boolean = false

  



  constructor(
    private fb: FormBuilder,
    private transactionService: TransactionsService,
    private businessService: BusinessService,
    private snackBar: MatSnackBar,
    private router: Router,
    private transactionsService: TransactionsService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer, 
    private location: Location
  ) {
    const sessionData = localStorage.getItem(USER_SESSION_KEY);
    this.userData = JSON.parse(sessionData);
    this.credentials = `${this.userData?.api_secret_key_live}:${this.userData?.api_public_key_live}`;

    const businessData = localStorage.getItem(BUSINESS_DATA_KEY);
    this.userBusinessData = JSON.parse(businessData);

    this.userCountry = this.userBusinessData.created_country

    const businessParameters = localStorage.getItem(BUSINESS_PARAMETERS); 
    if(businessParameters != undefined){
      this.collectionParameters = JSON.parse(businessParameters)  
    }
    const virtualAccount  = localStorage.getItem(VIRTUAL_BANK_ACCOUNT);
    if(virtualAccount != undefined && virtualAccount != 'undefined'){
      this.hasVirtualAccount = true;
      this.userVirtualAccount = JSON.parse(virtualAccount);
    }


    //this.currency = this.setCurrencyBasedOnBusinessCreatedCountry(this.userBusinessData.created_country)

   
  


    iconRegistry.addSvgIcon(
      'docs',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/docs.svg')
    );
    iconRegistry.addSvgIcon(
      'arrow2',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/arrow2.svg')
    );
    iconRegistry.addSvgIcon(
      'close',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/close.svg')
    );
    iconRegistry.addSvgIcon(
      'copy',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/copy.svg')
    );



  } 
  ngOnInit() { 
    //this.getBusinessParameters(this.userData.user_id)
    //this.getVirtualAccount(this.userData.user_id) 
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

 
 
  getBusinessParameters(user_id: any) { 
    this.businessService.getBusinessPrameters(user_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(response => { 
        if (response && response['status'] === true) {
          this.collectionParameters = response['data']['collection'] 
        } else {
          if (response['status'] === false) {
            //this.hasError = true;
           // this.errorMessage = response['message'];
          } else {
            //this.hasError = true;
            //this.showInternalServerErrorMessage()
          }
        }
      })
  }
  getVirtualAccount(user_id: any) {

    this.businessService.getVirtualAccount(user_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(response => { 
        if (response && response['status'] === true) {
          this.userVirtualAccount = response['data'] 
          this.hasVirtualAccount = true
          
        } else {
          if (response['status'] === false) {
            this.hasVirtualAccount = false
            //this.hasError = true;
            //this.errorMessage = response['message'];
          } else {
            //this.hasError = true;
            //this.showInternalServerErrorMessage()
          }
        }
      })
  }
 
  
 

  selectCurrency() {
    this.router.navigate(['/dashboards/recharge-methods']).then();
  }
  createVirtualAccount() {
    this.router.navigate(['/dashboards/virtual-account']).then();
  }


  copyAccountNumber() {
    const accountNumber = document.getElementById('account-number')?.innerText; 
    if (accountNumber) {
      navigator.clipboard.writeText(accountNumber).then(() => { 
        this.openSnackbar('Numéro de compte copié !')
      }).catch(err => {
        console.error('Failed to copy account number: ', err);
      });
    }
  }
  openSnackbar(message) {
    this.snackBar.open(message, 'CLOSE', {
      duration: 3000,
      horizontalPosition: 'right'
    });
  }
  goBack() {
    this.location.back();  // Retourne à la page précédente
  }
 

}
