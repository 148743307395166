<!-- <hr /> -->
<vex-curency-balance></vex-curency-balance>
<vex-page-layout>
  <vex-page-layout-header
    class="pb-16"
    fxLayout="column"
    fxLayoutAlign="center start"
    
  >
    <div
      [class.container]="layoutCtrl.value === 'boxed'"
      [class.px-gutter]="layoutCtrl.value === 'fullwidth'"
      class="flex flex-col justify-between w-full sm:flex-row"
    ></div>
  </vex-page-layout-header>

  <vex-page-layout-content
  
    [class.container]="layoutCtrl.value === 'boxed'"
    [class.px-gutter]="layoutCtrl.value === 'fullwidth'"
    class="-mt-6"
  >
    <div class="-mt-16 overflow-auto card">
      <div
        class="sticky left-0 h-16 px-6 border-b top bg-app-bar"
        
        fxLayout="row"
        fxLayoutAlign="start center"
      >
        <h2
          class="my-0 title ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l"
          fxFlex="none"
          fxHide.xs
        ></h2>

        <div
          class="px-4 border rounded-full bg-card"
          fxFlex="400px"
          fxFlex.lt-md="auto"
          fxHide.xs
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <ic-icon [icIcon]="icSearch" size="20px"></ic-icon>
          <input
            matInput
            class="w-full px-4 py-3 bg-transparent border-0 outline-none"
            placeholder="Search..."
            type="search"
            (keyup)="applyFilter($event)"
            #input
          >
        </div>

        <span fxFlex></span>

        <button
          class="ml-4"
          fxFlex="none"
          fxHide.gt-xs
          mat-icon-button
          type="button"
        >
          <mat-icon [icIcon]="icSearch"></mat-icon>
        </button>

        <button
        class="ml-4"
        fxFlex="none"
        mat-icon-button
        type="button"
        (click)=refreshData()
      >
        <mat-icon [icIcon]="icRefresh"></mat-icon>
      </button>
      </div>
      <table
        *ngIf="!isLoading"
        mat-table
        [dataSource]="dataSource"
        class="w-full mat-elevation-z"
        @stagger
        matSort
      >
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>ID</th>
          <td mat-cell *matCellDef="let element">{{ element.reference }}</td>
          <!-- <td mat-cell *matCellDef="">23rt56</td> -->
        </ng-container>

        <!-- created_at Column -->
        <ng-container matColumnDef="created_at">
          <th mat-header-cell *matHeaderCellDef>Date & Time</th>
          <!-- <td [matTooltip]="element.created_at" mat-cell *matCellDef="let element">{{ element.formatedDate }}</td> -->
          <mat-cell matTooltipPosition="after" [matTooltip]="element.formatedDate" *matCellDef="let element"> {{element.formatedDate}} </mat-cell>
          <!-- <td mat-cell *matCellDef="">25 jun 2021</td> -->
        </ng-container>

        <ng-container matColumnDef="country">
          <th mat-header-cell *matHeaderCellDef>Country</th>
          <td mat-cell *matCellDef="let element">{{ element.country }}</td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="provider">
          <th mat-header-cell *matHeaderCellDef>Network Provider</th>
          <td mat-cell *matCellDef="let element">{{ element.operator }}</td>
        </ng-container>

        <ng-container matColumnDef="wallet">
          <th mat-header-cell *matHeaderCellDef>Wallet number</th>
          <td mat-cell *matCellDef="let element">{{ element.phone_no }}</td>
        </ng-container>

        <ng-container matColumnDef="fee">
          <th mat-header-cell *matHeaderCellDef>Pal Fee</th>
          <td mat-cell *matCellDef="let element">{{ element.charges }}</td>
        </ng-container>

        <!-- currency Column -->
        <ng-container matColumnDef="currency">
          <th mat-header-cell *matHeaderCellDef>Currency</th>
          <td mat-cell *matCellDef="let element">{{ element.currency }}</td>
          <!-- <td mat-cell *matCellDef="">FCFA</td> -->
        </ng-container>

        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef>Amount Sent</th>
          <td mat-cell *matCellDef="let element">{{ element.amount }}</td>
          <!-- <td mat-cell *matCellDef="">1200</td> -->
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let item">
            <div
              [ngClass]="item.state?.cssClasses"
              class="px-2 py-1 my-4 text-xs font-medium rounded"
              fxFlex="none"
            >
              <span> {{ item.state?.text }}</span>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">
            No Transaction Data Found Or Matching The filter
          </td>
        </tr>
      </table>
      <div *ngIf="isLoading" class="-mt-16 overflow-auto card">
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            background: white;
          "
        >
          <mat-spinner color="primary"> </mat-spinner>
        </div>
      </div>
      <mat-paginator
        [pageSizeOptions]="pageSizeOptions"
        [pageSize]="pageSize"
        class="sticky left-0"
      ></mat-paginator>
    </div>
  </vex-page-layout-content>
</vex-page-layout>
