
<hr style="border-color: rgba(0, 0, 0, 0.169);">


<div class="container">

    <div style="display: flex; flex-wrap: wrap; margin-left: 20px; margin-right: 20px; "> 
        <div style="flex: 1  ; margin-right: 10px;  "> </div>

        <div style="flex: 1  ; margin-right: 10px; margin-top: 50px;">
            <div class="card">


                <div style="padding: 20px;">
                    <div style="display: flex;">
                        <div style="flex: 2">
                            <strong>Add beneficiary</strong>
                        </div>
                        <div style="flex: 1;">
                            <mat-icon [svgIcon]="'close'"
                                style="position: relative; left: 80%; color: orange; height: 30px; width: 30px; cursor: pointer;"
                                class="menu-item-icon mr-1" matListIcon aria-hidden="false" (click)="goBack()" >
                            </mat-icon>
                        </div>
                    </div>
                </div>
                <hr>


                <div style="padding: 30px;"> 

                    <form  [formGroup]="form">
                        <div class="input-row">
                            <mat-radio-group  formControlName="beneficiary_type" color="primary"
                                style="width: 100%;" aria-label="Select an option">
                                <mat-radio-button checked [value]="0">{{"Mobile Beneficiary" | translate}} </mat-radio-button> &nbsp;&nbsp;&nbsp;
                                <mat-radio-button [value]="1">{{"Bank Beneficiary" | translate}} </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </form>
                    <br>

                    

                    
                     
                    <div *ngIf="mobileBeneficiaryOption">
                        <br> <br>
                        <vex-add-mobile-beneficiary-v2></vex-add-mobile-beneficiary-v2>
                    </div>
                    <div *ngIf="bankBeneficiaryOption"> 
                        <br> <br>
                        <vex-add-bank-beneficiary-v2></vex-add-bank-beneficiary-v2>
                    </div>
                    
                </div> 

            </div>
        </div>


        <div style="flex: 1  ; margin-right: 10px; ">  </div> 
    </div>
</div>
































































