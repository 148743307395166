
import { fadeInUp400ms } from '../../../../@vex/animations/fade-in-up.animation';
import { stagger40ms } from '../../../../@vex/animations/stagger.animation';
import { Component, Inject, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators, } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import icClose from '@iconify/icons-ic/twotone-close';
import { BUSINESS_DATA_KEY, SUMMARY_DATA_KEY, USER_SESSION_KEY, } from 'src/app/Models/constants';
import { TransactionsService } from 'src/app/services/transactions.service';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MerchantData, SummaryData, User, } from 'src/app/Models/models.interface';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { ConfirmTransfersComponent } from '../confirm-transfers/confirm-transfers.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatRadioChange } from '@angular/material/radio';
import { BusinessService } from 'src/app/services/business.service';
import { BeneficiariesService } from 'src/app/services/beneficiaries.service';
import { DisburseCashComponent } from '../disburse-cash/disburse-cash.component';



export class MobileTransfer {
    country: string;
    amount: number;
    mobileNumber: string;
    repeatMobileNumber: string;
    object: string;
    operator: string;
    merchant: boolean;
    networkProviders: Array<any>;
}

@Component({
    selector: 'vex-make-mobile-disbursment',
    templateUrl: './make-mobile-disbursment.component.html',
    styleUrls: ['./make-mobile-disbursment.component.scss'],
    animations: [fadeInUp400ms, stagger40ms],
    providers: [
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'outline',
            } as MatFormFieldDefaultOptions,
        },
    ],
})
export class MakeMobileDisbursmentComponent implements OnInit, OnDestroy {
    layoutCtrl = new FormControl('boxed');

    countryData = {
        BJ: {
            currency: 'XOF',
            code: '+229',
            operators: [
                { name: 'MTN', value: 'mtn' },
                { name: 'MOOV', value: 'moov' },
            ],
        },
        CI: {
            currency: 'XOF',
            code: '+225',
            operators: [
                { name: 'MTN', value: 'mtn' },
                { name: 'ORANGE', value: 'orange' },
                { name: 'MOOV', value: 'moov' },
            ],
        },
        GH: {
            currency: 'GHS',
            code: '+233',
            operators: [
                { name: 'MTN', value: 'mtn' },
                { name: 'VODAFONE', value: 'vodafone' },
                { name: 'AIRTEL-TIGO', value: 'airtel-tigo' },
            ],
        },
        TG: {
            currency: 'XOF',
            code: '+227',
            operators: [{ name: 'MOOV', value: 'moov' }],
        },
        SN: {
            currency: 'XOF',
            code: '+221',
            operators: [
                { name: 'MTN', value: 'MTN' },
                { name: 'ORANGE', value: 'orange' },
            ],
        },
        NG: {
            currency: 'NGN',
            code: '+234',
            operators: [
                { name: 'MTN', value: 'MTN' },
                { name: 'AIRTEL-TIGO', value: 'airtel-tigo' },
            ],
        },
        CM: {
            currency: 'XAF',
            code: '+237',
            operators: [
                { name: 'MTN', value: 'MTN' },
                { name: 'ORANGE', value: 'orange' },
            ],
        },
    };
    availableCountries = [
        { code: 'BJ', label: 'BENIN', type: "" },
        { code: 'CI', label: 'COTE D\'IVOIRE', type: "" },
        { code: 'TG', label: 'TOGO', type: "" },
        { code: 'SN', label: 'SENEGAL', type: "" },
        { code: 'GH', label: 'GHANA' },
        { code: 'ML', label: 'MALI', type: "" },
        { code: 'GW', label: 'GUINEE B.', type: "" },
        { code: 'NE', label: 'NIGER', type: "" },
        { code: 'NG', label: 'NIGERIA', type: "finpesa" }, // vtx 
        { code: 'CM', label: 'CAMEROUN', type: "finpesa" }, // vtx 
        { code: 'BF', label: 'BURKINA FASO', type: "" }];
    avalaibleOperators = []

    icClose = icClose;
    transferForm: FormGroup;
    currency = 'XOF';
    dailingCode: string = '+229';
    maxLength: number = 8;
    transferData: any;
    userData: User;
    module_id: any = '102';
    moduleData: Object[];
    userBusinessData: MerchantData;
    isDisbursing: boolean;
    unsubscribe$ = new Subject();
    credentials: string;
    hasError: boolean;
    errorMessage: string;
    phoneNumberValidationPattern = /^[0-9]{8,10}$/;
    hasPhoneInputError: boolean;
    checkPhoneErrorMessage: string;

    clientName: any;
    noNameErrorMessage: any;
    isFetchingName: boolean;

    validationMessages = {
        repeat_phone_no: {
            pattern: 'Only 8 to 10 digits allowed ',
            required: 'Receiver\'s Phone Field  is required.',
            min: 'Please provide a correct phone number',
        },
        phone_no: {
            pattern: 'Only 8 to 10 digits allowed ',
            required: 'Receiver\'s Phone Field  is required.',
            min: 'Please provide a correct phone number',
            invalidPrefix: 'Invalid phone number prefix, Operator not found!',
        },
        amount: {
            pattern: 'Only number allowed',
            required: 'Amount This Field  is required.',
            amountExceededLimit: 'Amount in XOF should not exceed 1 700 000',
            hasComma: 'XOF does not support decimals',
        },
    };
    merchantSummaryData: SummaryData;
    placeHolder = '96040522';
    networkProviders: any[];
    country: any;
    operatorPrefixData: any;

    confirmedTransfer: boolean = false;
    approvedTransfer: boolean = false;
    submitBtnText: string = 'Confirm Transfer';
    isMerchantNumber: boolean = false;
    mobileTransfer: MobileTransfer = new MobileTransfer();
    mobileNumber: string;

    beneficiaries: any;
    beneficiariesFormated = [];
    selectBenefeficiary: boolean = false
    afterBeneficiarySelected: boolean = false
    showAmountInput: boolean = true
    showTransferReasonInput: boolean = true
    showCancelSelectBenef: boolean = false

    constructor(
        // @Inject(MAT_DIALOG_DATA) public defaults: any,
        //private dialogRef: MatDialogRef<DisburseCashComponent>,
        private fb: FormBuilder,
        private transactionsService: TransactionsService,
        private snackBar: MatSnackBar,
        private sharedData: SharedDataService,
        private changeDetector: ChangeDetectorRef,
        private businessService: BusinessService,
        private beneficiariesService: BeneficiariesService,
        private router: Router,
    ) {
        const sessionData = localStorage.getItem(USER_SESSION_KEY);
        this.userData = JSON.parse(sessionData);
        this.credentials = `${this.userData?.api_secret_key_live}:${this.userData?.api_public_key_live}`;

        this.merchantSummaryData = JSON.parse(localStorage.getItem(SUMMARY_DATA_KEY));
        const businessData = localStorage.getItem(BUSINESS_DATA_KEY);
        this.userBusinessData = JSON.parse(businessData);

        this.country = 'BJ';
    }



    ngOnInit() {
        //this.credentials = `${this.userBusinessData.api_secret_key_live}:${this.userBusinessData.api_public_key_live}`;
        this.setOperatorDialingCodes();
        this.getBeneficiariesList()
        this.transferForm = this.fb.group({
            is_merchant_transfer: [false],
            country: ['BJ', Validators.required],
            phone_no: [
                '',
                [
                    Validators.required,
                    Validators.pattern(this.phoneNumberValidationPattern),
                    Validators.min(8),
                    // this.validatePrefix
                ],
            ],
            repeat_phone_no: [
                '',
                [
                    Validators.required,
                    Validators.pattern(this.phoneNumberValidationPattern),
                    Validators.min(8),
                ],
            ], 
            recipient_phone_no: [
                '',
                [
                    //Validators.required,
                    Validators.pattern(this.phoneNumberValidationPattern),
                    Validators.min(8),
                ],
            ],


            amount: ['', [Validators.required, Validators.pattern(/^\d+(\.\d+)?$/)]],
            operator: [''],
            object: [''],
            beneficiary: [''],
            beneficiary_id: [0]
        });

        this.transferForm.get('amount').valueChanges.subscribe((value) => {
            if (this.currency === 'XOF' && value > 1700000) {
                this.transferForm
                    .get('amount')
                    .setErrors({ amountExceededLimit: true });
            }
            if (this.currency === 'XOF' && value?.toString().includes('.')) {
                this.transferForm.get('amount').setErrors({ hasComma: true });
            }
        }); 
        this.transferForm.get('country').setValue('BJ');
        this.currency = "XOF";
        this.setAvailableOperators('BJ')

        //this.currency = this.countryData[this.defaults?.country]?.currency;

        this.getModulesData(this.credentials);
        this.transferForm.get('phone_no').valueChanges.subscribe((value) => {
            this.transferForm.updateValueAndValidity();
            const prefixesData = this.operatorPrefixData?.filter(
                (data) => data.country === this.transferForm.get('country').value
            );
            let isValidPrefix = prefixesData?.some((data) =>
                data.prefixes.includes(value.substring(0, 2))
            ); 
            if (this.transferForm.get('country').value === 'GH') {
                isValidPrefix = prefixesData.some((data) =>
                    data.prefixes.includes(value.substring(0, 3))
                );
            } 
            if (this.transferForm.get('country').value === 'CI') {
                isValidPrefix = prefixesData.some((data) =>
                    data.prefixes.includes(value.substring(0, 2))
                );
            } 
            if (isValidPrefix) {
                let prefixData = prefixesData.find((data) =>
                    data.prefixes.includes(value.substring(0, 2))
                );

                if (this.transferForm.get('country').value === 'GH') {
                    prefixData = prefixesData.find((data) =>
                        data.prefixes.includes(value.substring(0, 3))
                    );
                }

                if (this.transferForm.get('country').value === 'CI') {
                    prefixData = prefixesData.find((data) =>
                        data.prefixes.includes(value.substring(0, 2))
                    );
                }
                const operator = prefixData?.operator;
                this.transferForm.get('operator').patchValue(operator);
            } else { 
                if(this.transferForm.get('country').value != 'BJ'){
                    this.transferForm.get('phone_no').setErrors({ invalidPrefix: true });
                } 
            }
            this.transferForm.updateValueAndValidity();
        });




        this.transferForm.get('beneficiary').valueChanges.subscribe(checked => {
            if (checked) {
                this.selectBenefeficiary = true
            } else {
                this.selectBenefeficiary = false;
            }
            this.transferForm.patchValue({
                beneficiary_id: 0,
                phone_no: '',
                repeat_phone_no: '',
                amount: '',
                country: 'BJ',
                operator: '',//mtn
            });
            this.currency = 'XOF'
        });
        this.transferForm.get('beneficiary_id').valueChanges.subscribe((value) => {
            value == 0 ? this.afterBeneficiarySelected = false : this.afterBeneficiarySelected = true
            if (!this.selectBenefeficiary) {
                this.showAmountInput = true
                this.showTransferReasonInput = true
                this.showCancelSelectBenef = false
            } else if (this.afterBeneficiarySelected) {
                this.showAmountInput = true
                this.showTransferReasonInput = true
                this.showCancelSelectBenef = true
            } else {
                this.showAmountInput = false
                this.showTransferReasonInput = false
                this.showCancelSelectBenef = false
            }

            this.setBeneficiary(value)
        })



        this.transferForm.get('country').valueChanges.subscribe((value) => {
            this.setAvailableOperators(value);
        })
    }

    validatePrefix(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            const prefixesData = this.operatorPrefixData.filter(
                (data) => data.country === this.transferForm.get('country').value
            );

            prefixesData.forEach((prefixeData) => {
                if (
                    !prefixeData.prefixes
                        .split(',')
                        .includes(control.value.substring(0, 2))
                ) {
                    return { invalidPrefix: true };
                }
            });
            return null;
        };
    }



    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    getNetworkProviders(value: string) {
        return this.countryData[value].operators;
    }

    approveTransfers() {
        // this.dialog.open(ConfirmTransfersComponent);

        const fee = this.getPalFee(
            this.transferForm.value['amount'],
            this.transferForm.value['country']
        );
        const amount = parseFloat(this.transferForm.value['amount']);
        this.transferForm.get('amount').setValue(amount);

        if (!this.transferForm.value['object']) {
            this.transferForm
                .get('object')
                .setValue(this.userBusinessData?.business_legal_name);
        }

        // this.isDisbursing = true;
        //this.confirmedTransfer = false;
        this.transferData.charges = fee;
        this.sharedData.saveTransferData(this.transferData, this.credentials);
        this.createTransfer();

    }

    getClientData() {
        this.isFetchingName = true;
        this.businessService
            .getClientDetails(this.transferData, this.credentials)
            .subscribe((response) => {
                this.isFetchingName = false
                if (response && response['status'] === true) {
                    this.clientName = response['data'].full_name;
                } else {
                    this.noNameErrorMessage = 'Failed to retreive client name assotiated to this phone number';
                }
            },
                // tslint:disable-next-line:no-unused-expression
                (error) => {
                    alert('ERROR')
                    this.isFetchingName = false;
                    this.noNameErrorMessage = 'Failed to retreive client name assotiated to this phone number';
                });
    }

    openSnackbar(message) {
        this.snackBar.open(message, 'CLOSE', {
            duration: 3000,
            horizontalPosition: 'right',
        });
    }

    get maxTransactionAmount() {
        return this.currency === 'XOF' ? 560000 : 6190;
    }

    get hasExceededFeeTransfers(): boolean {
        return (
            this.merchantSummaryData?.totalTransactionsAmount >
            this.maxTransactionAmount
        );
    }

    getPalFee(amount, country: string): number {
        const charges = this.userBusinessData.charges;
        if (this.hasExceededFeeTransfers && charges === 0) {
            switch (country) {
                case 'GH':
                    return (amount * 0.5) / 100;
                case 'BJ':
                    return (amount * 1) / 100;
                default:
                    return 0;
            }
        } else if (this.hasExceededFeeTransfers && charges !== 0) {
            return (amount * charges) / 100;
        } else {
            return 0;
        }
    }

    getModulesData(credentials) {
        this.transactionsService
            .getModulesData(credentials)
            .pipe(take(1))
            .subscribe((data) => {
                this.moduleData = data;
                this.networkProviders = this.moduleData.map(
                    (data: any) => data.operator
                );
            });
    }

    getMaxLength(country) {
        if (country == 'BJ' ) {
            return 8;
        } else if (country == 'SN' || country == 'CM') {
            return 9
        } else {
            
            return 10;
        }
    }

    setCurrency(option) {
        this.country = option.value;
        this.currency = this.countryData[option.value].currency;
        this.dailingCode = this.countryData[option.value].code;
        this.maxLength = this.getMaxLength(option.value);
        this.placeHolder = option.value === 'BJ' ? '96040522' : '0544990518';
        // this.transferForm.get("phone_no")?.setValue(this.dailingCode);

        if (option.value === 'BJ') {
            this.mobileTransfer.networkProviders = this.networkProviders?.filter(
                (provider) => provider !== 'vodafone' && provider !== 'airtel-tigo'
            );
        }
        if (option.value === 'GH') {
            this.module_id = 103;
            this.transferForm.get('operator').setValue(''); // vtx mtn
        }
        if (option.value === 'CI') {
            this.transferForm.get('operator').setValue(''); // vtx mtn
        }
    }

    setBeneficiary(beneficiary_id: number) {


        if (beneficiary_id != 0) {

            this.afterBeneficiarySelected = true;
            let beneficiary = this.beneficiaries.find((value) => value.id == beneficiary_id)
            let benef_country = ''
            //beneficiary.beneficiary_country == 'Not provided' ? benef_country = '' : benef_country =beneficiary.beneficiary_country
            this.transferForm.patchValue({
                phone_no: beneficiary.phone_no,
                repeat_phone_no: beneficiary.phone_no,
                country: beneficiary.country,
                operator: beneficiary.operator,
                is_merchant_transfer: (beneficiary.is_merchant === 1) ? true : false
            });
            this.currency = this.getCurrencyByCountry(beneficiary.country)

        }
        this.afterBeneficiarySelected = false;

    }
    onSelectBeneficiary() {


        this.selectBenefeficiary = true
        this.transferForm.patchValue({
            beneficiary_id: 0,
            phone_no: '',
            repeat_phone_no: '',
            amount: '',
            country: 'BJ',
            operator: '', // vtx mtn
        });
        this.currency = 'XOF'
    }

    setAvailableOperators(country = '') {
        //this.avalaibleOperators = []
        let operators: any = []
        for (let op of this.countryData[country].operators) {
            operators.push(op.value)
        }
         
        this.avalaibleOperators = operators
    }
    /*
    close() {
        this.dialogRef.close();
    }
    */
    onCheckConfirmNumber = () => {
        if (
            this.transferForm.value['phone_no'] ===
            this.transferForm.value['repeat_phone_no']
        ) {
            this.hasPhoneInputError = false;
        } else {
            this.hasPhoneInputError = true;
            this.checkPhoneErrorMessage = 'phone numbers must be identical';
        }
    };

    get phoneNumber(): AbstractControl {
        return this.transferForm.controls['phone_no'];
    }

    get confirmPhoneNumber(): AbstractControl {
        return this.transferForm.controls['repeat_phone_no'];
    }

    public toggle(event: MatRadioChange) {
        this.isMerchantNumber = event.value;
        this.changeDetector.detectChanges();
    }

    getBeneficiariesList() {


        this.beneficiariesService.getMobileBeneficiaries(this.userData?.user_id, this.credentials)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                (beneficiaries) => {
                    this.beneficiaries = beneficiaries
                    this.beneficiariesFormated = beneficiaries
                    if (beneficiaries?.length > 0) {

                        // GET BENEFICIARIES PHONES NAMES
                        this.businessService.getBulkClients(this.credentials, beneficiaries)
                            .pipe(takeUntil(this.unsubscribe$))
                            .subscribe(
                                (response) => {

                                    if (response["status"] === true) {
                                        this.beneficiariesFormated = [] // init
                                        for (let beneficiary of beneficiaries) {
                                            let benefFormat = { id: 0, full_name: '', phone_no: '', country: '' }
                                            let clientFound = response['data'].find(value => value.phone_no == beneficiary.phone_no)
                                            clientFound?.full_name != undefined ? benefFormat.full_name = clientFound?.full_name + ' |' : benefFormat.full_name = ''
                                            //benefFormat.full_name = clientFound?.full_name 
                                            benefFormat.id = beneficiary.id
                                            benefFormat.phone_no = beneficiary.phone_no
                                            benefFormat.country = beneficiary.country

                                            this.beneficiariesFormated.push(benefFormat)
                                        }

                                    }
                                }
                            )
                    }
                },
                (error) => {
                    console.error(error.message);
                }
            );

    }

    getCurrencyByCountry(country = '') {

        if (country == 'NG') {
            return 'NGN'
        } else if (country == 'CM') {
            return 'XAF'
        } else if (country == 'GH') {
            return 'GHS'
        } else {
            return 'XOF'
        }

        /**
         * { code: 'BJ', label: 'BENIN', type: "" },
        { code: 'CI', label: 'COTE D\'IVOIRE', type: "" },
        { code: 'TG', label: 'TOGO', type: "" },
        { code: 'SN', label: 'SENEGAL', type: "" },
         { code: 'GH', label: 'GHANA' },
        { code: 'ML', label: 'MALI', type: "" },
        { code: 'GW', label: 'GUINEE B.', type: "" },
        { code: 'NE', label: 'NIGER', type: "" },
        { code: 'NG', label: 'NIGERIA', type: "finpesa" }, // vtx 
        { code: 'BF', label: 'BURKINA FASO', type: "" }];
         */
    }

    getCountryName(countryCode: string) {

        switch (countryCode) {

            case 'TG':
                return 'TOGO'
            case 'GH':
                return 'GHANA'
            case 'BJ':
                return 'BENIN'
            case 'ML':
                return 'MALI'
            case 'CI':
                return "COTE D'IVOIRE"
            case 'SN':
                return "SENEGAL"
            case 'NG':
                return "NIGERIA"
            case 'NE':
                return "NIGER"
            default:
                return ""
        }

    }
    getCountryNameByCode(countryCode: string) {

        const country = this.availableCountries.find(
            (data) => data.code === countryCode
        );
        return country?.label || 'N/A';

    }

    createTransfer() {
        if (this.isAllowed("6") || this.isAllowed("1")) {

            this.isDisbursing = true;
            this.transactionsService
                .createTransaction(this.transferData, this.credentials)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((response) => { 
                    if (response && response['status'] === true) {
                        this.openSnackbar(response['message']);
                        //this.router.navigate(['/dashboards/cash-out-transactions']).then();
                        //transactions
                        this.router.navigate(['/dashboards/transactions']).then();
                    } else {
                        
                        if (response && response['status'] === false) {
                            this.hasError = true;
                            this.isDisbursing = false;
                            this.errorMessage = response['message'];
                            this.openSnackbar(response['message']);
                        } else {
                            
                            this.showInternalServerErrorMessage()
                        }
                    }
                },
                    (error: any) => {
                        this.showInternalServerErrorMessage()
                    });
        } else {
            this.openSnackbar("You are not allowed");
        }
    }
    showInternalServerErrorMessage() {
        this.isDisbursing = false;
        this.hasError = true;
        this.errorMessage = "Error. Please retry again later."
        this.openSnackbar("Error. Please retry again later.");
    }
    isAllowed(habl: string) {

        let habilitations = []
        let isAllow = false
        if (this.userData.secondaryAccount) {
            if (this.userData.habilitations != undefined) {
                habilitations = this.userData.habilitations.split(',')
                for (let hab of habilitations) {
                    if (hab == habl) {
                        isAllow = true
                    }
                }
            }
        } else {
            isAllow = true
        }
        return isAllow
    }

    cancelConfirmTransfer() {
        this.transferForm.reset();
        //vtx this.close();
    }

    cancelApprovedTransfer() {
        this.confirmedTransfer = false;
        this.submitBtnText = 'Confirm Transfer';
    }

    setOperatorDialingCodes() {
        this.transactionsService
            .getOperatorDailingPrefixes(this.credentials)
            .pipe(take(1))
            .subscribe((response) => {
                if (response && response?.status === true) {
                    this.operatorPrefixData = response.data;
                }
            });
    }

    cancelAction() {
        this.confirmedTransfer ? this.cancelApprovedTransfer() : this.cancelConfirmTransfer();
    }
    cancelSelectBenef() {
        this.selectBenefeficiary = false
        this.transferForm.patchValue({
            beneficiary: ''
        })
    }

    submitAction() { 
        if (!this.transferForm.valid) { 
            this.errorMessage = 'Please write required fields and verify constraints';
            return;
        }
        const mobileNumber = this.transferForm.value['phone_no'];

        if (mobileNumber.length != this.getMaxLength(this.country)) {

            if (this.country == 'BJ') {
                this.errorMessage = "Please phone number length is 8 characters";
            } else {
                this.errorMessage = "Please phone number length is 10 characters";
            }
 
            return;
        } 
        this.confirmedTransfer ? this.approveTransfers() : this.confirmTransfer();

    }

    getSelectedCountryName() {
        const country = this.transferForm.value['country'];
        switch (country) {
            case 'BJ': return 'Benin';
            case 'CI': return 'Cote d\'Ivoire';
            case 'GH': return 'Ghana';
            case 'SN': return 'Senegal';
            default: return 'Benin';
        }
    }

    confirmTransfer() {
 
        this.mobileTransfer.country = this.getSelectedCountryName();
        this.mobileTransfer.merchant = this.isMerchantNumber;
        this.mobileTransfer.operator = this.transferForm.value['operator'];
        this.mobileTransfer.amount = this.transferForm.value['amount'];
        this.mobileTransfer.mobileNumber = this.transferForm.value['phone_no'];
        this.mobileTransfer.repeatMobileNumber = this.transferForm.value['repeat_phone_no'];
        this.mobileTransfer.object = this.transferForm.value['object'];

        this.confirmedTransfer = true;
        this.submitBtnText = 'Approve Transfer';

        this.transferData = {
            ...this.transferForm.value,
            object: this.transferForm.value['object'],
            currency: this.currency,
            operator: this.mobileTransfer.operator,
            module_id: this.module_id,
            user_id: this.userData.user_id,
            hasExceededFeeTransfers: this.hasExceededFeeTransfers,
            merchant: this.isMerchantNumber
        };
        this.getClientData();

    }





    get isFormReady(): boolean {
        return true;
    }

}
