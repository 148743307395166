<div class="dropdown">
  <div class="dropdown-header" fxLayout="row" fxLayoutAlign="space-between center">
    <div>
      <div class="dropdown-heading">Notifications</div>
      <div class="dropdown-subheading">{{"You have" | translate}}  {{ notifications.length }} {{"new notifications" | translate}} .</div>
    </div>

    <!--<button [matMenuTriggerFor]="settingsMenu" mat-icon-button type="button">
      <mat-icon [icIcon]="icSettings" class="notifications-header-icon"></mat-icon>
    </button>-->
  </div>

  <div class="dropdown-content">
    <a *ngFor="let notification of notifications; trackBy: trackById"
       [class.read]="notification.read"
       [routerLink]="['/dashboards/user-notification']"
       class="notification"
       fxLayout="row"
       fxLayoutAlign="start center"
       matRipple>
      <ic-icon [icon]="notification.icon"
               [ngClass]="notification.colorClass"
               class="notification-icon"
               fxFlex="none"
               size="24px"></ic-icon>
      <div fxFlex="auto">
        <div class="notification-label">{{ notification.label }}</div>
        <div class="notification-description">{{ notification.datetime | relativeDateTime }}</div>
      </div>
      <mat-icon [icIcon]="icChevronRight" class="notification-chevron" fxFlex="none"></mat-icon>
    </a>
  </div>

  <div class="dropdown-footer" fxLayout="row" fxLayoutAlign="center center">
    <button color="primary" mat-button type="button" [routerLink]="['/dashboards/user-notification']">{{"VIEW ALL" | translate}} </button>
  </div>
</div>

<!--<mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
  <button mat-menu-item>
    <mat-icon [icIcon]="icClearAll"></mat-icon>
    <span>Mark all as read</span>
  </button> 

  <button mat-menu-item>
    <mat-icon [icIcon]="icNotificationsOff"></mat-icon>
    <span>Disable Notifications</span>
  </button> 
</mat-menu>-->
