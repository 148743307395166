 
<hr style="border-color: rgba(0, 0, 0, 0.169);">


<div class="container">

    <div style="display: flex; flex-wrap: wrap; margin-left: 20px; margin-right: 20px; ">

        <div style="flex: 1  ; margin-right: 10px;  "> </div>

        <div style="flex: 1  ; margin-right: 10px; margin-top: 50px;">
            <div class="card">

                <form [formGroup]="mobileMoneytopUpForm">
  
                    <div style="padding: 20px;">
                        <div style="display: flex;">
                            <div style="flex: 2">
                                <h3><strong>Mobile Money</strong></h3>
                            </div>
                            <div style="flex: 1;">
                                <mat-icon [svgIcon]="'close'"
                                    style="position: relative; left: 80%; height: 30px; width: 30px; cursor: pointer;"
                                    class="menu-item-icon mr-1" matListIcon aria-hidden="false" (click)="goBack()">
                                </mat-icon>
                            </div>
                        </div>
                    </div>
                    <hr>

                    <div style="padding-top: 30px;padding-left: 30px;padding-right: 30px;">


                        <mat-dialog-content fxLayout="column">

                            <div>
                                <div class="label"><label for="" class="label">{{"Enter the amount."| translate}}
                                    </label>
                                </div>
                            </div>
                            <!--<mat-form-field>
                                <input matInput formControlName="amount" placeholder="" type="number">
                                <mat-error>
                                </mat-error>
                            </mat-form-field>--> 
                            <mat-form-field appearance="outline" class="custom-input">
                                <input matInput formControlName="amount" type="number" (input)="formatAmount()">  
                                <mat-error>
                                  <!-- Afficher l'erreur si nécessaire -->
                                </mat-error>
                            </mat-form-field> 

                            <span *ngFor="let network of collectionParametersDataSelected?.operators">
                                <span *ngIf="network.name == mobileMoneytopUpForm.value.operator"> 
                                    <div>
                                        <strong> Network fee: </strong> {{(mobileMoneytopUpForm.value.amount*network.charges) / 100 | number:'1.0-0' }} <br>
                                        <strong>Net payable: </strong> {{((mobileMoneytopUpForm.value.amount*network.charges) / 100) +  mobileMoneytopUpForm.value.amount | number:'1.0-0' }}
                                    </div>  
                                </span>
                            </span> 
                            <br>
 
                            <div>
                                <div>
                                    <div class="label"><label for="" class="label">{{"Mobile money number."| translate}}
                                        </label>
                                    </div>
                                </div>
                                <div class="flex">
                                    <div style="width:30%">
                                        <mat-form-field   appearance="outline" class="custom-input" >
                                            <mat-select placeholder="Select an Operator" formControlName="operator">
                                                <mat-option value=""> Select operator </mat-option>
                                                 <mat-option value="{{network.name}}" *ngFor="let network of collectionParametersDataSelected?.operators"> {{network.name}}
                                                </mat-option> 
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div style="width: 70%;">
                                        <mat-form-field class="flex-auto w-full" appearance="outline" class="custom-input" style="width: 305px;">
                                            <input matInput formControlName="phone_no" placeholder="" maxlength="8"
                                                min="8" type="text">
                                            <mat-error>
                                                <!--<span
                                                    *ngFor=" let error of mobileMoneytopUpForm.get('phone_no').errors | keyvalue ">
                                                    {{ validationMessages.phone_no[error.key] | translate }}
                                                </span>-->
                                            </mat-error>
                                        </mat-form-field>
                                    </div> 
                                </div>
                            </div> 



                        </mat-dialog-content> 
                    </div>
                    <hr> 


                    <div class="hoverable-div" style="padding: 20px;cursor: pointer;"> 
                        <button color="primary" mat-button type="button"
                            [ngClass]="(isProcessing || mobileMoneytopUpForm.invalid )? 'invalid-send-button': 'valid-send-button'"
                            [disabled]="isProcessing || mobileMoneytopUpForm.invalid "(click)="rechargeAccount()" >{{ isProcessing === true?
                            'Processing...' :'Approve' | translate}}
                        </button> 

                        <br> 
                        You will receive a prompt message on your mobile phone to validate the payment.
                    </div>


                </form>
            </div>
        </div>

        <div style="flex: 1  ; margin-right: 10px; "> </div>

    </div>
 
</div>