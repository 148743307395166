import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableExporterDirective } from 'mat-table-exporter';
import { BUSINESS_DATA_KEY, COUNTRIES, SUMMARY_DATA_KEY, TRANSACTION_TABLE_LABELS, USER_SESSION_KEY } from 'src/app/Models/constants';
import { SummaryData } from 'src/app/Models/models.interface';
import { AfterViewInit, Input, OnDestroy, } from '@angular/core';
import icEdit from '@iconify/icons-ic/twotone-edit';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icSearch from '@iconify/icons-ic/twotone-search';
import icAdd from '@iconify/icons-ic/twotone-add';
import icFilterList from '@iconify/icons-ic/twotone-filter-list';
import icPhone from '@iconify/icons-ic/twotone-phone';
import icMail from '@iconify/icons-ic/twotone-mail';
import icMap from '@iconify/icons-ic/twotone-map';
import icMoreHoriz from '@iconify/icons-ic/twotone-more-horiz';
import icFolder from '@iconify/icons-ic/twotone-folder';
import icDateRange from '@iconify/icons-ic/twotone-date-range';
import icPerson from '@iconify/icons-ic/twotone-person';
import icRefresh from '@iconify/icons-ic/twotone-refresh';
import icBook from '@iconify/icons-ic/twotone-book';
import icAttachMoney from '@iconify/icons-ic/twotone-attach-money';
import { SelectionModel } from '@angular/cdk/collections';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { Customer } from 'src/app/pages/apps/aio-table/interfaces/customer.model';
import { aioTableData, aioTableLabels } from 'src/static-data/aio-table-data';
import icCloudDownload from '@iconify/icons-ic/twotone-cloud-download';
import { AuthserviceService } from 'src/app/services/authservice.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TransactionsService } from 'src/app/services/transactions.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CollectionReportFilterFormComponent } from '../../reports/collections-report/collection-report-filter-form/collection-report-filter-form.component';
import { TableUtil } from '../../reports/transactions-report/tableUtil';
import { MatSelectChange } from '@angular/material/select';
import { takeUntil } from 'rxjs/operators';
import moment from 'moment';















@Component({
  selector: 'vex-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.scss']
})
export class CollectionsComponent implements OnInit, OnDestroy, AfterViewInit {
  layoutCtrl = new FormControl('boxed');

  /**
   * Simulating a service with HTTP that returns Observables
   * You probably want to remove this and do all requests in a service with HTTP
   */
  subject$: ReplaySubject<Customer[]> = new ReplaySubject<Customer[]>(1);
  data$: Observable<Customer[]> = this.subject$.asObservable();
  unsubscribe$ = new Subject();
  @Input()
  columns: TableColumn<Customer>[] = [
    // { label: 'Checkbox', property: 'checkbox', type: 'checkbox', visible: true },
    { label: 'ID', property: 'id', type: 'text', visible: true },
    {
      label: 'date',
      property: 'created_at',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Wallet',
      property: 'wallet',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Country',
      property: 'country',
      type: 'text',
      visible: false,
      cssClasses: ['font-medium'],
    },
    { label: 'Currency', property: 'currency', type: 'text', visible: true, cssClasses: ['text-secondary', 'font-medium'] },
    {
      label: 'Network',
      property: 'operator',
      type: 'text',
      visible: false,
      cssClasses: ['font-medium'],
    },
    // { label: "Contact", property: "phone_no", type: "button", visible: true },
    {
      label: 'Amount Collected',
      property: 'amount_collected',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    { label: 'Settled Amount', property: 'settled_amount', type: 'text', visible: true, cssClasses: ['text-secondary', 'font-medium'] },
    {
      label: 'Status',
      property: 'status',
      type: 'badge',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Filter',
      property: 'filter',
      type: 'badge',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    // { label: 'Labels', property: 'labels', type: 'button', visible: true },
    // { label: "Actions", property: "actions", type: "button", visible: true },
  ];
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  dataSource: MatTableDataSource<any> | null;
  selection = new SelectionModel<any>(true, []);
  searchCtrl = new FormControl();
  exportOptions = {
    fileName: 'test',
    sheet: { reportsProps: { Author: 'PAL' } },
  };
  labels = aioTableLabels;
  icPhone = icPhone;
  icMail = icMail;
  icMap = icMap;
  icAttachMoney = icAttachMoney;
  icEdit = icEdit;
  icSearch = icSearch;
  icPerson = icPerson;
  icDelete = icDelete;
  icAdd = icAdd;
  icFilterList = icFilterList;
  icMoreHoriz = icMoreHoriz;
  icFolder = icFolder;
  icDateRange = icDateRange;
  icBook = icBook;
  icRefresh = icRefresh;
  icCloudDownload = icCloudDownload;
  form: FormGroup;
  statusLabels = TRANSACTION_TABLE_LABELS;
  displayedColumns  = []














  statuses = [
    { name: 'Awaiting action', value: 1 }, //Pending
    { name: 'Completed', value: 3 },
    { name: 'Aborted', value: 4 },
    { name: 'Network Error', value: 6 },
    { name: 'Processing', value: 2 },
    { name: 'Aborted', value: 0 }, //Cancelled
  ];
  menu: { current: { name: string, value: string }, options: { name: string, value: string }[] } = {
    current: { name: 'PayIn', value: "payin" },
    options: [
      { name: 'Collections', value: "payin" },
      { name: 'Mobile Transfers', value: "payouts" },
      { name: 'Bank Transfers', value: "all_transfers" },
      { name: 'Swaps', value: "all_swaps" },
    ]
  };
  countries = COUNTRIES;
  availableCountries = ['GH', 'BJ', 'CI'];
  networkProviders = ['mtn', 'orange'];
  currencies = ['GHS', 'XOF', 'XAF', 'NGN'];
  userData: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTableExporterDirective)
  matTableExporter: MatTableExporterDirective;
  transactionsData: any;
  transactionType: any;
  hasNoTransactions: boolean;
  palFee = 0;
  isLoading: boolean;
  merchantSummaryData: SummaryData;
  userSessionData: any;
  hasError: boolean = false;
  errorMessage: string;
  customers: any;
  users: any;
  products: any;
  transactions: any;
  userBusinessData: any;
  credentials: string;
  countrySelected: any;
  browserLanguage: string



  constructor(
    private authService: AuthserviceService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private transactionService: TransactionsService,
    private snackBar: MatSnackBar,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private translate: TranslateService,
  ) {

    const user = localStorage.getItem('current_user');
    const sessionData = JSON.parse(localStorage.getItem(USER_SESSION_KEY));
    this.userData = sessionData;
    this.credentials = `${this.userData?.api_secret_key_live}:${this.userData?.api_public_key_live}`;
    this.merchantSummaryData = JSON.parse(localStorage.getItem(SUMMARY_DATA_KEY));
    const businessData = localStorage.getItem(BUSINESS_DATA_KEY);
    if (businessData !== 'undefined') {
      this.userBusinessData = JSON.parse(businessData);
    }
    if (!sessionData) {
      router.navigate(['/auth/login']);
    }



    // vtx TRADUCTION 
    this.browserLanguage = window.navigator.language;
    let lang = this.browserLanguage.split('-')
    if (lang[0] == 'en') {

    }
    if (lang[0] == 'fr') {
      translate.setDefaultLang(lang[0]);
      this.translate.use(lang[0]);
    }







    this.columns.forEach(
      e => {
        if (e.visible) {
          this.displayedColumns.push(e.property);
        }
      }
    )



  }

  ngOnInit(): void {

    if (this.isAllowed('6') || this.isAllowed('1')) {
      this.form = this.fb.group({
        country: [''],
        dateFrom: [''],
        dateTo: [''],
        currency: [''],
        operator: [''],
        status: [''],
      });
      this.dataSource = new MatTableDataSource();
      this.search();
      this.searchCtrl.valueChanges
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((value) => this.onFilterChange(value));
    }





  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


  search() {
    if (this.form.value['dateFrom'] && this.form.value['dateTo']) {
      const from = new Date(this.form.value['dateFrom'])
        .toISOString()
        .slice(0, 19)
        .replace('T', ' ');
      const to = new Date(this.form.value['dateTo'])
        .toISOString()
        .slice(0, 19)
        .replace('T', ' ');
      this.form.value['dateFrom'] = from;
      this.form.value['dateTo'] = to;
    }

    this.isLoading = true;
    this.transactionService
      .searchTransactions(
        this.credentials,
        this.userBusinessData?.user_id,
        this.form.value,
        'collections'
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => {
          this.isLoading = false;
          if (response['status'] === true) {


            this.transactions = response['data'];
            this.getTransactionsList(response['data']);
            this.dataSource = new MatTableDataSource(this.transactions);
            //this.dataSource.data = this.transactions;
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;

            if (!this.dataSource.data.length) {
              this.hasError = true;
              this.errorMessage =
                'No data Found for the specified search criteria. Please try with different data';
            } else {
              this.hasError = false;
              this.errorMessage = '';
            }
          } else {
            this.hasError = true;
            this.errorMessage =
              'No data Found for the specified search criteria. Please try with different data';
          }
        },
        (error) => {
          this.isLoading = false;
          this.hasError = true;
          //this.errorMessage = 'No data Found for the specified search criteria. Please try with different data';

          //this.showInternalServerErrorMessage()
        }
      );
  }
  viewOrderDetails(order: any) {
    this.router.navigate(['/dashboards/orders/order-details/' + order.id]);
  }
  getStatusLabel(status: string) {
    return this.statusLabels.find((label) => label.text === status);
  }
  deleteOrder(order: any) {
  }
  deleteCustomers(customers: Customer[]) {
    customers.forEach((c) => this.deleteOrder(c));
  }
  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.dataSource.filter = value;
  }
  toggleColumnVisibility(column, event) {

    if (!column.visible) {
      this.displayedColumns = this.displayedColumns.filter(col => column.property !== col)
    } else {
      let columns = [column.property, ...this.displayedColumns]
      this.displayedColumns = this.columns.map(
        ordoredCol => columns.find(col => col == ordoredCol.property)
      ).filter(col => col)
    }
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }
  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }
  onLabelChange(change: MatSelectChange, row: Customer) {
  }
  exportTable() {
    TableUtil.exportTableToExcel('ExampleMaterialTable');
  }
  exportNormalTable() {
    TableUtil.exportTableToExcel('ExampleNormalTable');
  }
  exportArray() {
    const onlyNameAndSymbolArr: Partial<any>[] = this.dataSource.data.map(
      (x) => ({
        name: x.name,
        status: x.status,
      })
    );
    TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, 'ExampleArray');
  }
  openFilterFormPopup() {
    const config: MatDialogConfig = {
      width: '600px'
    };
    const dialogRef = this.dialog.open(CollectionReportFilterFormComponent, config);
    dialogRef.afterClosed().subscribe(form => {
      this.form = form;
      this.search();
    })
  }
  selectOption(current: { name: string, value: string }) {
    this.menu.current = current;
  }










  refreshData() {
    this.getTransactionsList();
  }

  getvisibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }
  getData() {
    return of(aioTableData.map((customer) => new Customer(customer)));
  }
  get hasExceededFreeTransfers(): boolean {
    return this.merchantSummaryData?.totalTransactionsAmount > 560000;
  }
  exportAsXlsx() {
    if (this.isAllowed("7")) {
      this.matTableExporter.exportTable('xlsx', {
        fileName: 'Transactions Report',
        sheet: 'report',
        Props: { Author: 'PAL Africa' },
      });
    } else {
      this.openSnackbar("You are not allowed");
    }

  }
  isAllowed(habl: string) {

    let habilitations = []
    let isAllow = false
    if (this.userData.secondaryAccount) {
      if (this.userData.habilitations != undefined) {
        habilitations = this.userData.habilitations.split(',')
        for (let hab of habilitations) {
          if (hab == habl) {
            isAllow = true
          }
        }
      }
    } else {
      isAllow = true
    }
    return isAllow
  }
  openSnackbar(message) {
    this.snackBar.open(message, 'CLOSE', {
      duration: 3000,
      horizontalPosition: 'right',
    });
  }
  getPalFee(amount, country: string): number {
    if (this.hasExceededFreeTransfers) {
      switch (country) {
        case 'GH':
          return (amount * 0.5) / 100;
        case 'BJ':
          return (amount * 1) / 100;
        default:
          return 0;
      }
    } else {
      return 0;
    }
  }
  resetForm() {
    this.form = this.fb.group({
      country: [''],
      dateFrom: [''],
      dateTo: [''],
      currency: [''],
      operator: [''],
      status: [''],
    });
    this.getTransactionsList();
  }
  getTransactionsList(filteredData = null) {
    this.isLoading = true;
    if (filteredData) {
      const transactions = filteredData;
      this.isLoading = false;

      this.transactionsData = transactions?.map((details) => {
        details.state = this.getStatusLabel(details.state);
        details.formatedDate = moment(details.created_at).fromNow();
        details.country = this.getCountryName(details.country);
        return details;
      });

      this.hasNoTransactions = transactions.length === 0;
      this.dataSource = new MatTableDataSource(this.transactionsData);
      //this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

    }
    else {
      this.transactionService
        .getUserTransactions(this.userData?.user_id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (transactions) => {
            transactions = filteredData ? filteredData : transactions;
            this.isLoading = false;
            this.transactionsData = transactions?.map((details) => {
              details.state = this.getStatusLabel(details.state);
              details.formatedDate = moment(details.created_at).fromNow();
              details.country = this.getCountryName(details.country);
              return details;
            });

            this.hasNoTransactions = transactions.length === 0;
            this.dataSource = new MatTableDataSource(this.transactionsData);
            //this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          },
          (error) => {
            this.isLoading = false;
            //console.error(error.message);
            //this.showInternalServerErrorMessage()
          }
        );
    }
  }
  getisFormReady(): boolean {
    return true;
  }
  getCountryName(countryCode: string): string {
    const countryData = this.countries.find(
      (country) => country.code === countryCode
    );
    return countryData.name;
  }
  getSalesRepName(user_id) {
    const salesRep = this.users?.find((user) => user.user_id === user_id);
    if (salesRep) {
      return salesRep.full_name;
    } else {
      return 'N/A';
    }
  }
  get isFormReady(): boolean {
    return true;
  }













}
