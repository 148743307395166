import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LayoutComponent } from "./layout.component";
import { RouterModule } from "@angular/router";
import { MatSidenavModule } from "@angular/material/sidenav";
import { ProgressBarModule } from "../components/progress-bar/progress-bar.module";
import { SearchModule } from "../components/search/search.module";


//VTX TRADUCTION
import { HttpClient} from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader}  from '@ngx-translate/http-loader';
import { ToolbarNotificationsModule } from "./toolbar/toolbar-notifications/toolbar-notifications.module";
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}



@NgModule({
  declarations: [LayoutComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatSidenavModule,
    ProgressBarModule,
    SearchModule,
    RouterModule, 
  ],
  exports: [LayoutComponent],
})
export class LayoutModule {}
