

<div mat-dialog-content>
    <form [formGroup]="form"> <!-- *ngIf="availableCountries.length"-->
 
        <div>


            <div class="input-row">
                <mat-radio-group [(ngModel)]="isMerchantNumber" formControlName="is_merchant" color="primary"
                    style="width: 100%;" aria-label="Select an option">
                    <mat-radio-button checked [value]="0">{{"Mobile Wallet" | translate}} </mat-radio-button>
                    <mat-radio-button [value]="1">{{"Merchant Wallet" | translate}} </mat-radio-button>
                </mat-radio-group>
            </div>

            <div> 
                <div>
                    <label for="">{{"Select the country" | translate}} </label>
                </div>
                <mat-form-field appearance="outline" style="width: 640px;">
                    <mat-select placeholder="Select a country" (selectionChange)="setCurrency($event)"
                        formControlName="country">
                        <!-- <mat-option value="SN">SENEGAL</mat-option> -->
                        <mat-option value="BJ">
                            <div style="display: flex; align-items: center;">
                                <img src="assets/contryFlg/flags/1/bj.png"
                                    style="height: 20px; width: auto; margin-right: 10px;">
                                BENIN
                            </div>
                        </mat-option>
                        <mat-option value="GH">
                            <div style="display: flex; align-items: center;">
                                <img src="assets/contryFlg/flags/1/gh.png"
                                    style="height: 20px; width: auto; margin-right: 10px;">
                                GHANA
                            </div>
                        </mat-option>
                        <mat-option value="CI">
                            <div style="display: flex; align-items: center;">
                                <img src="assets/contryFlg/flags/1/ci.png"
                                    style="height: 20px; width: auto; margin-right: 10px;">
                                COTE D'IVOIRE
                            </div>
                        </mat-option>

                        <mat-option value="SN">
                            <div style="display: flex; align-items: center;">
                                <img src="assets/contryFlg/flags/1/sn.png"
                                    style="height: 20px; width: auto; margin-right: 10px;">
                                SENEGAL
                            </div>
                        </mat-option>



                    </mat-select>
                </mat-form-field>
            </div> 

            <div> 
                <div>
                    <label for="">{{"Select the operator" | translate}} </label>
                </div>
                <mat-form-field appearance="outline" style="width: 640px;">
                    <mat-select placeholder="Select a country" (selectionChange)="setCurrency($event)"
                        formControlName="operator">
                        <!-- <mat-option value="SN">SENEGAL</mat-option> -->
                        <mat-option value="orange">
                           ORANGE
                        </mat-option> 
                        <mat-option value="mtn">
                            MTN
                        </mat-option> 
                        <mat-option value="moov">
                           MOOV
                        </mat-option> 
                        <mat-option value="vodarfone">
                            VODARFONE
                        </mat-option> 
                        <mat-option value="tigo">
                            TIGO
                        </mat-option> 
                    </mat-select>
                </mat-form-field>
            </div>
 
            <div>
                <div>
                    <label for="">{{isMerchantNumber?"Merchant Number":"Mobile wallet number" | translate}}</label>
                    <!--<div class="text-primary">{{ this.form.get('operator').value }}</div>-->
                </div>
                <mat-form-field appearance="outline" style="width: 640px;">
                    <input (ngModelChange)="onCheckConfirmNumber()" matInput formControlName="phone_no" #input  type="tel" pattern="\d*" placeholder="{{ placeHolder }}" maxlength="{{ maxLength }}">
                    <mat-error>
                        <span *ngFor=" let error of form?.get('phone_no').errors | keyvalue ">
                            {{ validationMessages.phone_no[error.key] }}
                        </span>
                    </mat-error>
                </mat-form-field>
            </div>
             
            <!--<div>
                <div>
                    <label for="">Repeat {{isMerchantNumber?"Merchant Number":"Mobile wallet number"}}</label> 
                </div>
                <mat-form-field appearance="outline" style="width: 640px;">
                    <input (ngModelChange)="onCheckConfirmNumber()" matInput formControlName="repeat_phone_no"  #input type="tel" pattern="\d*" placeholder="{{ placeHolder }}" maxlength="{{ maxLength }}">
                    <mat-error>
                        <span *ngFor="  let error of form.get('repeat_phone_no').errors | keyvalue  ">
                            {{ validationMessages.repeat_phone_no[error.key] }}
                        </span>
                    </mat-error>
                </mat-form-field>
                <mat-error>
                    <span class="identical" *ngIf="hasPhoneInputError">
                        {{ checkPhoneErrorMessage }}
                    </span>
                </mat-error>
            </div>-->



        </div>
    </form>
</div>

<br> <br> 
<div style="cursor: pointer;">  
    <button color="primary" mat-button type="button" class="invalid-send-button" style="width: 640px;" (click)="submitAction()" [disabled]="isProcessing || form.invalid" [ngClass]="(isProcessing || form.invalid )? 'invalid-send-button': 'valid-send-button'">
        {{ isProcessing === true? 'Processing...' :'Save mobile beneficiary' | translate}}
    </button>
</div>





 