
import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import icPhone from "@iconify/icons-ic/twotone-phone";
import icMail from "@iconify/icons-ic/twotone-mail";
import icMap from "@iconify/icons-ic/twotone-map";
import icEdit from "@iconify/icons-ic/twotone-edit";
import icDelete from "@iconify/icons-ic/twotone-delete";
import icSearch from "@iconify/icons-ic/twotone-search";
import icAdd from "@iconify/icons-ic/twotone-add";
import icFilterList from "@iconify/icons-ic/twotone-filter-list";
import icMoreHoriz from "@iconify/icons-ic/twotone-more-horiz";
import icFolder from "@iconify/icons-ic/twotone-folder";
import icRefresh from "@iconify/icons-ic/twotone-refresh";
import icCloudDownload from '@iconify/icons-ic/twotone-cloud-download';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { Subject } from "rxjs";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import {
  BUSINESS_DATA_KEY,
  TRANSACTION_TABLE_LABELS,
  USER_SESSION_KEY,
} from "src/app/Models/constants";
import { ActivatedRoute, Router } from "@angular/router";
import { TransactionsService } from "src/app/services/transactions.service";
import { takeUntil } from "rxjs/operators";
import { BusinessService } from "src/app/services/business.service";
import moment from "moment";
import { MatTableExporterDirective } from "mat-table-exporter";






@Component({
  selector: 'vex-financial-statment',
  templateUrl: './financial-statment.component.html',
  styleUrls: ['./financial-statment.component.scss']
})
export class FinancialStatmentComponent implements OnInit, AfterViewInit {

  menu: { current: { name: string, value: string }, options: { name: string, value: string }[] } = {
    current: { name: 'Financial Statment', value: "topup" },
    options: [
      { name: 'Financial Statment', value: "topup" }
    ]
  }

  displayedColumns: string[] = [
    // "id",
    "reference",
    'libelle',
    'account',
    
    
    'debit',
    'credit',
    
    'end_balance',
    
    'status',
    "created_at",
    
  ];
  dataSource = new MatTableDataSource();
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  layoutCtrl = new FormControl("boxed");
  searchCtrl = new FormControl();

  icPhone = icPhone;
  icMail = icMail;
  icMap = icMap;
  icEdit = icEdit;
  icSearch = icSearch;
  icDelete = icDelete;
  icAdd = icAdd;
  icFilterList = icFilterList;
  icMoreHoriz = icMoreHoriz;
  icFolder = icFolder;
  icRefresh = icRefresh;
  icCloudDownload = icCloudDownload;

  statusLabels = TRANSACTION_TABLE_LABELS;
  userData: any;
  unsubscribe$ = new Subject();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  transactionsData: any;
  transactionType: any;
  hasNoTransactions: boolean;
  userBusinessData: any;
  credentials: string;
  isLoading: boolean;

  @ViewChild(MatTableExporterDirective)
  matTableExporter: MatTableExporterDirective;

  defaultFilterCurrency = ""
  showFinancialStatment : boolean = false
  filterForm: FormGroup;
  currentCurrencyBalance = { currency: "", balance: 0, collectionBalance: 0 }
  currencyBalance = [
    { currency: "XOF", balance: 0, collectionBalance: 0 },
    { currency: "XAF", balance: 0, collectionBalance: 0 },
    { currency: "NGN", balance: 0, collectionBalance: 0 },
    { currency: "GHS", balance: 0, collectionBalance: 0 }
  ]
  currenctBalance : string = ''


  constructor(
    private router: Router,
    private transactionsService: TransactionsService,
    private businessService: BusinessService,
    private route: ActivatedRoute,
    private fb: FormBuilder, 
  ) {

    this.route.paramMap.subscribe((params) => {
      const currency = params.get('currency');
      if (currency) {
        this.defaultFilterCurrency = currency
      }
    });


    const sessionData = JSON.parse(localStorage.getItem(USER_SESSION_KEY));
    this.userData = sessionData;
    this.credentials = `${this.userData?.api_secret_key_live}:${this.userData?.api_public_key_live}`;

    const businessData = localStorage.getItem(BUSINESS_DATA_KEY);
    if (businessData !== "undefined") {
      this.userBusinessData = JSON.parse(businessData);
    }

    if (!sessionData) {
      router.navigate(["/auth/login"]);
    }
    //this.credentials = `${this.userBusinessData?.api_secret_key_live}:${this.userBusinessData?.api_public_key_live}`;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnInit(): void {
    
    this.getUserBalances()
    this.filterForm = this.fb.group({
      start_date: ['', Validators.required],
      end_date: ['', Validators.required],
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit() {
    
  }

  refreshData() {
   
  }

  getStatusLabel(status: string) {
    return this.statusLabels.find((label) => label.text === status);
  }



  getUserBalances() {
    //this.isLoading = true;
    this.businessService
      .getUserBalances(this.userData.user_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response: any) => { 
        if (response.data.length) {
          const balanceDataMap = this.toMap(response.data);
          Object.keys(balanceDataMap).forEach((currency) => {
            const countries = balanceDataMap[currency].map(
              (data) => data.country
            );
            const filtered = balanceDataMap[currency].filter(
              ({ country }, index) => !countries.includes(country, index + 1)
            );
            const balances = filtered.map((data) => data.balance);

            const result = balances?.reduce((acc: any, cur: any) => {
              return (balanceDataMap[currency] = acc + Number(cur));
            }, 0) as number;
            return result.toFixed(2);
          });
          this.currencyBalance = balanceDataMap;  

          this.currenctBalance = this.currencyBalance[this.defaultFilterCurrency]



           
           
        }
      },
        (error) => {
          //this.dataLoaded = false;
          //this.isLoading = false; 
          //this.showInternalServerErrorMessage()
        });
  }
  
  private toMap(balanceData) {
    const result = balanceData.reduce((dataMap, item) => {
      dataMap[item.currency] = dataMap[item.currency] || [];
      dataMap[item.currency].push(item);
      return dataMap;
    }, Object.create(null));

    return result;
  }



  






  isAllowed(habl: string) {

    let habilitations = []
    let isAllow = false
    if (this.userData.secondaryAccount) {
      if (this.userData.habilitations != undefined) {
        habilitations = this.userData.habilitations.split(',')
        for (let hab of habilitations) {
          if (hab == habl) {
            isAllow = true
          }
        }
      }
    } else {
      isAllow = true
    }
    return isAllow
  }


  selectOption(current: { name: string, value: string }) {
    this.menu.current = current;
  }



  navigateToFinancialStatmentReportComponent(){
    this.router.navigate(['/dashboards/financial-statment-report',this.defaultFilterCurrency, this.filterForm.get('start_date').value,this.filterForm.get('end_date').value ],  {
      
    })
  }







}
