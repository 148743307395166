import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SharedDataService {
  allData: object;

  constructor() {}

  saveTransferData(transfersData: any, credential: string) {
    this.allData = {
      transfersData:{
        ...transfersData
      },
      credential
    };

  }

  getTransferData() {
    return this.allData;
  }
}
