<!-- (ngSubmit)="save()" -->

<div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
    <h2 class="m-0 headline" fxFlex="auto">Password</h2>
    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon [icIcon]="icClose"></mat-icon>
    </button>
  </div>
  <mat-dialog-content fxLayout="column" class="content-part">
    <div
      class="">
      <h6>Enter your PAL’s password account to authorize this action</h6>
    </div>
    <div class="input-bloc w-full" [formGroup]="form">
      <div class="customInput">
        <mat-form-field class="w-full">
            <input matInput [type]="inputType" formControlName="confirmPassword"
                placeholder="Password" [(ngModel)]="password" class="bg-white">
            <button (click)="toggleVisibility()" mat-icon-button matSuffix
                matTooltip="Toggle Visibility" type="button">
                <mat-icon *ngIf="visible" [icIcon]="icVisibility"></mat-icon>
                <mat-icon *ngIf="!visible" [icIcon]="icVisibilityOff"></mat-icon>
            </button>
            <mat-error *ngIf="f?.confirmPassword?.errors?.required">This field is required</mat-error>
        </mat-form-field>
    </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="button">
      <div class="">
        <button color="primary" (click)="accepteAuthorisation()" [disabled]="!form.valid" mat-button class="confirm-btn px-4">
          Authorize Invitation
        </button>
      </div>
    </div>
  </mat-dialog-actions>