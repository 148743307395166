import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserNotificationModalComponent } from '../pages/dashboards/user-notification-modal/user-notification-modal.component';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


import { Observable, throwError as observableThrowError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserNotificationService {

  private notifications: String[] = [];
  constructor(private dialog: MatDialog,
    private http: HttpClient) { }


  addNotification(message: string) {
    this.notifications.push( message);  // Add notification object to the array
    this.showNextNotification();
  }

  showNextNotification(): void {
    if (this.notifications.length === 0) {
      return;  // No more notifications in queue, exit
    }

    const notification = this.notifications.shift();  // Get and remove the first notification

    const dialogRef = this.dialog.open(UserNotificationModalComponent, {
      data: { message: notification["message"] }  // Pass notification data to the modal
    });

    dialogRef.afterClosed().subscribe(() => {
      this.showNextNotification();  // Show next notification after modal closes
    });
  }

  getUserNotifications(user_id: any): Observable<any>{
    //
    const url = `${environment.getNotificationsUrl}`+user_id; 
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    })    
    return this.http.get(url).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        console.error("Error", error.message);
        return observableThrowError(error);
      })
    );
  }

  markReadedNotification(user_id: any, id){

    const url = `${environment.MarkNotificationReadedUrl}`+user_id+'/'+id; 
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer`,
    })  
    let data = { 
      user_id : user_id,
      id : id
    } 
    return this.http.get(url,).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        console.error("Error", error.message);
        return observableThrowError(error);
      })
    );


  }


  




}
