<!--<div >

    <div>
        <h2>New Notification: {{titre}} </h2>
    </div>
    <br> <br>
    <span>{{message}} </span>

</div>


-->




<!-- (ngSubmit)="save()" -->

<form>
    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
        <h3 class="m-0" fxFlex="auto">
            
        <h2>New Notification: {{titre}} </h2>
        </h3> 
        <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
            <mat-icon [icIcon]="icClose"></mat-icon>
        </button>
    </div>
    <mat-divider class="-mx-6 text-border"></mat-divider>
    <mat-dialog-content fxLayout="column" class="content-part" >
         
        {{message}}

    </mat-dialog-content>
    <mat-dialog-actions align="end" class="button">
        <!--<button mat-button mat-dialog-close type="button" class="cancel-button">CANCEL</button>-->
        <button color="primary" mat-button type="button"   class="send-button">OK</button>
    </mat-dialog-actions>
</form>