import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { BUSINESS_DATA_KEY, BUSINESS_PARAMETERS, USER_SESSION_KEY } from 'src/app/Models/constants';
import { BusinessService } from 'src/app/services/business.service';
import { takeUntil, take } from 'rxjs/operators';
import { Location } from '@angular/common';
@Component({
  selector: 'vex-methods-by-currency-choosed',
  templateUrl: './methods-by-currency-choosed.component.html',
  styleUrls: ['./methods-by-currency-choosed.component.scss']
})
export class MethodsByCurrencyChoosedComponent implements OnInit {


  collectionParameters: any
  userVirtualAccount: any
  selectedCollectionMethod: any
  userBusinessData :any
  credentials : string
  userData :any
  userCountry : string =''
  unsubscribe$ = new Subject();
  hasVirtualAccount : boolean = false

  



  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private router: Router,
    private businessService: BusinessService,
    private location: Location
  ) { 

    const sessionData = localStorage.getItem(USER_SESSION_KEY);
    this.userData = JSON.parse(sessionData);
    this.credentials = `${this.userData?.api_secret_key_live}:${this.userData?.api_public_key_live}`;

    const businessData = localStorage.getItem(BUSINESS_DATA_KEY);
    this.userBusinessData = JSON.parse(businessData);

    this.userCountry = this.userBusinessData.created_country

    const businessParameters = localStorage.getItem(BUSINESS_PARAMETERS); 
    if(businessParameters != undefined){
      this.collectionParameters = JSON.parse(businessParameters)  
    }
    //this.currency = this.setCurrencyBasedOnBusinessCreatedCountry(this.userBusinessData.created_country)



    iconRegistry.addSvgIcon(
      'docs',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/docs.svg')
    );
    iconRegistry.addSvgIcon(
      'arrow2',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/arrow2.svg')
    );
    iconRegistry.addSvgIcon(
      'close',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/close.svg')
    );
    iconRegistry.addSvgIcon(
      'copy',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/copy.svg')
    );


  }

  ngOnInit() { 
    //this.getBusinessParameters(this.userData.user_id)
    //this.getVirtualAccount(this.userData.user_id) 
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

 
 
  getBusinessParameters(user_id: any) { 
    this.businessService.getBusinessPrameters(user_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(response => {
        if (response && response['status'] === true) {
          this.collectionParameters = response['data']['collection']  
        } else {
          if (response['status'] === false) {
            //this.hasError = true;
           // this.errorMessage = response['message'];
          } else {
            //this.hasError = true;
            //this.showInternalServerErrorMessage()
          }
        }
      })
  }
  getVirtualAccount(user_id: any) {

    this.businessService.getVirtualAccount(user_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(response => { 
        if (response && response['status'] === true) {
          this.userVirtualAccount = response['data'] 
          this.hasVirtualAccount = true
        } else {
          if (response['status'] === false) {
            this.hasVirtualAccount = false
            //this.hasError = true;
            //this.errorMessage = response['message'];
          } else {
            //this.hasError = true;
            //this.showInternalServerErrorMessage()
          }
        }
      })
  }

  chooseDeposit(methodChoosed : any){
    this.router.navigate(['/dashboards/recharge-methods-choosed', methodChoosed]).then(); 
  }
  chooseMobileDeposit(){
    this.router.navigate(['/dashboards/recharge-by-mobile']).then(); 
  }
  chooseMerchantDeposit(){
    this.router.navigate(['/dashboards/recharge-by-merchant']).then(); 
  }
  chooseBankDeposit(){
    this.router.navigate(['/dashboards/recharge-by-bank']).then(); 
  }


  goBack() {
    this.location.back();  // Retourne à la page précédente
  }

}
