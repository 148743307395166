import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import icClose from '@iconify/icons-ic/twotone-close';
import moment, { now } from 'moment';
import { COUNTRIES } from 'src/app/Models/constants';

@Component({
  selector: 'vex-filter-form',
  templateUrl: './filter-form.component.html',
  styleUrls: ['./filter-form.component.scss']
})
export class FilterFormComponent implements OnInit {

  icClose = icClose;


  form: FormGroup;


  statuses = [
    { name: 'Pending', value: 1 },
    { name: 'Completed', value: 3 },
    { name: 'Error', value: 4 },
    { name: 'Network Error', value: 6 },
    { name: 'Processing', value: 2 },
    { name: 'Cancelled', value: 0 },
  ];
  operators = [
    { name: 'MTN', value: 'mtn' },
    { name: 'MOOV', value: 'moov' },
    { name: 'VODAFONE', value: 'vodafone' },
    { name: 'AIRTEL-TIGO', value: 'airtel-tigo' },
  ];
  countries = COUNTRIES;
  availableCountries = [
    { code: 'GH', label: 'GHANA' },
    { code: 'BJ', label: 'BENIN' },
    { code: 'CI', label: `COTE D'IVOIRE` }
  ];
  networkProviders = ['mtn', 'orange'];
  currencies = [{code:'XOF', name:'XOF'}, {code:'GHS', name:'GHS'}, {code:'XAF',name:'XAF'}, {code:'NGN',name:'NGN'},];
  constructor(private formBuilder: FormBuilder,private dialogRef: MatDialogRef<FilterFormComponent>) { 
    
    this.form = this.formBuilder.group({
      country: [null],
      status: [null, [Validators.required, Validators.pattern(/^[0-9]*$/)]],
      dateFrom: [moment(now()).subtract(1,'months').toDate()],
      dateTo: [moment(now()).toDate()],
    });
  }

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close(this.form); //on close pass data to parent
   }

}
