import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { fadeInUp400ms } from '../../../../@vex/animations/fade-in-up.animation';
import { stagger40ms } from '../../../../@vex/animations/stagger.animation';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import icVisibility from '@iconify/icons-ic/twotone-visibility';
import icVisibilityOff from '@iconify/icons-ic/twotone-visibility-off';
import { AuthserviceService } from 'src/app/services/authservice.service';
import { take } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BUSINESS_DATA_KEY, USER_SESSION_KEY } from 'src/app/Models/constants';
import { Router } from '@angular/router';

export class ChangePwdTemplate {
    oldPassword: string;
    newPassword: string;
    confNewPassword: string;
}

@Component({
    selector: 'vex-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
    animations: [fadeInUp400ms, stagger40ms],
    providers: [
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'outline',
            } as MatFormFieldDefaultOptions,
        },
    ],
})
export class ChangePasswordComponent implements OnInit {

    layoutCtrl = new FormControl('boxed');

    form: FormGroup;
    inputType = 'password';
    visible = false;

    icVisibility = icVisibility;
    icVisibilityOff = icVisibilityOff;
    changePwdTemplate: ChangePwdTemplate;
    errorMessage: string = '';
    hasNewPwdInputError: boolean = false;
    checkNewPwdErrorMessage: string = '';
    hasError: boolean;
    isUpdating: boolean;
    userData: any;

    constructor(
        private formBuilder: FormBuilder,
        private cd: ChangeDetectorRef,
        private authService: AuthserviceService,
        private snackBar: MatSnackBar,
        private router: Router
    ) {
        const sessionData = JSON.parse(localStorage.getItem(USER_SESSION_KEY));
        this.userData = sessionData;

        this.changePwdTemplate = new ChangePwdTemplate();
        setTimeout(() => {
            this.changePwdTemplate.oldPassword = '';
            this.changePwdTemplate.newPassword = '';
            this.changePwdTemplate.confNewPassword = '';
        }, 2000);
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            oldPassword: ['', [Validators.required]],
            newPassword: ['', [Validators.required]],
            confNewPassword: ['', [Validators.required]],
        });
    }

    get f() {
        return this.form.controls;
    }

    get isFormReady(): boolean {
        return true;
    }

    changePassword() {


        this.isUpdating = true;
        if (this.form.valid || !this.hasNewPwdInputError) {
            // updatepwd
            this.authService.updatePwd(this.form.value, this.userData.token).subscribe((response) => {
                if (response && response['currentUser']) {
                    this.openSnackbar('Password updated successfully');
                    this.logout();
                } else {
                    this.hasError = true;
                    this.isUpdating = false;
                    this.errorMessage = response['message'];
                    this.openSnackbar(response['message']);
                }
            },
                // tslint:disable-next-line:no-unused-expression
                (error: any) => {
                    this.isUpdating = false;
                    this.hasError = true;
                    this.errorMessage = error.message;
                    //console.error(error);
                    //this.openSnackbar(error.error.message);
                    this.showInternalServerErrorMessage()
                });
        }


    }
    showInternalServerErrorMessage() {
        this.errorMessage = "Error. Please retry again later."
        this.openSnackbar("Error. Please retry again later.");
    }



    logout() {
        localStorage.removeItem(BUSINESS_DATA_KEY);
        localStorage.removeItem(USER_SESSION_KEY);
        sessionStorage.clear();
        this.router.navigate(['auth/login']);
    }

    openSnackbar(message) {
        this.snackBar.open(message, 'CLOSE', {
            duration: 3000,
            horizontalPosition: 'right',
        });
    }

    onCheckConfirmNewPwd() {
        //if (this.form.value['newPassword'] === this.form.value['confNewPassword']) {
        if (this.changePwdTemplate.newPassword === this.changePwdTemplate.confNewPassword) {
            this.hasNewPwdInputError = false;
        } else {
            this.hasNewPwdInputError = true;
            this.checkNewPwdErrorMessage = 'Password must be identical';
        }
    }

    toggleVisibility() {
        if (this.visible) {
            this.inputType = 'password';
            this.visible = false;
            this.cd.markForCheck();
        } else {
            this.inputType = 'text';
            this.visible = true;
            this.cd.markForCheck();
        }
    }
}
