import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
} from '@angular/core';
import { MenuItem } from '../interfaces/menu-item.interface';
import { trackById } from '../../../../utils/track-by';
import icPerson from '@iconify/icons-ic/twotone-person';
import icSettings from '@iconify/icons-ic/twotone-settings';
import icAccountCircle from '@iconify/icons-ic/twotone-account-circle';
import icBalance from '@iconify/icons-ic/twotone-account-balance';
import icMoveToInbox from '@iconify/icons-ic/twotone-move-to-inbox';
import icListAlt from '@iconify/icons-ic/twotone-list-alt';
import icTableChart from '@iconify/icons-ic/twotone-table-chart';
import icCheckCircle from '@iconify/icons-ic/twotone-check-circle';
import icAccessTime from '@iconify/icons-ic/twotone-access-time';
import icDoNotDisturb from '@iconify/icons-ic/twotone-do-not-disturb';
import icOfflineBolt from '@iconify/icons-ic/twotone-offline-bolt';
import icChevronRight from '@iconify/icons-ic/twotone-chevron-right';
import icArrowDropDown from '@iconify/icons-ic/twotone-arrow-drop-down';
import icBusiness from '@iconify/icons-ic/twotone-business';
import icVerifiedUser from '@iconify/icons-ic/twotone-verified-user';
import icLock from '@iconify/icons-ic/twotone-lock';
import icNotificationsOff from '@iconify/icons-ic/twotone-notifications-off';
import { Icon } from '@visurel/iconify-angular';
import { PopoverRef } from '../../../../components/popover/popover-ref';
import { BUSINESS_DATA_KEY, BUSINESS_PARAMETERS, USER_SESSION_KEY, VIRTUAL_BANK_ACCOUNT } from 'src/app/Models/constants';
import { User } from 'src/app/Models/models.interface';
import { Router } from '@angular/router';
import { baseurlExp } from 'src/environments/environment';

export interface OnlineStatus {
    id: 'online' | 'away' | 'dnd' | 'offline';
    label: string;
    icon: Icon;
    colorClass: string;
}

@Component({
    selector: 'vex-toolbar-user-dropdown',
    templateUrl: './toolbar-user-dropdown.component.html',
    styleUrls: ['./toolbar-user-dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarUserDropdownComponent implements OnInit {
    businessSettingItems: MenuItem[] = [

        /*
        {
            id: '1',
            icon: icSettings,
            label: 'Settings',
            description: '',
            colorClass: 'text-teal',
            route: '/dashboards/profile',
        },
        */
        /*
        {
            id: '2',
            icon: icBalance,
            label: 'Balance',
            description: '',
            colorClass: 'text-teal',
            route: '/dashboards/balance',
        },
        */
        /* {
            id: '2',
            icon: icLock ,
            label: 'Change Password',
            description: '',
            colorClass: 'text-teal',
            route: '/dashboards/change-password',
        }, */

    ];

    palApiItems: MenuItem[] = [
        {
            id: '1',
            icon: icAccountCircle,
            label: 'PAL API',
            description: '',
            colorClass: 'text-teal',
            route: '/dashboards/api',
        },

    ];

    statuses: OnlineStatus[] = [
        {
            id: 'online',
            label: 'Online',
            icon: icCheckCircle,
            colorClass: 'text-green',
        },
        {
            id: 'away',
            label: 'Away',
            icon: icAccessTime,
            colorClass: 'text-orange',
        },
        {
            id: 'dnd',
            label: 'Do not disturb',
            icon: icDoNotDisturb,
            colorClass: 'text-red',
        },
        {
            id: 'offline',
            label: 'Offline',
            icon: icOfflineBolt,
            colorClass: 'text-gray',
        },
    ];

    activeStatus: OnlineStatus = this.statuses[0];

    trackById = trackById;
    icPerson = icPerson;
    icSettings = icSettings;
    icChevronRight = icChevronRight;
    icArrowDropDown = icArrowDropDown;
    icBusiness = icBusiness;
    icVerifiedUser = icVerifiedUser;
    icLock = icLock;
    icNotificationsOff = icNotificationsOff;
    userData: User;
    userPhoto: string;
    userBusinessData;

    constructor(
        private cd: ChangeDetectorRef,
        private router: Router,
        private popoverRef: PopoverRef<ToolbarUserDropdownComponent>
    ) {
        const sessionData = localStorage.getItem(USER_SESSION_KEY);
        this.userData = JSON.parse(sessionData);

        const businessData = localStorage.getItem(BUSINESS_DATA_KEY);
        this.userBusinessData = JSON.parse(businessData);

    }

    ngOnInit() {
        //this.userPhoto = baseurlExp.imageBaseURL+`/public/uploads/users/${this.userData.photo}`;
        this.userPhoto = this.userBusinessData?.business_logo 
        if (this.userBusinessData?.business_logo !== 'undefined') { 
            this.userPhoto = this.userBusinessData?.business_logo
        }
        if(!this.isAllowed("5") && !this.isAllowed("1")){
            this.businessSettingItems = this.businessSettingItems.filter(item => item.id !== '2');
        }
    }

    setStatus(status: OnlineStatus) {
        this.activeStatus = status;
        this.cd.markForCheck();
    }
    isAllowed(habl: string) {

        let habilitations = []
        let isAllow = false
        if (this.userData.secondaryAccount) {
            if (this.userData.habilitations != undefined) {
                habilitations = this.userData.habilitations.split(',')
                for (let hab of habilitations) {
                    if (hab == habl) {
                        isAllow = true
                    }
                }
            }
        } else {
            isAllow = true
        }
        return isAllow
    }

    close() {
        this.popoverRef.close();
    }

    logout() {
        localStorage.removeItem(BUSINESS_DATA_KEY);
        localStorage.removeItem(USER_SESSION_KEY);
        localStorage.removeItem(BUSINESS_PARAMETERS);
        localStorage.removeItem(VIRTUAL_BANK_ACCOUNT);
        sessionStorage.clear();
        this.popoverRef.close();
        this.router.navigate(['auth/login']);
    }
}
