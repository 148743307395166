import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { PopoverService } from '../../../components/popover/popover.service';
import { ToolbarUserDropdownComponent } from './toolbar-user-dropdown/toolbar-user-dropdown.component';
import icPerson from '@iconify/icons-ic/twotone-person';
import { BUSINESS_DATA_KEY, USER_SESSION_KEY } from '../../../../app/Models/constants';
import { User } from 'src/app/Models/models.interface';
import icSettings from '@iconify/icons-ic/twotone-settings';
import icDomain from '@iconify/icons-ic/twotone-domain';
import { TranslateService } from '@ngx-translate/core';
import { baseurlExp } from 'src/environments/environment.prod';

@Component({
  selector: 'vex-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  styleUrls: ['./toolbar-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarUserComponent implements OnInit {
  dropdownOpen: boolean;
  icPerson = icPerson;
  icDomain = icDomain;
  icSettings = icSettings;
  userData: User;
  userPhoto: string;
  userBusinessData: any;
  credentials: string;
  browserLanguage: string;

  constructor(private popover: PopoverService, private cd: ChangeDetectorRef,
    private translate: TranslateService) {
    const sessionData = localStorage.getItem(USER_SESSION_KEY);
    this.userData = JSON.parse(sessionData);
    const businessData = localStorage.getItem(BUSINESS_DATA_KEY);

    if (businessData !== 'undefined') {
      this.userBusinessData = JSON.parse(businessData);
      this.credentials = `${this.userBusinessData?.api_secret_key_live}:${this.userBusinessData?.api_public_key_live}`;
    }
    const origin = window.location.origin;
    if (this.userBusinessData?.business_logo !== 'undefined') { 
      this.userPhoto = this.userBusinessData?.business_logo
    }

 
    // vtx TRADUCTION 
    this.browserLanguage = window.navigator.language;
    let lang = this.browserLanguage.split('-')
    if (lang[0] == 'en') {
       
    }
    if (lang[0] == 'fr') {
      translate.setDefaultLang(lang[0]);
      console.log(lang[0])
      this.translate.use(lang[0]);
    }

  }

  ngOnInit() { }

  showPopover(originRef: HTMLElement) {
    this.dropdownOpen = true;
    this.cd.markForCheck();
    const popoverRef = this.popover.open({
      content: ToolbarUserDropdownComponent,
      origin: originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ],
    });

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      this.cd.markForCheck();
    });
  }
}
