<mat-divider class="-mx-6 text-border toolbar-mat-divider"></mat-divider>
<div class="ms-20" style="margin: 30px; margin-top: 30px;">
    <div *ngIf="!invitation">
        <div class="input-row">
            <div class="customInput"> 
            </div>
        </div>
        <div style="flex: 1;">
            <h5 class="ms-5 mt-5 me-auto"> </h5>
        </div>
        <div style="flex: 1; text-align: right;">
            <button style="border: solid 1px"  mat-button color="primary" class="add-funds" type="button"
            (click)="toInvite()">
                <mat-icon class="mr-2">add_circle_outlined</mat-icon> {{"Invite Your Team" | translate }}
            </button> 
        </div>

        <br>
        <div>


            <table mat-table [dataSource]="collaboratorsDataSource" class="w-full" matSort matTableExporter
                #exporter="matTableExporter">
                
                <ng-container matColumnDef="first_name">
                    <th mat-header-cell *matHeaderCellDef>{{"First Name"| translate}} </th>
                    <td mat-cell *matCellDef="let element">{{ element.first_name }}</td>
                </ng-container>
                <ng-container matColumnDef="last_name">
                    <th mat-header-cell *matHeaderCellDef>{{"Last Name"| translate}} </th>
                    <td mat-cell *matCellDef="let element">{{ element.last_name }}</td>
                </ng-container>
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef>Email</th>
                    <td mat-cell *matCellDef="let element">{{ element.email }}</td>
                </ng-container>

               <!-- <ng-container matColumnDef="authorization">
                    <th mat-header-cell *matHeaderCellDef>Authorization</th>
                    <td mat-cell *matCellDef="let element">
                        <span (click)="toUpdate(element)" class="underline cursor-pointer">Change authorization</span>
                    </td>
                </ng-container>-->
                <ng-container matColumnDef="role">
                    <th mat-header-cell *matHeaderCellDef>Role</th>
                    <td mat-cell *matCellDef="let element">
                      <span *ngFor="let role of userRole">
                        <span *ngIf="role.email == element.email">
                          {{role.role}}
                        </span>
                      </span>
                    </td>
                  </ng-container>
              
                <!--<ng-container matColumnDef="authorization">
                    <th mat-header-cell *matHeaderCellDef>Authorization</th>
                    <td mat-cell *matCellDef="let element">
                        <span (click)="toUpdate(element)" class="underline cursor-pointer">Change authorization</span>
                    </td>
                </ng-container>-->

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>{{"Status"| translate}} </th>
                    <td mat-cell *matCellDef="let element"> 
                        <span *ngIf="getStatus(element.status) == 'Pending'">
                            <span class="underline cursor-pointer pending-text" [ngClass]="getStatus(element.status)"> <b>{{ getStatus(element.status) }}</b> </span>
                        </span>
                        <span *ngIf="getStatus(element.status) == 'Joined'">
                            <span class="underline cursor-pointer joined-text" [ngClass]="getStatus(element.status)"> <b>{{ getStatus(element.status) }}</b> </span>
                        </span> 
                    </td>
                </ng-container>

            <!--    <ng-container matColumnDef="remove">
                    <th mat-header-cell *matHeaderCellDef>Remove</th>
                    <td mat-cell *matCellDef="let element">
                        <span (click)="removeUser(element)" class="cursor-pointer text-danger">Remove</span>
                    </td>
                </ng-container>-->

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element" style="text-align: right;">
                      <button mat-icon-button [matMenuTriggerFor]="columnFilterMenu">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #columnFilterMenu="matMenu">
                        <button mat-menu-item (click)="toUpdate(element)">
                          <mat-icon color="primary"> dialpad </mat-icon>
                          <span color="primary"> Permissions </span>
                        </button>
                        <button mat-menu-item (click)="removeUser(element)">
                          <mat-icon color="warn">delete</mat-icon>
                          <span color="warn">{{"Remove"| translate}} </span>
                        </button>
              
                      </mat-menu>
                    </td>
                </ng-container>

                  


                <tr mat-header-row *matHeaderRowDef="collaboratorsDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: collaboratorsDisplayedColumns"></tr>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">
                        {{ errorMessage || "No Team Member Found" | translate}}
                    </td>
                </tr>
            </table>

        </div>
    </div>


    <form *ngIf="invitation" [formGroup]="businessForm" action="">
        <div class="input-row" *ngIf="!updating" style="width: 25%;">
            <div class="customInput" style="margin-right: 10px;">
                <span>{{"First Name"| translate}} </span>
               <!-- <mat-form-field fxFlex="auto">
                    <input matInput >
                </mat-form-field>-->
                <input type="text"  formControlName="first_name" style="border: solid 1px #ccc; height: 40px;">
            </div>

           
            <div class="customInput" >
                <span>{{"Last Name"| translate}} </span>
              <!--  <mat-form-field fxFlex="auto">
                    <input matInput formControlName="last_name">
                </mat-form-field>-->
                <input type="text"  formControlName="last_name" style="border: solid 1px #ccc; height: 40px; width: 218px;">
            </div>


        </div>
        <div class="input-row" *ngIf="!updating" style="width: 32%;">

            <div class="customInput">
                <span>Email</span>
                <input type="email" formControlName="email" style="border: solid 1px #ccc; height: 40px;">
                <!--<mat-form-field fxFlex="auto">
                    <input matInput formControlName="email">
                    <mat-error *ngIf="businessForm?.get('email').errors?.pattern?.requiredPattern">
                        {{"Email adresse is incorrect"| translate}}
                    </mat-error>
                </mat-form-field>-->
                <br><br>
            </div>

        </div>
 

        <div class="mb-5" *ngIf="updating">
            <div class="text-bold">{{selectedUser.first_name}} {{selectedUser.last_name}}</div>
            <div class="text-grey">{{selectedUser.email}}</div>
            <mat-divider class="-mx-12 text-border"></mat-divider>
        </div>
        <!--<div class="input-row" *ngFor="let group of checkBoxGroups; let g = index">
            <div class="customInput" >
                <span class="checkboxs-header">{{group.title}}</span>
                <mat-checkbox *ngFor="let checkBox of group.checkBoxes; let c = index" (change)="selectAuthorization(g,c, $event)" [checked]="checkBox.checked" class="mb-2">{{checkBox.text}}</mat-checkbox>
            </div>
        </div>-->
        <div class="input-row" *ngFor="let group of checkBoxGroups; let r = index">
            <div class="customInput"> 
                <mat-checkbox [disabled]="group.disabled" (change)="selectRoleAuthorization(r, $event)"
                    [checked]="group.checked" class="mb-1"><span
                        class="checkboxs-header">{{group.roleTitle}}</span></mat-checkbox>

                <div *ngFor="let sousGroup of group.roleCheckBoxes; let g = index" class="ms-5 pl-5"
                    style="padding-left: 20px; margin-left: 20px;">

                    <mat-checkbox [disabled]="sousGroup.disabled" (change)="selectAuthorization(r,g,$event)"
                        [checked]="sousGroup.checked" class="mb-2">{{sousGroup.text}}</mat-checkbox>
                    <!-- <mat-checkbox [disabled]="sousGroup.disabled" (change)="selectAuthorization(r,g $event)" [checked]="sousGroup.checked" class="mb-2">{{sousGroup.text}}</mat-checkbox>
                    <div class="pl-5" *ngFor="let checkBox of sousGroup.checkBoxes; let c = index">
                        <mat-checkbox [disabled]="checkBox.disabled" (change)="selectAuthorization(r,g,c, $event)" [checked]="checkBox.checked" class="mb-2">{{checkBox.text}}</mat-checkbox>
                    </div>-->

                </div> <br>

            </div>
        </div>
        <div fxLayout="row" class="mt-8">  
            <button mat-flat-button class="cancel-btn mr-1" (click)="toCancel()">Cancel</button>
            <button mat-flat-button *ngIf="!updating" [disabled]="!isChechGroupsValid || !businessForm.valid || inviting"
                class="confirm-btn" type="submit" (click)="sendInvitation()">Send Invitation</button>
            <button mat-flat-button *ngIf="updating" [disabled]="!isChechGroupsValid || !businessForm.valid || inviting"
                class="confirm-btn" type="submit" (click)="updateUserHabilitations()">Apply changes</button>
        </div>
    </form>
</div>