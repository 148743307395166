import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { BUSINESS_DATA_KEY, BUSINESS_PARAMETERS, USER_SESSION_KEY } from 'src/app/Models/constants';
import { BusinessService } from 'src/app/services/business.service';
import { takeUntil, take } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
@Component({
  selector: 'vex-recharge-by-mobile-money',
  templateUrl: './recharge-by-mobile-money.component.html',
  styleUrls: ['./recharge-by-mobile-money.component.scss']
})
export class RechargeByMobileMoneyComponent implements OnInit {

  mobileMoneytopUpForm: FormGroup
  merchantDeposittopUpForm: FormGroup
  recieptFile: File
  isUploading: boolean = false

  topUpForm: FormGroup;
  currency = '';
  dailingCode = '+229';
  module_id = 102;
  data: any;
  userData: any;
  userBusinessData: any;
  isProcessing: boolean;
  unsubscribe$ = new Subject();
  credentials: string;
  hasError: boolean;
  errorMessage: string;


  userCountry: string = ""
  avalaibleOperators = []

  idDocumentFile: File;
  UploadedFileName: string = ''


  networks: any
  collectionParameters: any
  collectionParametersDataSelected: any

  browserLanguage: string

  formattedAmount: string = '';




  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private router: Router,
    private fb: FormBuilder,
    private translate: TranslateService,
    private businessService: BusinessService,
    private snackBar: MatSnackBar,
    private location: Location
  ) {


    const sessionData = localStorage.getItem(USER_SESSION_KEY);
    this.userData = JSON.parse(sessionData);
    this.credentials = `${this.userData?.api_secret_key_live}:${this.userData?.api_public_key_live}`;

    const businessData = localStorage.getItem(BUSINESS_DATA_KEY);
    this.userBusinessData = JSON.parse(businessData);

    this.userCountry = this.userBusinessData.created_country
    this.currency = this.setCurrencyBasedOnBusinessCreatedCountry(this.userBusinessData.created_country)


    const businessParameters = localStorage.getItem(BUSINESS_PARAMETERS); 
    if(businessParameters != undefined){
      this.collectionParameters = JSON.parse(businessParameters) 
    }

    // vtx TRADUCTION 
    this.browserLanguage = window.navigator.language;
    let lang = this.browserLanguage.split('-')
    if (lang[0] == 'en') {
    }
    if (lang[0] == 'fr') {
      translate.setDefaultLang(lang[0]);
      this.translate.use(lang[0]);
    }




    iconRegistry.addSvgIcon(
      'docs',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/docs.svg')
    );
    iconRegistry.addSvgIcon(
      'arrow2',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/arrow2.svg')
    );
    iconRegistry.addSvgIcon(
      'close',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/close.svg')
    );
    iconRegistry.addSvgIcon(
      'copy',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/copy.svg')
    );


  }

  ngOnInit(): void {

    this.topUpForm = this.fb.group({
      currency: [this.currency, Validators.required],
      amount: ['', [Validators.required, Validators.pattern(/^[0-9]+$/)]],
      operator: ['mtn', Validators.required],
    });
    this.mobileMoneytopUpForm = this.fb.group({
      phone_no: ['', Validators.required],
      currency: [this.currency, Validators.required],
      amount: ['', [Validators.required, Validators.pattern(/^[0-9]+$/)]],
      operator: ['mtn', Validators.required],
    })


    
    this.collectionParametersDataSelected = this.collectionParameters.find(
      collection => collection.code === this.userBusinessData?.created_country
    ); 
    if (this.collectionParametersDataSelected && this.collectionParametersDataSelected?.operators.length > 0) {
      this.mobileMoneytopUpForm.patchValue({
        operator: this.collectionParametersDataSelected.operators[0].name
      });
    }  
 

    this.mobileMoneytopUpForm.get('amount').valueChanges.subscribe(value => {
       
    });
    

    //this.getBusinessParameters(this.userData.user_id)


  } 
  formatAmount() {
    const value = this.mobileMoneytopUpForm.get('amount').value; 
    this.formattedAmount = value ? parseFloat(value).toLocaleString() : ''; // Utilisation de toLocaleString pour afficher avec des virgules
  }
  




  getBusinessParameters(user_id: any) {

    this.businessService.getBusinessPrameters(user_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(response => {
        if (response && response['status'] === true) { 
          this.collectionParameters = response['data']['collection']

          this.collectionParametersDataSelected = this.collectionParameters.find(
            collection => collection.code === this.userBusinessData?.created_country
          );
  
          if (this.collectionParametersDataSelected && this.collectionParametersDataSelected?.operators.length > 0) {
            this.mobileMoneytopUpForm.patchValue({
              operator: this.collectionParametersDataSelected.operators[0].name
            });
          }
  

        } else {
          if (response['status'] === false) {
            this.hasError = true;
            this.errorMessage = response['message'];
          } else {
            this.hasError = true;
            //this.showInternalServerErrorMessage()
          }
        }
      })
  }

  uploadID(event) {
    this.recieptFile = event.target.files[0];
    this.isUploading = true;
    //const reader: FileReader = new FileReader();
    //reader.onload = () => {
    //this.reciept = reader.result as string;
    //};
    //reader.readAsDataURL(this.recieptFile);

  }
  setCurrencyBasedOnBusinessCreatedCountry(country) {
    if (country == 'BJ' || country == 'CI' || country == 'SN') return 'XOF'
    if (country == 'CM') return 'XAF'
    if (country == 'GH') return 'GHS'

    return ''
  }


  rechargeAccount() {
    this.isProcessing = true;
    let phone_no = ""
    //let charges: number = 0

    this.topUpForm = this.fb.group({
      currency: [this.mobileMoneytopUpForm.value.currency],
      amount: [this.mobileMoneytopUpForm.value.amount],
      operator: [this.mobileMoneytopUpForm.value.operator],
    });
    phone_no = this.mobileMoneytopUpForm.value.phone_no
    //charges = ((this.mobileMoneytopUpForm.value.amount * 1.25) / 100) 
    this.data = {
      ...this.topUpForm.value,
      country: this.userCountry,
      //currency: this.currency,
      //module_id: this.module_id,
      user_id: this.userData.user_id,
      status: 'Pending',
      phone_no: phone_no,
      //charges: charges, 
    };
    this.businessService.requestTopUp(this.data, this.credentials)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(response => {
        this.isProcessing = false;
        if (response && response['status'] === true) {
          this.openSnackbar(response['message']);
          this.router.navigate(['/dashboards/balance', this.currency])
        } else {
          if (response['status'] === false) {
            this.hasError = true;
            this.errorMessage = response['message'];
            //VTXthis.router.navigate(['/dashboards/cash-in-transactions']) 
            this.mobileMoneytopUpForm = this.fb.group({
              phone_no: ['', Validators.required],
              currency: [this.currency, Validators.required],
              amount: ['', [Validators.required, Validators.pattern(/^[0-9]+$/)]],
              operator: ['mtn', Validators.required],
            })


          } else {
            this.hasError = true;
            this.showInternalServerErrorMessage()
          }
        }
      }),
      error => {
        this.hasError = true;
        this.errorMessage = error.message;
        this.showInternalServerErrorMessage()
      }

  }
  openSnackbar(message) {
    this.snackBar.open(message, 'CLOSE', {
      duration: 3000,
      horizontalPosition: 'right'
    });
  }
  showInternalServerErrorMessage() {
    this.errorMessage = "Error. Please retry again later."
    this.openSnackbar("Error. Please retry again later.");
  }
  goBack() {
    this.location.back();  // Retourne à la page précédente
  }



}
