import { NavigationEnd, Router } from '@angular/router';
import { DisburseCashComponent } from './../../../app/pages/dashboards/disburse-cash/disburse-cash.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
    Component,
    ElementRef,
    HostBinding,
    Input,
    OnInit,
    EventEmitter,
    HostListener,
    Output, ViewChild, OnChanges, SimpleChanges,
} from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import icBookmarks from '@iconify/icons-ic/twotone-bookmarks';
import emojioneUS from '@iconify/icons-emojione/flag-for-flag-united-states';
import emojioneDE from '@iconify/icons-emojione/flag-for-flag-germany';
import icMenu from '@iconify/icons-ic/twotone-menu';
import { ConfigService } from '../../services/config.service';
import { map, take, takeUntil } from 'rxjs/operators';
import icPersonAdd from '@iconify/icons-ic/twotone-person-add';
import icAssignmentTurnedIn from '@iconify/icons-ic/twotone-assignment-turned-in';
import icBallot from '@iconify/icons-ic/twotone-ballot';
import icDescription from '@iconify/icons-ic/twotone-description';
import icAssignment from '@iconify/icons-ic/twotone-assignment';
import icReceipt from '@iconify/icons-ic/twotone-receipt';
import icDoneAll from '@iconify/icons-ic/twotone-done-all';
import permIdentity from '@iconify/icons-ic/perm-identity';
import { NavigationService } from '../../services/navigation.service';
import icArrowDropDown from '@iconify/icons-ic/twotone-arrow-drop-down';
import { PopoverService } from '../../components/popover/popover.service';
import { MegaMenuComponent } from '../../components/mega-menu/mega-menu.component';
import icSearch from '@iconify/icons-ic/twotone-search';
import peopole from '@iconify/icons-ic/people-outline';
import { RechargeAccountComponent } from 'src/app/pages/dashboards/recharge-account/recharge-account.component';
import { NoBusinessModalComponent } from 'src/app/pages/dashboards/no-business-modal/no-business-modal.component';
import { BUSINESS_DATA_KEY, USER_SESSION_KEY } from 'src/app/Models/constants';
import { MerchantData } from 'src/app/Models/models.interface';
import icWallet from '@iconify/icons-ic/twotone-account-balance-wallet';
import { DisburseBankComponent } from '../../../app/pages/dashboards/disburse-cash/disburse-bank/disburse-bank.component';
import { MatMenu } from '@angular/material/menu';
import { checkRouterChildsData, displayRouterChildsData } from 'src/@vex/utils/check-router-childs-data';
import { filter, startWith, withLatestFrom } from 'rxjs/operators';
import { SuspendedBusinessModalComponent } from 'src/app/pages/dashboards/suspended-business-modal/suspended-business-modal.component';
import { BusinessService } from 'src/app/services/business.service';
import { PendingBusinessModalComponent } from 'src/app/pages/dashboards/pending-business-modal/pending-business-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AddFundsComponent } from 'src/app/pages/dashboards/add-funds/add-funds.component';
import { RejectedBusinessModalComponent } from 'src/app/pages/dashboards/rejected-business-modal/rejected-business-modal.component';

@Component({
    selector: 'vex-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit, OnChanges {
    isOpen: boolean = false;
    isOpenDisbursePopup: boolean;
    currentPage: any;

    @Input() mobileQuery: boolean;

    @Input()
    @HostBinding('class.shadow-b')
    hasShadow: boolean;

    navigationItems = this.navigationService.items;

    isHorizontalLayout$ = this.configService.config$.pipe(
        map((config) => config.layout === 'horizontal')
    );
    isVerticalLayout$ = this.configService.config$.pipe(
        map((config) => config.layout === 'vertical')
    );
    isNavbarInToolbar$ = this.configService.config$.pipe(
        map((config) => config.navbar.position === 'in-toolbar')
    );
    isNavbarBelowToolbar$ = this.configService.config$.pipe(
        map((config) => config.navbar.position === 'below-toolbar')
    );

    icSearch = icSearch;
    icBookmarks = icBookmarks;
    emojioneUS = emojioneUS;
    emojioneDE = emojioneDE;
    icMenu = icMenu;
    icPersonAdd = icPersonAdd;
    icAssignmentTurnedIn = icAssignmentTurnedIn;
    icBallot = icBallot;
    icDescription = icDescription;
    icAssignment = icAssignment;
    icReceipt = icReceipt;
    icDoneAll = icDoneAll;
    perm_identity = permIdentity;
    icWalletOutline = icWallet;
    icon_peapole = peopole;
    icArrowDropDown = icArrowDropDown;
    userBusinessData: any;
    userData: any;
    businessData: MerchantData;
    browserLanguage: string

    @ViewChild("menu", { static: true })
    menu: MatMenu;

    pageTitle$ = this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        startWith(null),
        map(() => displayRouterChildsData(this.router.routerState.root.snapshot, data => data.title))
    );
    businessDataLoaded: boolean;

    constructor(
        private layoutService: LayoutService,
        private configService: ConfigService,
        private navigationService: NavigationService,
        private popoverService: PopoverService,
        private dialog: MatDialog,
        private router: Router,
        private businessService: BusinessService,
        private translate: TranslateService,
        private snackBar: MatSnackBar,
    ) {
        const sessionData = localStorage.getItem(USER_SESSION_KEY);
        this.userData = JSON.parse(sessionData);

        // vtx TRADUCTION 
        this.browserLanguage = window.navigator.language;
        let lang = this.browserLanguage.split('-')
        if (lang[0] == 'en') {

        }
        if (lang[0] == 'fr') {
            translate.setDefaultLang(lang[0]);
            console.log(lang[0])
            this.translate.use(lang[0]);
        }

    }

    ngOnInit() {
        const businessData = localStorage.getItem(BUSINESS_DATA_KEY);
        if (!businessData || businessData === 'undefined') {
            this.getUserBusinessData(this.userData.user_id);
        } else {
            this.businessData = JSON.parse(businessData);
            this.businessDataLoaded = true;
        }
    }

    getUserBusinessData(userID) {
        this.businessService.getBusinessDetails(userID)
            .pipe(take(1))
            .subscribe(data => {
                this.businessData = data;
                this.businessDataLoaded = true;
                if (data) {
                    localStorage.setItem(BUSINESS_DATA_KEY, JSON.stringify(this.businessData));
                }
            });
    }

    closeDisburseMenu() {
        this.isOpenDisbursePopup = false;
    }

    ngOnChanges(changes: SimpleChanges): void {

    }

    openQuickpanel() {
        this.layoutService.openQuickpanel();
    }

    openSidenav() {
        this.layoutService.openSidenav();
    }

    openMegaMenu(origin: ElementRef | HTMLElement) {
        this.popoverService.open({
            content: MegaMenuComponent,
            origin,
            position: [
                {
                    originX: 'start',
                    originY: 'bottom',
                    overlayX: 'start',
                    overlayY: 'top',
                },
                {
                    originX: 'end',
                    originY: 'bottom',
                    overlayX: 'end',
                    overlayY: 'top',
                },
            ],
        });
    }

    openSearch() {
        this.layoutService.openSearch();
    }

    openRechargePopup(): void {

        //recharge-methods
        if (this.isAllowed("4") || this.isAllowed("1")) {
            if (this.userData.hasBusiness || !!this.businessData) {
                if (this.businessData?.status === 'pending') {
                    this.dialog.open(PendingBusinessModalComponent);
                } else if (this.businessData?.status === 'suspended') {
                    this.dialog.open(SuspendedBusinessModalComponent);
                }
                else if (this.businessData?.status === 'rejected') {
                    this.dialog.open(RejectedBusinessModalComponent);
                } else {
                    console.log("click")
                    this.router.navigate(['/dashboards/recharge']).then(); 
                }
            } else {
                this.dialog.open(NoBusinessModalComponent);
            }
        } else {
            this.openSnackbar("You are not allowed");
        }

 

        /*
        if (this.isAllowed("4") || this.isAllowed("1")) {
            if (this.userData.hasBusiness || !!this.businessData) {
                if (this.businessData?.status === 'pending') {
                    this.dialog.open(PendingBusinessModalComponent);
                } else if (this.businessData?.status === 'suspended') {
                    this.dialog.open(SuspendedBusinessModalComponent);
                }
                else if (this.businessData?.status === 'rejected') {
                    this.dialog.open(RejectedBusinessModalComponent);
                } else {
                    this.dialog.open(RechargeAccountComponent);  
                }
            } else {
                this.dialog.open(NoBusinessModalComponent);
            }
        } else {
            this.openSnackbar("You are not allowed");
        }
        */





    }
    isAllowed(habl: string) {

        let habilitations = []
        let isAllow = false
        if (this.userData.secondaryAccount) {
            if (this.userData.habilitations != undefined) {
                habilitations = this.userData.habilitations.split(',')
                for (let hab of habilitations) {
                    if (hab == habl) {
                        isAllow = true
                    }
                }
            }
        } else {
            isAllow = true
        }
        return isAllow
    }
    openSnackbar(message) {
        this.snackBar.open(message, 'CLOSE', {
          duration: 3000,
          horizontalPosition: 'right',
        });
      }

    onViewApiPart() {
        if (this.userData.hasBusiness || !!this.businessData) {
            if (this.businessData?.status === 'pending') {
                this.dialog.open(PendingBusinessModalComponent);
            } else if (this.businessData?.status === 'suspended') {
                this.dialog.open(SuspendedBusinessModalComponent);
            } else {
                this.router.navigate(['/dashboards/api']).then();
            }
        } else {
            this.dialog.open(NoBusinessModalComponent);
        }
    }

    toggleMenu() {
        this.isOpen = !this.isOpen;
    }

    toggleDisbursePopup() {
        this.isOpenDisbursePopup = !this.isOpenDisbursePopup;
    }



    toggleBulkDisbursePopup() {
        if (this.userData.hasBusiness || !!this.businessData) {
            if (this.businessData?.status === 'pending') {
                this.dialog.open(PendingBusinessModalComponent);
            } else if (this.businessData?.status === 'suspended') {
                this.dialog.open(SuspendedBusinessModalComponent);
            } else {
                this.isOpenDisbursePopup = !this.isOpenDisbursePopup;
                this.router.navigate(['/dashboards/bulk-disbursement']).then();
            }
        } else {
            this.dialog.open(NoBusinessModalComponent);
        }
    }
    openDisburseMobilePopup() {
        // this.isOpenDisbursePopup = !this.isOpenDisbursePopup;

        const config: MatDialogConfig = {
            width: '600px'
        };

        if (this.userData.hasBusiness || !!this.businessData) {
            if (this.businessData?.status === 'pending') {
                this.dialog.open(PendingBusinessModalComponent);
            } else if (this.businessData?.status === 'suspended') {
                this.dialog.open(SuspendedBusinessModalComponent);
            } else {
                //this.dialog.open(DisburseCashComponent, config);
                this.router.navigate(['/dashboards/make-mobile-disbursment']).then();

            }
            this.isOpenDisbursePopup = false;
        } else {
            this.dialog.open(NoBusinessModalComponent);
        }
    }
    openDisburseBankPopup() {
        const config: MatDialogConfig = {
            width: '600px'
        };
        if (this.userData.hasBusiness || !!this.businessData) {
            if (this.businessData?.status === 'pending') {
                this.dialog.open(PendingBusinessModalComponent);
            } else if (this.businessData?.status === 'suspended') {
                this.dialog.open(SuspendedBusinessModalComponent);
            } else {
                this.router.navigate(['/dashboards/make-transfer']).then();
            }
            this.isOpenDisbursePopup = false;
        } else {
            this.dialog.open(NoBusinessModalComponent);
        }
    }

}
