import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BusinessService } from 'src/app/services/business.service';
import {
  BUSINESS_DATA_KEY,
  SUMMARY_DATA_KEY,
  USER_SESSION_KEY,
} from 'src/app/Models/constants';
import { ExchangeService } from 'src/app/services/exchange.service';
import { Router } from '@angular/router';
@Component({
  selector: 'vex-swap',
  templateUrl: './swap.component.html',
  styleUrls: ['./swap.component.scss']
})
export class SwapComponent implements OnInit {

  unsubscribe$ = new Subject();
  collectionsBalanceData: any;
  balanceData: any;
  currencyBalance = [
    { currency: "XOF", balance: 0, collectionBalance: 0 },
    { currency: "XAF", balance: 0, collectionBalance: 0 },
    { currency: "NGN", balance: 0, collectionBalance: 0 },
    { currency: "GHS", balance: 0, collectionBalance: 0 }
  ]
  currentCurrencyBalance = { currency: "", balance: 0, collectionBalance: 0 }
  userData: any;
  credentials: string;

  exchangeForm: FormGroup;
  exchangeRate: number = 0
  exchangeRates: any = []
  maxAmountToExchange: number = 0
  exchangeRateExist:boolean = false
  amountToExchangeIsExceded : boolean = false
  startCurrencies = ['XOF', 'XAF', 'GHS', 'NGN']
  endCurrencies = ['NGN' ,'XOF','XAF', 'GHS']
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private service: BusinessService,
    private snackBar: MatSnackBar,
    private exchangeService: ExchangeService


  ) {
    const sessionData = JSON.parse(localStorage.getItem(USER_SESSION_KEY));
    this.userData = sessionData;
    this.credentials = `${this.userData?.api_secret_key_live}:${this.userData?.api_public_key_live}`;
  }

  ngOnInit(): void {

    this.getExchangeRates()
    this.getUserBalances()
    this.getUserCollectionsBalances()

    this.exchangeForm = this.fb.group({
      start_currency: ['', Validators.required],
      end_currency: ['', Validators.required],
      amount: ['', [Validators.required, Validators.pattern(/^[0-9]+$/)]],
      user_id : this.userData['user_id']
    });

    this.exchangeForm.get('start_currency').valueChanges.subscribe(
      (value) => {
        this.endCurrencies = ['NGN','XOF', 'XAF', 'GHS']
        this.endCurrencies = this.endCurrencies.filter((currency) => {
          return currency != value
        })
        
        this.exchangeForm.patchValue({
          "end_currency": this.endCurrencies[0]
        })

        this.exchangeRateExist = false
        const exchangeR = this.exchangeRates.find((exchange) => {
          return exchange.start_currency == this.exchangeForm.get('start_currency').value &&
            exchange.end_currency == this.exchangeForm.get('end_currency').value
        })  
        if(exchangeR != undefined){
          this.exchangeRateExist = true
          this.exchangeRate =  exchangeR.rate
        }  

        // SHOW START CURENCY BALANCE
        this.currentCurrencyBalance = this.currencyBalance.find(
          (currB) => {
            return currB.currency == value
          }
        )
        this.maxAmountToExchange = this.currentCurrencyBalance.balance
        //alert(this.maxAmountToExchange)
      }
    )
    this.exchangeForm.get('end_currency').valueChanges.subscribe(
      (value) => {

        this.exchangeRateExist = false
        const exchangeR = this.exchangeRates.find((exchange) => {
          return exchange.start_currency == this.exchangeForm.get('start_currency').value &&
            exchange.end_currency == this.exchangeForm.get('end_currency').value
        })
        if(exchangeR != undefined){
          this.exchangeRateExist = true
          this.exchangeRate =  exchangeR.rate
        }  
      }
    )



  }


  getUserBalances() {
    this.service
      .getUserBalances(this.userData.user_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response: any) => { 
        if (response.data.length) {
          const balanceDataMap = this.toMap(response.data);
          Object.keys(balanceDataMap).forEach((currency) => {
            const countries = balanceDataMap[currency].map(
              (data) => data.country
            );
            const filtered = balanceDataMap[currency].filter(
              ({ country }, index) => !countries.includes(country, index + 1)
            );
            const balances = filtered.map((data) => data.balance);

            const result = balances?.reduce((acc: any, cur: any) => {
              return (balanceDataMap[currency] = acc + Number(cur));
            }, 0) as number;
            return result.toFixed(2);
          });
          this.balanceData = balanceDataMap;
          for (let currencyB of this.currencyBalance) {
            if (currencyB.currency == "XOF") {
              if (!isNaN(this.balanceData.XOF)) {
                currencyB.balance = this.balanceData.XOF
              }
            }
            if (currencyB.currency == "XAF") {
              if (!isNaN(this.balanceData.XAF)) {
                currencyB.balance = this.balanceData.XAF
              }
            }
            if (currencyB.currency == "NGN") {
              if (!isNaN(this.balanceData.NGN)) {
                currencyB.balance = this.balanceData.NGN
              }
            }
            if (currencyB.currency == "GHS") {
              if (!isNaN(this.balanceData.GHS)) {
                currencyB.balance = this.balanceData.GHS
              }
            }
          }
          //this.dataSource = new MatTableDataSource(this.currencyBalance)
        }
      },
        (error) => {
          //this.dataLoaded = false;
          //this.isLoading = false; 
          //this.showInternalServerErrorMessage()
        });
  }
  getUserCollectionsBalances() {
    this.service
      .getUserCollectionsBalances(this.userData.user_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response: any) => {
          if (response.data.length) {
            const balanceDataMap = this.toMap(response.data);
            Object.keys(balanceDataMap).forEach((currency) => {
              const balances = balanceDataMap[currency].map(
                (data) => data.balance
              );
              const result = balances?.reduce((acc: any, cur: any) => {
                return (balanceDataMap[currency] = acc + Number(cur));
              }, 0) as number;
              return result.toFixed(2);
            });
            this.collectionsBalanceData = balanceDataMap;
            for (let currencyB of this.currencyBalance) {
              if (currencyB.currency == "XOF") {
                if (!isNaN(this.collectionsBalanceData.XOF)) {
                  currencyB.collectionBalance = this.collectionsBalanceData.XOF
                }
              }
              if (currencyB.currency == "XAF") {
                if (!isNaN(this.collectionsBalanceData.XAF)) {
                  currencyB.collectionBalance = this.collectionsBalanceData.XAF
                }
              }
              if (currencyB.currency == "NGN") {
                if (!isNaN(this.collectionsBalanceData.NGN)) {
                  currencyB.collectionBalance = this.collectionsBalanceData.NGN
                }
              }
              if (currencyB.currency == "GHS") {
                if (!isNaN(this.collectionsBalanceData.GHS)) {
                  currencyB.collectionBalance = this.collectionsBalanceData.GHS
                }
              }
            }
            //this.dataSource = new MatTableDataSource(this.currencyBalance)

          }
        },
        (error) => {
          //this.showInternalServerErrorMessage()
        });
  }
  private toMap(balanceData) {
    const result = balanceData.reduce((dataMap, item) => {
      dataMap[item.currency] = dataMap[item.currency] || [];
      dataMap[item.currency].push(item);
      return dataMap;
    }, Object.create(null));

    return result;
  }
  showInternalServerErrorMessage() {
    this.openSnackbar("Error. Please retry again later.");
  }
  openSnackbar(message) {
    this.snackBar.open(message, 'CLOSE', {
      duration: 5000,
      horizontalPosition: 'right',
    });
  }
  processExchange() { 
    if(this.isAmountValid()){  
      this.exchangeService.processExchange(this.exchangeForm.value, this.credentials)
      .subscribe(
        (response)=>{ 
          if(response["status"]){
            this.openSnackbar(response['message']) 
            this.router.navigate(["dashboards/transactions", 'swap']);
          }
        },
        (error)=>{
          this.showInternalServerErrorMessage()
        }
      )
    } 
  }


  
  isAmountValid(){

    if(this.currentCurrencyBalance.balance < this.exchangeForm.get('amount').value){
      this.amountToExchangeIsExceded = true
    }
    if(this.amountToExchangeIsExceded){
      return false
    }else{
      return true
    }
    
  }
  getExchangeRates() {
    this.exchangeService
      .getExchangeRates()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response: any) => {  
        if (response.status) {
          this.exchangeRates = response['data'] 
          
        }
      },
        (error) => { 
          //this.showInternalServerErrorMessage()
        });
  }


}
