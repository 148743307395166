<vex-page-layout-content [class.container]="layoutCtrl.value === 'boxed'"
[class.px-gutter]="layoutCtrl.value === 'fullwidth'" class="-mt-6">
<!--[@stagger]="true"-->
<div  class="w-full" vexContainer>
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="30px">
        <!--@fadeInUp-->
        <div  class="card" fxFlex="auto">
            <div *ngIf="!isFormReady" class="card overflow-auto -mt-16">
                <div style="
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          background: white;">
                    <mat-spinner color="primary"> </mat-spinner>
                </div>
            </div>

            <div class="table-btns-bar mt-7 ml-0 pl-0">
                <button app mat-button class="px-1" (click)="BopenFilterFormPopup()">
                    {{"Filters Applied"| translate}} <mat-icon [icIcon]="icFilterList" class="ml-1 mb-1"
                        color="black"></mat-icon>
                </button>
                <button (click)="exportAsXlsx()" class="px-1" mat-button>
                    {{"Download"| translate}} <mat-icon class="ml-1 mb-1" color="black"
                        [icIcon]="icCloudDownload"></mat-icon>
                </button>
                <button mat-button type="submit" class="disburs" (click)="onCreateTransfer()">
                    <mat-icon class="mr-3 text-white" matPrefix>add_circle_outlined</mat-icon>
                    {{"Make Transfer"| translate}}
                </button>
            </div>

            <div class="mt-3">
                <div class="bg-card rounded-full border px-4" fxFlex="250px" fxFlex.lt-md="auto" fxLayout="row"
                    fxLayoutAlign="start center">
                    <ic-icon [icIcon]="icSearch" size="28px"></ic-icon>
                    <input [formControl]="BsearchCtrl"
                        class="px-4 py-3 border-0 outline-none w-full bg-transparent"
                        placeholder="Enter trans ID to find..." type="search">
                </div>
            </div>
        </div>
    </div>
</div>
<br>
<br>
<br>
<br>
<div class="card overflow-auto -mt-16">
    <div *ngIf="isLoading" class="card overflow-auto -mt-16">
        <div style="
                display: flex;
                justify-content: center;
                align-items: center;
                background: white;
                height: 20rem;
              ">
            <mat-spinner color="primary"> </mat-spinner>
        </div>
    </div>

    <!--@stagger-->
    <table *ngIf="!isLoading" mat-table [dataSource]="BtransactionsDataSource" class="w-full mat-elevation-z"
         matSort matTableExporter #exporter="matTableExporter">
        <ng-container matColumnDef="reference">
            <th mat-header-cell *matHeaderCellDef>ID</th>
            <td mat-cell *matCellDef="let element">{{ element.reference }}</td>
        </ng-container>

        <!-- created_at Column -->
        <ng-container matColumnDef="created_at">
            <th mat-header-cell *matHeaderCellDef>{{ "Date & Time" | translate}}</th>
            <mat-cell matTooltipPosition="after" [matTooltip]="element.formatedDate" *matCellDef="let element">
                {{ element.created_at }}
            </mat-cell>
        </ng-container>
        <!-- Symbol Column -->
        <ng-container matColumnDef="bank_name">
            <th mat-header-cell *matHeaderCellDef>{{"Bank Name" | translate}} </th>
            <td mat-cell *matCellDef="let element">{{ element.bank_name }}</td>
        </ng-container>

        <ng-container matColumnDef="account_number">
            <th mat-header-cell *matHeaderCellDef>{{"Account Number" | translate}} </th>
            <td mat-cell *matCellDef="let element">{{ element.account_number }}</td>
        </ng-container>

        <ng-container matColumnDef="account_name">
            <th mat-header-cell *matHeaderCellDef>{{"Beneficiary"| translate}} </th>
            <td mat-cell *matCellDef="let element">{{ element.account_name }}</td>
        </ng-container>
        <ng-container matColumnDef="narration">
            <th mat-header-cell *matHeaderCellDef>{{"Description" | translate}} </th>
            <td mat-cell *matCellDef="let element">{{ element.narration }}</td>
        </ng-container>

        <ng-container matColumnDef="swift_code">
            <th mat-header-cell *matHeaderCellDef>{{"Swift Code"| translate}} </th>
            <td mat-cell *matCellDef="let element">{{ element.swift_code || 'N/A' }}</td>
        </ng-container>

        <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef>{{"Amount Sent"| translate}} </th>
            <td mat-cell *matCellDef="let element">{{ element.amount }}</td>
        </ng-container>

        <ng-container matColumnDef="currency">
            <th mat-header-cell *matHeaderCellDef>{{"Currency"| translate}} </th>
            <td mat-cell *matCellDef="let element">{{ element.currency }}</td>
        </ng-container>

        <ng-container matColumnDef="filter">
            <th *matHeaderCellDef><button [matMenuTriggerFor]="columnFilterMenu" class="" fxFlex="none"
                    mat-icon-button matTooltip="Filter Columns" type="button">
                    <mat-icon [icIcon]="icFilterList"></mat-icon>
                </button></th>
            <td mat-cell *matCellDef="let element"></td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>{{"Status" | translate}} </th>
            <td mat-cell *matCellDef="let item">
                <div [ngClass]="item.state?.cssClasses" class="px-2 py-1 my-4 text-xs font-medium rounded"
                    fxFlex="none">
                    <span> {{ item.state?.text }}</span>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="BtransactionsDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: BtransactionsDisplayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">
                {{
                errorMessage || "No Transaction Data Found Or Matching The filter" | translate
                }}
            </td>
        </tr>
    </table>

    <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize"
        class="sticky left-0"></mat-paginator>
</div>
</vex-page-layout-content>
<mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
<ng-container *ngFor="let column of BtransfersColumns">
    <button class="checkbox-item mat-menu-item" *ngIf="column.property!='filter'">
        <mat-checkbox (change)="BtoggleColumnVisibilityTrasactions(column, $event)" [(ngModel)]="column.visible"
            [checked]="column.visible" color="primary" name="columnsFilter" ngDefaultControl>
            {{ column.label | translate}}
        </mat-checkbox>
    </button>
</ng-container>
</mat-menu>