import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScriptLoaderServiceService {

  private scripts: any = {};

  constructor() {}

  loadScript(name: string, src: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.scripts[name]) {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      } else {
        this.scripts[name] = { loaded: false };
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = src;
        script.onload = () => {
          this.scripts[name].loaded = true;
          resolve({ script: name, loaded: true, status: 'Loaded' });
        };
        script.onerror = (error: any) => reject({ script: name, loaded: false, status: 'Load Failed' });
        document.head.appendChild(script);
      }
    });
  }
}