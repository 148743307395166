<div mat-dialog-title class="">
  <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
    <h2 class="m-0 headline" fxFlex="auto">Bank Transfer</h2>
    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon class="icon" [icIcon]="icClose"></mat-icon>
    </button>
  </div>
</div>

<mat-divider class="-mx-6 text-border"></mat-divider>
<div mat-dialog-content class="">
  <form [formGroup]="form" *ngIf="availableCountries.length" >
    <div class="input-bloc w-full" *ngIf="!confirmedTransfer">
      <div class="input-row">
        <div class="customInput">
          <div class="flex input-label">
            <span class="">How much to disburse?</span>
          </div>
          <mat-form-field class="sm:ml-6 flex-auto w-full" >
            <div matPrefix class="currency-prefix">
              <span> {{ form.get("currency").value }} </span>
            </div>
            <input matInput type="number" formControlName="amount" />
            <mat-error *ngIf="formControls?.amount?.errors?.required">This field is required</mat-error>
            <mat-error *ngIf="formControls?.amount?.errors?.max">You're limited to 20.000.000 XOF per
              transaction</mat-error>
          </mat-form-field>
          <!-- <div class="price-charged ">
                        <div class="small-green-circle"></div>
                        <span>You will be charged XOF 2,419.20</span>
                    </div> -->
        </div>
        <div class="customInput">
          <div class="customInput customSelect">
            <div class="flex input-label">
              <span class="">Select the recipient's country</span>
            </div>
            <mat-form-field class="sm:ml-6 flex-auto w-full">
              <mat-select [(ngModel)]="this.availableCountries[1].code" placeholder="Select a Country" formControlName="country">
                <mat-option *ngFor="let country of availableCountries" [value]="country.code">
                  {{ country.label }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="formControls?.country?.errors?.required">This field is required</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div *ngIf="!isSwiftCodeNeeded && !isFetchingBanksData" class="input-row">
        <div class="customInput">
          <div class="flex input-label">
            <span class="">Bank name</span>
          </div>
          <mat-form-field class="sm:ml-6 flex-auto w-100">
            <mat-select placeholder="Select a Bank" formControlName="account_bank" class="flex-auto w-100">
              <mat-option *ngFor="let bank of banksData" [value]="bank?.code">
                {{ bank?.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="formControls?.account_bank?.errors?.required">This field is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <mat-spinner style="font-size: small;" *ngIf="!isSwiftCodeNeeded && isFetchingBanksData" color="primary"> </mat-spinner>

      <div *ngIf="!isSwiftCodeNeeded && !isFetchingBankBranches" class="input-row">
        <div class="customInput">
          <div class="flex input-label">
            <span class="">Bank Branch</span>
          </div>
          <mat-form-field class="sm:ml-6 flex-auto w-full">
            <mat-select placeholder="Select a Branch" formControlName="bank_branch">
              <mat-option *ngFor="let bank of bankBranches" [value]="bank?.branch_name">
                {{ bank?.branch_name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="formControls?.bank_branch?.errors?.required">This field is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <mat-spinner style="font-size: small;" *ngIf="!isSwiftCodeNeeded &&  isFetchingBankBranches" color="primary"> </mat-spinner>

      <div *ngIf="isSwiftCodeNeeded" class="input-row">
        <div class="customInput">
          <div class="flex input-label">
            <span class="">Bank Name</span>
          </div>
          <mat-form-field class="sm:ml-6 flex-auto w-full">
            <input matInput type="text" formControlName="account_bank" />
            <mat-error *ngIf="formControls?.account_bank?.errors?.required">This field is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      
      <div *ngIf="isSwiftCodeNeeded" class="input-row">
        <div class="customInput">
          <div class="flex input-label">
            <span class="">Swift Code</span>
          </div>
          <mat-form-field class="sm:ml-6 flex-auto w-full">
            <input matInput type="text" formControlName="swift_code" />
            <mat-error *ngIf="formControls?.swift_code?.errors?.required">This field is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="input-row">
        <div class="customInput" >
          <div class="flex input-label">
            <span class="">Account number</span>
          </div>
          <mat-form-field class="sm:ml-6 flex-auto w-full">
            <input matInput type="text" formControlName="account_number" />
            <mat-error *ngIf="formControls?.account_number?.errors?.required">This field is required</mat-error>
            <mat-error *ngIf="formControls?.account_number?.errors?.pattern">This field must contain exactly 24 digits</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="input-row">
        <div class="customInput">
          <div class="flex input-label">
            <span class="">Account name</span>
          </div>
          <mat-form-field class="sm:ml-6 flex-auto w-full">
            <input matInput type="text" formControlName="beneficiary_name" />
            <mat-error *ngIf="formControls?.beneficiary_name?.errors?.required">This field is required</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="input-row">
        <!-- <div class="customInput customSelect">
          <div class="flex input-label">
            <span class="">Description</span>
          </div>
          <mat-form-field class="flex-auto w-full">
            <input matInput type="text" formControlName="narration" />
            <mat-error *ngIf="formControls?.narration?.errors?.required">This field is required</mat-error>
          </mat-form-field>
        </div> -->
        <div class="customInput">
          <div class="flex input-label">
            <span class="">Recipient Number</span>
          </div>
          <mat-form-field class="sm:ml-6 flex-auto w-full">
            <input matInput type="text" formControlName="phone_no">
            <mat-error *ngIf="formControls?.phone_no?.errors?.required">This field is required</mat-error>
          </mat-form-field>
        </div>
        <div class="customInput">
          <div class="flex input-label">
            <span class="">Description</span>
          </div>
          <mat-form-field class="flex-auto w-full mat-form-field">
            <input matInput class="mat-input-element" maxlength="20" type="text-area" formControlName="narration"
              #input>
          </mat-form-field>
        </div>
      </div>

      <div class="2xl:alert-error error-bloc" *ngIf="errorMessage && !form.valid">
        <span>{{ errorMessage }}</span>
      </div>
    </div>

    <div class="input-bloc" *ngIf="confirmedTransfer">
      <div class="approve-row">
        <div class="custom-content">
          <div class="flex input-label w-full">
            <span class="">Recipient's country</span>
          </div>
          <div class="approve-info">
            <span>{{ getCountryName() }}</span>
          </div>
        </div>
        <div class="custom-content">
          <div class="flex input-label w-full">
            <span class="">How much to disburse?</span>
          </div>
          <div class="approve-info">
            <span>{{ form.get("currency").value }}
              <span>{{ form.get("amount").value }}</span></span>
          </div>
        </div>


      </div>

      <div class="approve-row">
        <div class="custom-content">
          <div class="flex input-label w-full">
            <span class="">Bank name</span>
          </div>
          <div class="approve-info">
            <span>{{ getBankName(form.get("account_bank").value) }}</span>
          </div>
        </div>

        <div *ngIf="!isSwiftCodeNeeded" class="custom-content">
          <div class="flex input-label w-full">
            <span class="">Bank Branch</span>
          </div>
          <div class="approve-info">
            <span>{{ form.get("bank_branch").value }}</span>
          </div>
        </div>
        <div *ngIf="isSwiftCodeNeeded" class="custom-content">
          <div class="flex input-label w-full">
            <span class="">Swift Code</span>
          </div>
          <div class="approve-info">
            <span>{{ form.get("swift_code").value }}</span>
          </div>
        </div>
      </div>
      <div class="approve-row">
        <div class="custom-content">
          <div class="flex input-label w-full">
            <span class="">Account number</span>
          </div>
          <div class="approve-info">
            <span>{{ form.get("account_number").value }}</span>
          </div>
        </div>
      </div>

      <div class="approve-row">
        <div class="custom-content">
          <div class="flex input-label w-full">
            <span class="">Account name</span>
          </div>
          <div class="approve-info">
            <span>{{ form.get("beneficiary_name").value }}</span>
          </div>
        </div>
        <div class="custom-content">
          <div class="flex input-label w-full">
            <span class="">Recipient Phone Number</span>
          </div>
          <div class="approve-info">
            <span>{{ form.get("phone_no").value }}</span>
          </div>
        </div>
      </div>

      <div class="approve-row">
        <div class="custom-content">
          <div class="flex input-label w-full">
            <span class="">Description</span>
          </div>
          <div class="approve-info">
            <span>{{ form.get("narration").value }}</span>
          </div>
        </div>
      </div>

    </div>
  </form>
</div>
<mat-divider class="-mx-6 text-border"></mat-divider>

<div mat-dialog-actions class="btn-bloc">
  <button mat-flat-button *ngIf="confirmedTransfer" class="cancel-btn" type="button" (click)="cancelAction()">
    Cancel
  </button>
  <button mat-flat-button mat-dialog-close *ngIf="!confirmedTransfer" class="cancel-btn" type="button" (click)="cancelAction()">
    Cancel
  </button>
  
  <button *ngIf="!confirmedTransfer" mat-flat-button class="confirm-btn" type="button" (click)="submitAction()"
    [disabled]="form.invalid">
    {{ submitBtnText }}
  </button>
  <button *ngIf="confirmedTransfer" mat-flat-button class="confirm-btn" type="button" (click)="initiateBankTransfer()"
    [disabled]="form.invalid">
    {{ submitBtnText }}
  </button>
</div>