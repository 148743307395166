import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions, } from '@angular/material/form-field';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';

import icEdit from '@iconify/icons-ic/twotone-edit';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icSearch from '@iconify/icons-ic/twotone-search';
import icAdd from '@iconify/icons-ic/twotone-add';
import icFilterList from '@iconify/icons-ic/twotone-filter-list';
import icPhone from '@iconify/icons-ic/twotone-phone';
import icMail from '@iconify/icons-ic/twotone-mail';
import icMap from '@iconify/icons-ic/twotone-map';
import icMoreHoriz from '@iconify/icons-ic/twotone-more-horiz';
import icFolder from '@iconify/icons-ic/twotone-folder';
import icDateRange from '@iconify/icons-ic/twotone-date-range';
import icPerson from '@iconify/icons-ic/twotone-person';
import icRefresh from '@iconify/icons-ic/twotone-refresh';
import icBook from '@iconify/icons-ic/twotone-book';
import icCloudDownload from '@iconify/icons-ic/twotone-cloud-download';
import icAttachMoney from '@iconify/icons-ic/twotone-attach-money';
import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { MatTableExporterDirective } from 'mat-table-exporter';
import moment from 'moment';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import {
  BUSINESS_DATA_KEY,
  COUNTRIES,
  SUMMARY_DATA_KEY,
  TRANSACTION_TABLE_LABELS,
  USER_SESSION_KEY,
} from 'src/app/Models/constants';
import { SummaryData } from 'src/app/Models/models.interface';
import { Customer } from 'src/app/pages/apps/aio-table/interfaces/customer.model';
import { AuthserviceService } from 'src/app/services/authservice.service';
import { TransactionsService } from 'src/app/services/transactions.service';
import { aioTableData, aioTableLabels } from 'src/static-data/aio-table-data';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TableUtil } from '../reports/transactions-report/tableUtil';
import { CollectionReportFilterFormComponent } from '../reports/collections-report/collection-report-filter-form/collection-report-filter-form.component';
import { BeneficiariesService } from 'src/app/services/beneficiaries.service';
import { TransactionReportFilterFormComponent } from '../reports/transactions-report/transaction-report-filter-form/transaction-report-filter-form.component';
import { PendingBusinessModalComponent } from '../pending-business-modal/pending-business-modal.component';
import { SuspendedBusinessModalComponent } from '../suspended-business-modal/suspended-business-modal.component';
import { NoBusinessModalComponent } from '../no-business-modal/no-business-modal.component';
import { AddMobileBeneficiaryComponent } from '../reports/transactions-report/add-mobile-beneficiary/add-mobile-beneficiary.component';
import { TranslateService } from '@ngx-translate/core';




import peopole from '@iconify/icons-ic/people-outline';
import { MatMenu } from '@angular/material/menu';
import { DisburseCashComponent } from '../disburse-cash/disburse-cash.component';
import { FilterFormComponent } from '../reports/all-transfers/filter-form/filter-form.component';
import { AddBeneficiaryComponent } from '../reports/all-transfers/add-beneficiary/add-beneficiary.component';
import { ExchangeService } from 'src/app/services/exchange.service';
import { error } from '@angular/compiler/src/util';
@Component({
  selector: 'vex-view-transactions',
  templateUrl: './view-transactions.component.html',
  styleUrls: ['./view-transactions.component.scss'],
  animations: [fadeInUp400ms, stagger40ms],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'standard',
      } as MatFormFieldDefaultOptions,
    },
  ],
})
export class ViewTransactionsComponent implements OnInit, OnDestroy, AfterViewInit {
  layoutCtrl = new FormControl('boxed');

  /**
   * Simulating a service with HTTP that returns Observables
   * You probably want to remove this and do all requests in a service with HTTP
   */
  subject$: ReplaySubject<Customer[]> = new ReplaySubject<Customer[]>(1);
  data$: Observable<Customer[]> = this.subject$.asObservable();
  unsubscribe$ = new Subject();
  @Input()
  columns: TableColumn<Customer>[] = [
    // { label: 'Checkbox', property: 'checkbox', type: 'checkbox', visible: true },
    { label: 'ID', property: 'id', type: 'text', visible: true },
    {
      label: 'date',
      property: 'created_at',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Wallet',
      property: 'wallet',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Country',
      property: 'country',
      type: 'text',
      visible: false,
      cssClasses: ['font-medium'],
    },
    { label: 'Currency', property: 'currency', type: 'text', visible: true, cssClasses: ['text-secondary', 'font-medium'] },
    {
      label: 'Network',
      property: 'operator',
      type: 'text',
      visible: false,
      cssClasses: ['font-medium'],
    },
    // { label: "Contact", property: "phone_no", type: "button", visible: true },
    {
      label: 'Amount Collected',
      property: 'amount_collected',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    { label: 'Settled Amount', property: 'settled_amount', type: 'text', visible: true, cssClasses: ['text-secondary', 'font-medium'] },
    {
      label: 'Status',
      property: 'status',
      type: 'badge',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Filter',
      property: 'filter',
      type: 'badge',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    // { label: 'Labels', property: 'labels', type: 'button', visible: true },
    // { label: "Actions", property: "actions", type: "button", visible: true },
  ];
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  dataSource: MatTableDataSource<any> | null;
  selection = new SelectionModel<any>(true, []);
  searchCtrl = new FormControl();
  exportOptions = {
    fileName: 'test',
    sheet: { reportsProps: { Author: 'PAL' } },
  };
  labels = aioTableLabels;
  icPhone = icPhone;
  icMail = icMail;
  icMap = icMap;
  icAttachMoney = icAttachMoney;
  icEdit = icEdit;
  icSearch = icSearch;
  icPerson = icPerson;
  icDelete = icDelete;
  icAdd = icAdd;
  icFilterList = icFilterList;
  icMoreHoriz = icMoreHoriz;
  icFolder = icFolder;
  icDateRange = icDateRange;
  icBook = icBook;
  icRefresh = icRefresh;
  icCloudDownload = icCloudDownload;
  form: FormGroup;
  statusLabels = TRANSACTION_TABLE_LABELS;
  displayedColumns: string[] = [];
  statuses = [
    { name: 'Awaiting action', value: 1 }, //Pending
    { name: 'Completed', value: 3 },
    { name: 'Aborted', value: 4 },
    { name: 'Network Error', value: 6 },
    { name: 'Processing', value: 2 },
    { name: 'Aborted', value: 0 }, //Cancelled
  ];
  menu: { current: { name: string, value: string }, options: { name: string, value: string }[] } = {
    current: { name: 'PayIn', value: "payin" },
    options: [
      { name: 'Collections', value: "payin" },
      { name: 'Mobile Transfers', value: "payouts" },
      { name: 'Bank Transfers', value: "all_transfers" },
      { name: 'Swaps', value: "all_swaps" },
    ]
  };
  countries = COUNTRIES;
  availableCountries = ['GH', 'BJ', 'CI'];
  networkProviders = ['mtn', 'orange'];
  currencies = ['GHS', 'XOF', 'XAF', 'NGN'];
  userData: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTableExporterDirective)
  matTableExporter: MatTableExporterDirective;
  transactionsData: any;
  transactionType: any;
  hasNoTransactions: boolean;
  palFee = 0;
  isLoading: boolean;
  merchantSummaryData: SummaryData;
  userSessionData: any;
  hasError: boolean = false;
  errorMessage: string;
  customers: any;
  users: any;
  products: any;
  transactions: any;
  userBusinessData: any;
  credentials: string;
  countrySelected: any;


















  //PAYOUTS
  MlayoutCtrl = new FormControl('boxed'); 
  Msubject$: ReplaySubject<Customer[]> = new ReplaySubject<Customer[]>(1);
  Mdata$: Observable<Customer[]> = this.subject$.asObservable();
  transactionColumns: TableColumn<Customer>[] = [
    { label: 'ID', property: 'id', type: 'text', visible: true },
    {
      label: 'date',
      property: 'created_at',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Network',
      property: 'provider',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Wallet Number',
      property: 'wallet',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    // { label: "Contact", property: "phone_no", type: "button", visible: true },
    {
      label: 'Currency',
      property: 'currency',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Country',
      property: 'country',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Reference',
      property: 'network_transaction_ref',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Pal fee',
      property: 'charges',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Amount Sent',
      property: 'amount',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Start Balance',
      property: 'business_start_balance',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'End Balance',
      property: 'business_end_balance',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Status',
      property: 'status',
      type: 'badge',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Filter',
      property: 'filter',
      type: 'badge',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
  ];
  MpageSize = 10;
  MpageSizeOptions: number[] = [5, 10, 20, 50];
  MdataSource: MatTableDataSource<any> | null;
  MbeneficiariesDataSource: MatTableDataSource<any> | null;
  Mselection = new SelectionModel<any>(true, []);
  MsearchCtrl = new FormControl();
  searchCtrlBeneficiary = new FormControl();
  MexportOptions = {
    fileName: 'test',
    sheet: { reportsProps: { Author: 'PAL' } },
  };
  Mlabels = aioTableLabels;
  icon_peapole = peopole;
  Mform: FormGroup;
  MstatusLabels = TRANSACTION_TABLE_LABELS;
  MdisplayedColumns: string[] = [];
  MtransactionColsOrder = [];
  MbeneficiariesDisplayedColumns: string[] = [
    'phone_no',
    'country',
    'bank_name',
    'options',
  ];

  Mstatuses = [
    { name: 'Pending', value: 1 },
    { name: 'Completed', value: 3 },
    { name: 'Error', value: 4 },
    { name: 'Networ Error', value: 6 },
    { name: 'Processing', value: 2, },
    { name: 'Processing', value: 20, },
    { name: 'Cancelled', value: 0 },
  ];
  Mmenu: { current: { name: string, value: string }, options: { name: string, value: string }[] } = {
    current: { name: 'Payouts', value: "payouts" },
    options: [
      { name: 'Payouts', value: "payouts" },
      { name: 'Beneficiaries', value: "beneficiaries" },
    ]
  };
  MbeneficiaryOperationData: {
    message: string;
    selectedMobileBeneficiary: any
  } = { message: null, selectedMobileBeneficiary: null };
  Mcountries = COUNTRIES;
  MavailableCountries = ['GH', 'BJ', 'CI'];
  MnetworkProviders = ['mtn', 'orange'];
  Mcurrencies = ['GHS', 'XOF', 'XAF', 'NGN'];
  MuserData: any;
  MnumberOfBeneficiaries = 0;
  @ViewChild("MmenuMakeTransfer", { static: true })
  MmenuMakeTransfer: MatMenu;
  @ViewChild(MatTableExporterDirective)
  MmatTableExporter: MatTableExporterDirective;
  MtransactionsData: any;
  MtransactionType: any;
  MhasNoTransactions: boolean;
  MpalFee = 0;
  MisLoading: boolean;
  MmerchantSummaryData: SummaryData;
  MbeneficiariesData: any;
  MuserSessionData: any;
  MhasError = false;
  MerrorMessage: string;
  Mcustomers: any;
  Musers: any;
  Mproducts: any;
  Mtransactions: any;
  MuserBusinessData: any;
  Mcredentials: string;
  MisOpenDisbursePopup = false;
  @Input()
  MtransactionColumns: TableColumn<Customer>[] = [
    { label: 'ID', property: 'id', type: 'text', visible: true },
    {
      label: 'date',
      property: 'created_at',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Network',
      property: 'provider',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Wallet Number',
      property: 'wallet',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Currency',
      property: 'currency',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Country',
      property: 'country',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Reference',
      property: 'network_transaction_ref',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Pal fee',
      property: 'charges',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Amount Sent',
      property: 'amount',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Start Balance',
      property: 'business_start_balance',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'End Balance',
      property: 'business_end_balance',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Status',
      property: 'status',
      type: 'badge',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Filter',
      property: 'filter',
      type: 'badge',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
  ]; 
  browserLanguage: string















  // BANK TRANSACTIONS
  Bsubject$: ReplaySubject<Customer[]> = new ReplaySubject<Customer[]>(1);
  Bdata$: Observable<Customer[]> = this.subject$.asObservable();
  @Input()
  BtransfersColumns: TableColumn<Customer>[] = [
    { label: 'ID', property: 'reference', type: 'text', visible: true },
    {
      label: 'date',
      property: 'created_at',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Bank',
      property: 'bank_name',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Account No',
      property: 'account_number',
      type: 'text',
      visible: true,
    },
    {
      label: 'Swift Code',
      property: 'swift_code',
      type: 'text',
      visible: false,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Beneficiary',
      property: 'account_name',
      type: 'text',
      visible: false,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Amount Sent',
      property: 'amount',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Status',
      property: 'status',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Filter',
      property: 'filter',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
  ];
  BtransactionsDisplayedColumns: string[] = [];
  trasactionColsOrder: string[] = [];
  BpageSize = 10;
  BpageSizeOptions: number[] = [5, 10, 20, 50];
  BtransactionsDataSource: MatTableDataSource<any> | null;
  Bselection = new SelectionModel<any>(true, []);
  BsearchCtrl = new FormControl();
  BsearchCtrlBeneficiary = new FormControl();
  BexportOptions = {
    fileName: 'test',
    sheet: { reportsProps: { Author: 'PAL' } },
  };
  Bform: FormGroup;
  BstatusLabels = TRANSACTION_TABLE_LABELS;
  banksData: any;
  Bstatuses = [
    { name: 'Pending', value: 1 },
    { name: 'Completed', value: 3 },
    { name: 'Failed', value: 4 },
    { name: 'Network Error', value: 6 },
    { name: 'Processing', value: 2 },
    { name: 'Cancelled', value: 0 },
  ];
  operators = [
    { name: 'MTN', value: 'mtn' },
    { name: 'MOOV', value: 'moov' },
    { name: 'VODAFONE', value: 'vodafone' },
    { name: 'AIRTEL-TIGO', value: 'airtel-tigo' },
  ];
  @ViewChild(MatPaginator, { static: true }) Bpaginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) Bsort: MatSort;
  @ViewChild(MatTableExporterDirective)
  BmatTableExporter: MatTableExporterDirective;
  @ViewChild('menuMakeTransfer', { static: true })
  menuMakeTransfer: MatMenu;
  BtransactionsData: any;
  beneficiariesData: any;
  BhasNoTransactions: boolean;
  BisLoading: boolean = false;
  BmerchantSummaryData: SummaryData;
  BhasError = false;
  BerrorMessage: string;
  Bcustomers: any;
  Busers: any;
  Bproducts: any;
  Btransactions: any;
  isOpenDisbursePopup = false;
  beneficiaryOperationData: {
    message: string;
    selectedBankBeneficiary: any
  } = { message: null, selectedBankBeneficiary: null };




  //SWAPS
  //*ngIf="!BisLoading" mat-table [dataSource]="BtransactionsDataSource" 
  isLoadingSwaps: boolean = false
  userExchanges: any
  exchangeDataSource: MatTableDataSource<any> | null;
  /*
  exchangeDisplayedColumns: TableColumn<Customer>[] = [
    { label: 'ID', property: 'reference', type: 'text', visible: true },
    {
      label: 'date',
      property: 'created_at',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Start Currency',
      property: 'start_currency',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'End Currency',
      property: 'end_currency',
      type: 'text',
      visible: true,
    },
    {
      label: 'Amount',
      property: 'amount',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Amount Swap',
      property: 'amount_swap',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    
  ];
  */

  exchangeDisplayedColumns: string[] = [
    'reference',
    'created_at',
    'amount',
    'amount_swap',
  ];
  @ViewChild(MatPaginator, { static: true }) Exchangepaginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) Exchangesort: MatSort;








  constructor(
    private authService: AuthserviceService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private transactionService: TransactionsService,
    private snackBar: MatSnackBar,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private translate: TranslateService,
    //PAY OUTS       
    private beneficiariesService: BeneficiariesService,
    private exchangeService: ExchangeService
  ) {

    this.route.paramMap.subscribe((params) => {
      const type_transaction = params.get('type_transaction');
      if (type_transaction == 'swap') {
        this.selectOption({ name: 'Swaps', value: "all_swaps" })
      }
    });


    this.columns.forEach(
      e => {
        if (e.visible) {
          this.displayedColumns.push(e.property);
        }
      }
    )


    const user = localStorage.getItem('current_user');
    const sessionData = JSON.parse(localStorage.getItem(USER_SESSION_KEY));
    this.userData = sessionData;
    this.credentials = `${this.userData?.api_secret_key_live}:${this.userData?.api_public_key_live}`;
    this.merchantSummaryData = JSON.parse(localStorage.getItem(SUMMARY_DATA_KEY));
    const businessData = localStorage.getItem(BUSINESS_DATA_KEY);
    if (businessData !== 'undefined') {
      this.userBusinessData = JSON.parse(businessData);
    }
    if (!sessionData) {
      router.navigate(['/auth/login']);
    }




    //PAYOUTS 
    this.MtransactionColumns.forEach(
      e => {
        if (e.visible) {
          this.MdisplayedColumns.push(e.property);
        }
      }
    )
    const summaryData = JSON.parse(localStorage.getItem(SUMMARY_DATA_KEY));
    this.MmerchantSummaryData = summaryData;
    if (businessData !== 'undefined') {
      this.MuserBusinessData = JSON.parse(businessData);
    }
    if (!sessionData) {
      router.navigate(['/auth/login']);
    }









    // BANK TRANSACTIONS
    this.BtransfersColumns.forEach(
      e => {
        if (e.visible) {
          this.BtransactionsDisplayedColumns.push(e.property);
        }
      }
    )




    // vtx TRADUCTION 
    this.browserLanguage = window.navigator.language;
    let lang = this.browserLanguage.split('-')
    if (lang[0] == 'en') {

    }
    if (lang[0] == 'fr') {
      translate.setDefaultLang(lang[0]);
      this.translate.use(lang[0]);
    }


  }



  ngOnInit() {

    if (this.isAllowed('6') || this.isAllowed('1')) {




      this.form = this.fb.group({
        country: [''],
        dateFrom: [''],
        dateTo: [''],
        currency: [''],
        operator: [''],
        status: [''],
      });
      this.dataSource = new MatTableDataSource();
      this.search();
      this.searchCtrl.valueChanges
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((value) => this.onFilterChange(value));



      //PAYOUTS 
      this.form = this.fb.group({
        status: [''],
        dateFrom: [''],
        dateTo: [''],
        currency: [''],
        operator: ['']
      });
      this.MdataSource = new MatTableDataSource();
      this.MdataSource.paginator = this.paginator;
      this.MdataSource.sort = this.sort;
      this.Msearch();
      this.MsearchCtrl.valueChanges
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((value) => this.MonFilterChange(value));
      this.searchCtrlBeneficiary.valueChanges
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((value) => this.MonFilterChangeBeneficiary(value));






      // BANK TRANSFERS
      this.Bform = this.fb.group({
        country: [''],
        dateFrom: [''],
        dateTo: [''],
        currency: [''],
        status: [''],
      }); 
      //this.BgetBeneficiariesList();
      this.BsearchTransfers();
      this.BsearchCtrl.valueChanges
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((value) => this.BonFilterChange(value));
      /*
    this.BsearchCtrlBeneficiary.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => this.BonFilterChangeBeneficiary(value));
      */

      this.getUserExchange()









    } else {
      this.openSnackbar("You are not allowed");
    }






  }



  
  refreshData() {
    this.getTransactionsList();
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  getvisibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }
  getData() {
    return of(aioTableData.map((customer) => new Customer(customer)));
  }
  get hasExceededFreeTransfers(): boolean {
    return this.merchantSummaryData?.totalTransactionsAmount > 560000;
  }
  exportAsXlsx() {
    if (this.isAllowed("7")) {
      this.matTableExporter.exportTable('xlsx', {
        fileName: 'Transactions Report',
        sheet: 'report',
        Props: { Author: 'PAL Africa' },
      });
    } else {
      this.openSnackbar("You are not allowed");
    }

  }
  isAllowed(habl: string) {

    let habilitations = []
    let isAllow = false
    if (this.userData.secondaryAccount) {
      if (this.userData.habilitations != undefined) {
        habilitations = this.userData.habilitations.split(',')
        for (let hab of habilitations) {
          if (hab == habl) {
            isAllow = true
          }
        }
      }
    } else {
      isAllow = true
    }
    return isAllow
  }
  openSnackbar(message) {
    this.snackBar.open(message, 'CLOSE', {
      duration: 3000,
      horizontalPosition: 'right',
    });
  }
  getPalFee(amount, country: string): number {
    if (this.hasExceededFreeTransfers) {
      switch (country) {
        case 'GH':
          return (amount * 0.5) / 100;
        case 'BJ':
          return (amount * 1) / 100;
        default:
          return 0;
      }
    } else {
      return 0;
    }
  }
  resetForm() {
    this.form = this.fb.group({
      country: [''],
      dateFrom: [''],
      dateTo: [''],
      currency: [''],
      operator: [''],
      status: [''],
    });
    this.getTransactionsList();
  }
  getTransactionsList(filteredData = null) {
    this.isLoading = true;
    if (filteredData) {
      const transactions = filteredData;
      this.isLoading = false;

      this.transactionsData = transactions?.map((details) => {
        details.state = this.getStatusLabel(details.state);
        details.formatedDate = moment(details.created_at).fromNow();
        details.country = this.getCountryName(details.country);
        return details;
      });

      this.hasNoTransactions = transactions.length === 0;
      this.dataSource = new MatTableDataSource(this.transactionsData);
      //this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

    }
    else {
      this.transactionService
        .getUserTransactions(this.userData?.user_id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (transactions) => {
            transactions = filteredData ? filteredData : transactions;
            this.isLoading = false;
            this.transactionsData = transactions?.map((details) => {
              details.state = this.getStatusLabel(details.state);
              details.formatedDate = moment(details.created_at).fromNow();
              details.country = this.getCountryName(details.country);
              return details;
            });

            this.hasNoTransactions = transactions.length === 0;
            this.dataSource = new MatTableDataSource(this.transactionsData);
            //this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          },
          (error) => {
            this.isLoading = false;
            //console.error(error.message);
            this.showInternalServerErrorMessage()
          }
        );
    }
  }
  getisFormReady(): boolean {
    return true;
  }
  getCountryName(countryCode: string): string {
    const countryData = this.countries.find(
      (country) => country.code === countryCode
    );
    return countryData.name;
  }
  getSalesRepName(user_id) {
    const salesRep = this.users?.find((user) => user.user_id === user_id);
    if (salesRep) {
      return salesRep.full_name;
    } else {
      return 'N/A';
    }
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    //PAYOUTS
    this.MdataSource.paginator = this.paginator;
    this.MdataSource.sort = this.sort;

    //BANK TRANSFER
    //this.BtransactionsDataSource.paginator = this.paginator;
    //this.BtransactionsDataSource.sort = this.Bsort;

    //this.exchangeDataSource.paginator = this.Exchangepaginator
    //this.exchangeDataSource.sort = this.Exchangesort
  }













  search() {
    if (this.form.value['dateFrom'] && this.form.value['dateTo']) {
      const from = new Date(this.form.value['dateFrom'])
        .toISOString()
        .slice(0, 19)
        .replace('T', ' ');
      const to = new Date(this.form.value['dateTo'])
        .toISOString()
        .slice(0, 19)
        .replace('T', ' ');
      this.form.value['dateFrom'] = from;
      this.form.value['dateTo'] = to;
    }

    this.isLoading = true;
    this.transactionService
      .searchTransactions(
        this.credentials,
        this.userBusinessData?.user_id,
        this.form.value,
        'collections'
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => {
          this.isLoading = false;
          if (response['status'] === true) {


            this.transactions = response['data'];
            this.getTransactionsList(response['data']);
            this.dataSource = new MatTableDataSource();
            this.dataSource.data = this.transactions;
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;

            if (!this.dataSource.data.length) {
              this.hasError = true;
              this.errorMessage =
                'No data Found for the specified search criteria. Please try with different data';
            } else {
              this.hasError = false;
              this.errorMessage = '';
            }
          } else {
            this.hasError = true;
            this.errorMessage =
              'No data Found for the specified search criteria. Please try with different data';
          }
        },
        (error) => {
          this.isLoading = false;
          this.hasError = true;
          //this.errorMessage = 'No data Found for the specified search criteria. Please try with different data';

          this.showInternalServerErrorMessage()
        }
      );
  }
  viewOrderDetails(order: any) {
    this.router.navigate(['/dashboards/orders/order-details/' + order.id]);
  }
  getStatusLabel(status: string) {
    return this.statusLabels.find((label) => label.text === status);
  }
  deleteOrder(order: any) {
  }
  deleteCustomers(customers: Customer[]) {
    customers.forEach((c) => this.deleteOrder(c));
  }
  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.dataSource.filter = value;
  }
  toggleColumnVisibility(column, event) {

    if (!column.visible) {
      this.displayedColumns = this.displayedColumns.filter(col => column.property !== col)
    } else {
      let columns = [column.property, ...this.displayedColumns]
      this.displayedColumns = this.columns.map(
        ordoredCol => columns.find(col => col == ordoredCol.property)
      ).filter(col => col)
    }
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }
  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }
  onLabelChange(change: MatSelectChange, row: Customer) {
  }
  exportTable() {
    TableUtil.exportTableToExcel('ExampleMaterialTable');
  }
  exportNormalTable() {
    TableUtil.exportTableToExcel('ExampleNormalTable');
  }
  exportArray() {
    const onlyNameAndSymbolArr: Partial<any>[] = this.dataSource.data.map(
      (x) => ({
        name: x.name,
        status: x.status,
      })
    );
    TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, 'ExampleArray');
  }
  openFilterFormPopup() {
    const config: MatDialogConfig = {
      width: '600px'
    };
    const dialogRef = this.dialog.open(CollectionReportFilterFormComponent, config);
    dialogRef.afterClosed().subscribe(form => {
      this.form = form;
      this.search();
    })
  }
  selectOption(current: { name: string, value: string }) {
    this.menu.current = current;
  }




  getvisibleTransactionColumns() {
    return this.transactionColumns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }
  MgetData() {
    return of(aioTableData.map((customer) => new Customer(customer)));
  }
  gethasExceededFreeTransfers(): boolean {
    return this.merchantSummaryData?.totalTransactionsAmount > 560000;
  }
  MexportAsXlsx() {
    if (this.isAllowed("7")) {
      this.matTableExporter.exportTable('xlsx', {
        fileName: 'Transactions Report',
        sheet: 'report',
        Props: { Author: 'PAL Africa' },
      });
    } else {
      this.openSnackbar("You are not allowed");
    }

  }
  MrefreshData() {
    this.MgetTransactionsList();
  }
  MresetForm() {
    this.form = this.fb.group({
      status: [''],
      dateFrom: [''],
      dateTo: [''],
      currency: [''],
      operator: ['']
    });
    this.MgetTransactionsList();
  }
  MgetTransactionsList(filteredData = null) {
    this.isLoading = true;
    if (filteredData) {
      const transactions = filteredData;
      this.isLoading = false;
      this.MtransactionsData = transactions?.map((details) => {
        details.state = this.getStatusLabel(details.state);
        details.formatedDate = moment(details.created_at).fromNow();
        details.country = this.getCountryName(details.country);
        return details;
      });
      this.hasNoTransactions = transactions.length === 0 ? true : false;
      this.MdataSource = new MatTableDataSource(this.MtransactionsData);
      this.MdataSource.paginator = this.paginator;
      this.MdataSource.sort = this.sort;
    } else {
      this.transactionService
        .getUserTransactions(this.userData?.user_id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (transactions) => {
            this.isLoading = false;
            this.MtransactionsData = transactions?.map((details) => {
              details.state = this.getStatusLabel(details.state);
              details.formatedDate = moment(details.created_at).fromNow();
              details.country = this.MgetCountryName(details.country);
              return details;
            });
            this.hasNoTransactions = transactions.length === 0 ? true : false;
            this.MdataSource = new MatTableDataSource(this.MtransactionsData);
            this.MdataSource.paginator = this.paginator;
            this.MdataSource.sort = this.sort;
          },
          (error) => {
            this.isLoading = false;
            console.error(error.message);
            this.showInternalServerErrorMessage()
          }
        );
    }
  }
  get isFormReady(): boolean {
    return true;
  }
  MgetCountryName(countryCode: string): string {
    const countryData = this.countries.find(
      (country) => country.code === countryCode
    );
    return countryData.name;
  }
  MgetSalesRepName(user_id) {
    const salesRep = this.users?.find((user) => user.user_id === user_id);
    if (salesRep) {
      return salesRep.full_name;
    } else {
      return 'N/A';
    }
  }
  Msearch() {
    if (this.form.value['dateFrom'] && this.form.value['dateTo']) {
      const from = new Date(this.form.value['dateFrom'])
        .toISOString()
        .slice(0, 19)
        .replace('T', ' ');
      const to = new Date(this.form.value['dateTo'])
        .toISOString()
        .slice(0, 19)
        .replace('T', ' ');
      this.form.value['dateFrom'] = from;
      this.form.value['dateTo'] = to;
    }

    this.isLoading = true;
    this.transactionService
      .searchTransactions(
        this.credentials,
        this.userBusinessData?.user_id,
        this.form.value,
        'mobile_transfers'
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => {
          this.isLoading = false;
          if (response['status'] === true) {
            this.Mtransactions = response['data'];
            this.MgetTransactionsList(response['data']);
            this.MdataSource = new MatTableDataSource();
            this.MdataSource.data = this.Mtransactions;
            this.MdataSource.paginator = this.paginator;
            this.MdataSource.sort = this.sort;
            if (!this.MdataSource.data.length) {
              this.hasError = true;
              this.errorMessage =
                'No data Found for the specified search criteria. Please try with different data';
            } else {
              this.hasError = false;
              this.errorMessage = '';
            }
          } else {
            this.hasError = true;
            this.errorMessage =
              'No data Found for the specified search criteria. Please try with different data';
          }
        },
        (erro) => {
          this.isLoading = false;
          this.hasError = true;
          this.errorMessage =
            'No data Found for the specified search criteria. Please try with different data';
        }
      );
  }
  MviewOrderDetails(order: any) {
    this.router.navigate(['/dashboards/orders/order-details/' + order.id]);
  }
  MgetStatusLabel(status: string) {
    return this.statusLabels.find((label) => label.text === status);
  }
  MdeleteOrder(order: any) {
  }
  MdeleteCustomers(customers: Customer[]) {
    customers.forEach((c) => this.deleteOrder(c));
  }
  MonFilterChange(value: string) {
    if (!this.MdataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.MdataSource.filter = value;
    this.MdataSource.paginator = this.paginator;
    this.MdataSource.sort = this.sort;

  }
  MonFilterChangeBeneficiary(value: string) {
    if (!this.MdataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.MbeneficiariesDataSource.filter = value;
  }
  MtoggleColumnVisibility(column, event) {

    if (!column.visible) {
      this.MdisplayedColumns = this.MdisplayedColumns.filter(col => column.property !== col)
    } else {
      let columns = [column.property, ...this.displayedColumns]
      this.MdisplayedColumns = this.transactionColumns.map(
        ordoredCol => columns.find(col => col == ordoredCol.property)
      ).filter(col => col);
    }
  }
  MisAllSelected() {
    const numSelected = this.Mselection.selected.length;
    const numRows = this.MdataSource.data.length;
    return numSelected === numRows;
  }
  MmasterToggle() {
    this.isAllSelected()
      ? this.Mselection.clear()
      : this.MdataSource.data.forEach((row) => this.Mselection.select(row));
  }
  MtrackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }
  MonLabelChange(change: MatSelectChange, row: Customer) {
  }
  MexportTable() {
    TableUtil.exportTableToExcel('ExampleMaterialTable');
  }
  MexportNormalTable() {
    TableUtil.exportTableToExcel('ExampleNormalTable');
  }
  MexportArray() {
    const onlyNameAndSymbolArr: Partial<any>[] = this.MdataSource.data.map(
      (x) => ({
        name: x.name,
        status: x.status,
      })
    );
    TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, 'ExampleArray');
  }
  MopenFilterFormPopup() {
    const config: MatDialogConfig = {
      width: '600px'
    };
    const dialogRef = this.dialog.open(TransactionReportFilterFormComponent, config);
    dialogRef.afterClosed().subscribe(form => {
      this.form = form;
      this.search();
    })
  }
  MopenDisburseMobilePopup() {

    if (this.isAllowed('6') || this.isAllowed('1')) {
      if (this.userData.hasBusiness || !!this.userBusinessData) {
        if (this.userBusinessData?.status === 'pending') {
          this.dialog.open(PendingBusinessModalComponent);
        } else if (this.userBusinessData?.status === 'suspended') {
          this.dialog.open(SuspendedBusinessModalComponent);
        } else {
          this.router.navigate(['/dashboards/make-mobile-disbursment']).then();
        }
        this.MisOpenDisbursePopup = false;
      } else {
        this.dialog.open(NoBusinessModalComponent);
      }
    } else {
      this.openSnackbar("You are not allowed");
    }




  }
  closeDisburseMenu() {
    this.MisOpenDisbursePopup = false;
  }
  toggleDisbursePopup() {
    this.MisOpenDisbursePopup = !this.MisOpenDisbursePopup;
  }
  MselectOption(current: { name: string, value: string }) {
    this.menu.current = current;
  }
  deleteMobileBeneficiary(id: number) {
    this.beneficiariesService.deleteMobileBeneficiary(this.credentials, { user_id: this.userData?.user_id, id: id })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => {
          if (response.status == true) {
            this.openSnackbar(response.message);
            this.getBeneficiariesList();
          } else {
            if (response.status == false) {
              this.openSnackbar(response.message);
            } else {
              this.showInternalServerErrorMessage()
            }
          }
        },
        (error) => {
          this.isLoading = false;
          //console.error(error.message);
          this.showInternalServerErrorMessage()
        }
      );
  }
  getBeneficiariesList(filteredData = null) {
    this.isLoading = true;

    this.beneficiariesService.getMobileBeneficiaries(this.userData?.user_id, this.credentials)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (beneficiaries) => {
          this.MbeneficiariesData = beneficiaries?.map((details) => {
            details.formatedDate = moment(details.created_at).fromNow();

            details.country = this.getCountryName(details.country);

            return details;
          });
          this.isLoading = false;
          this.MbeneficiariesDataSource = new MatTableDataSource(this.MbeneficiariesData);
          this.MbeneficiariesDataSource.paginator = this.paginator;
          this.MbeneficiariesDataSource.sort = this.sort;
          this.MnumberOfBeneficiaries = this.MbeneficiariesDataSource.data.length;
        },
        (error) => {
          this.isLoading = false;
          console.error(error.message);
        }
      );

  }
  selectMobileBeneficiary(id) {
    this.MbeneficiaryOperationData.selectedMobileBeneficiary = id;
  }
  openAddBeneficiaryPopup() {
    const config: MatDialogConfig = {
      width: '600px',
    };
    const dialogRef = this.dialog.open(AddMobileBeneficiaryComponent, config);
    dialogRef.afterClosed().subscribe(
      (response) => {
        if (response) {
          if (response.status == true) {
            this.openSnackbar(response.message);
            this.getBeneficiariesList();
          } else {
            if (response.status == false) {
              this.openSnackbar(response.message);
            } else {
              this.showInternalServerErrorMessage()
            }
          }
        }
      }),
      (error) => {
        this.showInternalServerErrorMessage()
      }
  }






  //BANK TRANSACTIONS
  BresetForm() {
    this.form = this.fb.group({
      country: [''],
      dateFrom: [''],
      dateTo: [''],
      currency: [''],
      status: [''],
    });
    this.BgetTransactionsList();
  }

  BgetTransactionsList(filteredData = null) {
    this.BisLoading = true;
    // userId = 'a9twRK1JpPPQDrB6hNvfAr2ju682' this is a test User_uid
    if (filteredData) {
      const transactions = filteredData;
      this.BisLoading = false;
      this.BtransactionsData = transactions?.map((details) => {
        details.state = this.BgetStatusLabel(details.state);
        // details.palFee = this.getPalFee(details.amount, details.country);
        details.formatedDate = moment(details.created_at).fromNow();
        // details.country = this.getCountryName(details.country);
        return details;
      });

      this.BhasNoTransactions = transactions.length === 0;
      this.BtransactionsDataSource = new MatTableDataSource(this.BtransactionsData);
      this.BtransactionsDataSource.paginator = this.paginator;
      this.BtransactionsDataSource.sort = this.sort;
    } else {
      this.transactionService
        .getUserTransactions(this.userData?.user_id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (transactions) => {
            this.BisLoading = false;
            this.BtransactionsData = transactions?.map((details) => {
              details.state = this.getStatusLabel(details.state);
              // details.palFee = this.getPalFee(details.amount, details.country);
              details.formatedDate = moment(details.created_at).fromNow();
              details.country = this.BgetCountryName(details.country);
              return details;
            });

            this.BhasNoTransactions = transactions.length === 0;
            this.BtransactionsDataSource = new MatTableDataSource(this.BtransactionsData);
            this.BtransactionsDataSource.paginator = this.Bpaginator;
            this.BtransactionsDataSource.sort = this.Bsort;
          },
          (error) => {
            this.BisLoading = false;
            console.error(error.message);
          }
        );
    }
  }

  get BisFormReady(): boolean {
    return true;
  }

  BgetCountryName(countryCode: string): string {
    const countryData = this.countries.find(
      (country) => country.code === countryCode
    );
    return countryData?.name;
  }

  BgetBankName(code: string) {
    const bank = this.banksData.find(
      (data) => data.code === code
    );
    return bank.name || 'N/A';
  }

  BsearchTransfers() {
    if (this.Bform.value['dateFrom'] && this.Bform.value['dateTo']) {
      const from = new Date(this.Bform.value['dateFrom'])
        .toISOString()
        .slice(0, 19)
        .replace('T', ' ');
      const to = new Date(this.Bform.value['dateTo'])
        .toISOString()
        .slice(0, 19)
        .replace('T', ' ');
      // this.form.get("dateFrom").setValue(from);
      // this.form.get("dateTo").setValue(to);
      this.Bform.value['dateFrom'] = from;
      this.Bform.value['dateTo'] = to;
    }

    this.BisLoading = true;
    this.transactionService
      .searchTransactions(
        this.credentials,
        this.userBusinessData?.user_id,
        this.Bform.value,
        'bank_transfers'
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => {
          this.BisLoading = false;
          if (response['status'] === true) {
            this.Btransactions = response['data'];
            this.BgetTransactionsList(response['data']);
            this.BtransactionsDataSource = new MatTableDataSource();
            this.BtransactionsDataSource.data = this.Btransactions;
            this.BtransactionsDataSource.paginator = this.Bpaginator;
            this.BtransactionsDataSource.sort = this.Bsort;
            if (!this.BtransactionsDataSource.data.length) {
              this.BhasError = true;
              this.BerrorMessage =
                'No data Found for the specified search criteria. Please try with different data';
            } else {
              this.BhasError = false;
              this.BerrorMessage = '';
            }
          } else {
            this.BhasError = true;
            this.BerrorMessage =
              'No data Found for the specified search criteria. Please try with different data';
          }
        },
        (erro) => {
          this.BisLoading = false;
          this.BhasError = true;
          this.BerrorMessage =
            'No data Found for the specified search criteria. Please try with different data';
        }
      );
  }

  BgetStatusLabel(status: string) {
    return this.BstatusLabels.find((label) => label.text === status);
  }

  BonFilterChange(value: string) {
    if (!this.BtransactionsDataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.BtransactionsDataSource.filter = value;
  }
  /*
  BonFilterChangeBeneficiary(value: string) {
    if (!this.BbeneficiariesDataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.beneficiariesDataSource.filter = value;
  }
  */

  BtoggleColumnVisibilityTrasactions(column, event) {

    if (!column.visible) {
      this.BtransactionsDisplayedColumns = this.BtransactionsDisplayedColumns.filter(col => column.property !== col)
    } else {
      let columns = [column.property, ...this.BtransactionsDisplayedColumns]
      this.BtransactionsDisplayedColumns = this.BtransfersColumns.map(
        ordoredCol => columns.find(col => col == ordoredCol.property)
      ).filter(col => col)
    }
  }
  BgetFlag(country) {
    switch (country.toLowerCase()) {

      case "benin":
        return "<img src='assets/contryFlg/flags/1/bj.png'>"
      case "ghana":
        return "<img src='assets/contryFlg/flags/1/gh.png'>"
      case "togo":
        return "<img src='assets/contryFlg/flags/1/tg.png'>"
      case "nigeria":
        return "<img src='assets/contryFlg/flags/1/ng.png'>"
      case "cote d'ivoire":
        return "<img src='assets/contryFlg/flags/1/ci.png'>"
      case "senegal":
        return "<img src='assets/contryFlg/flags/1/se.png'>"
      case "mali":
        return "<img src='assets/contryFlg/flags/1/ml.png'>"
      case "niger":
        return "<img src='assets/contryFlg/flags/1/ne.png'>"
      case "côte d'ivoire":
        return "<img src='assets/contryFlg/flags/1/ci.png'>"
      case "guinea-bissau":
        return "<img src='assets/contryFlg/flags/1/gw.png'>"
      case "burkina faso":
        return "<img src='assets/contryFlg/flags/1/bf.png'>"
      default:
        return ''

    }

  }


  BdeleteOrder(selected: any[]) {

  }

  BopenDisburseMobilePopup() {
    // this.isOpenDisbursePopup = !this.isOpenDisbursePopup;

    const config: MatDialogConfig = {
      width: '600px'
    };
    const dialogRef = this.dialog.open(DisburseCashComponent, config);
  }

  BopenFilterFormPopup() {
    const config: MatDialogConfig = {
      width: '600px'
    };
    const dialogRef = this.dialog.open(FilterFormComponent, config);
    dialogRef.afterClosed().subscribe(form => {
      this.form = form;
      this.BsearchTransfers();
    })
  }



  BselectOption(current: { name: string, value: string }) {
    this.menu.current = current;
  }


  BdeleteBankBeneficiary(id: number) {
    this.beneficiariesService.deleteBankBeneficiary(this.credentials, { user_id: this.userData?.user_id, id: id })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => {
          if (response.status == true) {
            this.openSnackbar(response.message);
            this.getBeneficiariesList();
          } else {
            if (response.status == false) {
              this.openSnackbar(response.message);
            } else {
              this.showInternalServerErrorMessage()
            }
          }
        },
        (error) => {
          this.isLoading = false;
          this.showInternalServerErrorMessage()
        }
      );
  }
  showInternalServerErrorMessage() {
    this.errorMessage = "Error. Please retry again later."
    this.openSnackbar("Error. Please retry again later.");
  }

  get BtransfersVisibleColumns() {
    return this.BtransfersColumns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }

  onCreateTransfer() {

    if (this.isAllowed('6') || this.isAllowed('1')) { 
      if (this.userData.hasBusiness || !!this.userBusinessData) {
        if (this.userBusinessData?.status === 'pending') {
          this.dialog.open(PendingBusinessModalComponent);
        } else if (this.userBusinessData?.status === 'suspended') {
          this.dialog.open(SuspendedBusinessModalComponent);
        } else {
          this.router.navigate(['/dashboards/make-transfer']).then();
        }
        this.isOpenDisbursePopup = false;
      } else {
        this.dialog.open(NoBusinessModalComponent);
      }
    } else {
      this.openSnackbar("You are not allowed");
    }












  }




  getUserExchange() {
    this.exchangeService.getUserExchanges(this.credentials, this.userData['user_id'])
      .subscribe(
        (response) => {
          if (response['status']) {
            this.userExchanges = response['data'].reverse()
            this.exchangeDataSource = new MatTableDataSource(this.userExchanges);
          }
        },
        (error) => {

        }
      )

  }
  formattedDate(created_at): string {
    return this.datePipe.transform(created_at, 'dd-MM-yyyy HH:mm:ss');
    // Cela renverra la date formatée selon 'jour/mois/année heure:minute:seconde'
  }




















}
