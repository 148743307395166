 

<div mat-dialog-content >
    <form [formGroup]="form" *ngIf="availableCountries.length">
        <div>



            <div>
                <div>
                    <span >{{"Select the recipient's country" | translate}} </span>
                </div>
                <mat-form-field appearance="outline" style="width: 640px;">
                    <mat-select placeholder="Select a Country" formControlName="beneficiary_country">
                        <mat-option *ngFor="let country of availableCountries" [value]="country.code">

                            <div style="display: flex; align-items: center;">
                                <img src="assets/contryFlg/flags/1/{{country.code | lowercase}}.png"
                                    style="height: 20px; width: auto; margin-right: 10px;">
                                {{ country.label }}
                            </div>

                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="formControls?.beneficiary_country?.errors?.required">{{"This field is required"|
                        translate}} </mat-error>
                </mat-form-field>
            </div>





            <div *ngIf="!isSwiftCodeNeeded && !isFetchingBanksData" class="input-row">
                <div >
                    <div >
                        <span class="">{{"Bank name"| translate}} </span>
                    </div>
                    <mat-form-field appearance="outline" style="width: 640px;">
                        <mat-select placeholder="Select a Bank" formControlName="bank_code" class="flex-auto w-100">
                            <mat-option *ngFor="let bank of banksData" [value]="bank?.code ? bank?.code : bank?.id">
                                {{ bank.name }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formControls?.bank_name?.errors?.required">{{"This field is required"|
                            translate}}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <mat-spinner style="font-size: small;" *ngIf="!isSwiftCodeNeeded && isFetchingBanksData" color="primary">
            </mat-spinner>






            <div *ngIf="!isSwiftCodeNeeded && !isFetchingBankBranches && isBankBranchNeeded"  >
                <div>
                    <div>
                        <span class="">{{"Bank Branch" | translate}} </span>
                    </div>
                    <mat-form-field appearance="outline" style="width: 640px;">
                        <mat-select placeholder="Select a Branch" formControlName="bank_branch">
                            <mat-option *ngFor="let bank of bankBranches" [value]="bank.branch_name">
                                {{ bank.branch_name }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formControls?.bank_branch?.errors?.required">{{"This field is required"|
                            translate}}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <mat-spinner style="font-size: small;"
                *ngIf="!isSwiftCodeNeeded &&  isFetchingBankBranches && isBankBranchNeeded" color="primary">
            </mat-spinner>



            <div *ngIf="isSwiftCodeNeeded">
                <div>
                    <div class="flex input-label">
                        <span class="">{{"Bank Name"| translate}} </span>
                    </div>
                    <mat-form-field appearance="outline" style="width: 640px;">
                        <input matInput type="text" formControlName="bank_name" />
                        <mat-error *ngIf="formControls?.bank_name?.errors?.required">{{"This field is required"|
                            translate}}</mat-error>
                    </mat-form-field>
                </div>
            </div>





            <div *ngIf="isSwiftCodeNeeded">
                <div class="customInput">
                    <div class="flex input-label">
                        <span class="">{{"Swift Code"| translate}} </span>
                    </div>
                    <mat-form-field appearance="outline" style="width: 640px;">
                        <input matInput type="text" formControlName="swift_code" />
                        <mat-error *ngIf="formControls?.swift_code?.errors?.required">{{"This field is required"|
                            translate}}</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div>
                <div class="customInput">
                    <div>
                        <span class="">{{"Account number"| translate}} </span>
                    </div>
                    <mat-form-field appearance="outline" style="width: 640px;">
                        <!--placeholder="Ex: BJ0670150550509788803290" -->
                        <input [maxlength]="maxAccountNumberLength" matInput type="text"
                            formControlName="account_number" />
                        <mat-error *ngIf="formControls?.account_number?.errors?.required">{{"This field is required"|
                            translate}}</mat-error>

                        <mat-error *ngIf="formControls?.account_number?.errors?.pattern">
                            {{"This field must contain exactly"| translate}} {{accountNumberLength}} {{"characters"|
                            translate}}
                        </mat-error> 
                    </mat-form-field>
                    <span class="confirm-form-title flex-auto" *ngIf="isLoadingBankAccountName">
                        <mat-spinner color="primary"></mat-spinner> 
                    </span>
                    <span class="confirm-form-title flex-auto" *ngIf="!isLoadingBankAccountName"> 
                         {{bankAccountName}} 
                         <br><br>
                    </span> 
                </div>
            </div>

           <!-- <div>
                <div>
                    <div class="flex input-label">
                        <span class="">{{"Account name"| translate}} </span>
                    </div>
                    <mat-form-field appearance="outline" style="width: 640px;">
                        <input matInput type="text" formControlName="beneficiary_name" />
                        <mat-error *ngIf="formControls?.beneficiary_name?.errors?.required">{{"This field is required"|
                            translate}}</mat-error>
                    </mat-form-field>
                </div>
            </div> -->
            <div >
                <div class="flex input-label">
                    <span class="">Email</span>
                </div>
                <mat-form-field appearance="outline" style="width: 640px;">
                    <input matInput type="email" placeholder="@" formControlName="email" />

                </mat-form-field>
            </div>

            <div class="2xl:alert-error error-bloc" *ngIf="errorMessage && !form.valid">
                <span>{{ errorMessage| translate }}</span>
            </div>
        </div>


    </form>
</div> 

<!--
<div mat-dialog-actions class="btn-bloc -mx-3"> 
    <button color="primary" mat-button type="button" class="invalid-send-button" style="width: 640px;" (click)="submitAction()" [disabled]="isProcessing || form.invalid" [ngClass]="(isProcessing || form.invalid )? 'invalid-send-button': 'valid-send-button'">
    {{ isProcessing === true? 'Processing...' :'Save bank beneficiary' | translate}}
    </button>
 
</div>-->
<br> <br> 
<div style="cursor: pointer;">  
    <button color="primary" mat-button type="button" class="invalid-send-button" style="width: 640px;" (click)="submitAction()" [disabled]="isProcessing || form.invalid" [ngClass]="(isProcessing || form.invalid )? 'invalid-send-button': 'valid-send-button'">
        {{ isProcessing === true? 'Processing...' :'Save bank beneficiary' | translate}}
    </button>
</div>