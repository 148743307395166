<mat-divider class="-mx-6 text-border toolbar-mat-divider"></mat-divider>

<vex-page-layout>

    <vex-page-layout-content [class.container]="layoutCtrl.value === 'boxed'"
        [class.px-gutter]="layoutCtrl.value === 'fullwidth'" class="-mt-6">
        <!--[@stagger]="true"-->
        <div class="w-full" vexContainer>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="30px">
                <!--@fadeInUp-->
                <div class="card" fxFlex="auto">
                    <div *ngIf="!isFormReady" class="card overflow-auto -mt-16">
                        <div style="
                display: flex;
                justify-content: center;
                align-items: center;
                background: white;">
                            <mat-spinner color="primary"> </mat-spinner>
                        </div>
                    </div>
                    <div class="table-btns-bar mt-7 ml-0 pl-0">
                        <!--<button app mat-button class="px-0" (click)="MopenFilterFormPopup()">
                            {{"Filters Applied" |translate}} <mat-icon [icIcon]="icFilterList" class="ml-1 mb-1"
                                color="black"></mat-icon>
                        </button>-->
                        <div class="bg-card rounded-full border px-4 me-5" fxFlex="250px" fxFlex.lt-md="auto" fxLayout="row" fxLayoutAlign="start center">
                            <ic-icon [icIcon]="icSearch" size="28px"></ic-icon>
                            <input [formControl]="MsearchCtrl"
                                class="px-4 py-3 border-0 outline-none w-full bg-transparent"
                                placeholder="{{'Enter trans Ref to find...' }}" type="search">
                        </div> &nbsp; &nbsp; &nbsp;
                        <button (click)="exportAsXlsx()" class="px-0" mat-button>
                            {{"Download" |translate}} <mat-icon class="ml-1 mb-1" color="black"
                                [icIcon]="icCloudDownload"></mat-icon>
                        </button>
                        <button mat-button type="submit" class="disburs" [routerLink]="['/dashboards/make-transfer-c']">
                            <mat-icon class="mr-3 text-white" matPrefix>add_circle_outlined</mat-icon>
                            {{"Make Transfer" |translate}}
                        </button>
                    </div>
                    <div class="mt-3">
                        <div class="bg-card rounded-full border px-4" fxFlex="250px" fxFlex.lt-md="auto" fxLayout="row"
                            fxLayoutAlign="start center">
                            <!--<ic-icon [icIcon]="icSearch" size="28px"></ic-icon>
                            <input [formControl]="MsearchCtrl"
                                class="px-4 py-3 border-0 outline-none w-full bg-transparent"
                                placeholder="{{'Enter trans Ref to find...' }}" type="search">-->
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <br />
        <br />
        <br />
        <br />

        <div class="card overflow-auto -mt-16">
            <div *ngIf="isLoading" class="card overflow-auto -mt-16">
                <div style=" display: flex; justify-content: center;  align-items: center; background: white; ">
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                    <mat-spinner color="primary"> </mat-spinner>
                </div>
            </div>
            <!--@stagger-->
            <table *ngIf="!isLoading" mat-table [dataSource]="dataSource" class="w-full mat-elevation-z" matSort  matTableExporter #exporter="matTableExporter">

                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef>Reference</th>
                    <td mat-cell *matCellDef="let element">{{ element.reference }}</td>
                </ng-container>

                <ng-container matColumnDef="type_transaction">
                    <th mat-header-cell *matHeaderCellDef>Transaction</th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.type_transaction == 'pal_transfer'"> PAL Transfer</span>
                        <span *ngIf="element.type_transaction == 'mobile_transfer'"> Mobile Money</span>
                        <span *ngIf="element.type_transaction == 'bank_transfer'"> Bank Transfer</span>
                        <span *ngIf="element.type_transaction == 'exchange_transfer'"> Swap</span>
                        <span *ngIf="element.type_transaction == 'recharge_transfer'"> Deposit</span>
                    </td>
                </ng-container>
 

                <ng-container matColumnDef="created_at">
                    <th mat-header-cell *matHeaderCellDef>{{"Date & Time" |translate}} </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.created_at }}
                    </td> 
                </ng-container>
                <ng-container matColumnDef="country">
                    <th mat-header-cell *matHeaderCellDef>{{"Country" |translate}} </th>
                    <td mat-cell *matCellDef="let element">{{ element.country }}</td>
                </ng-container>

 
                <ng-container matColumnDef="wallet">
                    <th mat-header-cell *matHeaderCellDef>{{"Wallet number" |translate}} </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element[element.type_transaction]?.phone_no">
                            {{element[element.type_transaction]?.phone_no}} </span>

                            <span  *ngIf="element.type_transaction == 'bank_transfer'">
                                {{element[element.type_transaction]?.account_number}} 
                            </span>


                        <span *ngIf=" element.type_transaction != 'bank_transfer' && element.type_transaction != 'pal_transfer' && element[element.type_transaction]?.phone_no == undefined"> NA </span>
                        <span *ngIf=" element.type_transaction == 'pal_transfer'"> {{element[element.type_transaction]?.designation}} </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="provider">
                    <th mat-header-cell *matHeaderCellDef>{{"Network" |translate}} </th>
                    <td mat-cell *matCellDef="let element">
                        <span> {{element[element.type_transaction].operator}} </span>
                    </td>
                </ng-container>
 

                <ng-container matColumnDef="charges">
                    <th mat-header-cell *matHeaderCellDef>{{"Charges" |translate}} </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element[element.type_transaction]?.charges">
                            {{element[element.type_transaction].charges}} </span>
                        <span *ngIf="element[element.type_transaction]?.charges == undefined"> 0 </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="currency">
                    <th mat-header-cell *matHeaderCellDef>{{"Currency"|translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element[element.type_transaction]?.currency"> {{element[element.type_transaction]?.currency}} </span>
                        <span *ngIf="element[element.type_transaction]?.start_currency"> {{element[element.type_transaction]?.start_currency}} </span>

                    </td>
                </ng-container>


                <ng-container matColumnDef="amount">
                    <th mat-header-cell *matHeaderCellDef>{{"Amount" |translate}} </th>
                    <td mat-cell *matCellDef="let element">
                        <span> <span> {{element[element.type_transaction]?.amount}} </span> </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="business_start_balance">
                    <th mat-header-cell *matHeaderCellDef>{{"Start Balance" |translate}} </th>
                    <td mat-cell *matCellDef="let element">
                          <span> {{element[element.type_transaction]?.business_start_balance}} </span> 
                          <span> {{element[element.type_transaction]?.business_start_balance_start_currency}} </span> <!--SI C'EST UNE EXCHANGE-->

                        
                    </td>
                </ng-container>
                <ng-container matColumnDef="business_end_balance">
                    <th mat-header-cell *matHeaderCellDef>{{"End Balance" |translate}} </th>
                    <td mat-cell *matCellDef="let element">
                        <span> <span> {{element[element.type_transaction]?.business_end_balance}} </span> </span>
                        <span> {{element[element.type_transaction]?.business_end_balance_start_currency}} </span> <!--SI C'EST UNE EXCHANGE-->
                    </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>{{"Status" |translate}} </th>
                    <td mat-cell *matCellDef="let element">
                        <span
                            [ngClass]="element[element.type_transaction]?.status?.cssClasses">{{element[element.type_transaction]?.status?.text}}</span>
                    </td>
                </ng-container> 
                <!--<ng-container matColumnDef="filter">
                    <th *matHeaderCellDef>
                        <button [matMenuTriggerFor]="columnFilterMenu" class="" fxFlex="none" mat-icon-button
                            matTooltip="Filter Columns" type="button">
                            <mat-icon [icIcon]="icFilterList" color="primary"></mat-icon>
                        </button>
                    </th>
                    <td mat-cell *matCellDef="let element"></td>
                </ng-container>-->
                <tr mat-header-row *matHeaderRowDef="MdisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: MdisplayedColumns"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">
                        {{
                        errorMessage || "No Transaction Data Found Or Matching The filter" |translate
                        }}
                    </td>
                </tr>
            </table>

            <mat-paginator [pageSizeOptions]="MpageSizeOptions" [pageSize]="MpageSize"
                class="sticky left-0"></mat-paginator>
        </div>
    </vex-page-layout-content>
    <mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
        <ng-container *ngFor="let column of transactionColumns">
            <button class="checkbox-item mat-menu-item" *ngIf="column.property!='filter'">
                <mat-checkbox (change)="MtoggleColumnVisibility(column, $event)" [(ngModel)]="column.visible"
                    [checked]="column.visible" color="primary" name="columnsFilter" ngDefaultControl>
                    {{ column.label }}
                </mat-checkbox>
            </button>
        </ng-container>
    </mat-menu>
    <mat-menu #menuMakeTransfer="matMenu" id="disburse-popup" (close)="closeDisburseMenu()"
        (closed)="closeDisburseMenu()"
        class="z-10 w-50 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600 block"
        style="position: absolute; inset: 0 auto auto 0; margin: 0; transform: translate(23px, 70px);"
        data-popper-reference-hidden="" data-popper-escaped="" data-popper-placement="bottom">

        <ul class="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownInformationButton">
            <li (click)="MopenDisburseMobilePopup()">
                <a mat-button class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                    <span class="icon menu-item-icon">

                    </span>
                    <div class="disburse-content">
                        <h4>
                            {{"Disburse to Mobile wallet" |translate}}
                        </h4>
                        <p>{{"Send to mobile wallet or merchant account" |translate}} </p>
                    </div>
                </a>
            </li>

            <mat-divider></mat-divider>

            <li>
                <a [routerLink]="['/dashboards/make-transfer']" mat-button
                    class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                    <span class="icon menu-item-icon">
                    </span>
                    <div class="disburse-content">
                        <h4>
                            {{"Disburse to Bank account" |translate }}
                        </h4>
                        <p>{{"Send to bank account" |translate }} </p>
                    </div>
                </a>
            </li>

            <mat-divider></mat-divider>

            <li>
                <a [routerLink]="['/dashboards/bulk-disbursement']" (click)="toggleDisbursePopup()" mat-button
                    class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white ">
                    <span class="icon menu-item-icon">
                        <mat-icon class="" [icIcon]="icon_peapole"></mat-icon>
                    </span>
                    <div class=" disburse-content">
                        <h4>
                            {{"Bulk disbursement" |translate }}
                        </h4>
                        <p>{{"Send to multiple wallet at once" |translate }} </p>
                    </div>
                </a>
            </li>
        </ul>
    </mat-menu>



</vex-page-layout>