import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { USER_SESSION_KEY } from 'src/app/Models/constants';

@Component({
  selector: 'vex-liveness-modal',
  templateUrl: './liveness-modal.component.html',
  styleUrls: ['./liveness-modal.component.scss']
})
export class LivenessModalComponent implements OnInit {


  livenessUrl : any 
  sessionData : any 
  userData : any 
  sanitizedLivenessUrl: SafeResourceUrl | null = null;

  constructor( private sanitizer: DomSanitizer ,

    private router: Router,
    private dialog: MatDialog,

  ) {

    const sessionData = localStorage.getItem(USER_SESSION_KEY);
    if (sessionData) {
      this.sessionData = JSON.parse(sessionData);
      this.userData = JSON.parse(sessionData); 
      this.livenessUrl =  "https://liveness-check.pals.africa/?user_id="+this.userData.user_id

      this.sanitizedLivenessUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.livenessUrl);
    }


   }

  ngOnInit(): void {
    window.addEventListener('message', this.handleLivenessResponse.bind(this));
  }

  handleLivenessResponse(event: MessageEvent) {
    if (event.origin !== 'https://liveness-check.pals.africa') {
      // Vérifier que le message provient bien de la bonne origine pour des raisons de sécurité
      //console.warn('Origine non autorisée', event.origin);
      return;
    }

    // Traiter la réponse reçue
    //console.log('Réponse de liveness:', event.data);
    this.dialog.closeAll();
    this.router.navigate(['/dashboards/analytics']);

    this.logout()
    // Ferme l'iframe si le processus est terminé
    //this.afficherLiveness = false;

  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['/auth/login']);
  }

}
