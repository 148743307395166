import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions, } from '@angular/material/form-field';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';

import icEdit from '@iconify/icons-ic/twotone-edit';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icSearch from '@iconify/icons-ic/twotone-search';
import icAdd from '@iconify/icons-ic/twotone-add';
import icFilterList from '@iconify/icons-ic/twotone-filter-list';
import icPhone from '@iconify/icons-ic/twotone-phone';
import icMail from '@iconify/icons-ic/twotone-mail';
import icMap from '@iconify/icons-ic/twotone-map';
import icMoreHoriz from '@iconify/icons-ic/twotone-more-horiz';
import icFolder from '@iconify/icons-ic/twotone-folder';
import icDateRange from '@iconify/icons-ic/twotone-date-range';
import icPerson from '@iconify/icons-ic/twotone-person';
import icRefresh from '@iconify/icons-ic/twotone-refresh';
import icBook from '@iconify/icons-ic/twotone-book';
import icCloudDownload from '@iconify/icons-ic/twotone-cloud-download';
import icAttachMoney from '@iconify/icons-ic/twotone-attach-money';
import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { MatTableExporterDirective } from 'mat-table-exporter';
import moment from 'moment';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import {
  BUSINESS_DATA_KEY,
  COUNTRIES,
  SUMMARY_DATA_KEY,
  TRANSACTION_TABLE_LABELS,
  USER_SESSION_KEY,
} from 'src/app/Models/constants';
import { SummaryData } from 'src/app/Models/models.interface';
import { Customer } from 'src/app/pages/apps/aio-table/interfaces/customer.model';
import { AuthserviceService } from 'src/app/services/authservice.service';
import { TransactionsService } from 'src/app/services/transactions.service';
import { aioTableData, aioTableLabels } from 'src/static-data/aio-table-data';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';  
import { TranslateService } from '@ngx-translate/core';




import peopole from '@iconify/icons-ic/people-outline';
import { MatMenu } from '@angular/material/menu';   
import { ExchangeService } from 'src/app/services/exchange.service';
import { error } from '@angular/compiler/src/util';
import { FilterFormComponent } from '../../reports/all-transfers/filter-form/filter-form.component';
import { DisburseCashComponent } from '../../disburse-cash/disburse-cash.component';
import { PendingBusinessModalComponent } from '../../pending-business-modal/pending-business-modal.component';
import { SuspendedBusinessModalComponent } from '../../suspended-business-modal/suspended-business-modal.component';
import { NoBusinessModalComponent } from '../../no-business-modal/no-business-modal.component';
@Component({
  selector: 'vex-bank-transfers',
  templateUrl: './bank-transfers.component.html',
  styleUrls: ['./bank-transfers.component.scss']
})
export class BankTransfersComponent implements OnInit {

  // BANK TRANSACTIONS
  layoutCtrl = new FormControl('boxed');
  subject$: ReplaySubject<Customer[]> = new ReplaySubject<Customer[]>(1);
  Bdata$: Observable<Customer[]> = this.subject$.asObservable();
  unsubscribe$ = new Subject();
  @Input()
  BtransfersColumns: TableColumn<Customer>[] = [
    { label: 'ID', property: 'reference', type: 'text', visible: true },
    {
      label: 'date',
      property: 'created_at',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Bank',
      property: 'bank_name',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Account No',
      property: 'account_number',
      type: 'text',
      visible: true,
    },
    {
      label: 'Swift Code',
      property: 'swift_code',
      type: 'text',
      visible: false,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Beneficiary',
      property: 'account_name',
      type: 'text',
      visible: false,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Amount Sent',
      property: 'amount',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Status',
      property: 'status',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Filter',
      property: 'filter',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
  ];
  BtransactionsDisplayedColumns: string[] = [];
  trasactionColsOrder: string[] = [];
  dataSource: MatTableDataSource<any> | null;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  BtransactionsDataSource: MatTableDataSource<any> | null;
  Bselection = new SelectionModel<any>(true, []);
  BsearchCtrl = new FormControl();
  BsearchCtrlBeneficiary = new FormControl();
  BexportOptions = {
    fileName: 'test',
    sheet: { reportsProps: { Author: 'PAL' } },
  };
  form: FormGroup;
  BstatusLabels = TRANSACTION_TABLE_LABELS;
  banksData: any;
  Bstatuses = [
    { name: 'Pending', value: 1 },
    { name: 'Completed', value: 3 },
    { name: 'Failed', value: 4 },
    { name: 'Network Error', value: 6 },
    { name: 'Processing', value: 2 },
    { name: 'Cancelled', value: 0 },
  ];
  operators = [
    { name: 'MTN', value: 'mtn' },
    { name: 'MOOV', value: 'moov' },
    { name: 'VODAFONE', value: 'vodafone' },
    { name: 'AIRTEL-TIGO', value: 'airtel-tigo' },
  ];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTableExporterDirective)
  BmatTableExporter: MatTableExporterDirective;
  @ViewChild('menuMakeTransfer', { static: true })
  menuMakeTransfer: MatMenu;
  BtransactionsData: any;
  beneficiariesData: any;
  hasNoTransactions: boolean;
  isLoading: boolean = false;
  BmerchantSummaryData: SummaryData;
  hasError = false;
  errorMessage: string;
  Bcustomers: any;
  Busers: any;
  Bproducts: any;
  Btransactions: any;
  isOpenDisbursePopup = false;
  beneficiaryOperationData: {
    message: string;
    selectedBankBeneficiary: any
  } = { message: null, selectedBankBeneficiary: null };

  userData: any;
  credentials: string;
  merchantSummaryData: SummaryData;
  userBusinessData: any;
  browserLanguage: string
  countries = COUNTRIES;

  icPhone = icPhone;
  icMail = icMail;
  icMap = icMap;
  icAttachMoney = icAttachMoney;
  icEdit = icEdit;
  icSearch = icSearch;
  icPerson = icPerson;
  icDelete = icDelete;
  icAdd = icAdd;
  icFilterList = icFilterList;
  icMoreHoriz = icMoreHoriz;
  icFolder = icFolder;
  icDateRange = icDateRange;
  icBook = icBook;
  icRefresh = icRefresh;
  icCloudDownload = icCloudDownload;




  constructor(
    private authService: AuthserviceService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private transactionService: TransactionsService,
    private snackBar: MatSnackBar,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private translate: TranslateService,      
    
  ) {

    

 

    const user = localStorage.getItem('current_user');
    const sessionData = JSON.parse(localStorage.getItem(USER_SESSION_KEY));
    this.userData = sessionData;
    this.credentials = `${this.userData?.api_secret_key_live}:${this.userData?.api_public_key_live}`;
    this.merchantSummaryData = JSON.parse(localStorage.getItem(SUMMARY_DATA_KEY));
    const businessData = localStorage.getItem(BUSINESS_DATA_KEY);
    if (businessData !== 'undefined') {
      this.userBusinessData = JSON.parse(businessData);
    }
    if (!sessionData) {
      router.navigate(['/auth/login']);
    }

 

    // BANK TRANSACTIONS
    this.BtransfersColumns.forEach(
      e => {
        if (e.visible) {
          this.BtransactionsDisplayedColumns.push(e.property);
        }
      }
    )




    // vtx TRADUCTION 
    this.browserLanguage = window.navigator.language;
    let lang = this.browserLanguage.split('-')
    if (lang[0] == 'en') {

    }
    if (lang[0] == 'fr') {
      translate.setDefaultLang(lang[0]);
      this.translate.use(lang[0]);
    }


  }








 ngOnInit() {

    if (this.isAllowed('6') || this.isAllowed('1')) {
 

      // BANK TRANSFERS
      this.form = this.fb.group({
        country: [''],
        dateFrom: [''],
        dateTo: [''],
        currency: [''],
        status: [''],
      }); 
      //this.BgetBeneficiariesList();
      this.BsearchTransfers();
      this.BsearchCtrl.valueChanges
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((value) => this.BonFilterChange(value));
      /*
    this.BsearchCtrlBeneficiary.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => this.BonFilterChangeBeneficiary(value));
      */

     

 
    } else {
      this.openSnackbar("You are not allowed");
    }






  }
  








  

  
  refreshData() {
    this.getTransactionsList();
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  getvisibleColumns() {
    return this.BtransfersColumns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }
  getData() {
    return of(aioTableData.map((customer) => new Customer(customer)));
  }
  get hasExceededFreeTransfers(): boolean {
    return this.merchantSummaryData?.totalTransactionsAmount > 560000;
  }
  exportAsXlsx() {
    if (this.isAllowed("7")) {
      this.BmatTableExporter.exportTable('xlsx', {
        fileName: 'Transactions Report',
        sheet: 'report',
        Props: { Author: 'PAL Africa' },
      });
    } else {
      this.openSnackbar("You are not allowed");
    }

  }
  isAllowed(habl: string) {

    let habilitations = []
    let isAllow = false
    if (this.userData.secondaryAccount) {
      if (this.userData.habilitations != undefined) {
        habilitations = this.userData.habilitations.split(',')
        for (let hab of habilitations) {
          if (hab == habl) {
            isAllow = true
          }
        }
      }
    } else {
      isAllow = true
    }
    return isAllow
  }
  openSnackbar(message) {
    this.snackBar.open(message, 'CLOSE', {
      duration: 3000,
      horizontalPosition: 'right',
    });
  }
  getPalFee(amount, country: string): number {
    if (this.hasExceededFreeTransfers) {
      switch (country) {
        case 'GH':
          return (amount * 0.5) / 100;
        case 'BJ':
          return (amount * 1) / 100;
        default:
          return 0;
      }
    } else {
      return 0;
    }
  }
  resetForm() {
    this.form = this.fb.group({
      country: [''],
      dateFrom: [''],
      dateTo: [''],
      currency: [''],
      operator: [''],
      status: [''],
    });
    this.getTransactionsList();
  }
  getTransactionsList(filteredData = null) {
    this.isLoading = true;
    if (filteredData) {
      const transactions = filteredData;
      this.isLoading = false;

      this.BtransactionsData = transactions?.map((details) => {
        details.state = this.BgetStatusLabel(details.state);
        details.formatedDate = moment(details.created_at).fromNow();
        details.country = this.getCountryName(details.country);
        return details;
      });

      this.hasNoTransactions = transactions.length === 0;
      this.dataSource = new MatTableDataSource(this.BtransactionsData);
      //this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

    }
    else {
      this.transactionService
        .getUserTransactions(this.userData?.user_id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (transactions) => {
            transactions = filteredData ? filteredData : transactions;
            this.isLoading = false;
            this.BtransactionsData = transactions?.map((details) => {
              details.state = this.BgetStatusLabel(details.state);
              details.formatedDate = moment(details.created_at).fromNow();
              details.country = this.getCountryName(details.country);
              return details;
            });

            this.hasNoTransactions = transactions.length === 0;
            this.dataSource = new MatTableDataSource(this.BtransactionsData);
            //this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          },
          (error) => {
            this.isLoading = false;
            //console.error(error.message);
            this.showInternalServerErrorMessage()
          }
        );
    }
  }
  getisFormReady(): boolean {
    return true;
  }
  getCountryName(countryCode: string): string {
    const countryData = this.countries.find(
      (country) => country.code === countryCode
    );
    return countryData.name;
  }
  getSalesRepName(user_id) {
    const salesRep = this.Busers?.find((user) => user.user_id === user_id);
    if (salesRep) {
      return salesRep.full_name;
    } else {
      return 'N/A';
    }
  }
  ngAfterViewInit() {
     
    //BANK TRANSFER
    this.BtransactionsDataSource.paginator = this.paginator;
    this.BtransactionsDataSource.sort = this.sort;

    //this.exchangeDataSource.paginator = this.Exchangepaginator
    //this.exchangeDataSource.sort = this.Exchangesort
  }












 



 






  //BANK TRANSACTIONS
  BresetForm() {
    this.form = this.fb.group({
      country: [''],
      dateFrom: [''],
      dateTo: [''],
      currency: [''],
      status: [''],
    });
    this.BgetTransactionsList();
  }

  BgetTransactionsList(filteredData = null) {
    this.isLoading = true;
    // userId = 'a9twRK1JpPPQDrB6hNvfAr2ju682' this is a test User_uid
    if (filteredData) {
      const transactions = filteredData;
      this.isLoading = false;
      this.BtransactionsData = transactions?.map((details) => {
        details.state = this.BgetStatusLabel(details.state);
        // details.palFee = this.getPalFee(details.amount, details.country);
        details.formatedDate = moment(details.created_at).fromNow();
        // details.country = this.getCountryName(details.country);
        return details;
      });

      this.hasNoTransactions = transactions.length === 0;
      this.BtransactionsDataSource = new MatTableDataSource(this.BtransactionsData);
      this.BtransactionsDataSource.paginator = this.paginator;
      this.BtransactionsDataSource.sort = this.sort;
    } else {
      this.transactionService
        .getUserTransactions(this.userData?.user_id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (transactions) => {
            this.isLoading = false;
            this.BtransactionsData = transactions?.map((details) => {
              details.state = this.BgetStatusLabel(details.state);
              // details.palFee = this.getPalFee(details.amount, details.country);
              details.formatedDate = moment(details.created_at).fromNow();
              details.country = this.BgetCountryName(details.country);
              return details;
            });

            this.hasNoTransactions = transactions.length === 0;
            this.BtransactionsDataSource = new MatTableDataSource(this.BtransactionsData);
            this.BtransactionsDataSource.paginator = this.paginator;
            this.BtransactionsDataSource.sort = this.sort;
          },
          (error) => {
            this.isLoading = false;
            console.error(error.message);
          }
        );
    }
  }

  get BisFormReady(): boolean {
    return true;
  }

  BgetCountryName(countryCode: string): string {
    const countryData = this.countries.find(
      (country) => country.code === countryCode
    );
    return countryData?.name;
  }

  BgetBankName(code: string) {
    const bank = this.banksData.find(
      (data) => data.code === code
    );
    return bank.name || 'N/A';
  }

  BsearchTransfers() {
    if (this.form.value['dateFrom'] && this.form.value['dateTo']) {
      const from = new Date(this.form.value['dateFrom'])
        .toISOString()
        .slice(0, 19)
        .replace('T', ' ');
      const to = new Date(this.form.value['dateTo'])
        .toISOString()
        .slice(0, 19)
        .replace('T', ' ');
      // this.form.get("dateFrom").setValue(from);
      // this.form.get("dateTo").setValue(to);
      this.form.value['dateFrom'] = from;
      this.form.value['dateTo'] = to;
    }

    this.isLoading = true;
    this.transactionService
      .searchTransactions(
        this.credentials,
        this.userBusinessData?.user_id,
        this.form.value,
        'bank_transfers'
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => {
          this.isLoading = false;
          if (response['status'] === true) {
            this.Btransactions = response['data'];
            this.BgetTransactionsList(response['data']);
            this.BtransactionsDataSource = new MatTableDataSource();
            this.BtransactionsDataSource.data = this.Btransactions;
            this.BtransactionsDataSource.paginator = this.paginator;
            this.BtransactionsDataSource.sort = this.sort;
            if (!this.BtransactionsDataSource.data.length) {
              this.hasError = true;
              this.errorMessage =
                'No data Found for the specified search criteria. Please try with different data';
            } else {
              this.hasError = false;
              this.errorMessage = '';
            }
          } else {
            this.hasError = true;
            this.errorMessage =
              'No data Found for the specified search criteria. Please try with different data';
          }
        },
        (erro) => {
          this.isLoading = false;
          this.hasError = true;
          this.errorMessage =
            'No data Found for the specified search criteria. Please try with different data';
        }
      );
  }

  BgetStatusLabel(status: string) {
    return this.BstatusLabels.find((label) => label.text === status);
  }

  BonFilterChange(value: string) {
    if (!this.BtransactionsDataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.BtransactionsDataSource.filter = value;
  }
  /*
  BonFilterChangeBeneficiary(value: string) {
    if (!this.BbeneficiariesDataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.beneficiariesDataSource.filter = value;
  }
  */

  BtoggleColumnVisibilityTrasactions(column, event) {

    if (!column.visible) {
      this.BtransactionsDisplayedColumns = this.BtransactionsDisplayedColumns.filter(col => column.property !== col)
    } else {
      let columns = [column.property, ...this.BtransactionsDisplayedColumns]
      this.BtransactionsDisplayedColumns = this.BtransfersColumns.map(
        ordoredCol => columns.find(col => col == ordoredCol.property)
      ).filter(col => col)
    }
  }
  BgetFlag(country) {
    switch (country.toLowerCase()) {

      case "benin":
        return "<img src='assets/contryFlg/flags/1/bj.png'>"
      case "ghana":
        return "<img src='assets/contryFlg/flags/1/gh.png'>"
      case "togo":
        return "<img src='assets/contryFlg/flags/1/tg.png'>"
      case "nigeria":
        return "<img src='assets/contryFlg/flags/1/ng.png'>"
      case "cote d'ivoire":
        return "<img src='assets/contryFlg/flags/1/ci.png'>"
      case "senegal":
        return "<img src='assets/contryFlg/flags/1/se.png'>"
      case "mali":
        return "<img src='assets/contryFlg/flags/1/ml.png'>"
      case "niger":
        return "<img src='assets/contryFlg/flags/1/ne.png'>"
      case "côte d'ivoire":
        return "<img src='assets/contryFlg/flags/1/ci.png'>"
      case "guinea-bissau":
        return "<img src='assets/contryFlg/flags/1/gw.png'>"
      case "burkina faso":
        return "<img src='assets/contryFlg/flags/1/bf.png'>"
      default:
        return ''

    }

  }


  BdeleteOrder(selected: any[]) {

  }

  BopenDisburseMobilePopup() {
    // this.isOpenDisbursePopup = !this.isOpenDisbursePopup;

    const config: MatDialogConfig = {
      width: '600px'
    };
    const dialogRef = this.dialog.open(DisburseCashComponent, config);
  }

  BopenFilterFormPopup() {
    const config: MatDialogConfig = {
      width: '600px'
    };
    const dialogRef = this.dialog.open(FilterFormComponent, config);
    dialogRef.afterClosed().subscribe(form => {
      this.form = form;
      this.BsearchTransfers();
    })
  }



  

/*
  BdeleteBankBeneficiary(id: number) {
    this.beneficiariesService.deleteBankBeneficiary(this.credentials, { user_id: this.userData?.user_id, id: id })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => {
          if (response.status == true) {
            this.openSnackbar(response.message);
            this.getBeneficiariesList();
          } else {
            if (response.status == false) {
              this.openSnackbar(response.message);
            } else {
              this.showInternalServerErrorMessage()
            }
          }
        },
        (error) => {
          this.isLoading = false;
          this.showInternalServerErrorMessage()
        }
      );
  }*/
  showInternalServerErrorMessage() {
    this.errorMessage = "Error. Please retry again later."
    this.openSnackbar("Error. Please retry again later.");
  }

  get BtransfersVisibleColumns() {
    return this.BtransfersColumns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }

  onCreateTransfer() {

    if (this.isAllowed('6') || this.isAllowed('1')) { 
      if (this.userData.hasBusiness || !!this.userBusinessData) {
        if (this.userBusinessData?.status === 'pending') {
          this.dialog.open(PendingBusinessModalComponent);
        } else if (this.userBusinessData?.status === 'suspended') {
          this.dialog.open(SuspendedBusinessModalComponent);
        } else {
          this.router.navigate(['/dashboards/make-transfer']).then();
        }
        this.isOpenDisbursePopup = false;
      } else {
        this.dialog.open(NoBusinessModalComponent);
      }
    } else {
      this.openSnackbar("You are not allowed");
    }












  }
  get isFormReady(): boolean {
    return true;
  }





  formattedDate(created_at): string {
    return this.datePipe.transform(created_at, 'dd-MM-yyyy HH:mm:ss');
    // Cela renverra la date formatée selon 'jour/mois/année heure:minute:seconde'
  }




















}
