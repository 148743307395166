import { Injectable } from '@angular/core';
import { ScriptLoaderServiceService } from './script-loader-service.service';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VerificationService {
  private connect: any;

  constructor(private scriptLoader: ScriptLoaderServiceService) { }

    

  // WIDGET 2 PROD        66d5bcd38d8dcce37a88b31f

  initializeWidget(app_id: string, p_key: string,user_id:string) {
    return this.scriptLoader.loadScript('dojah-widget', 'https://widget.dojah.io/widget.js')
      .then(() => {
        const options = {
          app_id: app_id,  
          p_key: p_key,  
          type: 'custom',
          user_data: {
            //first_name: first_name, // facultatif
            //last_name:last_name, // facultatif
            //dob: dob, // YYYY-MM-DD Facultatif
            //residence_country: 'NG', // facultatif
            //email: 'email' // facultatif
          },
          metadata: {
            user_id: user_id,
          },
          config: {
            widget_id: '66d5bcd38d8dcce37a88b31f'  //'66a7d829f699880040093452', // '667579ea40de420040820b88' //  
          },
          onSuccess: (response: any) => {
            this.resolvePromise(response);
          },
          onError: (err: any) => {
            this.rejectPromise(err);
          },
          onClose: () => {
            //console.log('Widget closed');
          }
        };

        this.connect = new (window as any).Connect(options);
      })
      .catch(err => {
        console.error('Failed to load Dojah script', err);
      });
  }

  openWidget(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.connect) {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
        this.connect.setup();
        this.connect.open();
      } else {
        reject('Widget not initialized');
      }
    });
  }

  private resolvePromise: (value?: any) => void;
  private rejectPromise: (reason?: any) => void;



}