import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import {
  BUSINESS_DATA_KEY,
  SUMMARY_DATA_KEY,
  USER_SESSION_KEY,
} from 'src/app/Models/constants';
import { SummaryData } from 'src/app/Models/models.interface';
import { Customer } from 'src/app/pages/apps/aio-table/interfaces/customer.model';
import { AuthserviceService } from 'src/app/services/authservice.service';
import { TransactionsService } from 'src/app/services/transactions.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { ExchangeService } from 'src/app/services/exchange.service';
@Component({
  selector: 'vex-swaps',
  templateUrl: './swaps.component.html',
  styleUrls: ['./swaps.component.scss']
})
export class SwapsComponent implements OnInit, OnDestroy, AfterViewInit {
  layoutCtrl = new FormControl('boxed');
  unsubscribe$ = new Subject();






  browserLanguage: string
















  //SWAPS
  //*ngIf="!BisLoading" mat-table [dataSource]="BtransactionsDataSource" 
  isLoadingSwaps: boolean = false
  userExchanges: any
  exchangeDataSource: MatTableDataSource<any> | null;
  /*
  exchangeDisplayedColumns: TableColumn<Customer>[] = [
    { label: 'ID', property: 'reference', type: 'text', visible: true },
    {
      label: 'date',
      property: 'created_at',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Start Currency',
      property: 'start_currency',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'End Currency',
      property: 'end_currency',
      type: 'text',
      visible: true,
    },
    {
      label: 'Amount',
      property: 'amount',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Amount Swap',
      property: 'amount_swap',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    
  ];
  */

  exchangeDisplayedColumns: string[] = [
    'reference',
    'created_at',
    'amount',
    'amount_swap',
  ];
  subject$: ReplaySubject<Customer[]> = new ReplaySubject<Customer[]>(1);
  data$: Observable<Customer[]> = this.subject$.asObservable();
  @Input()
  BtransfersColumns: TableColumn<Customer>[] = [
    { label: 'ID', property: 'reference', type: 'text', visible: true },
    {
      label: 'date',
      property: 'created_at',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Bank',
      property: 'bank_name',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Account No',
      property: 'account_number',
      type: 'text',
      visible: true,
    },
    {
      label: 'Swift Code',
      property: 'swift_code',
      type: 'text',
      visible: false,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Beneficiary',
      property: 'account_name',
      type: 'text',
      visible: false,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Amount Sent',
      property: 'amount',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Status',
      property: 'status',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Filter',
      property: 'filter',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
  ];
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  BtransactionsDataSource: MatTableDataSource<any> | null;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  isLoading: boolean;
  merchantSummaryData: SummaryData;
  userSessionData: any;
  hasError: boolean = false;
  errorMessage: string;
  customers: any;
  users: any;
  products: any;
  transactions: any;
  userBusinessData: any;
  credentials: string;
  countrySelected: any;
  userData: any;








  constructor(
    private authService: AuthserviceService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private transactionService: TransactionsService,
    private snackBar: MatSnackBar,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private translate: TranslateService,


    private exchangeService: ExchangeService
  ) {




    const user = localStorage.getItem('current_user');
    const sessionData = JSON.parse(localStorage.getItem(USER_SESSION_KEY));
    this.userData = sessionData;
    this.credentials = `${this.userData?.api_secret_key_live}:${this.userData?.api_public_key_live}`;
    this.merchantSummaryData = JSON.parse(localStorage.getItem(SUMMARY_DATA_KEY));
    const businessData = localStorage.getItem(BUSINESS_DATA_KEY);
    if (businessData !== 'undefined') {
      this.userBusinessData = JSON.parse(businessData);
    }
    if (!sessionData) {
      router.navigate(['/auth/login']);
    }



    // vtx TRADUCTION 
    this.browserLanguage = window.navigator.language;
    let lang = this.browserLanguage.split('-')
    if (lang[0] == 'en') {

    }
    if (lang[0] == 'fr') {
      translate.setDefaultLang(lang[0]);
      this.translate.use(lang[0]);
    }


  }



  ngOnInit() {

    if (this.isAllowed('6') || this.isAllowed('1')) {

      this.getUserExchange()


    } else {
      this.openSnackbar("You are not allowed");
    }






  }
  ngAfterViewInit(): void {

  }




  refreshData() {
    //this.getTransactionsList();
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

















  getUserExchange() {
    this.isLoading = true
    this.exchangeService.getUserExchanges(this.credentials, this.userData['user_id'])
      .subscribe(
        (response) => {
          if (response['status']) {
            this.userExchanges = response['data'].reverse()
            
            this.exchangeDataSource = new MatTableDataSource(this.userExchanges); 
            this.exchangeDataSource.paginator = this.paginator;
            this.exchangeDataSource.sort = this.sort;
            this.isLoading = false
          }
        },
        (error) => {

        }
      )

  }
  formattedDate(created_at): string {
    return this.datePipe.transform(created_at, 'dd-MM-yyyy HH:mm:ss');
    // Cela renverra la date formatée selon 'jour/mois/année heure:minute:seconde'
  }
  isAllowed(habl: string) {

    let habilitations = []
    let isAllow = false
    if (this.userData.secondaryAccount) {
      if (this.userData.habilitations != undefined) {
        habilitations = this.userData.habilitations.split(',')
        for (let hab of habilitations) {
          if (hab == habl) {
            isAllow = true
          }
        }
      }
    } else {
      isAllow = true
    }
    return isAllow
  }
  openSnackbar(message) {
    this.snackBar.open(message, 'CLOSE', {
      duration: 3000,
      horizontalPosition: 'right',
    });
  }




















}
