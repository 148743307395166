<!-- <vex-curency-balance></vex-curency-balance> -->
<vex-transactions></vex-transactions> 
<!-- <div class="table">
    <table mat-table [dataSource]="dataSource" class="">
        <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef> ID45623 </th>
            <td mat-cell *matCellDef="let element"> {{element.position}} </td>
        </ng-container>

        <!-- Name Column -- >
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Date & Time </th>
            <td mat-cell *matCellDef="let element"> {{element.name}}
            </td>
        </ng-container>
        <!-- Weight Column -- >
        <ng-container matColumnDef="weight">
            <th mat-header-cell *matHeaderCellDef> Country </th>
            <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
        </ng-container>

        <!-- Symbol Column -- >
        <ng-container matColumnDef="symbol">
            <th mat-header-cell *matHeaderCellDef> Network </th>
            <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
        </ng-container>

        <ng-container matColumnDef="Wallet number">
            <th mat-header-cell *matHeaderCellDef> Wallet number </th>
            <td mat-cell *matCellDef="let element"> {{element.walletNumber}} </td>
        </ng-container>

        <ng-container matColumnDef="Pal Fee">
            <th mat-header-cell *matHeaderCellDef> Pal Fee</th>
            <td mat-cell *matCellDef="let element"> {{element.walFee}} </td>
        </ng-container>
        <ng-container matColumnDef="Amount received">
            <th mat-header-cell *matHeaderCellDef> Amount received</th>
            <td mat-cell *matCellDef="let element"> {{element.amountReceived}} </td>
        </ng-container>

        <ng-container matColumnDef="Status">
            <th mat-header-cell *matHeaderCellDef> Status</th>
            <td mat-cell *matCellDef="let element">
                <div _ngcontent-ftu-c226="" fxflex="none"
                    class="px-2 py-1 text-xs font-medium rounded ng-tns-c226-93 text-purple bg-purple-light ng-star-inserted"
                    style="flex: 0 0 auto; box-sizing: border-box; margin-right: 4px;width: 80px;"> Completed </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div> -->