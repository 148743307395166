<!-- (ngSubmit)="save()" -->
<div *ngIf="!isFormReady" class="card overflow-auto -mt-16">
  <div
    style="
      display: flex;
      justify-content: center;
      align-items: center;
      background: white;
      width: max-content;
      height: max-content;
    "
  >
    <mat-spinner color="primary"> </mat-spinner>
  </div>
</div>
<form [formGroup]="transferForm" (ngSubmit)="save()" *ngIf="isFormReady">
  <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
    <h2 class="headline m-0" fxFlex="auto">
      {{ isCreateMode == true ? "ADD DISBURSEMENT" : "UPDATE DISBURSEMENT" }}
    </h2>

    <button
      class="text-secondary"
      mat-dialog-close
      mat-icon-button
      type="button"
    >
      <mat-icon [icIcon]="icClose"></mat-icon>
    </button>
  </div>
  <mat-divider class="-mx-6 text-border"></mat-divider>
  <mat-dialog-content fxLayout="column" class="content-part mx-50">
    <div
      class="grid xs:grid-rows-4 md:grid-rows-2 grid-flow-col gap-4"
    >
     
      <div>
        <div class="grid grid-flow-col gap-4 grid-cols grid-rows">
          <div class="label">
            <label for="" class="label"> Mobile wallet number</label>
          </div>
        </div>
        <mat-form-field appearance="fill">
          <input
            (ngModelChange)="onCheckConfirmNumber()"
            matInput
            formControlName="phone_no"
            #input
            pattern="\d*"
            placeholder="{{ placeHolder }}"
            maxlength="{{ maxLength }}"
            ngDefaultControl
          />
          <mat-error>
            <span
              *ngFor="
                let error of transferForm?.get('phone_no').errors | keyvalue
              "
            >
              {{ validationMessages.phone_no[error.key] }}
            </span>
          </mat-error>
        </mat-form-field>
      </div>


      <div class="mobile-network">
        <div class="grid  grid-flow-col gap-4 grid-cols grid-rows">
          <div class="label">
            <label for="">Select the Mobile network</label>
          </div>
        </div>
        <mat-form-field appearance="fill">
          <mat-select
            (selectionChange)="setSelectedModule($event)"
            formControlName="operator"
            ngDefaultControl
          >
            <mat-option
              *ngFor="let provider of operators"
              [value]="provider.value"
              >{{ provider.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div class="repeate-phone">
        <div class="grid   grid-flow-col gap-4 grid-cols grid-rows">
          <div class="label">
            <label for="" class="label">Repeat Mobile wallet number</label>
          </div>
        </div>
        <mat-form-field appearance="fill">
          <input
          ngDefaultControl
            (ngModelChange)="onCheckConfirmNumber()"
            matInput
            formControlName="repeat_phone_no"
            #input
            type="tel"
            pattern="\d*"
            placeholder="{{ placeHolder }}"
            maxlength="{{ maxLength }}"
          />
          <mat-error>
            <span
              *ngFor="
                let error of transferForm?.get('repeat_phone_no').errors
                  | keyvalue
              "
            >
              {{ validationMessages.repeat_phone_no[error.key] }}
            </span>
          </mat-error>
        </mat-form-field>
        <mat-error>
          <span class="identical" *ngIf="hasPhoneInputError">
            {{ checkPhoneErrorMessage }}
          </span>
        </mat-error>
      </div>
      <div>
        <div class="grid grid-flow-col gap-4 grid-cols grid-rows">
          <div class="label">
            <label for="" class="label">How much are you disbursing?</label>
          </div>
        </div>
        <mat-form-field appearance="outline" class="field">
          <input type="number" ngDefaultControl matInput formControlName="amount" placeholder="" />
          <mat-icon matSuffix>
            <h6>{{ currency }}</h6>
          </mat-icon>
          <mat-error>
            <span
              *ngFor="let error of transferForm?.get('amount').errors | keyvalue"
            >
              {{ validationMessages.amount[error.key] }}
            </span>
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <!-- <div class="grid grid-rows grid-flow-col gap-4">
      <div>
        <div class="grid grid-flow-col gap-4 grid-cols grid-rows">
          <div class="label">
            <label for="" class="label">Purpose</label>
          </div>
        </div>
        <mat-form-field appearance="outline" class="field">
          <input  maxlength="18" ngDefaultControl matInput formControlName="amount" placeholder="" />
          
        </mat-form-field>
      </div>
     
    </div> -->


  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button">CANCEL</button>
    <button
      [disabled]="transferForm?.invalid || hasPhoneInputError"
      *ngIf="isCreateMode"
      color="primary"
      mat-button
      type="submit"
    >
      ADD DISBURSEMENT
    </button>
    <button
      [disabled]="transferForm?.invalid || hasPhoneInputError"
      *ngIf="isUpdateMode"
      color="primary"
      mat-button
      type="submit"
    >
      UPDATE DISBURSEMENT
    </button>
  </mat-dialog-actions>
</form>


